import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Crypto } from '../entidades/tools';
import { HttpClient } from '@angular/common/http';
import { Mensaje } from '../entidades/mensaje';
import { Router } from '@angular/router';
import { MobilPayment } from '../entidades/mobil-payment';

@Injectable({
  providedIn: 'root'
})
export class LocalstorageService {
  private lSPago = new BehaviorSubject([]);
  mobilPayment = this.lSPago.asObservable();

  constructor(
    private http: HttpClient,
    private router: Router,
  ) { }



  getEnvironment(): any {
    return new Crypto().decryptAES(localStorage.getItem('environment'));
  }

  setEnvironment(e: any) {
    localStorage.setItem('environment', new Crypto().encryptAES(e));
  }

  getDevice(): any {
    return new Crypto().decryptAES(localStorage.getItem('device'));
  }

  setDevice(e: any) {
    localStorage.setItem('device', new Crypto().encryptAES(e));
  }

  getIdaSeleccionada(): any {
    return new Crypto().decryptAES(localStorage.getItem('idaSeleccionada'));
  }


  setPasajerosAbordo(pasajeros) {
    localStorage.setItem('pasajerosAbordo', new Crypto().encryptAES(pasajeros));
  }

  getPasajerosAbordo(): any[] {

    var pasajeros: any[] = new Crypto().decryptAES(localStorage.getItem('pasajerosAbordo'));
    
    if (typeof pasajeros === 'undefined') { pasajeros = [] }

    if (!pasajeros) { pasajeros = [] }

    return pasajeros;
  }

  getMobilPayment(): MobilPayment[] {
    return new Crypto().decryptAES(localStorage.getItem('MobilPayment')) as MobilPayment[];
  }

  saveMobilPayment(mobilPayment: MobilPayment[]) {
    localStorage.setItem('MobilPayment', new Crypto().encryptAES(mobilPayment));
  }

  setCupon(cupon) {
    localStorage.setItem('cupon', new Crypto().encryptAES(cupon));
  }

  getCupon(): any {
    return new Crypto().decryptAES(localStorage.getItem('cupon'));
  }


  getOmitirValor() {
    return new Crypto().decryptAES(sessionStorage.getItem('omitirRegistro'));
  }
 

  deleteUsuario() {
    this.router.navigate(['/app']);
    localStorage.clear();
  }

  setOmitirRegistro(valor: boolean) {
    sessionStorage.setItem('omitirRegistro', new Crypto().encryptAES(valor));
  }

 

  setFiltroServicio(filtro: string) {
    localStorage.setItem('FiltroServicio', new Crypto().encryptAES(filtro));
  }

  getFiltroServicio(): string {
    return new Crypto().decryptAES(localStorage.getItem('FiltroServicio'));
  }

  deleteFiltroServicio() {
    localStorage.removeItem('FiltroServicio');
  }

  

  getNotificacionActiva(): Mensaje {
    let noti: Mensaje = new Crypto().decryptAES(localStorage.getItem('notificacion'));

    if (typeof noti === 'undefined') { noti = new Mensaje(); }

    if (!noti) { noti = new Mensaje(); }

    return noti;
  }

  setNotificacionActiva(notificacion: Mensaje) {
    localStorage.setItem('notificacion', new Crypto().encryptAES(notificacion));
  }

  getCodDescuento(): string {
    let noti: string = new Crypto().decryptAES(localStorage.getItem('codDescuento'));

    if (typeof noti === 'undefined') { noti = '' }

    if (!noti) { noti = '' }

    return noti;
  }

  setCodDescuento(codDescuento: string) {
    localStorage.setItem('codDescuento', new Crypto().encryptAES(codDescuento));
  }

  addMobilPayment(mobilPayment: MobilPayment) {
    const copia: MobilPayment[] = this.getMobilPayment();
    const nuevo: MobilPayment[] = [];

    localStorage.removeItem('MobilPayment');

    nuevo.push(mobilPayment);

    if (copia) {
      copia.forEach(c => {
        if (c.type !== mobilPayment.type) {
          nuevo.push(c);
        }
      });
    }

    this.saveMobilPayment(nuevo);
  }
}
