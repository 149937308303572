<section class="confirmacion-wrap">

    <h1>Confirmación de solicitud</h1>

    <div class="confirmacion-element">
        <p>Número de solicitud: <span class="confirmacion-titulo">{{resumenSolicitud.idsol}}</span></p>
    </div>
    <hr>
    <div class="confirmacion-data">
        <h3>Datos</h3>
        <div class="grid">
            <b>Nombre: </b>
            <p>{{resumenSolicitud.nombre}}</p>
            <b>Correo: </b>
            <p>{{resumenSolicitud.correo}}</p>
            <b>Teléfono: </b>
            <p>{{resumenSolicitud.telefono}}</p>
            <b>Ciudad: </b>
            <p>{{resumenSolicitud.ciudad}}, {{resumenSolicitud.estado}}</p>
            <b>Colonia: </b>
            <p>{{resumenSolicitud.colonia}}</p>
            <b>Dirección: </b>
            <p>{{resumenSolicitud.direccion}}</p>
            <b>Referencia: </b>
            <p>{{resumenSolicitud.referencia}}</p>
        </div>
    </div>
    <button class="btnBack" (click)="volverInicio()" id="btnRastreoConfClose">Cerrar</button>
</section>