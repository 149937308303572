<div class="container_herramienta">
    <img src="assets/img/web/delivery.webp" class="img_toAnimate">
    <div class="alinearIzq">
        <h1 class="txt-section">Pagar guias web</h1>
        <h3>Detalle de pago</h3>
        <br>
        <div class="resumen">
            <h2 style="color: #F05A28;">Resumen de la transacción</h2>
            <!-- <p>Folio: <span>5745</span></p>
            <p>Solicitud Número: <span>1410</span></p> -->
            <p>Nombre: <span>{{nameCliente}}</span></p>
            <p>Numero de guias: <span>{{datosTransaccion.numGuias}}</span></p>
            <p>Detalle: <span>{{datosTransaccion.detalle}}</span></p>
        </div>
        <div class="container_metodoPago">
            <div class="metodoPago">
                <span>Tarjeta de crédito o débito</span>
                <div class="cont_img" id="pago_tarjeta"><img class="icon" src="assets/iconos/detalle-pago/icon-tarjeta.png"></div>
            </div>
            <!-- <div class="metodoPago">
                <span>Oxxo Pay</span>
                <div class="cont_img" id="pago_oxxo"><img class="icon" src="assets/iconos/detalle-pago/icon-oxxo.png"></div>
            </div>
            <div class="metodoPago">
                <span>Coppel Pay</span>
                <div class="cont_img" id="pago_coppel"><img class="icon" src="assets/iconos/detalle-pago/icon-coppelPay.png"></div>
            </div>
            <div class="metodoPago">
                <span>Paypal</span>
                <div class="cont_img" id="pago_paypal"><img class="icon" src="assets/iconos/detalle-pago/icon-paypal.png"></div>
            </div> -->


            <div class="container_totales">
                <!-- <p>Costo: <span>$550.00</span></p>
                <p>IVA (16%): <span>$88.00</span></p> -->
                <p>Total: <span> ${{datosTransaccion.costo}} MXN</span></p>
            </div>

            <button id="btncontinuarcompra" class="btn_continuar" (click)="continuarCompra()">Continuar ></button>
        </div>

    </div>
</div>
