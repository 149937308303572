import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LoLlevaService {

  constructor(
    private http: HttpClient
  ) { }

  getCiudades(){
    let url = `${environment.URL_APIPAQ}/tlCiudadesDisponibles`
    return this.http.get(url);
  }

  getColonias(suc){
    let url = `${environment.URL_APIPAQ}/tlObtenerCobertura?suc=${suc}&CP=------`
    return this.http.get(url);
  }

  solicitarServicioLog(suc){
    let url = `${environment.URL_APIPAQ}/tloprlogTufesatelolleva?suc=${suc}&CP=------`
    return this.http.get(url);
  }

  solicitarServicio(suc, origen, destino, tpu, nombre, tel, correo, idser){
    let url = `${environment.URL_APIPAQ}/tloprTlSolicitud?opr=ALT&id=0&idsol=0&suc=${suc}&idcolo=${origen}&idcold=${destino}&tpu=${tpu}&nombre=${nombre}&tel=${tel}&crr=${correo}&idser=${idser}`
    return this.http.get(url);
  }

  getCotizacion(suc,origen, destino, tpu){
    let url = `${environment.URL_APIPAQ}/tloprTlCotizaSolicitud?suc=${suc}&idcolo=${origen}&idcold=${destino}&tpu=${tpu}`
    return this.http.get(url);
  }

  GetConfirmacion(folio){
    let url = `${environment.URL_APIPAQ}/GetConfirmacion?folio=${folio}`
    return this.http.get(url);
  }

}


