export class user {
    // la llave primaria se debe de llamar id o UsuarioId (claseId)
    idUser: number
    txtMsj: string
    user: string;
    name: string;
    correo: string;
    rfc: string;
    tel: string;
}
