import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { RastreoService } from 'src/app/servicios/rastreo.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Rastreo } from 'src/app/entidades/rastreo';
import { MensajeService } from 'src/app/servicios/mensaje.service';
import { MensajePopup } from 'src/app/entidades/MensajePopup';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-rastreo',
  templateUrl: './rastreo.component.html',
  styleUrls: ['./rastreo.component.css']
})
export class RastreoComponent implements OnInit {
  rastreoForm: FormGroup;
  step = 'first';
  packages;
  movements;
  codigo;
  codes = [];

  constructor(
    private rastreoService: RastreoService,
    private router: Router,
    private mensajeService: MensajeService,
    private activatedRoute: ActivatedRoute,
    private location: Location,
  ) { 
    this.activatedRoute.queryParams.subscribe(params => {
      let code = params['codigo'];
      this.codigo = code;
      let codeLs = JSON.parse(sessionStorage.getItem('codes'));
      if(codeLs){
        this.codes = codeLs;
      } 
      else if(code){
        this.codes.push(code);
      }
  });
  }

  ngOnInit(): void {
    this.rastreoForm = new FormGroup({
      rastreo: new FormControl('',[Validators.required])
    });
    
    if(this.codigo) this.track();

    this.rastreoService.paquetes.subscribe(
      estados => 
      {
        this.packages = estados
        if(this.packages.length) {
          this.step = 'second';
        }
      }
    );
  }

  track(){
    let rastreoInp = this.rastreoForm.value.rastreo;
    if(rastreoInp || this.codes){
    window.scrollTo(0,0);
    let cod = '';
    if(rastreoInp){
      this.codes.length > 0 ? cod = rastreoInp + ',' : cod = rastreoInp;
    }
    if(this.codes) {
      this.codes.forEach(function(code, idx, array) {
        if (idx === array.length - 1){ 
          cod += code;
        } else {
          cod += code + ',';
        }
      });
    }
      cod = cod.replace(/[\n\s]/g, ",");
      cod = cod.replace(/,+/g,",");
      this.location.go('/paqueteria/rastreo/captura?codigo='+cod)
      if(sessionStorage.getItem('codes')){
        sessionStorage.removeItem('codes');
      }      
      sessionStorage.setItem('codes', JSON.stringify(this.codes));
      this.rastreoService.track_pack(cod).subscribe(
        data => {
          this.packages = data;
          if(this.packages[0].msgnum === 0){     
            this.rastreoService.save_packs(this.packages);
            this.step = 'second';
          } else {
            this.mensajeService.subject$.next(new MensajePopup(this.packages[0].msgtxt, false, false)); 
          }
        }
      )
    }
  }

  details(id){
    this.movements = this.packages[id];
    this.step = 'third';
    window.scrollTo(0,0);
  }

  backStep(step){
    this.step = step;
    window.scrollTo(0,0);
  }

  verSolicitud(rastreo: Rastreo) {
    this.rastreoService.guardarRastreoSelected(rastreo);
    this.rastreoService.obtenerDomicilios(
      rastreo.Rastreo
    )
    .subscribe(
      colonias => {
        if (!colonias) {
          this.mensajeService.subject$.next(new MensajePopup("NO MANEJAMOS ENTREGA A DOMICILIO EN ESTA CIUDAD", false, false));
        } else if (colonias.length === 0 ) {
          this.mensajeService.subject$.next(new MensajePopup("NO MANEJAMOS ENTREGA A DOMICILIO EN ESTA CIUDAD", false, false));
        } else if (!colonias[0]) {
          this.mensajeService.subject$.next(new MensajePopup("NO MANEJAMOS ENTREGA A DOMICILIO EN ESTA CIUDAD", false, false));
        } else if (colonias[0].msgnum < 0) {
          this.mensajeService.subject$.next(new MensajePopup(colonias[0].msgtxt, false, false));
        } else {
          this.rastreoService.guardarColinias(colonias);
          this.router.navigate(['/paqueteria/rastreo/solicitud']);
          this.onTop();
        }
      }, error => {
        this.mensajeService.subject$.next(new MensajePopup("NO MANEJAMOS ENTREGA A DOMICILIO EN ESTA CIUDAD", false, false));
      }
    );
  }

  onTop() {
    document.body.scrollTop = 0;
  }

  agregarCodigo(){
    if(this.rastreoForm.valid){
      this.codes.push(this.rastreoForm.value.rastreo);
      this.rastreoForm.controls.rastreo.setValue('');
    }
  }

  eliminarCodigo(index){
    this.codes.splice( index, 1 );
  }

}
