<div class="pe-container">
    <a>Presencia en EUA y el noroeste de México</a>
    <div class="pe-input" >

        <input type="text" readonly="readonly" class="sucursal" (click)="openModal(templateOrig)" placeholder="Busca tu punto de envío"
        value={{origenSeleccionado.PobOrg}} >
        <button routerLink="/sucursales"><svg class="material-icons" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                viewBox="0 0 24 24">
                <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" fill="#FFFF" />
                <path d="M0 0h24v24H0z" fill="none" /></svg></button>
    </div>

</div>

<ng-template #templateOrig>
    <div class="modal-header">
        <img width="100%" height="100%" class="origen" src="assets/iconos/loc.png" alt="origen">
        <h4 class="modal-title pull-left">Punto de envío</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()" id="btnPaso1Origen">
            <span class="close" aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <app-seleccion-sucursal [inputTipo]="tipoOrigen" [inputBsModalRef]="modalRef"></app-seleccion-sucursal>
    </div>
</ng-template>
