import { Component, OnInit, ViewChild } from "@angular/core";
import { recolecciondom } from "../../servicios/recoleccion-dom.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-verifica",
  templateUrl: "./verifica.component.html",
  styleUrls: ["./verifica.component.css"],
})
export class VerificaComponent implements OnInit {
  Codigo: String;
  Nombre: String;
  Tipo: String;
  constructor(
    private recolecciondom: recolecciondom,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.Codigo = params.Codigo;
      this.Nombre = params.Nombre;
      this.Tipo = params.Tipo;
    });
    this.verificaremail(this.Codigo);
  }

  verificaremail(Codigo: String) {
    var resp: string;

    this.recolecciondom.getNotifEmail(Codigo).then((data) => {
      resp = data;
      if (this.Tipo === "0") {
        if (resp === "OK") {
          document.getElementById("notif_content").style.display = "block";
        } else {
          document.getElementById("notif_content2").style.display = "block";
        }
      } else {
        if (resp === "OK") {
          document.getElementById("notif_content3").style.display = "block";
        } else {
          document.getElementById("notif_content4").style.display = "block";
        }
      }
    });
  }
}

