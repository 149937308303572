<div class="gw-container">

  <h2>Buscar solicitudes</h2>

  <div class="divbtn-flex">
    <button class="btn-back" (click)="regresar()" type="button">Regresar</button>
  </div>

  <form>
    <div class="doc-div">
      <div class="gw-flex">
        <div class="gw-flex-l">
          <label class="lbl-text">Fecha inicio</label>
          <input type="doc-lbl" class="inp-text " placeholder="&nbsp;" bsDatepicker [bsConfig]="datePickerConfig"
            [bsValue]="fch1" (bsValueChange)="onValueChangefchini($event)" />
        </div>
        <div class="gw-flex-r">
          <label class="lbl-text">Fecha Final</label>
          <input type="doc-lbl" class="inp-text  " placeholder="&nbsp;" bsDatepicker [bsConfig]="datePickerConfig"
            [bsValue]="fch2" [minDate]="minDate" (bsValueChange)="onValueChangefchfin($event)" />
        </div>
      </div>
      <div class="form">

        <button name="Aceptar" class="gw-btn-continuar" type="submit" (click)="obtener()">Obtener</button>
      </div>

    </div>
  </form>
  <!-- menu -->
  <ng-container *ngIf="sol">
    <ng-container *ngIf="sol[0].idmsg == -1; else elseHist">
      <p class="p-validacion">No se encontraron facturas</p>


    </ng-container>
    <ng-template #elseHist>
      <div class="historial-div" *ngFor="let valor = index let i of sol">
        <div class="hist-header">
          <span class="span-titulo">No. Solicitud: {{i.idsol}}</span>
          <button class="button-titulo" (click)="detalleGuia(i)">VER DETALLES >></button>
        </div>
        <div class="hist-flex">
          <div class="hist-w div-datos ">

            <span class="is-Type1">Disponibles</span>
            <span class="is-Type">{{i.disp}}</span>
            <span class="is-Type1">Cantidad</span>
            <span class="is-Type">{{i.cantidad}}</span>
            <ng-container *ngIf=" (i.comentario != '' || i.comentario != null )">
              <span class="is-Type1">Comentario</span>
              <span class="is-Type">{{i.comentario}}</span>
            </ng-container>
          </div>


          <div class="hist-w div-datos ">

            <span class="is-Type1">Paquete</span>
            <span class="is-Type">{{i.tipo}} - {{i.descripcion}}</span>
            <span class="is-Type1">Peso (kg.)</span>
            <span class="is-Type">{{i.peso}}</span>
            <span class="is-Type1">Fecha</span>
            <span class="is-Type">{{i.fchAlt}}</span>

          </div>


          <div class="hist-w div-datos ">

            <span class="is-Type1">Estado</span>
            <span class="is-Type">{{i.estatus}}</span>
            <span class="is-Type1">Dimensiones (Cm.)</span>
            <span class="is-Type">{{i.largo}} Largo, {{i.ancho}} Ancho, {{i.alto}} Alto</span>

          </div>
        </div>

      </div>
    </ng-template>
  </ng-container>

</div>


<ng-template #modal>
  <div>
    <div class="modal-header">
      <h1 class="modal-title pull-left">Solicitud #{{this.detalleguia.idsol}}</h1>


      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
     <ng-container  *ngIf="this.detalleguia.tipopaq != 10" >
      <h5 >Detalle</h5>

      <form   class="guiaDoc-container" [formGroup]="documentacionForm">

        <label class="custom-radio-checkbox ">
          <!-- Input oculto -->
          <input class="custom-radio-checkbox__input" type="checkbox" name="genero" value="hombre"
            formControlName="cobrar">
          <!-- Imagen en sustitucion -->
          <span class="custom-radio-checkbox__show custom-radio-checkbox__show--checkbox"></span>
          <!-- Texto -->
          <span class="custom-radio-checkbox__text">Por cobrar</span>
        </label>

        <label class="custom-radio-checkbox">
          <!-- Input oculto -->
          <input class="custom-radio-checkbox__input" type="checkbox" name="genero" value="hombre"
            formControlName="recoleccion">
          <!-- Imagen en sustitucion -->
          <span class="custom-radio-checkbox__show custom-radio-checkbox__show--checkbox"></span>
          <!-- Texto -->
          <span class="custom-radio-checkbox__text">Recolección</span>
        </label>


        <label class="custom-radio-checkbox">
          <!-- Input oculto -->
          <input class="custom-radio-checkbox__input" type="checkbox" name="genero" value="hombre"
            formControlName="acuse">
          <!-- Imagen en sustitucion -->
          <span class="custom-radio-checkbox__show custom-radio-checkbox__show--checkbox"></span>
          <!-- Texto -->
          <span class="custom-radio-checkbox__text">Acuse recibo</span>
        </label>

      </form>
      <p class= "leyedaSol">Configuración habilitada para esta solicitud </p>

    </ng-container>


        <ng-container *ngIf="this.idguiassol; else idguiafalse">
          <h5 class='tituloGuias'>Guías utilizadas</h5>
          <div class="row" *ngFor="let valor = index let i of guias">
            <div class="col-sm-6">
              <span class="is-Type1">Folio</span>
              <span class="is-Type">{{i.folio}}</span>
            </div>
            <div class="col-sm-6">
              <span class="is-Type1">IdRefr</span>
              <span class="is-Type">{{i.idreft}}</span>
            </div>
            <div class="col-sm-6">
              <span class="is-Type1">Servicio</span>
              <span class="is-Type">{{i.servadom}}</span>
            </div>
            <div class="col-sm-6">
              <span class="is-Type1">Tipo</span>
              <span class="is-Type">{{i.tipo}}</span>
            </div>
            <div class="col-sm-6">
              <span class="is-Type1">Cantidad</span>
              <span class="is-Type">{{i.cantidad}}</span>
            </div>
            <div class="col-sm-6">
              <span class="is-Type1">Peso (Kg.)</span>
              <span class="is-Type">{{i.peso}}</span>
            </div>
            <div class="col-12">
              <span class="is-Type1">Dim. (Cm.)</span>
              <span class="is-Type">{{i.largo}} Largo, {{i.ancho}} Ancho, {{i.alto}} Alto</span>
            </div>

          </div>

        </ng-container>
        <ng-template #idguiafalse>
          <p class= "leyedaSol">No hay guías utilizadas con esta configuración</p>
        </ng-template>

    </div>
  </div>
</ng-template>

<button id="btn-modal" (click)="openModal(modal)" hidden></button>
