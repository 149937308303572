
<div class="container_historial">
    <h1>Historial de compras</h1>
    <div class="info_cliente">
        <p>{{nameCliente}}</p>
        <p>ID Cliente: {{idcliente}}</p>
    </div>
    <div class="search-name" id="search_bar">
        <input class="form_control" type="text" name="search" [(ngModel)]="searchText" autocomplete="on"
           placeholder="Buscar" id="frm_control">
        <img src="assets/iconos/busqueda.png" class="search_icon" id="icon_search">
     </div>
    <div class="container_table">
        <table>
            <thead>
                <tr>
                    <th>Folio</th>
                    <th>Fecha</th>
                    <th>Producto</th>
                    <th>Descripción</th>
                    <th>Detalle</th>
                    <th>Costo</th>
                    <th>Detalle</th>
                </tr>
            </thead>
            <tbody class="tbodyscroll">
                
                <tr title="Visualizar compra" *ngFor="let elemento of guiasAdquiridas  | filter:searchText;">
                    <td>{{elemento.folio}}</td>
                    <td>{{elemento.fecha}}</td>
                    <td>{{elemento.nombreProd}}</td>
                    <td>{{elemento.descripcion}}</td>
                    <td>{{elemento.detalle}}</td>
                    <td>${{elemento.costo}}</td>
                    <td><div class="btn_ver"  [routerLink]="['/confirmacion']" [queryParams]="{ rf: elemento.linkCompra }"  ><a  >Ver</a></div></td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="list_mobile">
        
        <div class="container_tables" *ngFor="let elemento of guiasAdquiridas  | filter:searchText;">
            <a href="/{{elemento.linkCompra}}">
            <table class="table_mobil" >
                <tbody>
                    <tr>
                        <td class="title">Folio</td>
                        <td class="detalle">{{elemento.folio}}</td>
                    </tr>
                    <tr>
                        <td class="title">Fecha</td>
                        <td class="detalle">{{elemento.fecha}}</td>
                    </tr>
                    <tr>
                        <td class="title">Producto</td>
                        <td class="detalle">{{elemento.nombreProd}}</td>
                    </tr>
                    <tr>
                        <td class="title">Descripción</td>
                        <td class="detalle">{{elemento.descripcion}}</td>
                    </tr>
                    <tr>
                        <td class="title">Detalle</td>
                        <td class="detalle">{{elemento.detalle}}</td>
                    </tr>
                    <tr>
                        <td class="title">Costo</td>
                        <td class="detalle">${{elemento.costo}}</td>
                    </tr>
                    <!-- <tr>
                        <td class="title">Detalle</td>
                        <td class="detalle"><a href="{{elemento.linkCompra}}"><div class="btn_ver">Ver</div></a></td>
                    </tr> -->
                </tbody>
            </table>
            </a>
        </div>
      
    </div>
</div>
