import { HttpClient, HttpHeaders } from "@angular/common/http";
import { DomSanitizer } from "@angular/platform-browser";
import { map } from "rxjs/operators";
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormsModule, FormControl, FormBuilder, Validators } from "@angular/forms";
import { CotizacionService } from "../../servicios/cotizacion.service";
import { recolecciondom } from "../../servicios/recoleccion-dom.service";
import { Router } from "@angular/router";
import { Package } from "src/app/entidades/package";
import { ThrowStmt } from "@angular/compiler";
import { HttpErrorResponse } from "@angular/common/http";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { Component, OnInit, TemplateRef, ViewChild, ElementRef, ɵConsole,} from "@angular/core";
import { MensajeService } from "../../servicios/mensaje.service";
import { MensajePopup } from "../../entidades/MensajePopup";
import { user } from "../../entidades/gwuser";
import jwt_decode from "jwt-decode";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-paqueteria-recoleccion",
  templateUrl: "./paqueteria-recoleccion.component.html",
  styleUrls: ["./paqueteria-recoleccion.component.css"],
})
export class PaqueteriaRecoleccionComponent implements OnInit {
  recoleccionForm: FormGroup;

  activeIndex: number = 0;
  clicked: number = 0;
  submitted = false;
  submitted2 = false;
  idfrecAnt: any;

  idestado: number;
  idciudad: number;
  idcolonia: number;
  cadestados: any[];
  cadciudades: any[];
  cadcolonias: any[];
  listdomfrecuente: any[] = [];

  modalRef: BsModalRef;

  estado: string;
  ciudad: string;
  colonia: string;
  nombre: string;
  correo: string;
  telefono: string;
  direccion: string;
  referencia: string;
  tipo: string;
  sol: string;
  rastreo: string;
  push: string;
  idsol: number;

  userGw: user;
  datagw: any;
  token: string;
  tokendatos: any;
  idcliente: number=-1;
  x: any;
  url: string;
  idrecfrec: any;
  isLoguedin: boolean;
  iddiropr: string;

  solidcd: string;
  solidedo: string;
  solidcol: string;

  public tarjetafrecuente: number;

  obj_newFrec = {
    iddirR: "34",
    idcli: "1",
    idedo: "1",
    Estado: "Aguascalientes",
    idcd: "1",
    ciudad: "Aguascalientes",
    idcol: "1",
    Colonia: '"Y" Griega',
    Nombre: "humberto alonso garcia quiroz",
    correo: "humbertogarciaq@gmail.com",
    Telefono: "6441675927",
    direccion: "entre montes apeninos y montes urales 85023",
    ref: "segunda casa",
  };

  obj_seleccFrec = {
    iddirR: "",
    idcli: "",
    idedo: "",
    Estado: "",
    idcd: "",
    ciudad: "",
    idcol: "",
    Colonia: "",
    Nombre: "",
    correo: "",
    Telefono: "",
    direccion: "",
    ref: "",
  };

  constructor(
    private router: Router,
    private recolecciondom: recolecciondom,

    private recolecciondomfrec: recolecciondom,

    private mensajeService: MensajeService,
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
    private http: HttpClient,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    if (localStorage.getItem("login")) {
      if (localStorage.getItem("login") === "true") {
        document.getElementById("newFrecuente").style.display = "inline-block";
        this.isLoguedin = true;
      }
    } else {
      this.isLoguedin = false;
      document.getElementById("cont-form").style.display = "inline-block";
      document.getElementById("doms_frecs").style.display = "none";
    }

    this.recoleccionForm = this.formBuilder.group({
      estado: ["", [Validators.required]],
      ciudad: ["", [Validators.required]],
      colonia: ["", [Validators.required]],
      nombre: ["", [Validators.required]],
      telefono: ["", [Validators.required, Validators.pattern("[0-9]{10}")]],
      correo: [
        "",
        [
          Validators.required,
          Validators.pattern("^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$"),
        ],
      ],
      direccion: ["", [Validators.required]],
      referencia: [],
    });

    this.recolecciondom.estadoR.subscribe((d) => (this.estado = d));
    this.recolecciondom.ciudadR.subscribe((d) => (this.ciudad = d));
    this.recolecciondom.coloniaR.subscribe((d) => (this.colonia = d));

    this.recolecciondom.getestados().subscribe((data) => {
      this.cadestados = data;
    });

    if (this.estado && this.ciudad && this.colonia) {
      this.estadoChanged(this.estado);
      this.ciudadChanged(this.ciudad);
      this.coloniaChanged(this.colonia);

      this.recoleccionForm.controls.estado.setValue(this.estado);
      this.recoleccionForm.controls.ciudad.setValue(this.ciudad);
      this.recoleccionForm.controls.colonia.setValue(this.colonia);
    }

    if (
      sessionStorage.getItem("token") == undefined ||
      sessionStorage.getItem("token") == "" ||
      sessionStorage.getItem("token") == null
    ) {
    } else {
      window.scrollTo(0, 0);
      this.token = sessionStorage.getItem("token");
      this.tokendatos = jwt_decode(this.token);
      this.userGw = this.tokendatos;
      this.idcliente = this.userGw.idUser;
    }

    var parametros: boolean = false;

    this.route.queryParams.subscribe((params) => {
      if (params.cd != undefined && params.edo != undefined) {
        parametros = true;
        this.solidcd = params.cd;
        this.solidedo = params.edo;

        this.estadoChanged(this.solidedo);
        this.ciudadChanged(this.solidcd);
        document.getElementById("cont-form").style.display = "inline-block";

        this.recoleccionForm.setValue({
          estado: [this.solidedo],
          ciudad: [this.solidcd],
          colonia: [""],
          nombre: [""],
          correo: [""],
          telefono: [""],
          direccion: [""],
          referencia: [""],
        });
      }
    });

    this.recolecciondom.getdomiciliosfrecuentes(this.idcliente).then((data) => {
      this.listdomfrecuente = data;

      if (
        this.listdomfrecuente.length != 0 &&
        localStorage.getItem("login") === "true"
      ) {
        if (parametros === false) {
          document.getElementById("cont-form").style.display = "none";
        }
      } else {
        document.getElementById("cont-form").style.display =
          "inline-block !important";
        document.getElementById("doms_frecs").style.display = "none ";
      }
    });
  }

  get a() {
    return this.recoleccionForm.controls;
  }

  solicitar() {
    this.submitted = true;

    if (this.recoleccionForm.valid) {
      this.submitted = false;
      this.solrecdm();
    }
  }

  solicitardomfrec(idfrec) {
    this.submitted = true;

    this.submitted = false;
    this.solrecdmFrec(this.listdomfrecuente[idfrec - 1]);
    const btn = document.getElementById("btn-modal") as HTMLElement;
    btn.click();
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  closeModal() {
    this.modalRef.hide();
  }

  estadoChanged(idedo) {
    this.cadciudades = [""];
    this.recoleccionForm.controls.ciudad.setValue("");
    this.recoleccionForm.controls.colonia.setValue("");
    this.idestado = idedo;
    this.get_ciudades();
  }

  get_ciudades() {
    this.recolecciondom.getciudad(this.idestado).subscribe((data) => {
      this.cadciudades = data;
    });
  }

  ciudadChanged(idcd) {
    this.cadcolonias = [""];
    this.recoleccionForm.controls.colonia.setValue("");
    this.idciudad = idcd;
    // console.log(this.idciudad);
    this.recolecciondom
      .getcolonia(this.idestado, this.idciudad)
      .subscribe((data) => {
        this.cadcolonias = data;
      });
  }

  coloniaChanged(idcol) {
    this.idcolonia = idcol;
  }

  solrecdm() {

    for (let i = 0; i < this.cadestados.length; i++) {
      if (this.recoleccionForm.value.estado == this.cadestados[i].idedo) {
        this.estado = this.cadestados[i].estado;
        break;
      }
    }

    for (let i = 0; i < this.cadciudades.length; i++) {
      if (this.recoleccionForm.value.ciudad == this.cadciudades[i].idcd) {
        this.ciudad = this.cadciudades[i].ciudad;
        break;
      }
    }

    for (let i = 0; i < this.cadcolonias.length; i++) {
      if (this.recoleccionForm.value.colonia == this.cadcolonias[i].idcol) {
        this.colonia = this.cadcolonias[i].colonia;
        break;
      }
    }

    this.nombre = this.recoleccionForm.value.nombre;
    this.correo = this.recoleccionForm.value.correo;
    this.telefono = this.recoleccionForm.value.telefono;
    this.direccion = this.recoleccionForm.value.direccion;
    this.referencia = this.recoleccionForm.value.referencia;

    this.rastreo = "0";
    this.push = "-";
    this.sol = this.recoleccionForm.value.nombre;
    this.tipo = "REC";

    if(sessionStorage.getItem("token")){
    this.token = sessionStorage.getItem("token");
    console.log(this.token);
    this.tokendatos = jwt_decode(this.token);
    this.userGw = this.tokendatos;
    this.idcliente = this.userGw.idUser;
    }
    else{
      this.idcliente=-1;
    }
    console.log(this.idcliente);

    this.recolecciondom
      .soldm(
        this.rastreo,
        this.push,
        this.sol,
        this.telefono,
        this.correo,
        this.idestado,
        this.idciudad,
        this.idcolonia,
        this.direccion,
        this.referencia,
        this.tipo,
        this.idcliente
      )
      .then((data) => {
        this.idsol = data.idsol;
        if (data.msgnum === 1) {
          // La operación fue exitosa, haz clic en el botón
          const btn = document.getElementById("btn-modal") as HTMLElement;
          btn.click();
        } else {
          // La operación no fue exitosa, maneja el error o muestra un mensaje
          console.error('Operación fallida:', data.msgtxt);
        }
      })
      .catch((error: HttpErrorResponse) => {
        this.modalRef.hide();
        if (error.error === "invalid_token") {
          this.mensajeService.subject$.next(
            new MensajePopup("Error, intente más tarde", false, false)
          );
        } else {
          this.mensajeService.subject$.next(
            new MensajePopup("Error, intente más tarde", false, false)
          );
        }
      });
  }

  ///solicitud de domicilio frecuente mediante ficha
  solrecdmFrec(idfrec) {
    this.estado = [idfrec.estado].toString();
    this.ciudad = [idfrec.ciudad].toString();
    this.colonia = [idfrec.colonia].toString();
    this.idestado = +[idfrec.idedo].toString();
    this.idciudad = +[idfrec.idcd].toString();
    this.idcolonia = +[idfrec.idcol].toString();
    this.nombre = [idfrec.Nombre].toString();
    this.correo = [idfrec.correo].toString();
    this.telefono = [idfrec.Telefono].toString();
    this.direccion = [idfrec.direccion].toString();
    this.referencia = [idfrec.ref].toString();

    this.rastreo = "0";
    this.push = "-";
    this.sol = [idfrec.Nombre].toString();
    this.tipo = "REC";

    this.token = sessionStorage.getItem("token");
    this.tokendatos = jwt_decode(this.token);
    this.userGw = this.tokendatos;
    this.idcliente = this.userGw.idUser;

    this.recolecciondom
      .soldm(
        this.rastreo,
        this.push,
        this.sol,
        this.telefono,
        this.correo,
        this.idestado,
        this.idciudad,
        this.idcolonia,
        this.direccion,
        this.referencia,
        this.tipo,
        this.idcliente
      )
      .then((data) => {
        this.idsol = data.idsol;
      })
      .catch((error: HttpErrorResponse) => {
        this.modalRef.hide();
        if (error.error === "invalid_token") {
          this.mensajeService.subject$.next(
            new MensajePopup("Error, intente más tarde", false, false)
          );
        } else {
          this.mensajeService.subject$.next(
            new MensajePopup("Error, intente más tarde", false, false)
          );
        }
      });
  }

  oprDeleteDomFrec() {
    var idcli: number;

    for (let i = 0; i < this.listdomfrecuente.length; i++) {
      if (this.listdomfrecuente[i].iddirR == this.iddiropr) {
        idcli = this.listdomfrecuente[i].idcli;
      }
    }

    // console.log(this.iddiropr);
    // console.log(idcli);
    this.recolecciondomfrec
      .eliminarDireccionesFrecuentes("BJA", this.iddiropr, idcli)
      .then((data) => {
        if (data.nummsg >= 1) {
          this.iddiropr = "0";
          this.toggleModalconfirm();
          this.mensajeService.subject$.next(
            new MensajePopup(data.txtmsg, true, true)
          );
          this.recolecciondom.getdomiciliosfrecuentes(idcli).then((data) => {
            this.listdomfrecuente = data;

            if (this.listdomfrecuente.length === 0) {
              document.getElementById("cont-form").style.display =
                "inline-block";
              document.getElementById("doms_frecs").style.display = "none ";
              // console.log(this.listdomfrecuente);
            }
          });
        }
      })
      .catch((error: HttpErrorResponse) => {
        this.iddiropr = "0";
        this.toggleModalconfirm();
        if (error.error === "invalid_token") {
          this.mensajeService.subject$.next(
            new MensajePopup("Error, intente más tarde", false, false)
          );
        } else {
          this.mensajeService.subject$.next(
            new MensajePopup("Error, intente más tarde", false, false)
          );
        }
      });
  }

  oprNewDomFrec(idfrec, opr) {
    idfrec.idcli = this.idcliente;
    idfrec.idedo = this.recoleccionForm.value.estado;
    idfrec.idcd = this.recoleccionForm.value.ciudad;
    idfrec.idcol = this.recoleccionForm.value.colonia;
    idfrec.Nombre = this.recoleccionForm.value.nombre;
    idfrec.correo = this.recoleccionForm.value.correo;
    idfrec.Telefono = this.recoleccionForm.value.telefono;
    idfrec.direccion = this.recoleccionForm.value.direccion;
    idfrec.ref = this.recoleccionForm.value.referencia;

    this.recolecciondomfrec
      .agregarDireccionesFrecuentes(
        opr,
        idfrec.iddirR,
        idfrec.idcli,
        idfrec.idedo,
        idfrec.idcd,
        idfrec.idcol,
        idfrec.Nombre,
        idfrec.correo,
        idfrec.Telefono,
        idfrec.direccion,
        idfrec.ref
      )
      .then((data) => {
        if (data.txtmsg === "operacion exitosa") {
          document.getElementById("cont-form").style.display = "none";
          document.getElementById("doms_frecs").style.display = "inline-block";
          this.mensajeService.subject$.next(
            new MensajePopup(data.txtmsg, true, true)
          );
          this.recolecciondom
            .getdomiciliosfrecuentes(this.idcliente)
            .then((data) => {
              this.listdomfrecuente = data;
            });
        } else {
          this.mensajeService.subject$.next(
            new MensajePopup(data.txtmsg, false, false)
          );
        }
      })
      .catch((error: HttpErrorResponse) => {
        this.modalRef.hide();
        if (error.error === "invalid_token") {
          this.mensajeService.subject$.next(
            new MensajePopup("Error, intente más tarde", false, false)
          );
        } else {
          this.mensajeService.subject$.next(
            new MensajePopup("Error, intente más tarde", false, false)
          );
        }
      });
  }

  //SELECCIONAR DOMICILIO FRECUENTE
  oprSeleccionarFrec() {
    var idfrec: any;

    this.toggleModalconfirm2();
    for (let i = 0; i < this.listdomfrecuente.length; i++) {
      if (this.listdomfrecuente[i].iddirR == this.iddiropr) {
        idfrec = this.listdomfrecuente[i];
      }
    }

    this.estadoChanged(idfrec.idedo);
    this.ciudadChanged(idfrec.idcd);
    this.coloniaChanged(idfrec.idcol);

    this.recoleccionForm.setValue({
      estado: [idfrec.idedo],
      ciudad: [idfrec.idcd],
      colonia: [idfrec.idcol],
      nombre: [idfrec.Nombre],
      correo: [idfrec.correo],
      telefono: [idfrec.Telefono],
      direccion: [idfrec.direccion],
      referencia: [idfrec.ref],
    });
  }
  // TERMINA SELECCIONAR DOMICILIO FRECUENTE

  limpiarcampos() {
    this.recoleccionForm.controls.estado.setValue("");
    this.recoleccionForm.controls.ciudad.setValue("");
    this.recoleccionForm.controls.colonia.setValue("");
    this.recoleccionForm.controls.nombre.setValue("");
    this.recoleccionForm.controls.correo.setValue("");
    this.recoleccionForm.controls.telefono.setValue("");
    this.recoleccionForm.controls.direccion.setValue("");
    this.recoleccionForm.controls.referencia.setValue("");
    this.closeModal();
  }
  limpiarcamposfrec() {
    this.recoleccionForm.controls.estado.setValue("");
    this.recoleccionForm.controls.ciudad.setValue("");
    this.recoleccionForm.controls.colonia.setValue("");
    this.recoleccionForm.controls.nombre.setValue("");
    this.recoleccionForm.controls.correo.setValue("");
    this.recoleccionForm.controls.telefono.setValue("");
    this.recoleccionForm.controls.direccion.setValue("");
    this.recoleccionForm.controls.referencia.setValue("");
  }

  toggleMostrar(i: number, x: number) {
    if (this.activeIndex == i && this.clicked == x) {
      this.activeIndex = -1;
      this.clicked = -1;
    } else {
      this.activeIndex = i;
      this.clicked = x;
    }
  }

  mostrarInfoFicha() {
    var x = document.getElementById("show_hide_info");
    if (x.style.height === "175px") {
      x.style.height = "0px";
    } else {
      x.style.height = "175px";
    }
  }

  mostrarFormulario(el) {
    document.getElementById("cont-form").style.display = "inline-block";
    document.getElementById("nuevoFrecuente").style.display = "none";
    document.getElementById("nuevoFrecuente2").style.display = "block";
  }

  mostrarbtn_frec(el) {
    document.getElementById("nuevoFrecuente").style.display = "inline-block";
    document.getElementById("nuevoFrecuente2").style.display = "none";
    document.getElementById("cont-form").style.display = "none";
  }

  // Para eliminacion
  toggleModalconfirm() {
    var mdl = document.getElementById("modal-confirm");
    if (mdl.style.display === "block") {
      document.getElementById("modal-confirm").style.display = "none";
      mdl.style.opacity = "0";
      mdl.style.transition = "all 0.3s ease-out;";
    } else {
      mdl.style.opacity = "1";
      mdl.style.transition = "all 0.4s ease-in;";
      document.getElementById("modal-confirm").style.display = "block";
    }
  }

  // Para seleccion
  toggleModalconfirm2() {
    var mdl = document.getElementById("modal-confirm2");
    if (mdl.style.display === "block") {
      document.getElementById("modal-confirm2").style.display = "none";
      mdl.style.opacity = "0";
      mdl.style.transition = "all 0.3s ease-out;";
    } else {
      mdl.style.opacity = "1";
      mdl.style.transition = "all 0.4s ease-in;";
      document.getElementById("modal-confirm2").style.display = "block";
    }
  }

  //para solicitar
  toggleModalconfirm3() {
    var mdl = document.getElementById("modal-confirm3");
    if (mdl.style.display === "block") {
      document.getElementById("modal-confirm3").style.display = "none";
      mdl.style.opacity = "0";
      mdl.style.transition = "all 0.3s ease-out;";
    } else {
      mdl.style.opacity = "1";
      mdl.style.transition = "all 0.4s ease-in;";
      document.getElementById("modal-confirm3").style.display = "block";
    }
  }

  seleccionariddiropr(iddiR) {
    this.iddiropr = iddiR;
    this.toggleModalconfirm();
    // console.log(iddiR);
  }

  seleccionariddiropr2(iddiR) {
    this.iddiropr = iddiR;
    this.toggleModalconfirm2();
  }

  seleccionariddiropr3(iddiR) {
    this.iddiropr = iddiR;
    this.toggleModalconfirm3();
  }

  evaluarFrecuentes() {
    if (this.listdomfrecuente.length != 0) {
      document.getElementById("doms_frecs").style.width = "45%";
    } else {
      document.getElementById("doms_frecs").style.width = "0px !important";
      document.getElementById("cont-form").style.display = "inline-block";
    }
  }

  recargar() {
    //window.location.href = window.location.href;
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/refresh', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  cargarfor(idfrec: string) {
    for (let i = 0; i < this.listdomfrecuente.length; i++) {
      if (this.listdomfrecuente[i].iddirR == this.iddiropr) {
        idfrec = this.listdomfrecuente[i];
      }
    }
  }
}
