import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contrato-adhesion',
  templateUrl: './contrato-adhesion.component.html',
  styleUrls: ['./contrato-adhesion.component.css']
})
export class ContratoAdhesionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
