import { Component, OnInit } from '@angular/core';
import { DetalleTransaccionComponent } from '../detalle-transaccion/detalle-transaccion.component';
import { Router } from "@angular/router";
import { user } from "../../entidades/gwuser";
import jwt_decode from "jwt-decode";
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-confirmacion',
  templateUrl: './confirmacion.component.html',
  styleUrls: ['./confirmacion.component.css']
})
export class ConfirmacionComponent implements OnInit {
  isLoguedin: boolean;
  costoTotal: string;
  
  userGw: user;
  datagw: any;
  token: string;
  tokendatos: any;
  idcliente: number=-1;
  nameCliente: any;
  
  referencia: any;

  datosTransaccion = 
    {
      // id: 0,
      // numGuias: 0,
      // costo: 0,
      // detalle: "",
      // beneficios: []
      id: 0,
      numGuias: 0,
      numAutorizacion: "",
      costo: "",
      detalle: "",
      beneficios: [""]
    };
  constructor(private router: Router, private activateRoute: ActivatedRoute) {

    this.activateRoute.queryParams.subscribe(params => {
      try{
        this.referencia = params['rf'];
        this.datosTransaccion = JSON.parse(atob(this.referencia));
      }
      catch(e){
        this.router.navigate(['**']);
      }
    });
   
   }

  ngOnInit(): void {
    this.costoTotal = localStorage.getItem("totalGuia");

    if(sessionStorage.getItem("token")){
      this.token = sessionStorage.getItem("token");
      this.tokendatos = jwt_decode(this.token);
      this.userGw = this.tokendatos;
      this.idcliente = this.userGw.idUser;
      this.nameCliente = this.userGw.name;
      // console.log(this.nameCliente);
    }
  }

}
