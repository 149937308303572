
<div class="verifica-container">
    <div class="notif_content" id="notif_content">
        <br><br>
        <h1 id="txt_conf">Verificación exitosa</h1>
        <img src="assets\img\notificacion\confirm_icon.png" class="img_notif">
        <br><br>
        <p id="txt_content">¡Buenas noticias <b>{{Nombre}}</b>, has confirmado tu email con exito!
            <br><br>
           Ahora podrás recibir notificaciones vía correo electrónico a cerca de motivos relacionados con tu cuenta.
        </p>
    </div>

    <div class="notif_content2" id="notif_content2">
        <br><br>
        <h1 id="txt_conf">Verificación Fallida</h1>
        <img src="assets\img\notificacion\error_icon.png" class="img_notif">
        <br><br>
        <p id="txt_content">Malas noticias <b>{{Nombre}}</b>, no hemos podido confirmado tu email,
            <br><br>
           Contactate a la sucursal mas cercana para verificar tus datos.
        </p>
    </div>

    <div class="notif_content" id="notif_content3">
        <br><br>
        <h1 id="txt_conf">Verificación exitosa</h1>
        <img src="assets\img\notificacion\confirm_icon.png" class="img_notif">
        <br><br>
        <p id="txt_content">¡Buenas noticias <b>{{Nombre}}</b>, has confirmado tu número telefónico con éxito!
            <br><br>
           Ahora podrás recibir notificaciones vía Whatsapp a cerca de motivos relacionados con tu cuenta.
        </p>
    </div>

    <div class="notif_content2" id="notif_content4">
        <br><br>
        <h1 id="txt_conf">Verificación Fallida</h1>
        <img src="assets\img\notificacion\error_icon.png" class="img_notif">
        <br><br>
        <p id="txt_content">Malas noticias <b>{{Nombre}}</b>, no hemos podido confirmado tu número telefónico,
            <br><br>
           Contactate a la sucursal mas cercana para verificar tus datos.
        </p>
    </div>
</div>

