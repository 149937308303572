import { Component, OnInit, ViewChild, ElementRef, Renderer2} from '@angular/core';
import { Router } from '@angular/router';
import { MensajeService } from 'src/app/servicios/mensaje.service';
import { MensajePopup } from 'src/app/entidades/MensajePopup';
import { MisformatosService } from 'src/app/servicios/misformatos.service';
import { GuiaswebServiceService } from '../../servicios/guiasweb-service.service';
import { Location } from '@angular/common';
import  Swal  from 'sweetalert2';

@Component({
  selector: 'app-misformatos',
  templateUrl: './misformatos.component.html',
  styleUrls: ['./misformatos.component.css']
})
export class MisformatosComponent implements OnInit {
  @ViewChild('contenedorUsuarios') contenedorUsuarios: ElementRef;
  public misFormatos: any[] = [];
  public valorBuscar: string = '';
  public resultadosBusqueda: any[] = [];

  mostrarModal: boolean = false;
  valorIngresado: string = '';
  mostrarAviso: boolean = false;
  idrspModal: string = '';
  usuariosFiltrados = [];
  usuarioSeleccionado: any = null;

  datosusr: any
  cadena: string;
  idUser: any;

  usuarios: any
  // usuarioSelected: any
  usuarioSelected: any = null;
  searchTerm: string = '';
  isOpen: boolean = false;

  constructor(
    private router: Router,
    private mensajeService: MensajeService,
    private misformatosService: MisformatosService,
    private GuiaswebServiceService: GuiaswebServiceService,
    private location: Location,
    private renderer: Renderer2
  ) {

   }

  ngOnInit(): void {
    this.VerificarToken();
    this.obtenerUsuario();
    this.cargarMisFormatos();
    this.cargarUsuarios();
  }

  obtenerUsuario(): void {
    this.GuiaswebServiceService.usrLgn.subscribe(
      async datos => {
        this.datosusr = datos;

        var bandera
        bandera = Object.keys(this.datosusr).length === 0;


        if (!bandera) {
          if (this.datosusr.name.length > 8) {
              // this.cadena = this.datosusr.name.substr(0, 8).trim();
              this.cadena = this.datosusr.name;
              this.idUser = this.datosusr.idUser
              // this.cadena = this.cadena + "..."
              await this.cadena;
          }
          else {
            this.cadena = this.datosusr.name;
          }
        }
      }
    );
  }

  VerificarToken() {
    if (sessionStorage.getItem('token') == undefined || sessionStorage.getItem('token') == "" || sessionStorage.getItem('token') == null) {
      this.mensajeService.subject$.next(new MensajePopup("Su sesión ha expirado", false, false));
      this.router.navigate(['/herramientasDigitales/login']);
      return;
    }
  }

  cargarMisFormatos(): void{
    this.misformatosService.cargaRspformatodigital(this.idUser).subscribe((data: any) =>{
        this.misFormatos = data
        // console.log(this.misFormatos);

    }, error => {
        console.log(error);
    });
  }

  crearFormato(): void{
    this.router.navigate(['/crear-mis-documentos']);
  }

  verFormato(id: string): void{

    let datosCodigo = {
      opr: "CCO",
      codigo: "1",
      usr: this.idUser
    }


     this.misformatosService.generarCodigoAcceso(datosCodigo).subscribe((response: any) => {

        if (response && parseInt(response.nummsg) > 0) {
             this.router.navigate([`/documentos/${id}`]);
         }

      }, error => {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "ERROR",
          text: error,
        });
      })



  }

  buscarUsuario(): void {
    // Filtrar usuarios basados en el valor de búsqueda
    this.usuariosFiltrados = this.usuarios.filter(usuario =>
      usuario.nombre.toLowerCase().includes(this.usuarioSeleccionado.toLowerCase())
    );
  }

  cargarUsuarios(): void {
    this.misformatosService.buscarUsuarios().subscribe((data: any) => {
        this.usuarios = data
        // console.log(this.usuarios);

    }, error => {
      console.log(error);

    })
  }
  imprimirValorBuscar(): void {
    if (!this.valorBuscar.trim()) {
      // console.log("Datos a buscar vacíos");
    } else {
      this.misformatosService.buscarRspformatodigital(this.valorBuscar).subscribe((data: any) => {
        // console.log(data);

      })
    }
  }

  buscarEnFormatos(): void {
    if (!this.valorBuscar.trim()) {
      // console.log("Ingrese un valor para buscar.");
      return;
    }

    this.resultadosBusqueda = this.misFormatos.filter((formato: any) =>
      formato.formato.toLowerCase().includes(this.valorBuscar.toLowerCase())
    );

    if (this.resultadosBusqueda.length === 0) {
      // console.log("No se encontraron coincidencias.");
    } else {
      // console.log("Resultados de la búsqueda:", this.resultadosBusqueda);
    }
  }

  mostrarDialogo(idrsp: string) {
    this.renderer.addClass(document.body, 'modal-open');
    this.idrspModal = idrsp
    this.mostrarModal = true;
    this.mostrarAviso = false; // Reiniciar el estado del aviso al abrir el modal
  }

  cerrarDialogo() {
    this.renderer.removeClass(document.body, 'modal-open');
    this.mostrarModal = false;
    this.valorIngresado = ''; // Limpiar el valor ingresado al cerrar el diálogo
    this.mostrarAviso = false; // Reiniciar el estado del aviso al cerrar el modal
    this.cargarMisFormatos();
    this.usuarioSelected = "";

     this.usuarioSeleccionado = ''; // Limpiar el valor del input
    //  this.contenedorUsuarios.nativeElement.innerHTML = '';
  }

  guardarValor() {

    let nuevoUsuario = {
        opr: 'ASIGNAR',
        idrsp: this.idrspModal,
        usr: this.usuarioSelected
      }

      if(this.usuarioSelected === "" || this.usuarioSelected === undefined || this.usuarioSelected === null ){
        Swal.fire({
          title: "¡ERROR!",
          text: "Debe seleccionar a un usuario para poder compartir un formato.",
          icon: "error",
          confirmButtonText: "CERRAR",
         });
       return
      }

      // console.log(this.usuarioSelected);

      this.misformatosService.actualizarUsuarioFormatoDigital(nuevoUsuario).subscribe((response: any) => {
        // console.log("esta es la respuesta"+ response);
        if (response && parseInt(response.nummsg) > 0) {
            Swal.fire({
              // position: "top-end",
              icon: "success",
              text: "Formato compartido exitosamente con el usuario" + this.usuarioSeleccionado,
              // imageUrl: "assets/iconos/happyface.gif",
              imageWidth: 100,
              imageHeight: 100,
              showConfirmButton: false,
              timer: 2500
            });
         }else{
           Swal.fire({
             // position: "top-end",
             icon: "error",
             text: "¡Error al compartir formato!",
            //  imageUrl: "assets/iconos/happyface.gif",
             imageWidth: 100,
             imageHeight: 100,
             showConfirmButton: false,
             timer: 2500
           });
         }

      }, error => {
        console.log(error);
      })

    this.cerrarDialogo(); // Cerrar el modal solo si se ingresó un valor
    this.usuarioSelected = ""

  }
  get filteredUsuarios() {
    return this.usuarios.filter(opcion =>
      opcion.nombre.toLowerCase().includes(this.searchTerm.toLowerCase())
    );
  }

  toggleSelect() {
    this.isOpen = !this.isOpen;
  }

  seleccionarUsuario(usuario: any) {
    this.usuarioSelected = usuario;
    this.isOpen = false;
  }

  selectusershare(usuario: any): void {
    this.usuarioSeleccionado = usuario.nombre;
    this.usuarioSelected = usuario.idUsr;
    // También puedes asignar el nombre del usuario al input directamente si es necesario
    // this.nombreInput = usuario.nombre;
  }

  copiarLink(): void{
    let rutaRaiz = window.location.origin;

    rutaRaiz  += `/documentos/${this.idrspModal}`
    // console.log('Ruta raíz:', rutaRaiz);

    navigator.clipboard.writeText(rutaRaiz)
    .then(() => {
      // Éxito al copiar al portapapeles
      this.mostrarAviso = true;
      setTimeout(() => {
        this.mostrarAviso = false;
      }, 3000);
      // console.log('¡Ruta copiada al portapapeles!');
    })
    .catch(err => {
      // Manejo de errores al copiar al portapapeles
      console.error('Error al copiar:', err);
    });

  }

}
