<div class="back_container">
    <img src="assets/img/web/descarga-app.webp" class="img_toAnimate">
    <div class="aboutus-container">
        <div class="services-container" style="display: none;">
            <div class="item" inViewport [inViewportOptions]="{ threshold: [0] }"
                (inViewportAction)="onIntersection($event)">
                <img width="100%" height="100%" class="lazyload" data-src="assets/img/aboutus/servicios.jpg"
                    alt="ImgMan">
            </div>

            <div class="text-container is-left">
                <h3 inViewport [inViewportOptions]="{ threshold: [0] }"
                    (inViewportAction)="onIntersectionText2($event)">SERVICIOS</h3>
                <p inViewport [inViewportOptions]="{ threshold: [0] }" (inViewportAction)="onIntersectionText2($event)">
                    Conoce más acerca que lo TufesaPack te puede brindar.</p>
                <button routerLink="/servicios" inViewport [inViewportOptions]="{ threshold: [0] }"
                    (inViewportAction)="onIntersectionBtn($event)">Ver más</button>
            </div>
        </div>
        <div class="im-container">
            <div class="im-info1">
                <h1 inViewport [inViewportOptions]="{ threshold: [0] }"
                    (inViewportAction)="onIntersectionText2($event)">Descarga nuestra App</h1>
                <p class="ptext" inViewport [inViewportOptions]="{ threshold: [0] }"
                    (inViewportAction)="onIntersectionText2($event)">TufesaPack se encarga de la logística de tu negocio
                    para que tú no tengas que preocuparte</p>
                <div class="im-info2">
                    <a inViewport [inViewportOptions]="{ threshold: [0] }"
                        (inViewportAction)="onIntersectionBtn($event)"
                        href="https://play.google.com/store/apps/details?id=com.envios.tufesapack" target="_blank">
                        <img width="100%" height="100%" class="lazyload" data-src="assets/iconos/googleplay.webp"
                            alt="android">
                    </a>
                    <a inViewport [inViewportOptions]="{ threshold: [0] }"
                        (inViewportAction)="onIntersectionBtn($event)"
                        href="https://apps.apple.com/mx/app/tufesapack/id1658629506" target="_blank">
                        <img width="100%" height="100%" class="lazyload" data-src="assets/iconos/appstore.webp"
                            alt="ios">
                    </a>

                </div>

            </div>
            <img id="img_deskop" width="100%" height="100%" inViewport [inViewportOptions]="{ threshold: [0] }"
                (inViewportAction)="onIntersection($event)" class="im-img lazyload"
                data-src="assets/img/aboutus/celular.webp" alt="celular">

            <img id="img_mobil" width="100%" height="100%" inViewport [inViewportOptions]="{ threshold: [0] }"
                (inViewportAction)="onIntersection($event)" class="im-img lazyload"
                data-src="assets/img/aboutus/celular_mobil.webp" alt="celular">

        </div>
    </div>
</div>
