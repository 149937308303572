import { Component, OnInit } from '@angular/core';
import { OwlModule } from "ngx-owl-carousel";
declare var $:any; 

@Component({
  selector: 'app-nuestras-marcas',
  templateUrl: './nuestras-marcas.component.html',
  styleUrls: ['./nuestras-marcas.component.css']
})
export class NuestrasMarcasComponent implements OnInit {
  
  constructor() {
    
   }

  ngOnInit(): void {

   this.ejecutarOwl();

  }

  ejecutarOwl(){
    var owl = $('.owl-carousel');
    owl.owlCarousel({
       
        loop: true,
        margin: 0,
        autoplay: true,
        autoplayTimeout: 2500,
        autoplayHoverPause:true,
        responsive:{
          0:{
              items:1,
              nav:false
          },
          600:{
              items:2,
              nav:false
          },
          1000:{
              items:3,
              nav:false,
              dots: true,
             
          }
      }
    });
    $('.play').on('click',function(){
        owl.trigger('play.owl.autoplay',[2000])
    })
    $('.stop').on('click',function(){
        owl.trigger('stop.owl.autoplay')
    });
  }
}



