<div class="gwlogin-container" [formGroup]="passForm">
  <div class="left">
    <h1>nueva contraseña</h1>
    <p>Solicitaremos esta contraseña cada vez que inicies sesión.</p>

    <div class="form-div">
      <div class="text mostrarpass" [ngClass]="{
          validacion:
            (f.contrasena.invalid || f.contrasena.hasError('IsEmpty')) &&
            submitted
        }">
        <label>Nueva contraseña</label>
        <input type="password" autocomplete="new-password" id="usr" placeholder="Correo o teléfono" type={{tipopass}}
          (keyup)="cambiarFocus($event, password)" formControlName="contrasena" />
        <img class="lazyload imgmenu" (click)="mostrarpassword()" data-src={{passimg}} alt="Promociones" />
      </div>

      <div class="val-valido" [ngClass]="{
          invalido:
            f.contrasena.invalid && submitted && f.contrasena.value !== ''
        }">
        * Las contraseñas debe contener una mayúsculas, un dígitos y un mínimo
        de 8 caracteres
      </div>
      <div class="val-valido" [ngClass]="{ invalido: f.contrasena.value == '' && submitted }">
        * Introduce la contraseña
      </div>
    </div>
    <div class="form-div">
      <div class="text" [ngClass]="{
        validacion:
          (f.contrasena2.invalid || f.contrasena2.hasError('IsEmpty')) &&
          submitted
      }">

        <label>confirma la Nueva contraseña</label>
        <input type="password" name="contrasena2" id="usr" placeholder="Correo o teléfono" #password
          (keyup)="cambiarFocus($event, btnEntrar)" formControlName="contrasena2" />
      </div>

      <div class="val-valido" [ngClass]="{
          invalido:
            f.contrasena2.invalid && submitted && f.contrasena2.value != ''
        }">
        * La contraseña no coinciden. Inténtalo de nuevo
      </div>
      <div class="val-valido" [ngClass]="{ invalido: f.contrasena2.value == '' && submitted }">
        * Confirma tu contraseña
      </div>
    </div>

    <button (click)="verificarpassword($event)" #btnEntrar id="entrar">
      Confirmar
    </button>
  </div>
</div>
