<div class="gwlogin-container" [formGroup]="codigoForm">
      <div class="left" *ngIf="verificado">
          <h1>Verifica que eres tú</h1>
          <p *ngIf="verificado.NumMsj == 2">Por seguridad, necesitamos verificar tu identidad. Hemos enviado un código a tu correo electronico {{verificado.cuenta}}</p>
          <p *ngIf="verificado.NumMsj == 1">Por seguridad, necesitamos verificar tu identidad. Hemos enviado un código a número de télefono movil {{verificado.cuenta}}</p>

          <div class="form-div">
          <div class="text" [ngClass]="{validacion: (f.codigo.invalid || f.codigo.hasError('IsEmpty')) && submitted}">
              <label>Código</label>
              <input type="text" id="usr" placeholder="Código" (keyup)="cambiarFocus($event, btnEntrar)" formControlName="codigo">
          </div>
          <div class="val-valido"
          [ngClass]="{invalido: f.codigo.invalid && submitted}">* Introduce el código de seguridad</div>
       </div>
          <button (click)="verficarcodigo($event)" #btnEntrar id="entrar">Continuar</button>
          <p class="gw-textSecond"><span class="gw-textSecond gw-span" (click)="reenviarcodigo()">Reenviar código</span></p>
      </div>
  </div>
