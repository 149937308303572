<div class="container_full">
    <img class="img_tufcorp2" src="assets/img/tufesa_corp.webp">
    <div class="container_left">
        <p>
            Nosotros somos tufesa “la marca que mueve”.
        </p>
        <br>
        <p>
            Fundada en 1993 como una empresa de autotransporte federal de pasajeros, desde entonces hemos estado
            expandiendo nuestra
            oferta incorporando nuevos servicios con tecnología de punta, con
            un solo común denominador: la movilidad de forma puntual, segura
            y cómoda. Además de redefinir la movilidad de pasajeros,
            incorporamos marcas que mueven: paquetería y refaccionaria.
        </p>
        <br>
        <p>
            <b>Misión</b>
            <br>
            Trasladar a personas y cosas.
        </p>
        <p>
            <b>Propósito</b>
            <br>
            Queremos hacer de la movilidad de personas y cosas una experiencia segura, puntual y exitosa
        </p>
        <p>
            <b>Nuestra promesa</b>
            <br>
            Inspirar a nuestro entorno a adoptar la tecnología y la
            responsabilidad social para que la experiencia de movilidad sea
            completamente exitosa.
        </p>
        <p>
            <b>Visión</b>
            <br>
            Ser la empresa de movilidad que satisfaga la necesidad de
            transportación de personas y cosas en méxico y usa, bienestar a
            nuestro entorno, poniendo como prioridad a nuestros
            colaboradores, para lograr la rentabilidad a nuestros socios
            accionistas y proveedores, y así contribuir a construir una mejor
            sociedad.
        </p>
        <p>
            <b>Valores</b>
            <br>
            Respetamos la individualidad de cada uno de nuestros
            colaboradores. Todos somos iguales.
            <br><br>
            Somos responsables y asumimos los efectos de cada una de
            nuestras acciones.
            <br><br>
            Somos honestos y hacemos lo correcto.
            <br><br>
            Practicamos la congruencia, es decir, que lo que pensamos,
            sentimos y hacemos está en sincronía.
            <br><br>
            Inspiramos a la excelencia.
            <br><br>
            Movemos con puntualidad.
        </p>
    </div>

    <div class="container_right">
        <img class="img_tufcorp" src="assets/img/tufesa_corp.webp">
    </div>
</div>
