import { Component, OnInit, Input } from '@angular/core';
import { GuiaswebServiceService } from "../../servicios/guiasweb-service.service";
import { Router } from "@angular/router";
import { PlatformLocation } from "@angular/common";

@Component({
  selector: "app-gw-menu-confirmacion",
  templateUrl: "./gw-menu-confirmacion.component.html",
  styleUrls: ["./gw-menu-confirmacion.component.css"],
})
export class GwMenuConfirmacionComponent implements OnInit {
  Remitente: boolean = false;
  destinatario: boolean = false;
  tipo: boolean = false;
  detalle: boolean = false;
  confirmacion: boolean = false;
  numeropantalla: number;
  
  constructor(
    private GuiaswebServiceService: GuiaswebServiceService,
    private router: Router,
    location: PlatformLocation
  ) {
    window.addEventListener("popstate", function (event) {});
  }

  async ngOnInit() {
    this.Remitente = true;
    this.GuiaswebServiceService.numP.subscribe((datos) => {
      this.numeropantalla = datos;
      this.cambiarpaso(this.numeropantalla);
    });
  }

  cambiardepantalla(num) {
    switch (num) {
      case 2: {
        if (this.Remitente == true) {
          this.GuiaswebServiceService.setplantilla(2);
        }
        break;
      }
      case 3: {
        if (this.destinatario == true) {
          this.GuiaswebServiceService.setplantilla(3);
        }
        break;
      }
      case 4: {
        if (this.tipo == true) {
          this.GuiaswebServiceService.setplantilla(4);
        }
        break;
      }
      case 5: {
        if (this.detalle == true) {
          this.GuiaswebServiceService.setplantilla(5);
        }
        break;
      }
      case 6: {
        if (this.confirmacion == true) {
          this.GuiaswebServiceService.setplantilla(6);
        }
        break;
      }
      default: {
        //statements;
        break;
      }
    }
  }

  cambiarpaso(plantilla) {
    switch (plantilla) {
      case 2: {
        this.Remitente = true;
        this.destinatario = false;
        this.tipo = false;
        this.detalle = false;
        this.confirmacion = false;
        break;
      }
      case 3: {
        this.Remitente = true;
        this.destinatario = true;
        this.tipo = false;
        this.detalle = false;
        this.confirmacion = false;
        break;
      }
      case 4: {
        this.Remitente = true;
        this.destinatario = true;
        this.tipo = true;
        this.detalle = false;
        this.confirmacion = false;
        break;
      }
      case 5: {
        this.Remitente = true;
        this.destinatario = true;
        this.tipo = true;
        this.detalle = true;
        this.confirmacion = false;
        break;
      }
      case 6: {
        this.Remitente = true;
        this.destinatario = true;
        this.tipo = true;
        this.detalle = true;
        this.confirmacion = true;
        break;
      }
      default: {
        //statements;
        break;
      }
    }
  }

  async backPaso(numeropantalla) {
    switch (numeropantalla) {
      case 2: {
        this.router.navigate(["/herramientasDigitales/menu"]);
        break;
      }
      case 3: {
        numeropantalla = 2;
        this.GuiaswebServiceService.setplantilla(2);
        break;
      }
      case 4: {
        numeropantalla = 3;
        this.GuiaswebServiceService.setplantilla(3);
        break;
      }
      case 5: {
        numeropantalla = 4;
        this.GuiaswebServiceService.setplantilla(4);
        break;
      }
      case 6: {
        numeropantalla = 5;
        this.GuiaswebServiceService.setplantilla(5);
        break;
      }

      default: {
        //statements;
        break;
      }
    }
  }
}
