import { Component, Input, OnInit } from "@angular/core";
import { GuiaswebServiceService } from "../../servicios/guiasweb-service.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-gw-doc-estructura",
  templateUrl: "./gw-doc-estructura.component.html",
  styleUrls: ["./gw-doc-estructura.component.css"],
})
export class GwDocEstructuraComponent implements OnInit {
  numeropantalla: number = 1;

  constructor(
    private GuiaswebServiceService: GuiaswebServiceService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.GuiaswebServiceService.setModuloG(1);
    if (
      sessionStorage.getItem("token") == undefined ||
      sessionStorage.getItem("token") == "" ||
      sessionStorage.getItem("token") == null
    ) {
      this.router.navigate(["/herramientasDigitales/login"]);
    } else {
      this.GuiaswebServiceService.numP.subscribe((datos) => {
        if (datos == 0) {
          this.router.navigate(["/herramientasDigitales/documentacion/menu"]);
        }
        this.numeropantalla = datos;
      });
    }
  }
}
