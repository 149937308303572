
        <!-- <app-titulo [inputTitulo]="'Preguntas frecuentes'" [inputSubtitulo]="'Consulta nuestros horarios mas frecuentes.'"></app-titulo> -->
<div class="container_herramienta">
    <img src="assets/img/web/preguntas-frec.webp" class="img_toAnimate">
    <div class="alinearIzq">
    <div class="container_pregFrec">
        <div class="espaciado">
<h1>Preguntas frecuentes</h1>
    </div>
        <div class="qa-content"> 
    
            <div class="qa-question">
                <input id="q1" type="checkbox" class="panel">
                <label class="plus"><img src="assets/iconos/flecha-hacia-abajo.png"></label>
                <label for="q1" class="panel-title">¿Cuál es la cobertura de TufesaPack?</label>
                <div class="panel-content">Nuestra cobertura es en todo el territorio mexicano así como envíos al extranjero.</div>
            </div>
            
            <div class="qa-question">
                <input id="q2" type="checkbox" class="panel">
                <label class="plus"><img src="assets/iconos/flecha-hacia-abajo.png"></label>
                <label for="q2" class="panel-title">¿Cuál es el horario y número de atención telefónica?</label>
                <div class="panel-content"> El número de teléfono es (644) 410 2444 ext. 3 con un horario de atención de Lun-Vie de 8:30 a 18:00 y Sáb de 8:00 a 13:00. </div>
            </div>
    
            <div class="qa-question">
                <input id="q3" type="checkbox" class="panel">
                <label class="plus"><img src="assets/iconos/flecha-hacia-abajo.png"></label>
                <label for="q3" class="panel-title">¿Qué sucede si mi paquete no ha llegado a su destino?</label>
                <div class="panel-content">El remitente puede proporcionar información al personal de atención a clientes para que los involucrados reciban información puntual de la ubicación del envío, o bien, introducir el código de rastreo en el apartado. </div>
            </div>
    
            <div class="qa-question">
                <input id="q4" type="checkbox" class="panel">
                <label class="plus"><img src="assets/iconos/flecha-hacia-abajo.png"></label>
                <label for="q4" class="panel-title">¿Qué sucede si mi paquete sufrió fractura y/o daño?</label>
                <div class="panel-content">El remitente deberá notificarse en la oficina origen para efectuar una investigación de lo sucedido y en su caso, activar el protocolo de indemnización. </div>
            </div>
        
            <div class="qa-question">
                <input id="q5" type="checkbox" class="panel">
                <label class="plus"><img src="assets/iconos/flecha-hacia-abajo.png"></label>
                <label for="q5" class="panel-title">¿Mediante qué unidades se transporta mi envío? </label>
                <div class="panel-content">De acuerdo a peso y/o dimensiones, los artículos pueden ser transportados por medio de unidades de autobús y/o tracto-camión. Esto puede influir en los tiempos de entrega.</div>
            </div>
    
            <div class="qa-question">
                <input id="q6" type="checkbox" class="panel">
                <label class="plus"><img src="assets/iconos/flecha-hacia-abajo.png"></label>
                <label for="q6" class="panel-title">¿Puedo obtener descuentos por realizar envíos de manera frecuente?</label>
                <div class="panel-content">El cliente puede acudir a su sucursal para recibir la información y clasificación de cliente “Contrato y/o Pre Pago” y recibir tarifas especiales o descuentos de hasta el 35%.</div>
            </div>
    
        </div>
        <div class="espaciado"></div>
    </div>
</div>
</div>
    
