<div class="contenido">
  <div class="container_datos">
    <div *ngIf="getlanguage() === 'ES'">
      <form [formGroup]="conektaForm" method="POST" action="" class="conekta" id="card-form">
        <h1> Pago con tarjeta bancaria</h1>
        <div class="conektpago">
          <p class="pTitular">Ingrese los siguientes datos</p>
        </div>
        <br>

        <div class="conektpago">
          <input class="Text" #cnknombre maxlength="100" #nombreInput type="text" placeholder="Nombre" 
             formControlName="cnknombre" name="cnknombre"
            (keyup)="cambiarFocus($event, cnkapellido)">
          <ng-container *ngIf="submitted">
            <div class="validation-container">
              <span class="validacion" *ngIf="f.cnknombre.hasError('whitespace')">No se permite campo vacio!</span>
              <span class="validacion" *ngIf="f.cnknombre.invalid">Por favor captura el nombre</span>
            </div>
          </ng-container>
        </div>

        <div class="conektpago">
          <input class="Text" maxlength="100" #cnkapellido type="text" placeholder="Apellido" 
            formControlName="cnkapellido" name="cnkapellido" (keyup)="cambiarFocus($event, cnkcorreo)">
          <ng-container *ngIf="submitted">
            <div class="validation-container">
              <span class="validacion" *ngIf="f.cnkapellido.hasError('whitespace')">No se permite campo vacio!</span>
              <span class="validacion" *ngIf="f.cnkapellido.invalid">Por favor captura el apellido</span>
            </div>
          </ng-container>
        </div>

        <div class="conektpago">
          <input class="Text" maxlength="100" #cnkcorreo type="email" inputmode="email" autocomplete="email" 
            placeholder="Correo" formControlName="cnkcorreo" name="cnkcorreo"
            (keyup)="cambiarFocus($event, cnktarjhambiente)">
          <ng-container *ngIf="submitted">
            <div class="validation-container">
              <span class="validacion" *ngIf="f.cnkcorreo.hasError('whitespace')">No se permite campo vacio!</span>
              <span class="validacion" *ngIf="f.cnkcorreo.invalid">Introduce un correo válido</span>
            </div>
          </ng-container>
        </div>

    

        <div class="contenedorPago2">
          <div>
            <div style="display: flex; flex-direction: row;">
              <div class="content-select" dropdown>
                <select #codigoTelInput [(ngModel)]="paisSelected" [ngModelOptions]="{standalone: true}">
                  <option id="codTel" value="{{item}}" *ngFor="let item of listaPaisCodigoTel">{{item}}</option>
                </select>
                <i></i>
              </div>
              <div style="width: 50%;">
                <input class="content-p3terminosText" #cnkTelefono type="text" inputmode="tel" maxlength="10" 
                  [placeholder]="'Teléfono'" formControlName="cnktelefono"
                  name="cnktelefono" (keyup)="cambiarFocus($event, cnktarjhambiente)" (keydown)="onKeydown($event)"
                  id="cnktelefono">
              </div>
            </div>
            <div>
              <ng-container *ngIf="submitted">
                <div class="validation-container">
                  <span class="validacion" *ngIf="f.cnktelefono.hasError('whitespace')">No se permite campo
                    vacio!</span>
                  <span class="validacion" *ngIf="f.cnktelefono.invalid">Introduce un teléfono valido(10 digitos)</span>
                </div>
              </ng-container>
            </div>
          </div>
        </div>


        <div class="conektpago">
          <input class="Text" #cnktarjhambiente type="text" maxlength="100" placeholder="Nombre del tarjetahabiente"
            formControlName="cnktarjhambiente" name="cnktarjhambiente"
            (keyup)="cambiarFocus($event, cnktarjbancaria)" data-conekta="card[name]">
          <ng-container *ngIf="submitted">
            <div class="validation-container">
              <span class="validacion" *ngIf="f.cnktarjhambiente.hasError('whitespace')">No se permite campo
                vacio!</span>
              <span class="validacion" *ngIf="f.cnktarjhambiente.invalid">Por favor introduce el tarjetahabiente</span>
            </div>
          </ng-container>
        </div>

        <div class="conektpago">
          <input class="Text" #cnktarjbancaria type="text" maxlength="16" inputmode="tel"
            placeholder="Número de tarjeta bancaria" formControlName="cnktarjbancaria" name="cnktarjbancaria"
            (keyup)="cambiarFocus($event, cnkmes)" (focusout)="cambiarFocus($event, cnkmes)"
            data-conekta="card[number]">
          <ng-container *ngIf="submitted">
            <div class="validation-container">
              <span class="validacion" *ngIf="f.cnktarjbancaria.hasError('whitespace')">No se permite campo
                vacio!</span>
              <span class="validacion" *ngIf="f.cnktarjbancaria.invalid">Por favor introduce el # de tarjeta</span>
            </div>
          </ng-container>
        </div>

        <div class="conektpago3" >
          <div class="conektpago2">
            <input class="conektaText2" #cnkcvc type="text" inputmode="numeric" maxlength="4" placeholder="CVV" 
              formControlName="cnkcvc" name="cnkcvc" (keyup)="cambiarFocus($event, cnkcalle)" data-conekta="card[cvc]">
            <ng-container *ngIf="submitted">
              <div class="validation-container">
                <span class="validacion" *ngIf="f.cnkcvc.hasError('whitespace')">No se permite campo vacio!</span>
                <span class="validacion" *ngIf="f.cnkcvc.invalid">Introduce el CVV</span>
              </div>
            </ng-container>
          </div>

          <div class="conektpago2">
            <input class="conektaText2" #cnkano type="text" inputmode="numeric" maxlength="4" placeholder="Año AAAA"
              formControlName="cnkano" name="cnkano" (keyup)="cambiarFocus($event, cnkcvc)"
              data-conekta="card[exp_year]">
            <ng-container *ngIf="submitted">
              <div class="validation-container">
                <span class="validacion" *ngIf="f.cnkano.hasError('whitespace')">No se permite campo vacio!</span>
                <span class="validacion" *ngIf="f.cnkano.invalid">Introduce el año</span>
              </div>
            </ng-container>
          </div>

          <div class="conektpago2" >
            <input class="conektaText2" #cnkmes maxlength="2" type="text" inputmode="numeric" placeholder="Mes MM"
              formControlName="cnkmes" name="cnkmes" (keyup)="cambiarFocus($event, cnkano)"
              data-conekta="card[exp_month]">
            <ng-container *ngIf="submitted">
              <div class="validation-container">
                <span class="validacion" *ngIf="f.cnkmes.hasError('whitespace')">No se permite campo vacio!</span>
                <span class="validacion" *ngIf="f.cnkmes.invalid">Introduce el mes</span>
              </div>
            </ng-container>
          </div>

        </div>

        <div class="conektpago" hidden>
          <input class="conektaText" #cnkcalle type="text" maxlength="100" placeholder="Calle"
            formControlName="cnkcalle" name="cnkcalle" (keyup)="cambiarFocus($event, cnkcolonia)">
          <ng-container *ngIf="submitted">
            <div class="validation-container">
              <span class="validacion" *ngIf="f.cnkcalle.hasError('whitespace')">No se permite campo vacio!</span>
              <span class="validacion" *ngIf="f.cnkcalle.invalid">La Calle es requerida</span>
            </div>
          </ng-container>
        </div>

        <div class="conektpago" hidden>
          <input class="conektaText" #cnkcolonia type="text" maxlength="100" placeholder="Colonia"
            formControlName="cnkcolonia" name="cnkcolonia" (keyup)="cambiarFocus($event, cnkciudad)">
          <ng-container *ngIf="submitted">
            <div class="validation-container">
              <span class="validacion" *ngIf="f.cnkcolonia.hasError('whitespace')">No se permite campo vacio!</span>
              <span class="validacion" *ngIf="f.cnkcolonia.invalid">La Colonia es requerida</span>
            </div>
          </ng-container>
        </div>

        <div class="conektpago" hidden>
          <input class="conektaText" #cnkciudad type="text" maxlength="100" placeholder="Ciudad"
            formControlName="cnkciudad" name="cnkciudad" (keyup)="cambiarFocus($event, cnkestado)">
          <ng-container *ngIf="submitted">
            <div class="validation-container">
              <span class="validacion" *ngIf="f.cnkciudad.hasError('whitespace')">No se permite campo vacio!</span>
              <span class="validacion" *ngIf="f.cnkciudad.invalid">La Ciudad es requerida</span>
            </div>
          </ng-container>
        </div>

        <div class="conektpago" hidden>
          <input class="conektaText" #cnkestado type="text" maxlength="100" placeholder="Estado"
            formControlName="cnkestado" name="cnkestado" (keyup)="cambiarFocus($event, cnkcp)">
          <ng-container *ngIf="submitted">
            <div class="validation-container">
              <span class="validacion" *ngIf="f.cnkestado.hasError('whitespace')">No se permite campo vacio!</span>
              <span class="validacion" *ngIf="f.cnkestado.invalid">El Estado es requerido</span>
            </div>
          </ng-container>
        </div>

        <div class="conektpago" hidden>
          <input class="conektaText" #cnkcp type="text" inputmode="numeric" maxlength="5" placeholder="Codigo Postal"
            formControlName="cnkcp" name="cnkcp" (keyup)="cambiarFocus($event, cnkpais)">
          <ng-container *ngIf="submitted">
            <div class="validation-container">
              <span class="validacion" *ngIf="f.cnkcp.hasError('whitespace')">No se permite campo vacio!</span>
              <span class="validacion" *ngIf="f.cnkcp.invalid">El CP es requerido</span>
            </div>
          </ng-container>
        </div>

        <div class="conektpago" hidden>
          <input class="conektaText" #cnkpais type="text" maxlength="100" placeholder="Pais" formControlName="cnkpais"
            name="cnkpais" (keyup)="cambiarFocus($event, idconekta)">
          <ng-container *ngIf="submitted">
            <div class="validation-container">
              <span class="validacion" *ngIf="f.cnkpais.hasError('whitespace')">No se permite campo vacio!</span>
              <span class="validacion" *ngIf="f.cnkpais.invalid">El Pais es requerido</span>
            </div>
          </ng-container>
        </div>

        <div class="terminos">
          <input type="checkbox" id="conbox-2">
          <label class="txtLabel" for="conbox-2">Quiero recibir información y publicidad</label>
            <label class="txtLabel" >Al hacer clic en "Pagar" acepta nuestros <a class="aviso" routerLink="/contrato/adhesion" target="_blank">Contrado de Adhesión</a> 
              y haber leido nuestro
              <a target="_blank" routerLink="/informacion/avisoPrivacidad">Aviso de privacidad.</a></label>
          <div *ngIf="submitted && f.terminosConekta.errors">
            <div *ngIf="f.terminosConekta.errors.required">
              <span class="conektapagovalterminos"></span>
            </div>
          </div>
        </div>
      </form> 

       <input type="hidden" id="conektaErros" />
      <input type="text" name="conektaTokenId" id="conektaTokenId" hidden>
      <input type="text" name="guardado" id="guardado" [(ngModel)]="estaGuardado" hidden>

      <div class="botonesproceso">
        <button type="button" id="ConektaGetToken" onclick="creartokenConekta()" hidden></button>
        <button type="button" id="Conektaprocesapago" (click)="oprGetVentaWeb()" hidden></button>
        <button type="button" id="btnMostrarError" (click)="mostrarError()" hidden></button>
      </div> 


      <!-- Boton que activa el procesamiento de pago -->
       <div class="botonesproceso" style="margin-top: -50px;">
        <button class="btnBack" routerLink="/detalle-transaccion"> Regresar </button>

        <button name="idconekta" id="idconekta" class="btnContinuar" #idconekta (click)="generartoken()">Pagar
        </button>
      </div>
    </div>
  </div>
    <div id="conektaErros"></div> 

    <ng-template #tConditions>
      <div class="modal-header">
        <h4 class="modal-title pull-left">Términos y Condiciones</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span class="close" aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
      </div>
    </ng-template>
  </div> 

    <!-- <app-sub-resumen></app-sub-resumen>  -->
