import { Component, OnInit,ViewChild } from '@angular/core';
import { CrearDocumentosService } from 'src/app/servicios/crear-documentos.service';
import { Router } from '@angular/router';
import { GuiaswebServiceService } from '../../servicios/guiasweb-service.service';
import { SignatureComponent } from '../signature/signature.component';
import  Swal  from 'sweetalert2';

@Component({
  selector: 'app-crear-mis-formatos',
  templateUrl: './crear-mis-formatos.component.html',
  styleUrls: ['./crear-mis-formatos.component.css']
})
export class CrearMisFormatosComponent implements OnInit {
  @ViewChild(SignatureComponent) signatureComponent: SignatureComponent;

  plantillas: any [] = [];
  selectedPlantilla: any = null;
  encabezados: any[] = [];

  showModalSignature: boolean = false;
  datosusr: any
  cadena: string;
  idUser: any;
  selectedOption:any = '';
  idfirma:any;

  constructor(
    private crearDocumentosService: CrearDocumentosService,
    private router: Router,
    private GuiaswebServiceService: GuiaswebServiceService,
    ) { }

  ngOnInit(): void {
    this.obtenerUsuario();
    this.obtenerPantilas();
  }

  ngAfterViewInit() {
    this.signatureComponent.datobaseChanged.subscribe((datobase: string) => {
      // console.log( "Este viene de " + datobase);

      const insertimg = this.encabezados.find(item => item.idencabezado === this.idfirma);

      if(insertimg){
         console.log("encontrado");
         insertimg.catalogorsp = datobase;
      }else{
        console.log( "no encontrado" );
      }

    });

    this.signatureComponent.closeModalChanged.subscribe((datoclosemodal: boolean) => {
       console.log(datoclosemodal);
       this.closeModalSignature();

    });
  }

  // guardarvaloroption(){
  //   console.log(this.selectedOption);

  // }

  isCampoVacio(campo: any): boolean {
    // Verifica si el campo es nulo, indefinido, una cadena vacía o un arreglo vacío
    return campo === null || campo === undefined || (typeof campo === 'string' && campo.trim() === '') || (Array.isArray(campo) && campo.length === 0);
  }

  // Validaciónes específicas para cada tipo de input
  soloNumeros(event: any) {
    event.target.value = event.target.value.replace(/[^0-9]/g, '');
  }

  validarFirma(encabezado: any) {
    // Lógica de validación para 'firma'
    console.log('Validando firma:', encabezado);
  }

  validarTelefono(event: any) {
    event.target.value = event.target.value.replace(/[^\d()-]/g, '');
  }

  validarEmail(correo: any) {
    var regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (regex.test(correo)) {
      // console.log('El correo electrónico es válido.');
      return true;
    } else {
      // console.log('El correo electrónico no es válido.');
      return false;
    }
  }

   closeModalSignature() {
     this.showModalSignature = false;
   }

   openModalSignature(idseccion) {
     this.clearSignature();
     console.log("este es el id de firma" + idseccion);
     this.showModalSignature = true;
     this.idfirma = idseccion;
     // this.seccionfirma = seccion;
   }

   clearSignature(){
     this.signatureComponent.clearSignature();
   }

   obtenerPantilas(): void{
     this.crearDocumentosService.cargarFormatosDigitales_tfp(this.idUser,"").subscribe((data: any[]) => {
       this.plantillas = data
       console.log(this.plantillas);

     }, error => {
       console.log(error);

     })
   }

  seleccionarPlantilla(): void {
    if (this.selectedPlantilla) {
      const idForma = this.selectedPlantilla.idforma;

      this.crearDocumentosService.cargarFormatoDigitalEncabezados_tfp(this.idUser, idForma).subscribe((encabezados: any[]) => {
        this.encabezados = encabezados;
        console.log(this.encabezados);

      }, error => {
        console.log(error);
      });
    } else {
      this.encabezados = []
    }
  }

  obtenerUsuario(): void {
    this.GuiaswebServiceService.usrLgn.subscribe(
      async datos => {
        this.datosusr = datos;

        var bandera
        bandera = Object.keys(this.datosusr).length === 0;


        if (!bandera) {
          if (this.datosusr.name.length > 8) {
            this.cadena = this.datosusr.name.substr(0, 8).trim();
            this.idUser = this.datosusr.idUser
            this.cadena = this.cadena + "..."
            await this.cadena;
          }
          else {
            this.cadena = this.datosusr.name;
          }
        }
      }
    );
  }

  crearFormatoDigital(): void{

    for (const encabezado of this.encabezados) {
      // Verifica que encabezado y su propiedad tipoinput existan antes de acceder a ellas
      if (encabezado && encabezado.tipoinput) {
        // Verifica si el campo está vacío antes de realizar la validación específica
        if (this.isCampoVacio(encabezado.catalogorsp)) {
            console.log('Capture la informacion del encabezado', encabezado.tituloencabezado);
            Swal.fire({
              icon: 'info',
              title: 'CAMPO VACIO!',
              text: 'Es necesario capturar informacion de' +' '+ encabezado.tituloencabezado,
            });
           return;
        } else {
          // Resto del código de validarInput
          if (encabezado.tipoinput === 'select') {
              // if(Array.isArray(encabezado.catalogorsp)){
                if(this.selectedOption === ""){
                 Swal.fire({
                  icon: 'info',
                  title: '¡CAMPO VACIO!',
                  text: 'Es necesario capturar informacion de'+' '+ encabezado.tituloencabezado,
                });
               return;
                }
          } else if (encabezado.tipoinput === 'firma') {
             this.validarFirma(encabezado.catalogorsp);
          } else if (encabezado.tipoinput === 'textlong') {

          } else if (encabezado.tipoinput === 'text') {

          } else if (encabezado.tipoinput === 'phone') {

             if(encabezado.catalogorsp.length <= 12){
              Swal.fire({
               icon: 'error',
               title: '¡ERROR!',
               text: 'El campo'+' '+ encabezado.tituloencabezado +' '+ 'no debe tener menos de 13 caracteres',
             });
            return;
             }
          } else if (encabezado.tipoinput === 'numeric') {

          } else if (encabezado.tipoinput === 'input') {

          } else if (encabezado.tipoinput === 'email') {

            if (this.validarEmail(encabezado.catalogorsp)) {
              // El correo es válido, puedes realizar acciones adicionales aquí si es necesario
              console.log('Correo electrónico válido para', encabezado.tituloencabezado);

            } else {
              // El correo no es válido, muestra un mensaje de error o realiza acciones necesarias
              console.log('Correo electrónico no válido para', encabezado.tituloencabezado);
              Swal.fire({
                icon: 'error',
                title: 'ERROR!',
                text: 'El correo electrónico no es válido.',
              });
              return;
            }

          } else if (encabezado.tipoinput === 'date') {

          } else if (encabezado.tipoinput === 'currency') {

          } else {
            console.log('Tipo de input no reconocido');
          }
        }
      } else {
        console.log('Encabezado o su propiedad tipoinput no está definido.');
      }
    }

    let ids: string = ""
    // const primerIdEncabezado = this.encabezados[0].idencabezado;
    // const ultimoIdEncabezado = this.encabezados[this.encabezados.length - 1].idencabezado;

    let cadenaConcatenada = '';

    // for (let i = 0; i < this.encabezados.length; i++) {
    //     cadenaConcatenada += this.encabezados[i].catalogorsp + '|';
    //     ids += this.encabezados[i].idencabezado + '|'
    // }

    for (let i = 0; i < this.encabezados.length; i++) {
      if (typeof this.encabezados[i].catalogorsp === 'object') {
          cadenaConcatenada += this.selectedOption + '|';
      } else {
          cadenaConcatenada += this.encabezados[i].catalogorsp + '|';
      }
      ids += this.encabezados[i].idencabezado + '|';
  }

    // Eliminar el último '|'
    cadenaConcatenada = cadenaConcatenada.slice(0, -1);
    ids = ids.slice(0, -1);

    // console.log('Cadena concatenada:', cadenaConcatenada);

    // console.log(`[Llamada opr] 0, 8 , ${this.selectedPlantilla.idforma}, 'OPR', ${primerIdEncabezado}|${ultimoIdEncabezado}, ${cadenaConcatenada}`);

    // Aquí puedes acceder a los datos guardados en los ngModel
    // console.log('Plantilla seleccionada:', this.selectedPlantilla);
    // console.log('Encabezados guardados:', this.encabezados.length);
    // Creación del arreglo con la estructura requerida
   const arregloDatos =
     {
       idrsp: "0",
       idforma: this.selectedPlantilla.idforma,
       usr: this.idUser,
       status: 'OPR',
       ids: ids,
       valores: cadenaConcatenada.toString()
     };

  this.crearDocumentosService.crearFormatoDigital(arregloDatos).subscribe((response: any) => {
   if (parseInt(response.nummsg) > 0) {
       Swal.fire('¡FORMATO CREADO!', response.txtmsg,'success');
       this.router.navigate(['/mis-documentos']);
    }

   if (parseInt(response.nummsg) < 0) {
       Swal.fire('¡ERROR!', response.txtmsg,'error');
       this.router.navigate(['/mis-documentos']);
   }
  //  console.log(response);

   }, error => {
  console.log(error);
  Swal.fire('¡ERROR!', error,'error');
  this.router.navigate(['/mis-documentos']);

})
// console.log('Arreglo de datos:', arregloDatos)
  }

}


