import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder,ReactiveFormsModule } from "@angular/forms";
import { FormsModule } from '@angular/forms';
import { user } from "../../entidades/gwuser";
import jwt_decode from "jwt-decode";
import { AdministrarCuentaService } from "src/app/servicios/administrar-cuenta.service";
import { Router, ActivatedRoute } from '@angular/router';

declare var $: any;

const phoneRegex = /^[0-9]{10}$/;
const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

@Component({
  selector: 'app-tupromo',
  templateUrl: './tupromo.component.html',
  styleUrls: ['./tupromo.component.css']
})
export class TupromoComponent implements OnInit, AfterViewInit {

  userGw: user;
  datagw: any;
  token: string;
  tokendatos: any;
  idcliente: number = -1;
  datacode : any;
  // datos que recibe
  nombre: string;
  correo: string;
  telefono: string;
  tipoPromo: string;
  msg: string;

  via: string ="";
  promoact :any;

  selectedImage: string;
  textoImg: string ="";

  tipoPublicidad: string;
  leyenda: string;

  formulario: FormGroup;


  constructor(private administrarCuentaService: AdministrarCuentaService,private activatedRoute: ActivatedRoute, private router: Router, private formBuilder: FormBuilder) {
    // this.formulario = new FormGroup({
    //   nombre: new FormControl("", Validators.required),
    //   correo: new FormControl("", [Validators.email, Validators.required]),
    //   telefono: new FormControl("", [
    //     Validators.required,
    //     Validators.pattern("[0-9]{10}"),
    //   ]),
    //   slctcomo: new FormControl("",Validators.required)
    // });

    this.activatedRoute.queryParams.subscribe(params => {
      try{
        this.tipoPromo = params['code'];
        // this.tipoPublicidad = params['tipoPublicidad'];
         console.log(this.tipoPromo);
        if (this.tipoPromo == "" || this.tipoPromo == undefined){
          this.router.navigateByUrl('/');
        }
        //  if (this.tipoPublicidad == ""){
        //    this.router.navigate(['**']);
        //  }
      }
      catch(e){
        console.log(e);
        //redirige a home
        this.router.navigateByUrl('/');
      }
    });

  }
  options = [
    { label: '', value: ['assets/img/web/delivery.webp'], leyenda: "" },
    // { label: 'PROMOTPNSMY24', value: ['assets/img/promociones/landingpromonenis.png'], leyenda: "1. Promoción y Vigencia.- 20 guías web con un peso máximo de 5 kilos por $1,599, válido del 2 de mayo al 10 de mayo del 2024. Los sobres especiales están incluidos en esta promoción. La promoción no es aplicable junto con otras promociones o descuentos. La tarifa se calcula con base al peso volumétrico y se aplica según el peso y las dimensiones reales del paquete. La promoción aplica únicamente al costo del envío, excluyendo cualquier costo asociado al sobre o embalaje utilizado para el traslado del paquete, el cual se cobrará en caso de que se adquiera en sucursal. 2. Servicios y Cobertura.-  La promoción es válida solo para envíos que salgan dentro de la cobertura de TufesaPack® y se reciban dentro de la cobertura de TufesaPack®. Se ofrece exclusivamente el servicio ocurre a ocurre, no servicio a domicilio. Sin embargo, se puede solicitar el servicio a domicilio con un cargo adicional de $50, siempre y cuando la ciudad de destino cuente con este servicio disponible. 3. Compra y Pago.- Válido únicamente para compras y envíos realizados dentro de la cobertura TufesaPack®, solo compras digitales. Se acepta el pago con tarjeta. La promoción es válida para guías web pagadas en el momento de la transacción, no para envíos por cobrar ni para contratos prepago. 4. Políticas.- Los cambios y cancelaciones están sujetos a las políticas estándar de TufesaPack®️, y nos reservamos el derecho de modificar o cancelar esta promoción en cualquier momento sin previo aviso." },
    // { label: 'PROMOTPALAMADREMY24', value: ['assets/img/promociones/landingpromodiamadre.jpg'], leyenda: "Términos y Condiciones 1. Promoción y Vigencia.- Envíos de 0 hasta 5 kilos con un 10 % de descuento, válido del 8 de mayo al 10 de mayo del 2024. Los sobres especiales están incluidos en esta promoción. La promoción no es aplicable junto con otras promociones o descuentos. La tarifa se calcula con base al peso volumétrico y se aplica según el peso y las dimensiones reales del paquete. La promoción aplica únicamente al costo del envío, excluyendo cualquier costo asociado al sobre o embalaje utilizado para el traslado del paquete, el cual se cobrará en caso de que se adquiera en sucursal. 2. Servicios y Cobertura.- La promoción es válida solo para envíos que salgan dentro de la cobertura de TufesaPack® y se reciban dentro de la cobertura de TufesaPack®. Se ofrece exclusivamente el servicio ocurre a ocurre, no servicio a domicilio. Sin embargo, se puede solicitar el servicio a domicilio con un cargo adicional de $50, siempre y cuando la ciudad de destino cuente con este servicio disponible. 3. Compra y Pago.- Válido únicamente para compras y envíos realizados dentro de la cobertura TufesaPack®, no para compras digitales, solo compras en mostrador. Se acepta el pago en efectivo y con tarjeta. La promoción es válida para envíos pagados en el momento de la transacción, no para envíos por cobrar ni para contratos prepago. 4. Políticas.- Los cambios y cancelaciones están sujetos a las políticas estándar de TufesaPack®️, y nos reservamos el derecho de modificar o cancelar esta promoción en cualquier momento sin previo aviso." },
    { label: 'PROMOTPAGPYCNA30AG25', value: ['assets/img/promociones/landingpromoagpcananea.jpg'], leyenda:"Condiciones.- Se permiten envíos de paquetes de 1 a 5 kilos. La tarifa se basa en el peso volumétrico, aplicable al peso y dimensiones reales del paquete. Disponible solo para el servicio ocurre-ocurre (no incluye servicio a domicilio). Cobertura.- Válido únicamente en envíos desde Cananaea y Agua Prieta, con destino a toda la ruta TufesaPack®. Duración.- La promoción es válida durante 3 meses, del miércoles 4 septiembre a miércoles 4 de diciembre. Compra y Pago.- Válido únicamente en compras realizadas dentro de las sucursales; no aplica en compras digitales. Se acepta pago en efectivo y tarjeta (donde esté disponible este método de pago). Válido solo para envíos pagados, no aplica en envíos por cobrar ni en contratos prepago. Políticas.- Los cambios y cancelaciones están sujetos a las políticas estándar de TufesaPack®. Nos reservamos el derecho de modificar o cancelar este servicio en cualquier momento sin previo aviso."},
    { label: 'PROMOTPHMOAG24', value: ['assets/img/promociones/landingpromohermosillo.jpg'], leyenda: "Términos y Condiciones 1. Promoción y Vigencia.- Descuento del 20% en envíos de 1 a 40 kilos, válido durante 3 meses a partir del lunes 9 de septiembre al lunes 9 de diciembre. Los sobres especiales están incluidos en esta promoción. El descuento no es aplicable junto con otras promociones o descuentos. La tarifa se calcula con base al peso volumétrico y se aplica según el peso y las dimensiones reales del paquete. El descuento aplica únicamente al costo del envío, excluyendo cualquier costo asociado al sobre o embalaje utilizado para el traslado del paquete, el cual se cobrará en caso de que se adquiera en sucursal. 2. Servicios y Cobertura.- La promoción es válida solo para envíos que salgan de la sucursal de Hermosillo hacia las sucursales de Tijuana, Mexicali, Tepic y Guadalajara (Zapopan y Tlaquepaque). Se ofrece exclusivamente el servicio ocurre a ocurre, no servicio a domicilio. Sin embargo, se puede solicitar el servicio a domicilio con un cargo adicional de $50, siempre y cuando la ciudad de destino cuente con este servicio disponible. 3. Compra y Pago.- Válido únicamente para compras y envíos realizados dentro de la sucursal de Hermosillo, no para compras digitales. Se acepta el pago en efectivo y con tarjeta. La promoción es válida para envíos pagados en el momento de la transacción, no para envíos por cobrar ni para contratos prepago. 4. Políticas.- Los cambios y cancelaciones están sujetos a las políticas estándar de TufesaPack®, y nos reservamos el derecho de modificar o cancelar esta promoción en cualquier momento sin previo aviso." },
  ];

  // options = [
  //   { label: '', value: 'assets/img/web/delivery.webp', leyenda: "" },
  //   { label: 'TEPMXL', value: ['assets/img/promociones/MXLTEP.webp', 'assets/img/promociones/TEP.webp', 'assets/img/promociones/MXL.webp'], leyenda:"Promoción exclusiva para envíos saliendo desde la sucursal Tepic o Mexicali hacia la cobertura TufesaPack: Guadalajara, Peñas, Tecuala, Acaponeta, Escuinapa, El Rosario, Mazatlán, Culiacán, Guamúchil, Guasave, Los Mochis, Navojoa, Ciudad Obregón, Empalme, Guaymas, Hermosillo, San Luis Río Colorado, Magdalena, Ímuris, Nogales, Cananea, Agua Prieta, Caborca, Sonoyta, Santa Ana, Tijuana, Tepic (saliendo de Mexicali) y Mexicali (saliendo de Tepic). Aplica solo servicio ocurre - ocurre, no en servicio domicilio. Vigencia de la promoción del 4 de julio al 4 de noviembre del 2023. Solo es válida en envíos de sobre de hasta 1 kilo y en envíos de caja de 1 a 40 kilos. Se acepta pago en efectivo y tarjeta. No aplica con otras promociones. Para hacer válida la promoción debes de registrar tu nombre, celular y correo, ya que te enviaremos tú código por medios digitales y tendrás que presentarlo en mostrador al realizar el envío. El código es intransferible y puedes usarlo las veces que quieras durante el período vigente. Tarifa aplicable a peso y dimensiones reales del paquete."},
  //   { label: 'TPZ50', value: ['assets/img/promociones/banner-promo.webp'], leyenda:"Términos y condiciones: 50 % de descuento en tu primer envío* Promoción exclusiva para envíos saliendo desde la sucursal Zapopan, JAL. hacia la cobertura TufesaPack: Tepic, Peñas, Tecuala, Acaponeta, Escuinapa, El Rosario, Mazatlán, Culiacán, Guamúchil, Guasave, Los Mochis, Navojoa, Ciudad Obregón, Empalme, Guaymas, Hermosillo,  Magdalena, Ímuris, Nogales, Cananea, Agua Prieta, Caborca,  Santa Ana, Sonoyta,​ San Luis Río Colorado, Mexicali y Tijuana. Aplica solo servicio ocurre - ocurre, no en servicio domicilio. Vigencia de la promoción del 19 de julio al 19 de agosto del 2023.  Solo es válida en envíos de sobre (hasta 1 kilo) y en envíos de paquetes (cajas de 1 a 40 kilos). Se acepta pago en fectivo y tarjeta. No aplica con otras promociones. Para hacer válida la promoción debes de presentar el código en mostrador al realizar el envío. El código es intransferible y puedes usarlo 1 vez durante el período vigente. Tarifa aplicable a peso y dimensiones reales del paquete. ​Tarifa se considera peso bruto o volumétrico."},
  //   { label: 'PROMOSON', value: ['assets/img/promociones/PROMOSON.jpg'], leyenda: "Promoción exclusiva en envíos desde Cananea, Hermosillo, Guaymas, Empalme, Obregón y Navojoa hacia toda la cobertura de TufesaPack. Vigencia del 15 de octubre al 30 de noviembre del 2023. Aplica solo servicio ocurre a ocurre, no servicio a domicilio. Válido en envío pagado, no por cobrar ni contratos prepago. Solo es válida en envíos de 0 a 5 kilos. Se acepta pago en efectivo y tarjeta. No aplica con otras promociones. Tarifa aplicable a peso y dimensiones reales del paquete." },
  // ];


  ngOnInit(): void {

    this.formulario = this.formBuilder.group({
      nombre: ['', Validators.required],
      correo: ['', [Validators.required, Validators.email, Validators.pattern(emailRegex)]],
      telefono: ['', [Validators.required,Validators.pattern(phoneRegex)]],
      slctcomo: ['', Validators.required],
    });



    this.promoact = this.options[0];

    for (let i = 0; i < this.options.length; i++) {
      if(this.options[i].label == this.tipoPromo){
        this.promoact = this.options[i]
      }
    }
  }
  ngAfterViewInit() {
    this.initCarousel();
    // Para obtener el texto después de que la vista esté lista
  //  document.getElementById("slctoptn").setAttribute("value",this.tipoPublicidad);
    //document.getElementById("slctoptn").setAttribute("value",this.tipoPromo);
  }


  initCarousel(): void {
    $(document).ready(function () {
      $('.owl-carousel').owlCarousel({
        loop: true,
        margin: 0,
        responsiveClass: true,
        autoHeight: false,
        autoplay: true, // Agregado para habilitar el autoplay
        autoplayTimeout: 3000, // Especifica el tiempo de espera entre transiciones (en este caso, 3 segundos)
        responsive: {
          0: {
            items: 1,
            nav: true
          },
          // 600: {
          //   items: 3,
          //   nav: false
          // },
          // 1000: {
          //   items: 5,
          //   nav: true,
          //   loop: false
          // }

        }

      });
    });
  }


  obtenerCodigo(){
    this.administrarCuentaService.obtenerPromo(this.nombre, this.correo, this.telefono, this.via, this.tipoPromo).then((data) => {
      this.datacode = data;
      if ((this.datacode.msg) === "1") {
        this.showMensaje(this.datacode.msg);
         document.getElementById("mensajeAlert").style.display = "block";
      } else {
        this.showMensaje(this.datacode.msg);
        document.getElementById("mensajeAlert").style.display = "block";
      }
  });
  }

  showMensaje(msg: string) {
    this.msg = msg;
  }

  get userF() {
    return this.formulario.controls;
  }

  submitForm() {
    if (this.formulario.valid) {
      this.obtenerCodigo();
    }

  }

  // onOptionSelected() {
  //   this.selectedImage = this.via;
  // }

}
