<div class="container_full">
    <h1 class="txt-section">Nuestras Marcas</h1>
    <div class="container_boxes">
        <div class="owl-carousel owl-theme owl-loaded owl-drag">
            <div class="owl-stage-outer">
                <div class="owl-stage" style="">
                    <div class="owl-item ">
                        <div class="item">
                            <img src="assets/img/tufesa_corp.webp">
                            <h3>Movemos personas con origen de viaje en México</h3>
                        </div>
                    </div>
                    <div class="owl-item">
                        <div class="item">
                            <img src="assets/iconos/marcas/tufesa_internacional.webp">
                            <h3>Movemos personas con origen de viaje en USA.</h3>
                        </div>
                    </div>
                    <div class="owl-item">
                        <div class="item">
                            <img _ngcontent-hvx-c281="" src="assets/iconos/marcas/tufesapack.webp"
                                style="margin-top: 30px;">
                            <h3>Movemos cosas.</h3>
                        </div>
                    </div>
                    <div class="owl-item">
                        <div class="item">
                            <img src="assets/iconos/marcas/yoko.webp">
                            <h3>Comercializamos piezas que
                                mueven.</h3>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div class="owl-nav disabled">
            </div>
        </div>
    </div>
    <br><br>
</div>