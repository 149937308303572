import { Injectable } from '@angular/core';
import { login } from '../entidades/login';
import { user } from '../entidades/gwuser';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { documentacion } from '../entidades/gwdocumentacion';
import { tipo } from '../entidades/gwtipo';
import { detalle } from '../entidades/gwdetalle';
import { cuenta } from '../entidades/gwcuenta'
import { cuentaverificada } from '../entidades/cuentaverificada'

@Injectable({
  providedIn: 'root'
})
export class GuiaswebServiceService {


  constructor(
    private http: HttpClient
  ) { }

  usrLogin = new BehaviorSubject(new user());
  usrLgn = this.usrLogin.asObservable();

  private numplantilla = new BehaviorSubject<number>(0);
  numP = this.numplantilla.asObservable();

  public setplantilla(value) {
    this.numplantilla.next(value);
  }

  private numlogin = new BehaviorSubject<boolean>(false);
  numlg = this.numlogin.asObservable();

  public setnumlogin(value) {
    this.numlogin.next(value);
  }

  private numplantillaHist = new BehaviorSubject<number>(0);
  numPHist = this.numplantillaHist.asObservable();

  public setplantillaHist(value) {
    this.numplantilla.next(value);
  }

  private numPlantillaSol = new BehaviorSubject<number>(0);
  numPsol = this.numPlantillaSol.asObservable();

  public setPlantillaSol(value) {
    this.numPlantillaSol.next(value);
  }

  async datosUsrLogin(value) {
    this.usrLogin.next(value);
  }

  private numModuloG = new BehaviorSubject<number>(0);
  numMG = this.numModuloG.asObservable();

  public setModuloG(value) {
    this.numModuloG.next(value);
  }

  private cuentaverificacion = new BehaviorSubject(new cuentaverificada());
  cuentaVerf = this.cuentaverificacion.asObservable();

  public setCuentaverificada(value) {
    this.cuentaverificacion.next(value);
  }

  // login
  async login(usuario: login) {
    let url = `${environment.URL_APIPAQ}/login?usuario=${usuario.user}&contrasena=${usuario.pass}`

    return this.http.get(url).toPromise();
  }

  // iniciar sesion en la aplicacion
  async Iniciar_Sesion(usuario: login, uuid:string) {
    // convertir el objeto a una cadena json
    const userJSON = JSON.stringify(usuario);

    // el objeto json se codifica en base64
    const userBase64 = btoa(userJSON);

    // opciones del Http para hacer post y el envio de,
    // datos codificados
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-type': 'application/json',
        Authorization: 'Basic ' + userBase64
      })
    };

    const url = `${environment.URL_APIPAQ}/GuiasLogin?uuid=${uuid}`;
    return this.http.post<any>(url, "", httpOptions).toPromise();
  }


  // pantalla para verificar cuenta

  async verificarCuenta(verificar) {
    let url = `${environment.URL_APIPAQ}/GwVerificarCuenta?verificar=${verificar}`
    return this.http.get<cuentaverificada>(url).toPromise();
  }

  async verificarCodigo(idcliente, codigo) {
    let url = `${environment.URL_APIPAQ}/GwVerificarCodigo?idcliente=${idcliente}&codigo=${codigo}`
    return this.http.get<any>(url).toPromise();
  }

  async cambiarcontrasena(idcliente, contrasena, idcodigo) {
    let url = `${environment.URL_APIPAQ}/GwCambiarcontrasena?idcliente=${idcliente}&contrasena=${contrasena}&idcodigo=${idcodigo}`

    return this.http.get<any>(url).toPromise();
  }


  // ----- pantalla historial detalle guia -------
  // gcancelar guia

  async cancelarGuia(usr, folio, motivo) {
    let url = `${environment.URL_APIPAQ}/GwCancelarGuia?folio=${folio}&motivo=${motivo}&usrcan=${usr}`
    return this.http.get<any>(url).toPromise();
  }


  // ----- pantalla nueva cuenta -------
  // guardar cuenta usr

  async GwRegistrarUsrv2(cuenta: cuenta) {

    const url = `${environment.URL_APIPAQ}/GwRegistrarUsrv2`;

    return this.http.post<detalle>(url, cuenta).toPromise();
  }


  // menu principal
  async datosCliente(idCliente) {
    let url = `${environment.URL_APIPAQ}/getinfocliente?idcliente=${idCliente}`
    return this.http.get<user>(url).toPromise();
  }


  // pantalla tipo de paquete
  async CargarTipoPaq(idCliente: number, token: string) {
    let url = `${environment.URL_APIPAQ}/CargarTipoPaqueteClienteweb?cliente=${idCliente}`
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-type': 'application/json',
        // Authorization: 'Bearer ' + token
        Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2MjIyNDU0NjgsInVzZXIiOiJsdWlzZTEyMjAyNTM4IiwibmFtZSI6Imx1aXMgZSIsImlkVXNlciI6MiwidHh0TXNqIjoidXNhcmlvIHZhbGlkbyIsImNvcnJlbyI6Imx1aXNlbnJpcXVlZV8yM0Bob3RtYWlsLmNvbSIsInRlbCI6IjY0MjQzNTUzMjMifQ.KM7a9ENEQVrkxuPzAfwwJIpFyhhptP26i9ys1hiN5vA'
      })
    };
    return this.http.get(url, httpOptions).toPromise();
  }


  // pantalla remitente
  async obtenerPais(tipo: string, idsesion: number, token: string) {
    let url = `${environment.URL_APIPAQ}/obtenerPaises?Tipo=${tipo}&idsesion=${idsesion}`

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };

    return this.http.get(url, httpOptions).toPromise();
  }

  async obtenerEstado(idpais: number, tipo: string, idsesion: number, token: string) {
    let url = `${environment.URL_APIPAQ}/obtenerEstados?idpais=${idpais}&Tipo=${tipo}&idsesion=${idsesion}`
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.http.get(url, httpOptions).toPromise();
  }

  async obtenerCiudades(idedo: number, tipo: string, idsesion: number, token: string) {
    let url = `${environment.URL_APIPAQ}/obtenerCiudades?idedo=${idedo}&Tipo=${tipo}&idsesion=${idsesion}`
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };

    return this.http.get(url, httpOptions).toPromise();
  }

  async obtenerColonias(idedo: number, idcd: number, tipo: string, idsesion: number, token: string) {
    let url = `${environment.URL_APIPAQ}/obtenerColonias?idedo=${idedo}&idcd=${idcd}&Tipo=${tipo}&idsesion=${idsesion}`
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };

    return this.http.get(url, httpOptions).toPromise();
  }

  // guadar cambios por sesion en pantalla documetar guia
  async GwoprsesionDetV2(doc: documentacion, token: string) {

    const url = `${environment.URL_APIPAQ}/GwoprsesionDetV2`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };

    return this.http.post<any>(url, doc, httpOptions).toPromise();
  }


  //tipo de paquete
  async obtenerTipoEnvio(idsesion: number, token: string) {
    let url = `${environment.URL_APIPAQ}/GwTipoEnvio?idsesion=${idsesion}`
    return this.http.get(url).toPromise();
  }

  async verificar_tipo(idsesion: number, concepto: tipo[], token: string) {

    const url = `${environment.URL_APIPAQ}/GwVerificartiposV2?idsesion=${idsesion}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };


    return this.http.post<any>(url, concepto, httpOptions).toPromise();
  }

  // ----- pantalla detalle -------
  // obtener datos checkbox

  async GwDetEnvioV2(det: detalle, idsesion: number, token: string) {

    const url = `${environment.URL_APIPAQ}/GwDetEnvioV2?idsesion=${idsesion}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };


    return this.http.post<detalle>(url, det, httpOptions).toPromise();
  }


  async obtenerImporteGuia(token: string,origen: string, destino: string, peso: number, tipocobro: string,entrega: string,descripcion: string,clasificacion: string) {
    let url = `${environment.URL_APIPAQ}/obtenerImporteGuia?origen=${origen}&destino=${destino}&peso=${peso}&tipocobro=${tipocobro}&entrega=${entrega}&descripcion=${descripcion}&clasificacion=${clasificacion}`
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.http.get(url, httpOptions).toPromise();
  }

  async ValidarCupon(idsesion: number,cupon: string, token: string,importe: string) {
    let url = `${environment.URL_APIPAQ}/validarcupon?idsesion=${idsesion}&cupon=${cupon}&importe=${importe}`
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.http.get(url, httpOptions).toPromise();
  }


  //------ pantalla de confirmacion ---------
  //obtener datos de la guias

  async cargarGuiacompleta(idsesion: number, idcliente: number, token: string) {

    let url = `${environment.URL_APIPAQ}/Gwcargarpaquete?sesion=${idsesion}&idcliente=${idcliente}`
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };

    return this.http.get(url, httpOptions).toPromise();
  }

  //obtener datos de la guias

  async finalizardoc(idsedet: number, idsesion: number, idcliente: number, token: string) {

    let url = `${environment.URL_APIPAQ}/Gwfinalizardoc?idsedet=${idsedet}&idsesion=${idsesion}&idcliente=${idcliente}`
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };

    return this.http.get(url, httpOptions).toPromise();
  }


  //--------------solicitar guias web---------------------
  async obtenerTipoGuias(token: string) {
    let url = `${environment.URL_APIPAQ}/Gwtiposcombo`
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };

    return this.http.get(url, httpOptions).toPromise();
  }


  //cargar historial de guias

  async GwgetsolWeb(idcliente: number) {
    let url = `${environment.URL_APIPAQ}/GwgetsolWeb?idcliente=${idcliente}`
    return this.http.get(url).toPromise();
  }

  //solicitar guias web
  async oprSolicitud(idcliente: number, tipo: string, cantidad: number, peso: number, largo: number, ancho: number, alto: number, comentario: string, valorD: string, tipopaq: number, token: string) {
    let url = `${environment.URL_APIPAQ}/GwOprSolicitud?opr=ALT&idsol=0&idcliente=${idcliente}&tipo=${tipo}&cantidad=${cantidad}&peso=${peso}&largo=${largo}&ancho=${ancho}&alto=${alto}&comentario=${comentario}&valordeclarado=${valorD}&tipopaquete=${tipopaq}`
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };

    return this.http.get(url, httpOptions).toPromise();
  }


  //------ pantalla de Historial guia ---------
  //obtener historial guia

  async gwHistorialGuia(idcliente: number, finicio: string, ffinal: string, token: string) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };

    let url = `${environment.URL_APIPAQ}/GwHistorialguias?idcliente=${idcliente}&finicio=${finicio}&ffinal=${ffinal}`
    return this.http.get(url, httpOptions).toPromise();
  }

  async ObtenerSolDetalle(idcliente: number, finicio: string, ffinal: string, token: string) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };

    let url = `${environment.URL_APIPAQ}/ObtenerSolDetalle?idcliente=${idcliente}&fchinicial=${finicio}&fechaFin=${ffinal}`
    return this.http.get(url, httpOptions).toPromise();
  }


  async CargarSolDetxGuias(idsol: number, token: string) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };

    let url = `${environment.URL_APIPAQ}/CargarSolDetxGuias?idsol=${idsol}`
    return this.http.get(url, httpOptions).toPromise();
  }



  //------ pantalla de Historial factura ---------
  //obtener historial factura

  async gwHistorialFactura(idcliente: number, finicio: string, ffinal: string, token: string) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };

    let url = `${environment.URL_APIPAQ}/GwHistorialfactura?idcliente=${idcliente}&finicio=${finicio}&ffinal=${ffinal}`
    return this.http.get(url, httpOptions).toPromise();
  }


  async facturapdf(tokken: string, archivo: string, iReferencia: string, tipo: string) {

    iReferencia.toString();
    //archivo = archivo.replace(" ", "");
    window.location.href = "https://ventas.tufesa.com.mx/wsrestwebjsonpaq/facturapdf?tokken=" + tokken + "&archivo=" + archivo + "&iReferencia=" + iReferencia.toString() + "&tipo=" + tipo + "";
    // window.location.href = "https://ventas.tufesa.com.mx/WSRestWEBJSONPaq/facturapdf?tokken=" + tokken + "&archivo=" + archivo + "&iReferencia=" + iReferencia + "&tipo=" + tipo + "";
  }


  async facElectronicaPdf(seriefol: string, idref: string) {
    window.location.href = "https://ventas.tufesa.com.mx/wsrestfe/ABC123/facturapdf?archivo=" + seriefol + "&ireferencia=" + idref + "&tipocomprobante=PAQ";

  }

  async facElectronicaXml(seriefol: string, idref: string) {
    window.location.href = "https://ventas.tufesa.com.mx/wsrestfe/ABC123/facturaxml?archivo=" + seriefol + "&ireferencia=" + idref + "&tipocomprobante=PAQ";
  }


}

