import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { GuiaswebServiceService } from '../../servicios/guiasweb-service.service';
import { Router } from '@angular/router';
import { documentacion } from '../../entidades/gwdocumentacion';
import { detalle } from 'src/app/entidades/gwdetalle';
import { MensajePopup } from 'src/app/entidades/MensajePopup';
import jwt_decode from 'jwt-decode';
import { HttpErrorResponse } from '@angular/common/http';
import { MensajeService } from 'src/app/servicios/mensaje.service';

@Component({
  selector: 'app-gw-doc-detalle',
  templateUrl: './gw-doc-detalle.component.html',
  styleUrls: ['./gw-doc-detalle.component.css']
})
export class GwDocDetalleComponent implements OnInit {

  submittedDetalle: boolean;
  docDetalleForm: FormGroup;
  datagw: any;
  doc: documentacion;
  valcheck: any;
  det: detalle
  tipopaquete: string;

  token: string;
  tokendatos: any;

  constructor(
    private GuiaswebServiceService: GuiaswebServiceService,
    private router: Router,
    private mensajeService: MensajeService
  ) { }

  ngOnInit(): void {
    this.validarsesion();
      this.tipopaquete = decodeURIComponent(escape(window.atob( sessionStorage.getItem('gwTipopaq') )));
      var docaux = decodeURIComponent(escape(window.atob( sessionStorage.getItem('gwDocumentacion') )));
    this.doc = JSON.parse(docaux);

    this.DocumentacionDetalleForm();
    this.det = this.doc.detalle;
    this.GwDetEnvio();
    this.cargardatosGuardatos();

    window.scrollTo(0,0);

    if(this.doc.remitente.colonia != -1 )
    {
      this.doc.detalle.recoleccion = 1
      this.docDetalleForm.controls.recoleccion.setValue(true);

    }

  }


  //form de detalle el documento
  DocumentacionDetalleForm() {
    this.docDetalleForm = new FormGroup({
      //detalle
      porcobrar: new FormControl(false),
      recoleccion: new FormControl(false),
      acuse: new FormControl(false),
      ValorDeclarado: new FormControl(),
      comentario: new FormControl(),
    });
  }


  get DocDetalle() { return this.docDetalleForm.controls; }

  validarsesion(){
    if(sessionStorage.getItem('token') == undefined || sessionStorage.getItem('token') == ""  || sessionStorage.getItem('token') == null ){
      this.mensajeService.subject$.next(new MensajePopup("Su sesión ha expirado", false, false));
      this.router.navigate(['/herramientasDigitales/login']);
    }

    if(sessionStorage.getItem('gwTipopaq') == undefined || sessionStorage.getItem('gwTipopaq') == ""  || sessionStorage.getItem('gwTipopaq') == null || sessionStorage.getItem('gwDocumentacion') == undefined || sessionStorage.getItem('gwDocumentacion') == ""  || sessionStorage.getItem('gwDocumentacion') == null){
      this.mensajeService.subject$.next(new MensajePopup("Su sesión ha expirado", false, false));
      this.router.navigate(['/herramientasDigitales/documentacion/menu']);
    }
  }


  cargardatosGuardatos() {
      if(this.det.cobrar == 1){
        this.docDetalleForm.controls.porcobrar.setValue(true);
      }
      if(this.det.recoleccion == 1){
        this.docDetalleForm.controls.recoleccion.setValue(true);
      }
      if(this.det.acuse == 1){
        this.docDetalleForm.controls.acuse.setValue(true);
      }
      this.docDetalleForm.controls.comentario.setValue(this.doc.detalle.comentario);
      this.docDetalleForm.controls.ValorDeclarado.setValue(this.doc.detalle.valordec);


  }

  //creamos la sesion en el paso 5
  async guardarsesion_5() {
    this.validarsesion();
    this.submittedDetalle = true;

    if (this.docDetalleForm.valid) {
      this.llenardetalle();
      this.doc.paso = 5;
      await this.GuiaswebServiceService.GwoprsesionDetV2(this.doc, this.token).then(
        d => {
          this.datagw = d

          // agregamos los datos para el paso 6
          this.doc.idsedet = this.datagw.idsedet;
          this.doc.idsesion = this.datagw.idsesion;

          sessionStorage.removeItem('gwDocumentacion');
          sessionStorage.setItem('gwDocumentacion',  window.btoa(unescape(encodeURIComponent(JSON.stringify(this.doc) ))));
          this.GuiaswebServiceService.setplantilla(6);
        }
      ).catch((error: HttpErrorResponse) => {
        if (error.error.ExceptionMessage == "invalid_token") {
          this.mensajeService.subject$.next(new MensajePopup("Su sesión ha expirado", false, false));
          this.router.navigate(['/herramientasDigitales/login']);
        } else {
          this.mensajeService.subject$.next(new MensajePopup("ocurrio un error inesperado al iniciar sesión en tu cuenta", false, false));
        }
      });
    }

  }


  // carga los check box
  async GwDetEnvio() {

    await this.GuiaswebServiceService.GwDetEnvioV2(this.det, this.doc.idsesion, this.token).then(
      d => {
        this.valcheck = d;
        if (this.valcheck.cobrar == 0) {
          this.docDetalleForm.controls.porcobrar.disable();
        } else {
          this.docDetalleForm.controls.porcobrar.enable();
        }
        if (this.valcheck.recoleccion == 0 || this.doc.remitente.colonia == -1) {
          this.docDetalleForm.controls.recoleccion.disable();

          this.valcheck.recoleccion = 0;

        } else {

          this.docDetalleForm.controls.recoleccion.enable();
        }
        if (this.valcheck.acuse == 0) {
          this.docDetalleForm.controls.acuse.disable();
        } else {
          this.docDetalleForm.controls.acuse.enable();
        }
      }
    ).catch((error: HttpErrorResponse) => {
      if (error.error.ExceptionMessage == "invalid_token") {
        this.mensajeService.subject$.next(new MensajePopup("Su sesión ha expirado", false, false));
        this.router.navigate(['/herramientasDigitales/login']);
      } else {
        this.mensajeService.subject$.next(new MensajePopup("ocurrio un error inesperado al iniciar sesión en tu cuenta", false, false));
      }
    });
  }

  //al seleccionar un check box mandamos modificamos el det y cargamos de nuevo los chec box
  cambiarcheck(caso: string) {
    switch (caso) {
      case 'cobrar':
        if (this.det.cobrar == 1) {
          this.det.cobrar = 0
        } else {
          this.det.cobrar = 1
        }
        this.GwDetEnvio();
        break;
      case 'recoleccion':
        if (this.det.recoleccion == 1) {
          this.det.recoleccion = 0
        } else {
          this.det.recoleccion = 1
        }
        this.GwDetEnvio();
        break;

      case 'acuse':
        if (this.det.acuse == 1) {
          this.det.acuse = 0
        } else {
          this.det.acuse = 1
        }
        this.GwDetEnvio();
        break;

      default:
        break;
    }

  }

  //al seleccionar continuar guardamos los valores en la estructura
  llenardetalle() {
    if (this.docDetalleForm.controls.porcobrar.value) {
      this.doc.detalle.cobrar = 1

    }
    else {
      this.doc.detalle.cobrar = 0;
    }

    if (this.docDetalleForm.controls.recoleccion.value) {
      this.doc.detalle.recoleccion = 1
    }
    else {
      this.doc.detalle.recoleccion = 0;
    }

    if (this.docDetalleForm.controls.acuse.value) {
      this.doc.detalle.acuse = 1
    }
    else {
      this.doc.detalle.acuse = 0;
    }

    this.doc.detalle.valordec = this.docDetalleForm.controls.ValorDeclarado.value
    this.doc.detalle.comentario = this.docDetalleForm.controls.comentario.value


  }

 // limpiamos la pantalla detalle
  limpiarDetalle() {
    this.docDetalleForm.controls.porcobrar.setValue('');
    this.docDetalleForm.controls.recoleccion.setValue('');
    this.docDetalleForm.controls.acuse.setValue('');
    this.docDetalleForm.controls.ValorDeclarado.setValue('');
    this.docDetalleForm.controls.comentario.setValue('');
    this.submittedDetalle = false;
    window.scrollTo(0,0);
  }

  regresarMenu(){

    this.GuiaswebServiceService.setplantilla(4);

     }
}
