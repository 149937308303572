import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CotizacionService } from 'src/app/servicios/cotizacion.service';
import { cotizaAvanzado } from 'src/app/entidades/cotizaAvanzado';

@Component({
  selector: 'app-cotizacion-resumen',
  templateUrl: './cotizacion-resumen.component.html',
  styleUrls: ['./cotizacion-resumen.component.css']
})
export class CotizacionResumenComponent implements OnInit {
  cotizacion: cotizaAvanzado;
  total: number = 0;

  isClassActive: boolean = true;

  inputnombre:string="";
  inputapellido:string="";
  inputtelefono:string="";
  inputcorreo:string="";

  rspregcot:any;

  constructor(
    private router: Router,
    private cotizacionService: CotizacionService
  ) { }

  ngOnInit() {
    this.cotizacionService.cotizacion.subscribe(
      datos => {
        if(datos){
          this.cotizacion = datos;
          this.total = parseInt(this.cotizacion.costoBase) + parseInt(this.cotizacion.cobrar) + parseInt(this.cotizacion.seguro) + parseInt(this.cotizacion.domicilio) + parseInt(this.cotizacion.recoleccion) + parseInt(this.cotizacion.embalaje) + parseInt(this.cotizacion.acuse);

        }
      }
    );
  }

  backCotizacion(){
    this.router.navigate(['/cotizacion']);
  }

  openCloseInfo(){
    this.isClassActive = !this.isClassActive;

    //inicializar los inputs
    this.inputnombre = "";
    this.inputapellido = "";
    this.inputcorreo = "";
    this.inputtelefono = "";
  }

  solicitarMasInfo(){
    if (this.inputnombre ===""){
      alert("Capture su nombre") ;
      return; 
    }

    if (this.inputapellido ===""){
      alert("Capture su apellido") ;
      return;       
    }

    if (this.inputcorreo ===""){
      alert("Capture su correo") ;
      return;       
    }


    if (this.inputtelefono ===""){
      alert("Capture su teléfono") ;
      return;       
    }
    //continuar con el proceso de envío
    
    this.cotizacionService
    .regsolcotizacion( "ALT","0",this.inputapellido ,this.inputnombre,this.inputcorreo , this.inputtelefono,this.cotizacion.idcotizacion, "AVZ" )
    .subscribe((datos) => {
      this.rspregcot = datos
      if (this.rspregcot.id > 0) {
        //ocultar formulario
        this.isClassActive = true;
        
        //volver a dejar vacios los inputs
        this.inputnombre = "";
        this.inputapellido = "";
        this.inputcorreo = "";
        this.inputtelefono = "";
      }
    });

    //ocultar formulario
     this.isClassActive = true;

       //volver a dejar vacios los inputs
       this.inputnombre = "";
       this.inputapellido = "";
       this.inputcorreo = "";
       this.inputtelefono = "";
  }

}
