<app-gw-menusuperior></app-gw-menusuperior>
<div class="gw-titulogeneral">
    <h1 class="tituloEstructura">Documentar</h1>
    <app-gw-menu-confirmacion></app-gw-menu-confirmacion>
    <app-gw-doc-remitente *ngIf="numeropantalla == 2"></app-gw-doc-remitente>
    <app-gw-doc-destinatario *ngIf="numeropantalla == 3"></app-gw-doc-destinatario>
    <app-gw-doc-tipo *ngIf="numeropantalla == 4"></app-gw-doc-tipo>
    <app-gw-doc-detalle *ngIf="numeropantalla == 5"></app-gw-doc-detalle>
    <app-gw-doc-completa *ngIf="numeropantalla == 6"></app-gw-doc-completa>
</div>
