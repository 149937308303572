import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-titulo',
  templateUrl: './titulo.component.html',
  styleUrls: ['./titulo.component.css']
})
export class TituloComponent implements OnInit {
  @Input('inputTitulo') titulo: string = '';
  @Input('inputSubtitulo') subtitulo = '';

  constructor() { }

  ngOnInit() {
  }

}
