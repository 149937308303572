<link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.6.3/css/all.css"
    integrity="sha384-UHRtZLI+pbxtHCWp1t77Bi1L4ZtiqrqD80Kn4Z8NTSRyMA2Fd33n5dQ8lWUE00s/" crossorigin="anonymous">

<footer class="footer-section">
    <div class="container" style="max-width: 80%;margin-left: 10%;">
        <div class="footer-content pt-5 pb-5">
            <div class="row">

                <div class="col-xl-2 col-lg-4 col-md-6 mb-30">
                    <div class="footer-widget">
                        <div class="footer-widget-heading">
                            <h3>HERRAMIENTAS</h3>
                        </div>
                        <ul>
                            <li><a id="rastreafooter" routerLink="paqueteria/rastreo/captura">Rastrea</a></li>
                            <li><a id="enviafooter" routerLink="">Envía</a></li>
                            <li><a id="cotizafooter" routerLink="/cotizacion">Cotiza tu envío</a></li>
                            <li><a id="sucursalesfooter" routerLink="/sucursales">Sucursales</a></li>
                            <li><a id="recoleccionfooter" routerLink="paqueteria/recoleccion">Recolección</a></li>
                            <li><a id="facturacionfooter" routerLink="facturacion">Facturación</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-4 col-md-6 mb-30">
                    <div class="footer-widget">
                        <div class="footer-widget-heading">

                            <h3>SERVICIOS</h3>
                        </div>
                        <ul>
                            <li><a id="mensajeriapaqueteriafooter" routerLink="/mensajeria">Mensajeria y Paquetería</a></li>
                            <li><a id="cargafooter" routerLink="carga">Carga</a></li>
                            <li><a id="deliveryfooter" routerLink="delivery">Delivery</a></li>
                            <li><a id="deliveryfooter" routerLink="tufesapack-horas">TufesaPack Horas</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-4 col-md-6 mb-30">
                    <div class="footer-widget">
                        <div class="footer-widget-heading">
                            <h3>DE INTERÉS</h3>
                        </div>
                        <ul>
                            <li><a id="preguntasfrecuentesfooter" routerLink="preguntasFrecuentes">Preguntas frecuentes</a></li>
                            <li><a id="tipsdeseguridadfooter" routerLink="informacion/tipsseguridad">Tips de seguridad</a></li>
                            <li><a id="avisoprivacidadfooter" routerLink="informacion/avisoPrivacidad">Aviso de privacidad</a></li>
                            <li><a id="contactofooter" routerLink="contacto">Contacto</a></li>
                            <li><a id="bolsadetrabajofooter" routerLink="bolsatrabajo">Bolsa de trabajo</a></li>
                            <!-- <li><a routerLink="condiciones">Términos y condiciones</a></li> -->
                            <li><a id="contratoadhesionfooter" routerLink="contrato/adhesion">Contrato de adhesión</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-4 col-md-6 mb-30">
                    <div class="footer-widget">
                        <div class="footer-widget-heading">
                            <h3>NOSOTROS</h3>
                        </div>
                        <ul>
                            <li><a id="grupotufesafooter" routerLink="/grupo-tufesa">Grupo Tufesa</a></li>
                            <li><a id="nuestrasmarcasfooter" routerLink="/nuestras-marcas">Nuestras Marcas</a></li>
                            <li><a id="tufesafooter" href="https://www.tufesa.com.mx/" target="_blank">Tufesa</a></li>
                            <li><a id="directoriofooter" routerLink="informacion/directorio">Directorio</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-6 mb-30">
                    <div class="footer-widget">
                        <div class="footer-widget-heading">
                            <h3></h3>
                        </div>
                        <div class="footer-text">
                            <p style="font-weight: bold;">Síguenos</p>
                        </div>
                        <div class="footer-social-icon">
                            <a href="https://www.instagram.com/tufesapack" target="blank"><i
                                    class="fab fa-instagram social-icon-bg"></i></a>
                            <a href="https://www.facebook.com/TufesaPack" target="blank"><i
                                    class="fab fa-facebook-f social-icon-bg"></i></a>
                            <a href="https://twitter.com/tufesapack" target="blank"><i
                                    class="fab fa-twitter social-icon-bg"></i></a>
                            <!-- <a href="https://twitter.com/tufesapack" target="blank">
                              <img src="assets/iconos/x-twitter.svg" class="social-icon-bg">
                            </a> -->
                            <a href="https://wa.me/526444162668" target="_blank"><i
                                    class="fab fa-whatsapp social-icon-bg"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="copyright-area">
        <div class="container">
            <div class="row">
                <div class="col-xl-6 col-lg-6 text-center text-lg-left">
                    <div class="copyright-text">
                        <p>2024 GRUPO TUFESA®</p>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 d-none d-lg-block text-right">
                    <div class="footer-menu">
                        <ul>
                            <!-- <li><a routerLink="condiciones">Terminos y Condiciones</a></li> -->
                            <li><a routerLink="informacion/avisoPrivacidad">Politicas de Privacidad</a></li>
                            <li><a href="#">Mapa de Sitio</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <script type="text/javascript">
        (function(e,t,o,n,p,r,i){e.visitorGlobalObjectAlias=n;e[e.visitorGlobalObjectAlias]=e[e.visitorGlobalObjectAlias]||function(){(e[e.visitorGlobalObjectAlias].q=e[e.visitorGlobalObjectAlias].q||[]).push(arguments)};e[e.visitorGlobalObjectAlias].l=(new Date).getTime();r=t.createElement("script");r.src=o;r.async=true;i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)})(window,document,"https://diffuser-cdn.app-us1.com/diffuser/diffuser.js","vgo");
        vgo('setAccount', '612243746');
        vgo('setTrackByDefault', true);

        vgo('process');
    </script>
</footer>
