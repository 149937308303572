<div class="container_herramienta">
    <img src="assets/img/web/tfph_transp.webp" class="img_toAnimate">
    <div class="alinearIzq">
        <h1 class="txt-section">TufesaPack Horas</h1>
            <div class="descripcion_cont">
            <p>
                <b>Servicio TufesaPack Horas</b><br>
                El servicio de TufesaPack Horas garantiza que tus paquetes lleguen el mismo día en el que los envías.
            </p>
            <p>
                <b>Condiciones</b><br>
                Para el servicio de TufesaPack Horas, se permiten envíos de sobre de 0 a 1 kilo y de caja de 0 a 10 kilos. La tarifa se basa en peso volumétrico, aplicable a peso y dimensiones reales del paquete. Solo servicio ocurre - ocurre, no servicio a domicilio. 
            </p>
            <p>
                <b>Cobertura</b><br>
                El servicio de TufesaPack Horas es válido únicamente en:
                <br><br>
               <li>Envíos saliendo desde la sucursal de Ciudad Obregón hacia las sucursales de Guaymas, Empalme, Hermosillo, Navojoa, Los Mochis.</li>
                <li>Envíos saliendo de la sucursal de Culiacán (solo sucursal terminal de autobuses Alfonso G. Calderón Col. Nuevo Milenium Local 7 Central de Autobuses, Culiacán, Sin.) hacia las sucursales Guamúchil, Guasave, Los Mochis, Mazatlán.</li>
                <li>Envíos saliendo de la sucursal Cananea hacia las sucursales de Agua Prieta y Hermosillo (solo sucursal terminal Blvd. Luis Encinas #337 Col. Universidad, C.P. 83040, Hermosillo, Son.)</li>
            </p>
            <p>
                <b>Tiempo de envío y recolección</b><br>
                TufesaPack Horas aplica y se podrá contratar solamente cuando el cliente realice el envío del paquete de lunes a viernes antes de los siguientes horarios:
                <br><br>
                <li>Cobertura estipulada en Ciudad Obregón: envíos antes las 12:00 p.m., de lunes a viernes.</li>
                <li>Cobertura estipulada en Culiacán: envíos antes de las 11:00 a.m., de lunes a viernes.</li>
                <li>Cobertura estipulada en Cananea: envíos antes de las 12:00 p.m., de lunes a viernes.</li>
            </p>

            <p>
                Para recolectar el paquete, el mismo día, el destinatario deberá de acudir a sucursal en los siguientes horarios:
                <br><br>
                <li>Empalme a las 4:00 p.m.</li>
                <li>Hermosillo Centro Operativo, Guaymas, Navojoa, Los Mochis, Guamúchil, Guasave y Mazatlán a las 7:00 p.m. </li>
                <li>Agua Prieta a las 8:00 p.m.</li>
            </p>

            <p>
                <b>Compra y Pago</b><br>
                Válido únicamente en compras dentro de las sucursales, no compras digitales. Se acepta pago en efectivo y tarjeta (donde se cuente con este método de pago). Válido en envío pagado, no por cobrar ni contratos prepago.
            </p>

            <p>
                <b>Garantía</b><br>
                El servicio TufesaPack Horas cuenta con una garantía la cual abarca en que si el envío no llega dentro del tiempo estipulado, se otorga 1 envío gratis, servicio ocurre - ocurre, peso hasta 10 kilos y mismos destinos que se contrataron. Se tendrá 30 días posteriores al envío, para hacer válida esta garantía.
            </p>

            <p>
                <b>Políticas</b><br>
                Los cambios y cancelaciones están sujetos a las políticas estándar de TufesaPack® y se reserva el derecho de modificar o cancelar este servicio en cualquier momento sin previo aviso.
            </p>
        </div>
    </div>
</div>
