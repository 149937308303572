<app-gw-menusuperior></app-gw-menusuperior>
<br><br>
<br><br>
<!-- tabla de historial de recolección  con angular material y boostrap-->

<div class="container" *ngIf="isLoguedin === true">
  <div class="row">
    <div class="col-12" style="padding-right: 30px;">
      <mat-card>
        <h1 style="text-align: center;">Listado de recolección</h1>
        <div>
          <input id="input" matInput (keyup)="applyFilter($event)" placeholder="Filtrar">
        </div>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" id="tbl_hist" data-ng-init="cambiarColor()">
          <div class="left-column"></div>
          <!-- Columna de folio -->
          <ng-container matColumnDef="Folio">
            <th mat-header-cell *matHeaderCellDef id="tableth" class="col_id" style="border-radius: 6px 0 0 0;"> Folio
            </th>
            <td mat-cell *matCellDef="let element" id="tabletd" class="col_id">{{element.folio}}</td>
          </ng-container>

          <!-- Columna de nombre -->
          <ng-container matColumnDef="Nombre">
            <th mat-header-cell *matHeaderCellDef id="tableth" class="col_nombre"> Nombre </th>
            <td mat-cell *matCellDef="let element" id="tabletd" class="col_nombre2">{{element.Nombre}} </td>
          </ng-container>
          <!-- Columna de estatus -->
          <ng-container matColumnDef="Estatus">
            <th mat-header-cell *matHeaderCellDef id="tableth" class="col_estatus"> Estatus </th>
            <td mat-cell *matCellDef="let element" id="tabletd" class="col_estatus">{{element.Estatus}}</td>
          </ng-container>

          <!-- Columna de correo -->
          <ng-container matColumnDef="Correo">
            <th mat-header-cell *matHeaderCellDef id="tableth" class="col_correo"> Correo </th>
            <td mat-cell *matCellDef="let element" id="tabletd" class="col_correo">{{element.Correo}}</td>
          </ng-container>

          <!-- Columna de telefono -->
          <ng-container matColumnDef="Telefono">
            <th mat-header-cell *matHeaderCellDef id="tableth" class="col_tel"> Telefono </th>
            <td mat-cell *matCellDef="let element" id="tabletd" class="col_tel">{{element.Telefono}}</td>
          </ng-container>

          <!-- Columna de estado -->
          <ng-container matColumnDef="Estado">
            <th mat-header-cell *matHeaderCellDef id="tableth" class="col_edo"> Estado </th>
            <td mat-cell *matCellDef="let element" id="tabletd" class="col_edo">{{element.Estado}}</td>
          </ng-container>
          <!-- Columna de ciudad -->
          <ng-container matColumnDef="Ciudad">
            <th mat-header-cell *matHeaderCellDef id="tableth" class="col_cd"> Ciudad </th>
            <td mat-cell *matCellDef="let element" id="tabletd" class="col_cd">{{element.Ciudad}}</td>
          </ng-container>
          <!-- Columna de colonia -->
          <ng-container matColumnDef="Colonia">
            <th mat-header-cell *matHeaderCellDef id="tableth" class="col_col"> Colonia </th>
            <td mat-cell *matCellDef="let element" id="tabletd" class="col_col">{{element.Colonia}}</td>
          </ng-container>

          <!-- Columna de direccion -->
          <ng-container matColumnDef="Direccion">
            <th mat-header-cell *matHeaderCellDef id="tableth" class="col_dir"> Direccion </th>
            <td mat-cell *matCellDef="let element" id="tabletd" class="col_dir">{{element.Direccion}}</td>
          </ng-container>

          <!-- Columna de referencia -->
          <ng-container matColumnDef="Referencia">
            <th mat-header-cell *matHeaderCellDef id="tableth" class="col_ref" style="border-radius: 0px 6px 0 0;">
              Referencia </th>
            <td mat-cell *matCellDef="let element" id="tabletd" class="col_ref">{{element.Referencia}}</td>
          </ng-container>



          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="fila_tbl" data-toggle="modal"
            data-target="#exampleModal" (click)="cambiarestatusimg()" (click)="highlight(row)"></tr>
        </table>

        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons
          aria-label="Select page of periodic elements">
        </mat-paginator>
      </mat-card>
    </div>
  </div>
</div>
<br><br>
<br>


<div class="mdl_recolecc">
  <!-- Modal container -->
  <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="title_modal">Detalle de recolección</h1>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="container_detail">
            <div class="banner_detail">
              <img src="assets/img/hero/promociones/5.jpg">
            </div>
            <div class="data_client">
              <h3 id="client_rec"><b>Cliente:</b> {{nombremdl}} </h3>
              <h3 id="txt_fol"><b>Folio:</b> {{foliomdl}}</h3>
            </div>
          </div>
          <div class="status_rec">

            <div class="stepper d-flex flex-column mt-5 ml-2">
              <div class="d-flex mb-1" *ngFor="let estatusrec of estatusrecols">
                <div class="d-flex flex-column pr-4 align-items-center">
                  <div class="rounded-circle py-2 px-3 bg-primary text-white mb-1" id="circle_step"><img
                      class="img_box2" id="imgbox" src={{estatusrec.ImgEstatus}}></div>
                  <div class="line h-100"></div>
                </div>
                <div>
                  <h5 class="text-dark" id="titulo">{{estatusrec.Titulo}}</h5>
                  <p class="lead text-muted pb-3" id="status">{{estatusrec.Status}}</p>
                  <p class="lead text-muted pb-3"><b>Dir. de recoleccion:</b> {{estatusrec.Direccion}}</p>
                  <p class="lead text-muted pb-3">Paquete solicitado por: {{estatusrec.EntregoSolicito}}</p>
                  <p class="lead text-muted pb-3"><b>Fecha:</b> {{estatusrec.Fecha}} </p>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>