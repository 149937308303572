import { Injectable } from '@angular/core';
declare var gtag;

@Injectable({
  providedIn: 'root'
})
export class GoogleTagManagerService {

  constructor() { }

  load( valor, transaction_id, paquete) {
    try {
      valor = parseInt(valor);

      // medir google adds
      /*(window as any).gtag('event',
        'conversion',
        {
          'send_to': 'AW-846359833/KQ4LCLfasOUBEJnayZMD',
          'value': valor,
          'currency': 'MXN',
          'transaction_id': transaction_id
        });*/

      var items = [];
      //boletos.forEach((b, i) => {
        var item = {
          sku: paquete.folio, //identifacodr,
          name: paquete.ciudadOrigen +", "+ paquete.origen, //ciudad, colonia
          category: paquete.ciudadDestino +", "+ paquete.destino,
          price: parseInt(valor),
          quantity: 1
        };
        items.push(item);
        // console.log("se agrego el item" + paquete.folio);
      //});

      var body = {
        "transaction_id": transaction_id,
        "affiliation": "TUFESA online",
        "value": valor,
        "currency": "MXN",
        "transactionProducts": items
      };

      // console.log("se agrego el body");
      // google analitycs
      (window as any).gtag('event', 'purchase', body);

      // console.log(" (window as any).gtag('event', 'purchase', body);");
     // forma 2 de la pagina de google analitycs
     (window as any).dataLayer = (window as any).dataLayer || [];
     (window as any).dataLayer.push({
       'transactionId': transaction_id,
       'transactionAffiliation': 'TUFESA online',
       'transactionTotal': valor,
       'transactionProducts': items
     });
      // console.log("todo bien");
    } catch (error) {
      // console.log(error);
    }
  }

  load2(tipo, valor, transaction_id, boletos) {
    try {

      valor = parseInt(valor);

      // medir google adds
      gtag('event',
        'conversion',
        {
          'send_to': 'AW-846359833/KQ4LCLfasOUBEJnayZMD', 'value': valor, 'currency': 'MXN', 'transaction_id': transaction_id
        });

      var items = [];
      boletos.forEach((b, i) => {
        var item = { id: b.idocup, name: b.Nombre, list_name: "", category: "", variant: "", list_position: i + 1, quantity: 1, price: parseInt(b.Cstpsj) };
        items.push(item);
      });

      var body = {
        "transaction_id": transaction_id,
        "affiliation": "TUFESA online",
        "value": valor,
        "currency": "MXN",
        "tax": 0,
        "shipping": 0,
        "items": items
      };
      // google analitycs
      gtag('event', 'purchase', body);
    } catch (error) {

    }
  }
}
