import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Location } from '@angular/common'; // libreria angular para interactuar con el explorador

import { Destino } from '../../entidades/destino'; // clase de destino
import { DestinoService } from '../../servicios/destino.service'; // servicio de destino para obtener y mandar informacion del seleccionado
import { BsModalRef } from 'ngx-bootstrap/modal';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-seleccion-sucursal',
  templateUrl: './seleccion-sucursal.component.html',
  styleUrls: ['./seleccion-sucursal.component.css']
})
export class SeleccionSucursalComponent implements OnInit {
  @Input('inputTipo') tipo: string = ''; // variable que indica que tipo de selecion es, O=Origen, D=Destino
  @Input('inputBsModalRef') modalRef: BsModalRef;

  destinoSeleccionado: Destino; // indica el destino seleccionado
  destinos: Destino[] = []; // array de destinos
  destinosEquiv: Destino[] = [];
  destinosFiltrados: Destino[] = []; // array de destinos
  estado: string = '';
  isSearching: boolean = false;
  isOnSearchChange: boolean = false;
  searchValue: string = '';
  URLUSAindx: string = 'http://www.tufesainternacional.com/index.html';
  destinosActualesSusbcription: Subscription;

  // siempre hay que importar las librerias y servicios seleccionados
  constructor(
    private location: Location,
    private destinoService: DestinoService,
    private deviceService: DeviceDetectorService
  ) { }

  ngOnDestroy(): void {
    this.destinosActualesSusbcription.unsubscribe();

  }

  // al iniciar revisar que path es e indicar si es origen o destino la seleccion
  ngOnInit() {
    // obtener los destinos que se guardaron en el servicio destinoService
    this.destinosActualesSusbcription = this.destinoService.destinosActuales.subscribe(dat => this.destinos = dat);
  }

  // Estado
  isEstadoNuevo(estado: string) {

    if (this.estado !== estado) {
      this.estado = estado;
      return true;
    } else {
      return false;
    }
  }

  ngAfterContentChecked(): void {
  }

  ngAfterViewInit(): void {
    const isDesktopDevice = this.deviceService.isDesktop();
    if (isDesktopDevice === true) {
      document.getElementById('srch').focus();
    }

  }

  onSearchChange(searchValue: string) {
    this.isOnSearchChange = true;
    this.searchValue = searchValue;

    if (searchValue) {
      this.isSearching = true;
      this.searchValue = this.searchValue.toLowerCase();
      this.estado = '';

      let filtro: Destino[];
      filtro = [];

      this.destinos.forEach(d => {
        if (d.PobOrg.toLowerCase().indexOf(this.searchValue) > -1) {
          filtro.push(d);
        }
      });
      this.destinosFiltrados = filtro;
    } else {
      this.isSearching = false;
    }
    this.isOnSearchChange = false;
  }

  // regresar
  goBack(): void {
    this.location.back();
  }

  // funcion cuando seleccionan un destino
  onSelectDestino(destino: Destino): void {
    // depende del tipo guardar el seleccionado
    if (this.tipo === 'O') {
      this.destinoService.changeOrigen(destino);

    } else if (this.tipo === 'D') {
      this.destinoService.changeDestino(destino);
    }
    this.closeModal();
  }

  closeModal() {
    this.modalRef.hide();
  }

  getlanguage() {
    return environment.language;
  }
}
