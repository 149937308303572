<div class="container_historial">
  <h1>Mis Formatos</h1>
  <div class="info_cliente">
    <!-- <p>{{nameCliente}}</p> -->
    <p>{{cadena}}</p>
    <!-- <p>ID Cliente: {{idcliente}}</p> -->
    <p>#{{idUser}}</p>
  </div>
  <!-- <div class="btn_ver" (click)="crearFormato()"><a>Crear</a></div> -->

  <!-- <div class="search-name" id="search_bar">
    <input class="form_control" type="text" name="search" autocomplete="on"
      placeholder="Buscar" id="frm_control"
      [(ngModel)]="valorBuscar" (input)="imprimirValorBuscar()">
    <img src="assets/iconos/busqueda.png" class="search_icon" id="icon_search">
  </div> -->

  <div class="search-name" id="search_bar">
    <input class="form_control" type="text" name="search" autocomplete="on" placeholder="Buscar" id="frm_control" [(ngModel)]="valorBuscar" (input)="buscarEnFormatos()"/>
    <img src="assets/iconos/busqueda.png" class="search_icon" id="icon_search"/>
    <div class="btn-create" (click)="crearFormato()"><a>Crear</a></div>
  </div>

  <ng-container *ngIf="!this.valorBuscar.trim(); else elseTemplate">
    <div class="container_table">
      <table>
        <thead>
          <tr>
            <th>Folio</th>
            <th>Fecha</th>
            <th>Formato</th>
            <th>Estatus</th>
            <th>Detalle</th>
            <th>Share</th>
          </tr>
        </thead>
        <tbody class="tbodyscroll" *ngFor="let formato of misFormatos">
          <tr>
            <td>{{ formato.idrsp }}</td>
            <td>{{ formato.fchini }}</td>
            <td>{{ formato.formato }}</td>
            <td>{{ formato.status }}</td>

            <td>
              <div class="btn_ver" (click)="verFormato(formato.idrsp)">
                <a >Ver</a>
              </div>
            </td>
            <td>
              <div class="btn_ver"(click)="mostrarDialogo(formato.idrsp)">
                <a >Share</a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="list_mobile">
      <div class="container_tables">
        <a href="">
          <table class="table_mobil">
            <thead>
              <tr>
                <th>Folio</th>
                <th>Fecha</th>

                <th>Formato</th>
                <th>Estatus</th>
                <th>Detalle</th>
                <th>Share</th>
              </tr>
            </thead>
            <tbody class="tbodyscroll" *ngFor="let formato of misFormatos">
              <tr>
                <td>{{ formato.idrsp }}</td>
                <td>{{ formato.fchini }}</td>

                <td>{{ formato.formato }}</td>
                <td>{{ formato.status }}</td>

                <td>
                  <div class="btn_ver">
                    <a (click)="verFormato(formato.idrsp)">Ver</a>
                  </div>
                </td>
                <td>
                  <div class="btn_ver">
                    <a (click)="verFormato(formato.idrsp)">Share</a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </a>
      </div>
    </div>
  </ng-container>
  <ng-template #elseTemplate>
    <!-- VERSION PC -->
    <div class="container_table">
      <table>
        <thead>
          <tr>
            <th>Folio</th>
            <th>Fecha</th>

            <th>Formato</th>
            <th>Estatus</th>
            <th>Detalle</th>
            <th>Share</th>
          </tr>
        </thead>
        <tbody class="tbodyscroll" *ngFor="let formato of resultadosBusqueda">
          <tr>
            <td>{{ formato.idrsp }}</td>
            <td>{{ formato.fchini }}</td>
            <td>{{ formato.formato }}</td>
            <td>{{ formato.status }}</td>

            <td>
              <div class="btn_ver">
                <a (click)="verFormato(formato.idrsp)">Ver</a>
              </div>
            </td>
            <td>
              <div class="btn_ver">
                <a (click)="verFormato(formato.idrsp)">Share</a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- VERSION MOBILE -->
    <div class="list_mobile">
      <div class="container_tables">
        <a href="">
          <table class="table_mobil">
            <thead>
              <tr>
                <th>Folio</th>
                <th>Fecha</th>
                <th>Formato</th>
                <th>Estatus</th>
                <th>Detalle</th>
                <th>Share</th>
              </tr>
            </thead>
            <tbody
              class="tbodyscroll"
              *ngFor="let formato of resultadosBusqueda"
            >
              <tr >


                <td>{{ formato.idrsp }}</td>
                <td>{{ formato.fchini }}</td>

                <td>{{ formato.formato }}</td>
                <td>{{ formato.status }}</td>

                <td>
                  <div class="btn_ver">
                    <a (click)="verFormato(formato.idrsp)">Ver</a>
                  </div>
                </td>
                <td>
                  <div class="btn_ver">
                    <a (click)="verFormato(formato.idrsp)">Share</a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </a>
      </div>
    </div>
  </ng-template>
</div>

<!-- Modal para el cuadro de diálogo de compartir formato -->
<div class="modal" tabindex="-1" role="dialog" [ngClass]="{'d-block': mostrarModal}" (click)="cerrarDialogo()">
  <div class="modal-dialog" role="document" (click)="$event.stopPropagation()">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" style="font-weight: bolder; margin-bottom: 4px;">Compartir formato</h3>
        <h3 class="modal-title" style="font-size: 14px; margin-bottom: 0px;"> Folio: {{idrspModal}}</h3>
        <button type="button" class="close" aria-label="Close" (click)="cerrarDialogo()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="modal-body">
            <!-- <select name="select"
            *ngIf="usuarios"
            id="{{ usuarioSelected ? usuarioSelected.idUsr : '' }}"
            [(ngModel)]="usuarioSelected"
            >
            <option value="-1" disabled>Seleccione</option>
            <option *ngFor="let opcionesSelect of usuarios"
             [ngValue]="opcionesSelect.idUsr "
            >
         {{opcionesSelect.nombre}}
         </option>
         </select> -->

         <div class="search-name" id="search_bar">
           <input class="search-user" type="text" name="search-user" autocomplete="on" placeholder="Buscar usuario" id="search-user" (input)="buscarUsuario()" [(ngModel)]="usuarioSeleccionado"/>
           <img src="assets/iconos/busqueda.png" class="search_icon" id="icon_search"/>
         </div>

         <div #contenedorUsuarios class="content-user-list">
          <div class="item-user" *ngFor="let usuario of usuariosFiltrados" (click)="selectusershare(usuario)">
            <label style="font-size: 12px; cursor: pointer;" >{{ usuario.nombre }}</label>
          </div>
         </div>

          </div>
      </div>
      <div *ngIf="mostrarAviso" class="alert alert-warning mt-2 aviso" [class.aviso-oculto]="!mostrarAviso">
        ¡Ruta copiada al portapapeles!
     </div>
      <div class="modal-footer">
        <button type="button" class="btnwidth btn-copi" (click)="copiarLink()">Copiar</button>
        <!-- <button type="button" class="btnwidth btn-cancel" (click)="cerrarDialogo()">Cancelar</button> -->
        <button type="button" class="btnwidth btn-share" (click)="guardarValor()">Compartir</button>
      </div>
    </div>
  </div>
</div>

