<div class="list-group">
    <input id="srch" class="busqueda" placeholder="Buscar" (keyup)="onSearchChange($event.target.value);">
    <div *ngIf="!isSearching">
        <div *ngFor="let destino of destinos" (click)="onSelectDestino(destino)">
            <div class="list-group-item"
                *ngIf="((destino.esOrigen && tipo==='O') || (destino.esDestino && tipo==='D')) && !destino.esEquiv">
                <div class="estado" *ngIf="isEstadoNuevo(destino.Estado)">{{destino.Estado}}</div>
                <div class="ciudad">{{destino.PobOrg}}</div>
            </div>
        </div>
    </div>

    <div *ngIf="isSearching && !isOnSearchChange">
        <div *ngFor="let destino of destinosFiltrados" (click)="onSelectDestino(destino)">
            <div class="list-group-item" *ngIf="(destino.esOrigen && tipo==='O') || (destino.esDestino && tipo==='D')">
                <div class="ciudad">{{destino.Estado}}/{{destino.PobOrg}}</div>
            </div>
        </div>
    </div>

</div>