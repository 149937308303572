<div class="container_herramienta">
  <img src="assets/iconos/info-util/icon_tipsSeg.webp" class="img_toAnimate">
  <div class="alinearIzq">
      <h1 class="txt-section">Tips de seguridad</h1>
   
        <ol>
          <li>Medidas máximas 80 cm. x 80 cm. x 80 cm. Peso máximo 80kg. Todo lo que sea de naturaleza frágil debe estar correctamente empacado. (Televisores, cerámicas, vidrio, etc.).</li>
          <br>
          <li>Las cotizaciones son en base a las medidas cúbicas o al peso.</li>
          <br>
          <li>Considera empaques nuevos para una mayor protección de tus envíos.</li>
          <br>
          <li>Tener a la mano la carta factura que avale el valor de los artículos.</li>
        </ol>

  </div>
</div>