import { Entrega } from "./entrega";
import { Historial } from "./historial";
import { Incidencias } from "./incidencias";

export class Rastreo {
    Rastreo: string;
    cant: string;
    code: string; 
    destinatario: string;
    destino: string;
    dispEntDm: number;
    entrega: Entrega;
    envio: string;
    fecha: string;
    folio: string;
    historial: Historial[];
    hora: string;
    importe: string;
    indicencias: Incidencias[];
    msgnum: number;
    msgtxt: string;
    origen: string;
    rastreoExt: string;
    remitente: string;
    tipo: string;
    movimiento: string;
    ubicacion: string; 
    fchultmov: string; 
    horultmov: string;
    estatus: number;
    domicilio: number;
    rastreotext: string;
}