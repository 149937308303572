import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { MensajeService } from "src/app/servicios/mensaje.service";
import { MensajePopup } from "src/app/entidades/MensajePopup";
import { Router } from "@angular/router";
import { GuiaswebServiceService } from "../../servicios/guiasweb-service.service";
import { cuenta } from "../../entidades/gwcuenta";

@Component({
  selector: "app-gw-usr-registro",
  templateUrl: "./gw-usr-registro.component.html",
  styleUrls: ["./gw-usr-registro.component.css"],
})
export class GwUsrRegistroComponent implements OnInit {
  usrForm: FormGroup;
  submitted: boolean;
  cuenta = new cuenta();
  data: any;
  nombre: boolean = false;
  codigo: boolean = false;
  passimg: string = "assets/iconos/guiasweb/verfalse.png";
  tipopass: string = "password";
  passInvalidad: boolean;

  constructor(
    private GuiaswebServiceService: GuiaswebServiceService,
    private router: Router,
    private formBuider: FormBuilder,
    private mensajeService: MensajeService) {
    this.usrForm = formBuider.group(
      {
        nombre: new FormControl("", [Validators.required]),
        contrasena: new FormControl("", [
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(
            "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9!¡?¿()#:;.,-_%&/@<>|]{8,}$"
          ),
        ]),
        contrasena2: new FormControl("", [
          Validators.required,
          Validators.pattern(
            "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9!¡?¿()#:;.,-_%&/@<>|]{8,}$"
          ),
        ]),

        correo: new FormControl("", [Validators.email, Validators.required]),
        telefono: new FormControl("", [
          Validators.required,
          Validators.pattern("[0-9]{10}"),
        ]),
        cp: new FormControl("", [
          Validators.required,
          Validators.pattern("[0-9]{5}"),
        ]),
        rfc: new FormControl(""),
      },
      {
        validators: this.MustMatch("contrasena", "contrasena2"),
      }
    );
  }

  get usr() {
    return this.usrForm.controls;
  }

  ngOnInit(): void {}

  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  mostrarpassword() {
    if (this.passimg == "assets/iconos/guiasweb/verfalse.png") {
      this.passimg = "assets/iconos/guiasweb/vertrue.png";
      this.tipopass = "text";
    } else {
      this.passimg = "assets/iconos/guiasweb/verfalse.png";
      this.tipopass = "password";
    }
  }

  validarnumeros(e) {
    if (
      !(
        (e.keyCode >= 48 && e.keyCode <= 57) ||
        e.keyCode == 8 ||
        e.keyCode == 9 ||
        e.keyCode == 13 ||
        e.keyCode == 38 ||
        e.keyCode == 40 ||
        e.keyCode == 37 ||
        e.keyCode == 39 ||
        (e.keyCode >= 96 && e.keyCode <= 105)
      )
    ) {
      e.preventDefault();
    }
  }

  //creamos la sesion en el paso 3
  async guardarusr() {
    this.submitted = true;

    if (this.usrForm.valid) {
      this.cuenta.usuario = this.usrForm.controls.nombre.value;
      this.cuenta.contrasena = this.usrForm.controls.contrasena.value;
      this.cuenta.correo = this.usrForm.controls.correo.value;
      this.cuenta.tel = this.usrForm.controls.telefono.value;
      this.cuenta.cp = this.usrForm.controls.cp.value;
      this.cuenta.rfc = this.usrForm.controls.rfc.value;

      await this.GuiaswebServiceService.GwRegistrarUsrv2(this.cuenta).then(
        (d) => {
          this.data = d;
          if (this.data.id > 0) {
            this.mensajeService.subject$.next(
              new MensajePopup(this.data.msg, true, true)
            );
            this.router.navigate(["/herramientasDigitales/login"]);
          } else {
            this.mensajeService.subject$.next(
              new MensajePopup(this.data.msg, false, false)
            );
          }
        }
      );
    }
  }
}



