import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MisformatosService {

  constructor(private http: HttpClient) { }

  cargaRspformatodigital(usr: string): Observable<any[]> {
    let url = `${environment.URL_APIPAQbeta}/cargaRspformatodigital?usr=${usr}`
    return this.http.get<any>(url)
   }
   buscarRspformatodigital(valorBsq: string): Observable<any[]> {
    let url = `${environment.URL_APIPAQbeta}/buscarRspformatodigital?valorBsq=${valorBsq}`
    return this.http.get<any>(url)
   }

   actualizarUsuarioFormatoDigital(body: any): Observable<any[]> {
    let url = `${environment.URL_APIPAQbeta}/oprrspformatodigital`
    return this.http.post<any>(url, body)
   }

   buscarUsuarios(): Observable<any[]> {
    let url = `${environment.URL_APIPAQbeta}/cargarUsuariosTFP`
    return this.http.get<any>(url)
   }

   generarCodigoAcceso(body: any): Observable<any[]> {
    let url = `${environment.URL_APIPAQbeta}/codigosVerFormatos`
    return this.http.post<any>(url, body)
   }


}
