import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { user } from "../../entidades/gwuser";
import jwt_decode from "jwt-decode";
import { ActivatedRoute, Params } from '@angular/router';
 import { pagosService } from 'src/app/servicios/pagos.service';

@Component({
  selector: 'app-historial-compra',
  templateUrl: './historial-compra.component.html',
  styleUrls: ['./historial-compra.component.css']
})
export class HistorialCompraComponent implements OnInit {

searchText: string;
isLoguedin: boolean;

detalleCompra: any;

userGw: user;
datagw: any;
token: string;
tokendatos: any;
idcliente: number=-1;
nameCliente: any;

guiasAdquiridas = [
  {
    id: 1,
    nombreProd:"",
    descripcion: "",
    detalle:"",
    costo: "",
    folio: "",
    fecha: "",
    linkCompra: ""
  }
];

  constructor(private router: Router, private activateRoute: ActivatedRoute, private pagosservice: pagosService,) { }

  ngOnInit(): void {

   

    if(sessionStorage.getItem("token")){
      this.token = sessionStorage.getItem("token");
      this.tokendatos = jwt_decode(this.token);
      this.userGw = this.tokendatos;
      this.isLoguedin = true;
      this.idcliente = this.userGw.idUser;
      this.nameCliente = this.userGw.name;
      this.router.navigate(["/historial-compra"]);
    }
    else {
    this.isLoguedin = false;
    this.router.navigate(["/herramientasDigitales/login"]);
  }
  this.obtenerDetalleCompras();
  }

  Verdetallecompra(url): void {
    
    // Navegar al componente destino con parámetros
    this.router.navigate([url]);
  }

  obtenerDetalleCompras(){
    if(sessionStorage.getItem("token")){
      this.token = sessionStorage.getItem("token");
      this.tokendatos = jwt_decode(this.token);
      this.userGw = this.tokendatos;
      this.idcliente = this.userGw.idUser;
      this.isLoguedin = true;
      }
      else{
        this.idcliente=-1;
        this.isLoguedin = false;
        this.router.navigate(["/herramientasDigitales/login"]);
      }
    this.pagosservice.getHistorialCompras(this.idcliente).then((data) => {
       this.detalleCompra = data;
      this.guiasAdquiridas = this.detalleCompra;
    });
    
  }

}
