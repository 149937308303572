import { Component, OnInit } from '@angular/core';
import { GuiaswebServiceService } from '../../servicios/guiasweb-service.service';
import { user } from '../../entidades/gwuser';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { formatDate } from 'ngx-bootstrap/chronos';
import { Router } from '@angular/router';
import { MensajeService } from 'src/app/servicios/mensaje.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MensajePopup } from 'src/app/entidades/MensajePopup';
import jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-gw-facturas',
  templateUrl: './gw-facturas.component.html',
  styleUrls: ['./gw-facturas.component.css']
})
export class GwFacturasComponent implements OnInit {
  userGw: user;
  factutas: any;

  initialValues1: any;
  initialValues2: any;

  token: string;
  tokendatos: any;

  // fecha
  datePickerConfig: Partial<BsDatepickerConfig>;
  fechaini: string = '';
  fechafin: string = '';
  fch1: Date = new Date();
  fch2: Date = new Date();
  minDate: Date = new Date();

  constructor(
    private GuiaswebServiceService: GuiaswebServiceService,
    private localeService: BsLocaleService,
    private router: Router,
    private mensajeService: MensajeService
  ) {
    this.localeService.use("es");
    this.datePickerConfig = Object.assign(
      {},
      {
        containerClass: "theme-orange",
        isAnimated: true,
        showWeekNumbers: false,
        dateInputFormat: "DD/MM/YYYY"
      }
    );
  }

  ngOnInit(): void {
    this.GuiaswebServiceService.setModuloG(4);
    //obtenemos los datos del cliente logueado


    this.VerificarToken()
      this.token = sessionStorage.getItem('token')
      this.tokendatos = jwt_decode(this.token);
      this.userGw = this.tokendatos;
      this.fechaini = formatDate(this.fch1, "YYYY-MM-DD");;
      this.fechafin = formatDate(this.fch1, "YYYY-MM-DD");;
      window.scrollTo(0,0);

  }

  VerificarToken() {
    if (sessionStorage.getItem('token') == undefined || sessionStorage.getItem('token') == "" || sessionStorage.getItem('token') == null) {
      this.mensajeService.subject$.next(new MensajePopup("Su sesión ha expirado", false, false));
      this.router.navigate(['/herramientasDigitales/login']);
      return;
    }
  }


  // este guarda la fecha seleccionada
  onValueChangefchini(value: Date): void {
    let fch: any;

    if (value) {
      fch = formatDate(value, "YYYY-MM-DD");
      this.fechaini = fch;
      this.fechafin = this.fechaini
      this.fch2 = value;
    }


    this.minDate = value;

  }

  onValueChangefchfin(value: Date): void {
    let fch: any;

    if (value) {
      fch = formatDate(value, "YYYY-MM-DD");
      this.fechafin = fch;
    }

  }

  obtener() {
    window.scrollTo(0, 0);
    this.fechaini = this.fechaini.replace('-', '');
    this.fechaini = this.fechaini.replace('-', '');
    this.fechafin = this.fechafin.replace('-', '');
    this.fechafin = this.fechafin.replace('-', '');
    this.cargarfacturas();

  }


  async cargarfacturas() {
    this.VerificarToken()
    await this.GuiaswebServiceService.gwHistorialFactura(this.userGw.idUser, this.fechaini, this.fechafin, this.token).then(
      d => {

        this.factutas = d;
      }
    ).catch((error: HttpErrorResponse) => {
      if (error.error.ExceptionMessage == "invalid_token") {
        this.mensajeService.subject$.next(new MensajePopup("Su sesión ha expirado", false, false));
        this.router.navigate(['/herramientasDigitales/login']);
      } else {
        this.mensajeService.subject$.next(new MensajePopup("ocurrio un error inesperado, intente de nuevo", false, false));
      }
    });
  }

  async descargarFactura(seriefol, idref) {
    this.VerificarToken();
    await this.GuiaswebServiceService.facElectronicaPdf(seriefol, idref).then()
      .catch((error: HttpErrorResponse) => {
        if (error.error.ExceptionMessage == "invalid_token") {
          this.mensajeService.subject$.next(new MensajePopup("Su sesión ha expirado", false, false));
          this.router.navigate(['/herramientasDigitales/login']);
        } else {
          this.mensajeService.subject$.next(new MensajePopup("ocurrio un error inesperado, intente de nuevo", false, false));
        }
      });

  }

}
