<app-gw-menusuperior></app-gw-menusuperior>

<div class="guiasDoc-container">
    <h1 class="tituloEstructura">Documentar envío</h1>
    <label class="subtituloImg">Seleccione una guía para su envío.</label> <img
        class="lazyload imgmenu imgInterrogacion " (click)="Abrirdescripcion()"
        data-src='assets/iconos/guiasweb/informacion.png' alt="Promociones" />
    <div class="tipos_container" *ngIf="tipos">
        <div class="guiaDoc-menu u-flex">
            <a routerLink="/codigo-promocion"><button class="btn_promocode">Tengo un código de promoción 
                <svg class="material-icons" height="24" viewBox="0 0 24 24"
                  width="24" xmlns="http://www.w3.org/2000/svg">
                  <path _ngcontent-vuf-c10="" d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" fill="#FFFF">
                  </path>
                  <path _ngcontent-vuf-c10="" d="M0 0h24v24H0z" fill="none" id="cotizar3"></path>
                </svg>
            </button></a>
            <div class="buttonMenu isfourth btnmostrador" title="Predocumenta una guía nueva" id="cont_btns_menu">
                <img class="lazyload imgmenu addmostrador " data-src='assets/iconos/guiasweb/add.png'/>
                    <div class="cont-info">
                        <p class="p-tipos">Nueva guía para mostrador</p>
                        <p class="tipo_pago">PAGO EN SUCURSAL / ONLINE</p>
                        <p class="p-descrip">Pre-documenta tu envío y entrega en nuestra sucursal más cercana.</p>
                    </div>
                    <div class="buttons-menu" id="btns_menu">
                        <button class="btn_documentar2" (click)="abrirHerramienta(4)">Documentar</button>
                    </div>
            </div>

            <button [ngClass]="{isfirst: tipos[1].total > 0}" class="buttonMenu is_first " id="cont_btns_menu2 ">
                <img class="lazyload imgmenu addmostrador " data-src='assets/iconos/guiasweb/prepago2.png' />
                <div class="cont-info">
                    <p class="p-tipos">Prepago</p>
                    <p class="tipo_pago2">GUÍAS DE CONVENIO</p>
                    <p class="p-descrip">Obtén costo preferencial por medio de guías prepago. Solicita tu cotización en
                        apartado de solicitudes o: contacto@tufesapack.com</p>
                </div>
                <div class="buttons-menu" id="btns_menu">
                    <button [ngClass]="{disponibles: tipos[1].total > 0}" class="btn_documentar nodisponibles" (click)="abrirHerramienta(1)">Documentar</button>
                    <a href="/herramientasDigitales/guias"><button class="btn_solicitar">Solicitar</button ></a>
                </div>
                <div>
                    <p class="p-tipos-2">Disponibles: <span class="ttotal">{{tipos[1].total}}</span></p>
                </div>
            </button>

            <button [ngClass]="{issecond: tipos[2].total > 0}" class="buttonMenu is_second" id="cont_btns_menu3">
                <img class="lazyload imgmenu addmostrador " data-src='assets/iconos/guiasweb/sin_costo2.png' />
                <div class="cont-info">
                    <p class="p-tipos">Consumo</p>
                    <p class="tipo_pago2">GUÍAS DE CONVENIO</p>
                    <p class="p-descrip">Envía a través de un crédito pre-estipulado, el cual se pagará a final de la
                        fecha establecida en convenio con la empresa.</p>
                </div>
                <div class="buttons-menu" id="btns_menu">
                    <button [ngClass]="{disponibles: tipos[2].total > 0}" class="btn_documentar nodisponibles" (click)="abrirHerramienta(2)">Documentar</button>
                    <a href="/herramientasDigitales/guias"><button class="btn_solicitar" >Solicitar</button></a>
                </div>
                <div>
                    <p class="p-tipos-2">Disponibles: <span class="ttotal">{{tipos[2].total}}</span></p>
                </div>
            </button>
            <button [ngClass]="{isthird: tipos[3].total > 0}" class="buttonMenu is_third " id="cont_btns_menu4">
                <img class="lazyload imgmenu addmostrador " data-src='assets/iconos/guiasweb/contrato.png' />
                <div class="cont-info">
                    <p class="p-tipos">Cortesía</p>
                    <p class="tipo_pago2">GUIAS DE CORTESÍA</p>
                    <p class="p-descrip">Envíos proporcionados por la empresa como una cortesía y/o promoción.</p>
                </div>
                <div class="buttons-menu" id="btns_menu">
                    <button [ngClass]="{disponibles: tipos[3].total > 0}" class="btn_documentar nodisponibles" (click)="abrirHerramienta(3)">Documentar</button>
                    <a href="/herramientasDigitales/guias"><button class="btn_solicitar">Solicitar</button></a>
                </div>
                <div>
                    <p class="p-tipos-2">Disponibles: <span class="ttotal">{{tipos[3].total}}</span></p>
                </div>
            </button>

        </div>
    </div>

    
</div>

<ng-template #modal>
    <div>
        <div class="modal-header">
            <h3 class="modal-title pull-left">Tipos de guía</h3>


            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <label class="lbl-text-tipos">Guía para mostrador</label>
            <p class="lbl-text-tipos1">Pre-documenta tu envío y entraga en nuestra sucursal más cercana.</p>
            <label class="lbl-text-tipos">Prepago</label>
            <p class="lbl-text-tipos1">Obtén un costo preferencial al realizar envíos por medio de guías prepago.
                Solicita tu cotizacion en apartado de solicitudes o al correo contacto@tufesapack.com. </p>
            <label class="lbl-text-tipos">Consumo</label>
            <p class="lbl-text-tipos1">Envía a través de un crédito pre estipulado, el cual se pagara a final de la
                fecha establecida en contrato con la empresa.</p>
            <label class="lbl-text-tipos">Sin costo</label>
            <p class="lbl-text-tipos1">Envíos realizados por la empresa de manera interna.</p>

        </div>
    </div>
</ng-template>

<button id="btn-modal" (click)="openModal(modal)" hidden></button>