// las clases se declaran con la primera en mayuscula
export class Destino {
    CodOrg: string;
    PobOrg: string;
    Pais: string;
    Estado: string;
    esDestino: boolean;
    esOrigen: boolean;
    esSubDst: boolean;
    esSubOrg: boolean;
    esEquiv: boolean;

    constructor(PobOrg: string, CodOrg: string, Pais: string, Estado: string, esOrigen: boolean, esDestino: boolean, esSubDst: boolean, esSubOrg: boolean, esEquiv: boolean) {
        this.PobOrg = PobOrg;
        this.CodOrg = CodOrg;
        this.Pais = Pais;
        this.Estado = Estado;
        this.esDestino = esDestino;
        this.esOrigen = esOrigen;
        this.esSubDst = esSubDst;
        this.esSubOrg = esSubOrg;        
        this.esEquiv = esEquiv;
    }
}