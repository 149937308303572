<div class="container_herramienta">
    <img src="assets/img/web/carga.webp" class="img_toAnimate">
    <div class="alinearIzq">
        <h1 class="txt-section">Carga</h1>
        <p>
            Servicio de entrega y recolección de carga: Vamos dónde tú nos digas. 
            Realizamos la recolección en tus oficinas y entregamos en el punto de tu preferencia.
            <br><br>
            Para los clientes de mayor volumen ofrecemos el servicio 
            personalizado y particular para tu envío, el destino lo eliges tú.
            <br><br>
            Contamos con unidades especializadas a la vez con un constante monitoreo, haciendo con ello, tus envíos más fáciles.</p>
    </div>
</div>
