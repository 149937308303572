import { defineLocale, formatDate } from "ngx-bootstrap/chronos";
import { BsDatepickerConfig, BsLocaleService } from "ngx-bootstrap/datepicker";
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { Component, OnInit, TemplateRef, ViewChild, ɵConsole } from '@angular/core';
import { esLocale } from "ngx-bootstrap/locale"; // esLocale, es(idiomimport { formatDate } from 'ngx-bootstrap/chronos';
import { GuiaswebServiceService } from '../../servicios/guiasweb-service.service';
import { MensajeService } from 'src/app/servicios/mensaje.service';
import { HttpErrorResponse } from '@angular/common/http';
import jwt_decode from 'jwt-decode';
import { Router } from '@angular/router';
import { MensajePopup } from 'src/app/entidades/MensajePopup';
import { user } from '../../entidades/gwuser';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';


defineLocale('es', esLocale);

@Component({
  selector: 'app-gw-solicitudguia-detalle',
  templateUrl: './gw-solicitudguia-detalle.component.html',
  styleUrls: ['./gw-solicitudguia-detalle.component.css']
})
export class GwSolicitudguiaDetalleComponent implements OnInit {

  documentacionForm: FormGroup;
  sol: any;
  token: string
  tokendatos: any;
  userGw: user;
  modalRef: BsModalRef;
  detalleguia: any;
  guias: any;
  idguiassol : boolean = false;

   // fecha
   datePickerConfig: Partial<BsDatepickerConfig>;
   locale = "es";
   fechaini: string = '';
   fechafin: string = '';
   fechafin2: string = '';
   fch1: Date = new Date();
   fch2: Date = new Date();
   minDate: Date = new Date();
   showMeridian: any;
   isMeridian: any;
   showSpinners: any;


  constructor(
    private localeService: BsLocaleService,
    private GuiaswebServiceService: GuiaswebServiceService,
    private router: Router,
    private mensajeService: MensajeService,
    private modalService: BsModalService,
  ) {
    this.localeService.use("es");
    this.datePickerConfig = Object.assign(
      {},
      {
        containerClass: "theme-orange",
        isAnimated: true,
        showWeekNumbers: false,
        dateInputFormat: "DD/MM/YYYY"
      }
    );
  }

  ngOnInit(): void {
    this.token = sessionStorage.getItem('token')
    this.tokendatos = jwt_decode(this.token);
    this.userGw = this.tokendatos;
    this.DocumentacionForm();
    this.fechaini = formatDate(this.fch1, "YYYY-MM-DD");;
    this.fechafin = formatDate(this.fch1, "YYYY-MM-DD");;
    window.scrollTo(0, 0);
    this.obtener();
  }


  DocumentacionForm() {
    this.documentacionForm = new FormGroup({

      cobrar: new FormControl({ value: null, disabled: true }),
      recoleccion: new FormControl({ value: null, disabled: true }),
      acuse: new FormControl({ value: null, disabled: true }),
      ValorDeclarado: new FormControl({ value: null, disabled: true }),
      comentario: new FormControl({ value: null, disabled: true }),


    });
  }
  get doc() { return this.documentacionForm.controls; }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  closeModal() {
    this.guias = "";
    this.modalRef.hide();
  }

  // este guarda la fecha seleccionada
  onValueChangefchini(value: Date): void {
    let fch: any;

    if (value) {
      fch = formatDate(value, "YYYY-MM-DD");
      this.fechaini = fch;
      this.fechafin = this.fechaini
      this.fch2 = value;
    }
    this.minDate = value;
  }

  onValueChangefchfin(value: Date): void {
    let fch: any;

    if (value) {
      fch = formatDate(value, "YYYY-MM-DD");
      this.fechafin = fch;
    }
  }

  detalleGuia(id) {
    this.detalleguia = id;
    this.llenardetalle();
    this.CargarSolDetxGuias(id.idsol);
    const btn = document.getElementById('btn-modal') as HTMLElement;
    btn.click();

  }

  llenardetalle(){
    if(this.detalleguia.cobrar == 1){
      this.documentacionForm.controls.cobrar.setValue(true);
    }
    else{
      this.documentacionForm.controls.cobrar.setValue(false);

    }
    if(this.detalleguia.recoleccion == 1){
      this.documentacionForm.controls.recoleccion.setValue(true);
    }
    else{
      this.documentacionForm.controls.recoleccion.setValue(false);
    }
    if(this.detalleguia.acuse == 1){
      this.documentacionForm.controls.acuse.setValue(true);
    }
    else{
      this.documentacionForm.controls.acuse.setValue(false);
    }
  }

  obtener() {
    window.scrollTo(0, 0);
    this.fechaini = this.fechaini.replace('-', '');
    this.fechaini = this.fechaini.replace('-', '');

    this.fechafin = this.fechafin.replace('-', '');
    this.fechafin = this.fechafin.replace('-', '');
    this.obtener_Solicitudes();
  }


  regresar(){
    this.GuiaswebServiceService.setPlantillaSol(1);
   }

   async obtener_Solicitudes() {
    await this.GuiaswebServiceService.ObtenerSolDetalle(this.userGw.idUser, this.fechaini, this.fechafin, this.token).then(
      d => {

        this.sol = d;
      }
    ).catch((error: HttpErrorResponse) => {
      if (error.error.ExceptionMessage == "invalid_token") {
        this.mensajeService.subject$.next(new MensajePopup("Su sesión ha expirado", false, false));
        this.router.navigate(['/herramientasDigitales/login']);
      } else {
        this.mensajeService.subject$.next(new MensajePopup("ocurrio un error inesperado, intente de nuevo", false, false));
      }
    });
  }

  async CargarSolDetxGuias(idsol) {
    await this.GuiaswebServiceService.CargarSolDetxGuias(idsol, this.token).then(
      d => {

        this.guias = d;
        if(this.guias[0].idsesion == -1)
        {
          this.idguiassol = false
        }
        else{
          this.idguiassol = true
        }
      }
    ).catch((error: HttpErrorResponse) => {
      if (error.error.ExceptionMessage == "invalid_token") {
        this.mensajeService.subject$.next(new MensajePopup("Su sesión ha expirado", false, false));
        this.router.navigate(['/herramientasDigitales/login']);
      } else {
        this.mensajeService.subject$.next(new MensajePopup("ocurrio un error inesperado, intente de nuevo", false, false));
      }
    });
  }

}
