import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(
    private http: HttpClient
  ) { }

  setVisit(t, f){
    let url = `${environment.URL_APIPAQ}telog?t=${t}&f=${f}`
    return this.http.get(url);
  }

  setdevice(usr, uuid): Observable<Object> {
    
    let url = `${environment.URL_APIPAQ}/stdv?uuid=${uuid}&usr=${usr}`
    return this.http.get(url);
  }


}
