import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tips-seguridad',
  templateUrl: './tips-seguridad.component.html',
  styleUrls: ['./tips-seguridad.component.css']
})
export class TipsSeguridadComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
