import { Injectable } from '@angular/core';
// importar el http client para hacer peticiones
import { HttpClient } from '@angular/common/http';
// importar clase destino para hacer el cast de destinos y que lo pueda tipear
import { Destino } from '../entidades/destino';
// importar la clase environment donde se pueda tener variablas del entorno, ej. la url de la API
import { environment } from '../../environments/environment'
// operadores para hacer opreaciones antes o despues de hacer una peticion
import { BehaviorSubject } from 'rxjs';

// declarar Constantes

@Injectable({
  providedIn: 'root'
})
export class DestinoService {
  // inyectar el http client
  constructor(private http: HttpClient) { }

  private destinosList: Destino[] = [
    new Destino('Mexicali (Carranza)', 'MXL', 'MEX', 'BAJA CALIFORNIA', true, true, false, false, false),
    new Destino('Mexicali Centro', 'MXC', 'MEX', 'BAJA CALIFORNIA', true, true, false, false, true),
    new Destino('Mexicali Centro Civico', 'MXC', 'MEX', 'BAJA CALIFORNIA', true, true, false, false, false),
    new Destino('Tijuana', 'TJI', 'MEX', 'BAJA CALIFORNIA', true, true, false, false, true),
    new Destino('Tijuana Insurgentes', 'TJI', 'MEX', 'BAJA CALIFORNIA', true, true, false, false, false),
    new Destino('Guadalajara', 'GDL', 'MEX', 'JALISCO', true, true, false, false, true),
    new Destino('Guadalajara (Zapopan)', 'ZAP', 'MEX', 'JALISCO', true, true, false, false, false),
    new Destino('Guadalajara(Tlaquepaque)', 'GDL', 'MEX', 'JALISCO', true, true, false, false, false),
    new Destino('Acaponeta', 'ACP', 'MEX', 'NAYARIT', true, true, false, false, false),
    new Destino('Ixtlan del Rio', 'IXT', 'MEX', 'NAYARIT', true, true, false, false, true),
    new Destino('Las Peñitas', 'PNA', 'MEX', 'NAYARIT', true, true, false, false, true),
    new Destino('Peñas', 'PNA', 'MEX', 'NAYARIT', true, true, false, false, false),
    new Destino('Tecuala', 'TEC', 'MEX', 'NAYARIT', true, true, false, false, false),
    new Destino('Tepic', 'TEP', 'MEX', 'NAYARIT', true, true, false, false, false),
    new Destino('CASETA Villa Union', 'VUN', 'MEX', 'SINALOA', false, true, false, false, false),
    new Destino('Culiacan', 'CUL', 'MEX', 'SINALOA', true, true, false, false, false),
    new Destino('Culiacan Rosales', 'CUL', 'MEX', 'SINALOA', true, true, false, false, true),
    new Destino('El Rosario', 'ROS', 'MEX', 'SINALOA', true, true, false, false, false),
    new Destino('Escuinapa', 'ECU', 'MEX', 'SINALOA', true, true, false, false, false),
    new Destino('Guamuchil', 'GMH', 'MEX', 'SINALOA', true, true, false, false, false),
    new Destino('Guasave', 'GVE', 'MEX', 'SINALOA', true, true, false, false, false),
    new Destino('Los Mochis', 'MOC', 'MEX', 'SINALOA', true, true, false, false, false),
    new Destino('Mazatlan', 'MAZ', 'MEX', 'SINALOA', true, true, false, false, false),
    new Destino('Mochis', 'MOC', 'MEX', 'SINALOA', true, true, false, false, true),
    new Destino('Rosario', 'ROS', 'MEX', 'SINALOA', false, true, false, false, true),
    new Destino('Villa', 'VUN', 'MEX', 'SINALOA', false, true, false, false, true),
    new Destino('Agua Prieta', 'AGP', 'MEX', 'SONORA', true, true, true, false, false),
    new Destino('Caborca', 'CAB', 'MEX', 'SONORA', true, true, false, false, false),
    new Destino('Cajeme', 'OBR', 'MEX', 'SONORA', true, true, true, true, true),
    new Destino('Cananea', 'CAN', 'MEX', 'SONORA', true, true, false, false, false),
    new Destino('Cd Obregon', 'OBR', 'MEX', 'SONORA', true, true, true, true, true),
    new Destino('CD Obregón', 'OBR', 'MEX', 'SONORA', true, true, true, true, true),
    new Destino('Cd. Obregon', 'OBR', 'MEX', 'SONORA', true, true, true, true, false),
    new Destino('Ciudad Jardin', 'EMP', 'MEX', 'SONORA', true, true, false, true, true),
    new Destino('Ciudad Obregon', 'OBR', 'MEX', 'SONORA', true, true, true, true, true),
    new Destino('Empalme', 'EMP', 'MEX', 'SONORA', true, true, false, true, false),
    new Destino('Guaymas', 'GYM', 'MEX', 'SONORA', true, true, false, true, false),
    new Destino('H. Caborca', 'CAB', 'MEX', 'SONORA', true, true, false, false, true),
    new Destino('Hermosillo', 'HMO', 'MEX', 'SONORA', true, true, false, true, false),
    new Destino('Heroica Caborca', 'CAB', 'MEX', 'SONORA', true, true, false, false, true),
    new Destino('Heroica Guaymas', 'GYM', 'MEX', 'SONORA', true, true, false, true, true),
    new Destino('Heroica Nogales', 'NOG', 'MEX', 'SONORA', true, true, false, false, true),
    new Destino('Heroico Puerto de Guaymas', 'GYM', 'MEX', 'SONORA', true, true, false, true, true),
    new Destino('Hillo', 'HMO', 'MEX', 'SONORA', true, true, false, true, true),
    new Destino('Imuris', 'IMU', 'MEX', 'SONORA', false, true, false, false, false),
    new Destino('Imuris', 'IMU', 'MEX', 'SONORA', false, true, false, false, true),
    new Destino('Magdalena', 'MAG', 'MEX', 'SONORA', true, true, false, false, false),
    new Destino('Magdalena de Kino', 'MAG', 'MEX', 'SONORA', true, true, false, false, true),
    new Destino('Navojoa', 'NAV', 'MEX', 'SONORA', true, true, false, false, false),
    new Destino('Nogales', 'NOG', 'MEX', 'SONORA', true, true, false, false, false),
    new Destino('Obregon', 'OBR', 'MEX', 'SONORA', true, true, true, true, true),
    new Destino('Obregón', 'OBR', 'MEX', 'SONORA', true, true, true, true, true),
    new Destino('Puerto de Guaymas', 'GYM', 'MEX', 'SONORA', true, true, false, true, true),
    new Destino('San Luis', 'SLR', 'MEX', 'SONORA', true, true, false, false, true),
    new Destino('San Luis Rio Colorado', 'SLR', 'MEX', 'SONORA', true, true, false, false, false),
    new Destino('Santa Ana', 'STN', 'MEX', 'SONORA', true, true, false, true, true),
    new Destino('Santa Ana Norte', 'STN', 'MEX', 'SONORA', true, true, false, true, false),
    new Destino('Santana', 'STN', 'MEX', 'SONORA', true, true, false, true, true),
    new Destino('Sonoyta', 'SNY', 'MEX', 'SONORA', true, true, false, false, false),
    new Destino('Vicam', 'VIC', 'MEX', 'SONORA', false, true, false, false, false),
    new Destino('Lozoya', 'PHC', 'EUA', 'ARIZONA', false, true, false, false, true),
    new Destino('Nogales Arizona', 'NAZ', 'EUA', 'ARIZONA', true, true, false, false, false),
    new Destino('Nogales Az.', 'NAZ', 'EUA', 'ARIZONA', true, true, false, false, true),
    new Destino('Phoenix', 'PHX', 'EUA', 'ARIZONA', true, true, false, false, false),
    new Destino('Phoenix Az.', 'PHX', 'EUA', 'ARIZONA', true, true, false, false, true),
    new Destino('Tucson', 'TUC', 'EUA', 'ARIZONA', true, true, false, false, false),
    new Destino('Bakersfield', 'BAK', 'EUA', 'CALIFORNIA', true, true, false, false, true),
    new Destino('Bakersfield California', 'BAK', 'EUA', 'CALIFORNIA', true, true, false, false, false),
    new Destino('Barstow', 'BAR', 'EUA', 'CALIFORNIA', false, true, false, false, true),
    new Destino('Barstow California', 'BAR', 'EUA', 'CALIFORNIA', false, true, false, false, false),
    new Destino('Colton', 'COL', 'EUA', 'CALIFORNIA', true, true, false, false, false),
    new Destino('Corona', 'COR', 'EUA', 'CALIFORNIA', false, true, false, false, true),
    new Destino('Corona California', 'COR', 'EUA', 'CALIFORNIA', false, true, false, false, false),
    new Destino('Delano', 'DLA', 'EUA', 'CALIFORNIA', false, true, false, false, true),
    new Destino('Delano California', 'DLA', 'EUA', 'CALIFORNIA', false, true, false, false, false),
    new Destino('Fontana', 'FON', 'EUA', 'CALIFORNIA', true, true, false, false, true),
    new Destino('Fontana California', 'FON', 'EUA', 'CALIFORNIA', true, true, false, false, false),
    new Destino('Fresno', 'FRE', 'EUA', 'CALIFORNIA', true, true, false, false, true),
    new Destino('Fresno California', 'FRE', 'EUA', 'CALIFORNIA', true, true, false, false, false),
    new Destino('Fronteras', 'SJF', 'EUA', 'CALIFORNIA', true, true, false, false, true),
    new Destino('Gilroy', 'GIL', 'EUA', 'CALIFORNIA', true, true, false, false, true),
    new Destino('Gilroy California', 'GIL', 'EUA', 'CALIFORNIA', true, true, false, false, false),
    new Destino('Huntington', 'HPK', 'EUA', 'CALIFORNIA', true, true, false, false, true),
    new Destino('Huntington Park', 'HPK', 'EUA', 'CALIFORNIA', true, true, false, false, false),
    new Destino('Indio', 'IDO', 'EUA', 'CALIFORNIA', true, true, false, false, true),
    new Destino('Indio California', 'IDO', 'EUA', 'CALIFORNIA', true, true, false, false, false),
    new Destino('Los Angeles (Mariana s)', 'LAA', 'EUA', 'CALIFORNIA', true, true, false, false, false),
    new Destino('Los Angeles Downtown', 'LAD', 'EUA', 'CALIFORNIA', true, true, false, false, false),
    new Destino('Los Angeles East', 'LAN', 'EUA', 'CALIFORNIA', true, true, false, false, false),
    new Destino('Los Baños California', 'LBN', 'EUA', 'CALIFORNIA', true, true, false, false, false),
    new Destino('Merced', 'MER', 'EUA', 'CALIFORNIA', true, true, false, false, true),
    new Destino('Merced California', 'MER', 'EUA', 'CALIFORNIA', true, true, false, false, false),
    new Destino('Modesto', 'MOD', 'EUA', 'CALIFORNIA', true, true, false, false, true),
    new Destino('Modesto California', 'MOD', 'EUA', 'CALIFORNIA', true, true, false, false, false),
    new Destino('Oakland', 'OKL', 'EUA', 'CALIFORNIA', true, true, false, false, true),
    new Destino('Ontario', 'ONT', 'EUA', 'CALIFORNIA', true, true, false, false, false),
    new Destino('Oxnard California', 'OXN', 'EUA', 'CALIFORNIA', true, true, false, false, false),
    new Destino('Pacoima', 'SFE', 'EUA', 'CALIFORNIA', true, true, false, false, true),
    new Destino('Sacramento', 'SAC', 'EUA', 'CALIFORNIA', false, true, false, false, true),
    new Destino('Sacramento California', 'SAC', 'EUA', 'CALIFORNIA', false, true, false, false, false),
    new Destino('Sacramento Mundo Travel', 'SAM', 'EUA', 'CALIFORNIA', true, true, false, false, false),
    new Destino('San Diego', 'SDS', 'EUA', 'CALIFORNIA', false, true, false, false, true),
    new Destino('San Diego Seaport', 'SDS', 'EUA', 'CALIFORNIA', false, true, false, false, false),
    new Destino('San Fernando', 'SFE', 'EUA', 'CALIFORNIA', true, true, false, false, true),
    new Destino('San Fernando (Pacoima)', 'SFE', 'EUA', 'CALIFORNIA', true, true, false, false, false),
    new Destino('San Francisco', 'SFC', 'EUA', 'CALIFORNIA', true, true, false, false, true),
    new Destino('San Isidro', 'SYS', 'EUA', 'CALIFORNIA', true, true, false, false, true),
    new Destino('San Jose', 'SJF', 'EUA', 'CALIFORNIA', true, true, false, false, true),
    new Destino('San Jose Fronteras', 'SJF', 'EUA', 'CALIFORNIA', true, true, false, false, true),
    new Destino('San Jose Tropicana', 'SJS', 'EUA', 'CALIFORNIA', false, true, false, false, true),
    new Destino('San Jose, Ca. Concord', 'SJC', 'EUA', 'CALIFORNIA', true, true, false, false, false),
    new Destino('San Jose, Ca. Fronteras', 'SJF', 'EUA', 'CALIFORNIA', true, true, false, false, false),
    new Destino('San Jose, Ca. Tropicana', 'SJS', 'EUA', 'CALIFORNIA', false, true, false, false, false),
    new Destino('San Jose, Ca. Tropicana', 'SJS', 'EUA', 'CALIFORNIA', true, true, false, false, false),
    new Destino('San Ysidro', 'SYS', 'EUA', 'CALIFORNIA', true, true, false, false, true),
    new Destino('San Ysidro CA', 'SYS', 'EUA', 'CALIFORNIA', true, true, false, false, false),
    new Destino('Santa Ana California', 'SAN', 'EUA', 'CALIFORNIA', false, true, false, false, false),
    new Destino('Selma', 'SEL', 'EUA', 'CALIFORNIA', false, true, false, false, true),
    new Destino('Selma California', 'SEL', 'EUA', 'CALIFORNIA', false, true, false, false, false),
    new Destino('Stockton', 'STK', 'EUA', 'CALIFORNIA', true, true, false, false, true),
    new Destino('Stockton California', 'STK', 'EUA', 'CALIFORNIA', true, true, false, false, false),
    new Destino('Tropicana', 'SJS', 'EUA', 'CALIFORNIA', false, true, false, false, true),
    new Destino('Tulare', 'TUL', 'EUA', 'CALIFORNIA', false, true, false, false, false),
    new Destino('Turlock', 'TUR', 'EUA', 'CALIFORNIA', false, true, false, false, true),
    new Destino('Turlock California', 'TUR', 'EUA', 'CALIFORNIA', false, true, false, false, false),
    new Destino('Victorville', 'VTO', 'EUA', 'CALIFORNIA', false, true, false, false, true),
    new Destino('Victorville-Hesperia Ca', 'VTO', 'EUA', 'CALIFORNIA', false, true, false, false, false),
    new Destino('Las Vegas', 'VEG', 'EUA', 'NEVADA', true, true, false, false, false),
    new Destino('Vegas', 'VEG', 'EUA', 'NEVADA', true, true, false, false, true),
    new Destino('Beaver', 'BEA', 'EUA', 'UTAH', false, true, false, false, true),
    new Destino('Beaver Utah', 'BEA', 'EUA', 'UTAH', false, true, false, false, false),
    new Destino('Beaver Utah', 'BEA', 'EUA', 'UTAH', false, true, false, false, true),
    new Destino('Cedar City', 'CED', 'EUA', 'UTAH', false, true, false, false, true),
    new Destino('Cedar City Utah', 'CED', 'EUA', 'UTAH', false, true, false, false, false),
    new Destino('Fillmore', 'FIL', 'EUA', 'UTAH', false, true, false, false, true),
    new Destino('Fillmore Utah', 'FIL', 'EUA', 'UTAH', false, true, false, false, false),
    new Destino('Orem Utah', 'PRO', 'EUA', 'UTAH', true, true, false, false, false),
    new Destino('Parowan', 'PAR', 'EUA', 'UTAH', false, true, false, false, true),
    new Destino('Parowan Utah', 'PAR', 'EUA', 'UTAH', false, true, false, false, false),
    new Destino('Payson', 'PAY', 'EUA', 'UTAH', false, true, false, false, true),
    new Destino('Payson Utah', 'PAY', 'EUA', 'UTAH', false, true, false, false, false),
    new Destino('Salt Lake', 'SLC', 'EUA', 'UTAH', true, true, false, false, true),
    new Destino('Salt Lake City Utah', 'SLC', 'EUA', 'UTAH', true, true, false, false, false),
    new Destino('St George', 'STG', 'EUA', 'UTAH', false, true, false, false, true),
    new Destino('St George Utah', 'STG', 'EUA', 'UTAH', false, true, false, false, false)
  ];

  private destinosSource = new BehaviorSubject(this.destinosList);
  destinosActuales = this.destinosSource.asObservable();

  // variable para compartir informacion como observable, la declaramos como destino, recibe en el constructor un PobOrg

  private origenSource =
    new BehaviorSubject(
      (!environment.production) ?
        new Destino('Cd. Obregon', 'OBR', 'MEX', 'SONORA', true, true, false, false, false) :
        new Destino('Origen', '', '', '', true, true, false, false, false)
    );
  // variable para indicar el origen seleccionado
  origenSeleccionado = this.origenSource.asObservable();

  // igual que la anterior pero con destino
  private destinoSource =
    new BehaviorSubject(
      (!environment.production) ?
        new Destino('Navojoa', 'NAV', 'MEX', 'SONORA', true, true, false, false, false) :
        new Destino('Destino', '', '', '', true, true, false, false, false)
    );
  destinoSeleccionado = this.destinoSource.asObservable();

  // cambiar el valor de origen
  changeOrigen(origen: Destino) {
    this.origenSource.next(origen);
  }

  // cambiar el valor de destino
  changeDestino(destino: Destino) {
    this.destinoSource.next(destino);
  }

  getDestinoList() {
    this.destinosSource.next(this.destinosList);
  }

  searchDestino(cod: string): Destino {
    let lst: Destino[];
    lst = this.destinosSource.getValue();

    let x: Destino;
    x = null;
    try {
      let i = 0;
      for (i = 0; i < lst.length; i++) {
          if (lst[i].CodOrg.toLowerCase() === cod.toLowerCase() && !lst[i].esEquiv) {
          return lst[i];
        }
      }
    } catch (e) {

    }

    return x;

  }

  getOrigen(latitud: number, longitud: number) {
    let url = `${environment.URL_API}/commsucgeo?lat=${latitud}&lon=${longitud}`

    url += (!environment.production) ? `&istestmode=true` : '';

    return this.http.get<Destino>(url);
  }
}
