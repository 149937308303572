<div class="body">
    <div class="contenido">
       <!--<app-header></app-header>-->
        <app-menu *ngIf="!hideMenu"></app-menu>
       <router-outlet (activate)="onActivate($event)"></router-outlet>
    </div>

    <app-mensaje></app-mensaje>

    <!--<app-informacion-util></app-informacion-util> -->
    <app-footer *ngIf="!hideFooter"></app-footer>
    <ng-container *ngIf="!isFirstLoad; else firstLoad">
        <app-loading *ngIf="isLoading || isLoadingFijo"></app-loading>
    </ng-container>
    <ng-template #firstLoad>
        <app-loading></app-loading>
    </ng-template>
</div>

<input type="hidden" id="conektapk"/>





