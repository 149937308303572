<div class="gw-container">
    <form [formGroup]="docTipoForm">
        <div class="doc-div">
            <div class="gw-flex-titulos">
                <div>
                    <h4>Tipo</h4>
                    <p class="gw-flex-titulo2">{{this.tipopaquete}}</p>
                </div>
                <div>
                    <button class="gw-btn-backpantalla" (click)="limpiartodo()" type="button">Limpiar</button>
                </div>
            </div>

            <div class="gw-flex">
                <div class="form-flex-1">
                    <label class="lbl-text">Tipo</label>
                    <div class="select">
                        <select id="tipo" formControlName="selectTipo"
                            [ngClass]="{validacion: (DocTipo.selectTipo.invalid || DocTipo.selectTipo.hasError('isEmpty')) && submittedTipo}"
                            required (change)="tipoChanged($event.target.value)">
                            <option value="" disabled selected class="option s">Seleccione</option>
                            <option *ngFor="let i of tipoEnvio" [value]="i.idTipo">
                                {{ i.Tipo }}
                            </option>
                        </select>
                    </div>
                    <div class="val-valido" [ngClass]="{invalido: (DocTipo.selectTipo.invalid)  && submittedTipo}">*
                        Introduce un tipo
                    </div>
                </div>

                <div class="form-flex-4">
                    <label class="lbl-text">Cantidad</label>
                    <input type="number" min="1" formControlName="cantidad" placeholder="00"
                        (keydown)="cambiarFocus($event)" inputmode="decimal"
                        [ngClass]="{validacion: (DocTipo.cantidad.invalid || DocTipo.cantidad.hasError('IsEmpty')) && submittedTipo  }">
                    <div class="val-valido" [ngClass]="{invalido: (DocTipo.cantidad.invalid)  && submittedTipo }">*
                        Introduce la cantidad
                    </div>
                </div>

                <div class="form-flex-1">
                    <label class="lbl-text">Peso</label>
                    <input type="number" min="1" formControlName="peso" placeholder="00 kg."
                        (keydown)="cambiarFocus($event)" inputmode="decimal"
                        [ngClass]="{validacion: (DocTipo.peso.invalid || DocTipo.peso.hasError('IsEmpty')) && submittedTipo}">
                    <div class="val-valido" [ngClass]="{invalido: (DocTipo.peso.invalid)  && submittedTipo}">* Introduce
                        el
                        peso
                    </div>
                </div>
            </div>


            <ng-container *ngIf="showDimensiones">
            <p class="p-dimensiones">Dimensión en centímentros</p>
            <div class="u-flex">
                <div class="form-flex-3">
                    <label class="lbl-text">Largo</label>
                    <input type="number" min="1" formControlName="largo" placeholder="00 Cm."
                        (keydown)="cambiarFocus($event)" inputmode="decimal"
                        [ngClass]="{validacion: (DocTipo.largo.invalid || DocTipo.largo.hasError('IsEmpty')) && submittedTipo}">
                    <div class="val-valido" [ngClass]="{invalido: (DocTipo.largo.invalid)  && submittedTipo}">*
                        Introduce el
                        largo
                    </div>
                </div>


                <div class="form-flex-2">
                    <label class="lbl-text">Ancho</label>
                    <input type="number" min="1" formControlName="ancho" placeholder="00 Cm."
                        (keydown)="cambiarFocus($event)" inputmode="decimal"
                        [ngClass]="{validacion: (DocTipo.ancho.invalid || DocTipo.ancho.hasError('IsEmpty')) && submittedTipo}">
                    <div class="val-valido" [ngClass]="{invalido: (DocTipo.ancho.invalid)  && submittedTipo}">*
                        Introduce el
                        ancho
                    </div>
                </div>


                <div class="form-flex-3">
                    <label class="lbl-text">Alto</label>
                    <input type="number" min="1" formControlName="alto" placeholder="00 Cm."
                        (keydown)="cambiarFocus($event)" inputmode="decimal"
                        [ngClass]="{validacion: (DocTipo.alto.invalid || DocTipo.alto.hasError('IsEmpty')) && submittedTipo}">
                    <div class="val-valido" [ngClass]="{invalido: (DocTipo.alto.invalid)  && submittedTipo}">* Introduce
                        el alto
                    </div>

                </div>
            </div>
          </ng-container>

            <div *ngIf="validaciontipos">
                <p class="p-validacion">{{msgtipos}}
                </p>
            </div>
            <div class="div-form">

                <button name="Aceptar" class="btn-back" type="submit" (click)="AgregarTablaTipo()">Agregar</button>
            </div>
        </div>
    </form>


    <div class="wrapper" *ngIf="arrtipos.length">

        <div class="table">

            <div class="row header">
                <div class="cell titilo ">
                    Tipo
                </div>
                <div class="cell titilo">
                    Cantidad
                </div>
                <div class="cell titilo">
                    Peso
                </div>
                <div class="cell titilo">
                    Ancho
                </div>
                <div class="cell titilo">
                    Largo
                </div>
                <div class="cell titilo">
                    Alto
                </div>
                <div class="cell titilo">

                </div>
            </div>

            <div class="row" *ngFor="let valor = index let i of arrtipos">
                <div class="cell" data-title="Tipo">
                    {{i.tipo}}
                </div>
                <div class="cell" data-title="Cantidad">
                    {{i.cantidad}}
                </div>
                <div class="cell" data-title="Peso">
                    {{i.peso}} kg.
                </div>
                <div class="cell" data-title="Ancho">
                    {{i.ancho}} cm.
                </div>
                <div class="cell" data-title="Largo">
                    {{i.largo}} cm.
                </div>
                <div class="cell" data-title="Alto">
                    {{i.alto}} cm.
                </div>
                <div class="cell" data-title="Quitar">
                    <button class="btn-x" (click)="quitarTipo(valor)">x</button>
                </div>
            </div>

        </div>
    </div>

    <div class="gw-reverse-btn">
        <button name="Aceptar" class="gw-btn-continuar gw-btn-pasos" type="submit"
            (click)="guardarsesion_4()">Continuar</button>
        <button class="btn-back gw-btn-pasos" type="button" (click)="regresarMenu()">Regresar</button>
    </div>
</div>
