import { Component, OnInit } from '@angular/core';
import { login } from '../../entidades/login';
import { GuiaswebServiceService } from '../../servicios/guiasweb-service.service'
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { MyValidators } from 'src/app/entidades/Validators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from '../../entidades/gwusr';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { MensajePopup } from 'src/app/entidades/MensajePopup';
import { MensajeService } from 'src/app/servicios/mensaje.service';
import { Location } from '@angular/common';
import { ActivatedRoute, Params } from '@angular/router';
 

import jwt_decode from 'jwt-decode';


@Component({
  selector: 'app-gw-user-login',
  templateUrl: './gw-user-login.component.html',
  styleUrls: ['./gw-user-login.component.css']
})
export class GwUserLoginComponent implements OnInit {
  failedLogin = false;
  registroForm: FormGroup;
  submitted: boolean;
  lgn: any;
  datosclien: User;
  userGw: User;
  token: any;
  gwc: any;
  toComponente: string = "";
  tfdv:string="";



  constructor(
    private GuiaswebServiceService: GuiaswebServiceService,
    private router: Router,
    private mensajeService: MensajeService,
    private _location: Location,
    private activateRoute: ActivatedRoute
  ) {
    this.activateRoute.queryParams.subscribe(params => {
      try{
        if (params['to'] != undefined){
          this.toComponente = params['to'];
        }
        
        console.log(this.toComponente )
      }
      catch(e){
        //redirige a login
        this.router.navigate(['**']);
      }
    });

  }

  ngOnInit(): void {

    this.tfdv =""
    if(localStorage.getItem("tfdv")){
      this.tfdv = localStorage.getItem("tfdv")
    }


    this.loginForm();
    sessionStorage.removeItem('token');
    localStorage.removeItem('token');
    this.GuiaswebServiceService.setnumlogin(false);


    document.getElementById("usr").focus();
    window.scroll(0,0);
  }

  get f() { return this.registroForm.controls; }

  loginForm() {
    this.registroForm = new FormGroup({
      user1: new FormControl('', [Validators.required]),
      pass1: new FormControl('', [Validators.required])
    });
  }


  cambiarFocus(e, id) {
    if (e.keyCode === 13) {
      e.preventDefault();

      if (id.id == "entrar") {
        this.login(e);

      }
      else {
        id.focus();
      }

    }
  }

  async login(e) {
    e.preventDefault();
    this.submitted = true;

    if (this.registroForm.valid) {
      const LoginUser = new login();
      LoginUser.user = this.registroForm.controls.user1.value
      LoginUser.pass = this.registroForm.controls.pass1.value

      await this.GuiaswebServiceService.Iniciar_Sesion(LoginUser, this.tfdv).then(
        d => {

          this.lgn = d;

          if (this.lgn.NumMsj <= 0) {
            this.mensajeService.subject$.next(new MensajePopup(this.lgn.TxtMsj, false, false));

          }
          else {

            this.token = jwt_decode(this.lgn.TxtMsj);

            if (this.token.idUser > 0) {

              sessionStorage.setItem('token', this.lgn.TxtMsj);

              this.gwc = jwt_decode(sessionStorage.getItem('token'));
              this.userGw = this.gwc;

              this.GuiaswebServiceService.datosUsrLogin(this.userGw);

              this.GuiaswebServiceService.setnumlogin(true);
              localStorage.setItem('login', 'true');
              localStorage.setItem ('idUsuario',this.token.idUser);

              
              if (this.toComponente != "" && this.toComponente != "confirmacion"){
                this.router.navigate(['/'+ this.toComponente ]);
              }else  if (this.toComponente === "confirmacion"){
                this._location.back();
              }
              else {
                this.router.navigate(['/herramientasDigitales/documentacion/menu']);
              }
              

       
            } else {
              this.failedLogin = true;
            }

          }
        }

      ).catch((error: HttpErrorResponse) => {
        if (error.error._Message == undefined) {
          this.mensajeService.subject$.next(new MensajePopup("ocurrio un error inesperado al iniciar sesión en tu cuenta", false, false));
        } else {
          this.mensajeService.subject$.next(new MensajePopup(error.error._Message, false, false));
        }
      });
    }

  }

  CrearCuenta() {
    this.router.navigate(['/herramientasDigitales/registro']);
  }

  Cambiarcontrasena(){
    this.router.navigate(['/herramientasDigitales/restablecer/contrasena']);
  }
}

