import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { GuiaswebServiceService } from '../../servicios/guiasweb-service.service'
import { HttpErrorResponse } from '@angular/common/http';
import { MensajePopup } from 'src/app/entidades/MensajePopup';
import { MensajeService } from 'src/app/servicios/mensaje.service';
import { Router } from '@angular/router';
import {cuentaverificada } from '../../entidades/cuentaverificada'

@Component({
  selector: 'app-gw-verificarcuenta',
  templateUrl: './gw-verificarcuenta.component.html',
  styleUrls: ['./gw-verificarcuenta.component.css']
})
export class GwVerificarcuentaComponent implements OnInit {
  codigoForm: FormGroup;
  submitted: boolean;
  verificado: cuentaverificada = undefined;
  data : any;


  constructor(
    private GuiaswebServiceService: GuiaswebServiceService,
    private router: Router,
    private mensajeService: MensajeService
  ) { }

  ngOnInit(): void {
    this.GuiaswebServiceService.cuentaVerf.subscribe(
      datos => {

       this.verificado = datos;

       if(Object.keys(this.verificado).length == 0){

        this.router.navigate(['/herramientasDigitales/restablecer/contrasena']);
       }
      }
    );
    this.loginForm();
  }


  get f() { return this.codigoForm.controls; }

  loginForm() {
    this.codigoForm = new FormGroup({
      codigo: new FormControl('', [Validators.required])
    });
  }

  cambiarFocus(e, id) {
    if (e.keyCode === 13) {
      e.preventDefault();

      if (id.id == "entrar") {
      }
      else {
        id.focus();
      }

    }
  }

  async verficarcodigo(e) {
    e.preventDefault();
    this.submitted = true;

    if (this.codigoForm.valid) {
      await this.GuiaswebServiceService.verificarCodigo(this.verificado.id, this.codigoForm.controls.codigo.value).then(
        d => {

          this.data = d;

          if (this.data.NumMsj <= 0) {
               this.mensajeService.subject$.next(new MensajePopup(this.data.TxtMsj, false, false));
          }
          else {
            this.router.navigate(['/herramientasDigitales/contrasena']);
          }
        }

      ).catch((error: HttpErrorResponse) => {
        if (error.error._Message == undefined) {
          this.mensajeService.subject$.next(new MensajePopup("ocurrio un error inesperado al iniciar sesión en tu cuenta", false, false));
        } else {
          this.mensajeService.subject$.next(new MensajePopup(error.error._Message, false, false));
        }
      });
    }

  }

  async reenviarcodigo() {
    await this.GuiaswebServiceService.verificarCuenta(this.verificado.cuenta).then(
      d => {

        this.data = d;

        if (this.data.NumMsj <= 0) {
          this.mensajeService.subject$.next(new MensajePopup(this.data.TxtMsj, false, false));
          this.router.navigate(['/herramientasDigitales/restablecer/contrasena']);
        }
        else {
           this.GuiaswebServiceService.setCuentaverificada(this.data);
           this.mensajeService.subject$.next(new MensajePopup(this.data.TxtMsj, true, true));
        }
      }

    ).catch((error: HttpErrorResponse) => {
      if (error.error._Message == undefined) {
        this.mensajeService.subject$.next(new MensajePopup("ocurrio un error inesperado al iniciar sesión en tu cuenta", false, false));
      } else {
        this.mensajeService.subject$.next(new MensajePopup(error.error._Message, false, false));
      }
    });


  }

}

