import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css'],
  preserveWhitespaces: false
})
export class AboutUsComponent implements OnInit {
  animation = false;
  animation1 = false;
  animation2 = false;
  animation3 = false;

  constructor() { }

  ngOnInit() {
  }

  public onIntersectionText({ target, visible}: { target: Element; visible: boolean; id: number}): void {
    if(visible){
    target.classList.add('fade-in-bottom');
    //this.animation = true;
  }
 }

 public onIntersectionText2({ target, visible}: { target: Element; visible: boolean; id: number}): void {
  if(visible){
  target.classList.add('fade-in-fwd');
  //this.animation1 = true;
  }
}

 public onIntersection({ target, visible}: { target: Element; visible: boolean; id: number}): void {
  if(visible){
  target.classList.add('scale-in-center');
  //this.animation2 = true;
  }
}

public onIntersectionBtn({ target, visible}: { target: Element; visible: boolean; id: number}): void {
  if(visible){
  target.classList.add('scale-in-hor-center');
  //this.animation3 = true;
  }
}

}
