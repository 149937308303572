import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Mensaje } from '../entidades/mensaje';
import { environment } from '../../environments/environment';
// import { environment } from '../../environments/environment.beta';
import { MensajeWeb } from '../entidades/mensaje-web';
import { MensajeVentaWeb } from 'src/app/entidades/mensaje-venta-web';

@Injectable({
  providedIn: 'root'
})
export class pagosService {

  constructor(private http: HttpClient) {}


   async procesarpagoconekta(numrsv: number, ticket: string, tokenid: string, email: string, telefono: string, publicidad: boolean, idioma: string, cliente: string, tarjeta: string, istestmode: boolean) {
    var url: string = `${environment.URL_pagoAPI}/conektaBuy2_tfp?numrsv=${numrsv}&ticket=${ticket}&tokenid=${tokenid}&email=${email}&telefono=${telefono}&publicidad=${publicidad}&idioma=${idioma}&cliente=${cliente}&tarjeta=${tarjeta}`;

    if (!environment.production) url += `&istestmode=true`;
    //  console.log(url); //  return new Mensaje;
    return this.http.get<Mensaje>(url).toPromise();
  }

 async oprVentaWeb(opr: string, idvta: string, idrefr: string, idrefr2: string, tpo: string, tpo2: string, idcliente: string, idrefpago: string, telefono: string, correo: string,imptot: string,metpgo: string,idfac: string) {
    var url: string = `${environment.URL_APIPAQ}/oprventaweb?opr=${opr}&idvta=${idvta}&idrefr=${idrefr}&idrefr2=${idrefr2}&tpo=${tpo}&tpo2=${tpo2}&idcliente=${idcliente}&idrefrpgo=${idrefpago}&telefono=${telefono}&correo=${correo}&imptot=${imptot}&metpgo=${metpgo}&idfact=${idfac}`;
    return this.http.get<MensajeWeb>(url).toPromise();
  }

  facturar(numrsv: number, nombre:string, rfc:string , domfiscal:string, regfiscal:string){
    var url: string = `${environment.URL_APIPAQ}/facturar?numrsv=${numrsv}&nombre=${nombre}&rfc=${rfc}&domfiscal=${domfiscal}&regfiscal=${regfiscal}`;
    return this.http.get<MensajeWeb>(url).toPromise();
  }

  ventafacturada(numrsv: number){
    var url: string = `${environment.URL_APIPAQ}/cargardatosventaweb?numrsv=${numrsv}`;
    return this.http.get<MensajeVentaWeb>(url).toPromise();
  }

  getHistorialCompras(idcliente:number) {
    var url: string = `${environment.URL_APIPAQ}/cargarhistorialmiscompras?idcliente=${idcliente}`;
    return this.http.get<any>(url).toPromise();
  }


}
