import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { ResumenSolicitudCS } from '../entidades/resumen_solicitud';

@Injectable({
  providedIn: 'root'
})
export class SolicitudEntregaService {

  constructor(
    private http: HttpClient
  ) { }

  private respuestaSolicitud = new BehaviorSubject(new ResumenSolicitudCS());
  respuestaSol = this.respuestaSolicitud.asObservable();

  solicitar(rastreo: string, solicita: string, telefono: number, correo: string, estado: number, ciudad: number, colonia: number, direccion: string, referencia: string) {
    var uri = `${environment.URL_API}/commSolEntDm?rastreo=${rastreo}&push=-&sol=${solicita}&tel=${telefono}&crr=${correo}&edo=${estado}&cd=${ciudad}&col=${colonia}&dir=${direccion}&ref=${referencia}&tipo=ENT`;

    while (uri.indexOf(" ", 0) > -1) {
      uri = uri.replace(" ", "+");
    }


    if (!environment.production) uri += '&isTestMode=true';

    return this.http.get<ResumenSolicitudCS> (
      uri
    );
  }

  guardarResumen(resumen: ResumenSolicitudCS) {
    this.respuestaSolicitud.next(resumen);
    //console.log(resumen);
  }

}