import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { BehaviorSubject, Observable } from "rxjs";
import { Rastreo } from "../entidades/rastreo";
import { ColoniasDspEntDm } from "../entidades/solicitud";
import { Ciudades } from "../entidades/ciudades";
import { Estados } from "../entidades/estados";
import { stringify } from "querystring";

@Injectable({
  providedIn: "root",
})

export class recolecciondom {

  private estadoRec = new BehaviorSubject<string>("");
  estadoR = this.estadoRec.asObservable();

  private ciudadRec = new BehaviorSubject<string>("");
  ciudadR = this.ciudadRec.asObservable();

  private coloniaRec = new BehaviorSubject<string>("");
  coloniaR = this.coloniaRec.asObservable();

  constructor(private http: HttpClient) {}

  public setEstado(value) {
    this.estadoRec.next(value);
  }
  public setCiudad(value) {
    this.ciudadRec.next(value);
  }
  public setColonia(value) {
    this.coloniaRec.next(value);
  }

  getestados1() {
    let url = `${environment.URL_APIPAQ}/commEdoDspRecDm`;

    if (!environment.production) {
      url += "&isTestMode=true";
    }

    return this.http.get<any[]>(url);
  }

  getestados() {
    let url = `${environment.URL_API}/commEdoDspRecDm`;

    return this.http.get<any[]>(url);
  }

  getciudad(idedo: number) {
    let url = `${environment.URL_API}/commCdDspRecDm?idedo=${idedo}`;

    return this.http.get<any[]>(url);
  }

  getcolonia(idedo: number, idcd: number) {
    let url = `${environment.URL_API}/commColDspRecDm?idedo=${idedo}&idcd=${idcd}`;
    return this.http.get<any[]>(url);
  }

  getdomiciliosfrecuentes(idcliente: number) {
    let url = `${environment.URL_APIPAQ}/CargarClienteweb_dirRecoleccion?idCli=${idcliente}`;
    return this.http.get<any[]>(url).toPromise();
  }

  async soldm(
    rastreo: string,
    push: string,
    sol: string,
    tel: string,
    crr: string,
    edo: number,
    cd: number,
    col: number,
    dir: string,
    ref: string,
    tipo: string,
    idcliente: number
  ) {
    const url = `${environment.URL_API}/commSolEntDm?rastreo=${rastreo}&push=${push}&sol=${sol}&tel=${tel}&crr=${crr}&edo=${edo}&cd=${cd}&col=${col}&dir=${encodeURIComponent(dir)}&ref=${encodeURIComponent(ref)}&tipo=${tipo}&idcli=${idcliente}`;

    return this.http.get<any>(url).toPromise();
  }

  async eliminarDireccionesFrecuentes(
    opr: string,
    iddirr: string,
    idcli: number
  ) {
    const urlDelete = `${environment.URL_APIPAQ}/oprClienteweb_dirRecoleccion?opr=${opr}&iddirr=${iddirr}&idcli=${idcli}&idedo=0&idcd=0&idcol=0&Nombre=x&correo=x&Telefono=x&direccion=x&ref=x`;
    return this.http.get<any>(urlDelete).toPromise();
  }

  async agregarDireccionesFrecuentes(
    opr: string,
    iddirr: string,
    idcli: number,
    idedo: number,
    idcd: number,
    idcol: number,
    Nombre: string,
    correo: string,
    Telefono: string,
    direccion: string,
    ref: string
  ) {
    const urlAdd = `${environment.URL_APIPAQ}/oprClienteweb_dirRecoleccion?opr=${opr}&iddirr=${iddirr}&idcli=${idcli}&idedo=${idedo}&idcd=${idcd}&idcol=${idcol}&Nombre=${Nombre}&correo=${correo}&Telefono=${Telefono}&direccion=${direccion}&ref=${ref}`;
    return this.http.get<any>(urlAdd).toPromise();
  }

  gethistorialsolicitudes(idcliente:number) {
    let url = `${environment.URL_APIPAQ}/historialsolicitudes?idcliente=`+idcliente;
    return this.http.get<any>(url);
  }

  getStatusRec(folio: number) {
    let url = `${environment.URL_APIPAQ}/DetalleRecoleccion?folio=`+folio;
    return this.http.get<any>(url).toPromise();
  }

  getNotifEmail(Codigo:String){
     let url = `${environment.URL_APIPAQ}/VerificarContacto?codigo=`+Codigo;
    //  console.log(url);
     return this.http.get<any>(url).toPromise();
  }

  getDataContacto(sugerencia : string,correo: string, telefono: string){
    let url = `${environment.URL_API}/buzonsugerencia?sugerencia=`+ sugerencia + '&correo='+ correo +'&telefono=' + telefono;
    return this.http.get<any>(url).toPromise();
  }

  getValidacionCode(iduser:number, codigo:string) {
    let url = `${environment.URL_APIPAQ}/wsValidarcodigopromocion?iduser=`+iduser+'&codigo='+codigo;
    return this.http.get<any>(url).toPromise();
  }

  canjearCode(iduser:number, codigo:string, idpromo:number) {
    let url = `${environment.URL_APIPAQ}/wsCanjearcodigopromocion?iduser=`+iduser+'&codigo='+codigo+'&idpromo='+idpromo;
    return this.http.get<any>(url).toPromise();
  }

  getPaquetesGuias(idcliente:number) {
    let url = `${environment.URL_APIPAQ}/CargarguiasPaquetes?idcli=`+idcliente;
    return this.http.get<any>(url).toPromise();
  }

  getDetallePaquetesGuias(idpaquete:string, idcliente:number) {
    let url = `${environment.URL_APIPAQ}/CargarguiasPaquetesDescripcion?idcli=`+idcliente+'&idp='+idpaquete;
    return this.http.get<any>(url).toPromise();
  }


}
