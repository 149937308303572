import { Component, OnInit, TemplateRef } from '@angular/core';

import { Destino } from '../../entidades/destino'; 
import { DestinoService } from '../../servicios/destino.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-punto-envio',
  templateUrl: './punto-envio.component.html',
  styleUrls: ['./punto-envio.component.css']
})
export class PuntoEnvioComponent implements OnInit {
  // variables
  origenSeleccionado: Destino; // origen seleccionado
  destinoSeleccionado: Destino; // destino seleccionado
  tipoOrigen = 'O';
  modalRef: BsModalRef;  

  constructor(
    private destinoService: DestinoService,
    private modalService: BsModalService
  ) { }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  // al inicar vamos a obtener el origen y destino que este seleccionado en destinoService
  ngOnInit() {
    this.destinoService.origenSeleccionado.subscribe(
      data => this.origenSeleccionado = data
    );
  }

  ngAfterViewInit(): void {
    this.destinoService.getDestinoList();
  }

}
