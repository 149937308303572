<nav class=" fijo navbar navbar-expand-lg navbar-light  fade-in-top" id="nav">

  <a class="navbar-brand" routerLink="/"><img width="100%" height="100%" class="tufesaLogo"
      src="assets/iconos/logo_tp.webp" alt="Tufesa"></a>

  <button id="btnMenu" class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
    aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation" id="btnMenu">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarNavDropdown">
    <ul class="navbar-nav">

      <a class="nav-item nav-link" routerLink="paqueteria/rastreo/captura" (click)="closeMenu('rastreo')">
        RASTREA
      </a>

      <a class="nav-item nav-link" routerLink="/cotizacion" (click)="closeMenu('')">
        COTIZA
      </a>

      <a class="nav-item nav-link" (click)="closeMenu('')" routerLink="/herramientasDigitales/documentacion/menu">
        ENVÍA
      </a>

      <a class="nav-item nav-link" (click)="closeMenu('')" routerLink="/paqueteria/recoleccion">
        RECOLECCIÓN
      </a>

      <a class="nav-item nav-link" routerLink="/sucursales" (click)="closeMenu('')">
        SUCURSALES
      </a>

      <a class="nav-item nav-link" routerLink="/contacto" (click)="closeMenu('')">
        CONTACTO
      </a>


      <li class="nav-item dropdown">
        <div *ngIf="isLogged === false">
          <a class="dropdown-toggle botonsesion nav-item nav-link" href="#" id="navbarDropdownMenuLink4" role="button"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">IDENTIFÍCATE</a>
          <div class="dropdown-menu">
            <a class="dropdown-item" (click)="closeMenu('')" routerLink="herramientasDigitales/login">Iniciar Sesión</a>
            <a class="dropdown-item" (click)="closeMenu('')" routerLink="herramientasDigitales/registro">Regístrate</a>
          </div>
        </div>
        <div *ngIf="isLogged === true">

          <a class="nav-link dropdown-toggle username" href="#" id="navbarDropdownMenuLink4" role="button"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{cadena}}
          </a>

          <div class="dropdown-menu">
            <a class="dropdown-item" (click)="verificarToken('GW1')"
              routerLink="herramientasDigitales/documentacion/menu">Documentar guías</a>
            <a class="dropdown-item" (click)="verificarToken('GW2')" routerLink="herramientasDigitales/guias">Solicitar
              guías</a>
            <a class="dropdown-item" (click)="verificarToken('GW3')"
              routerLink="herramientasDigitales/facturas">Historial de Facturas</a>
            <a class="dropdown-item" (click)="verificarToken('GW4')"
              routerLink="herramientasDigitales/historial">Historial de guías</a>
            <a class="dropdown-item" (click)="verificarToken('GW5')"
              routerLink="herramientasDigitales/listado-recoleccion">Listado recoleccion</a>
            <a class="dropdown-item" (click)="verificarToken('GW6')"
              routerLink="herramientasDigitales/administrar-cuenta">Administrar Cuenta</a>
            <a class="dropdown-item" (click)="verificarToken('GW7')"
              routerLink="historial-compra">Historial de compra</a>
            <a class="dropdown-item" (click)="verificarToken('GW8')"
              routerLink="mis-documentos">Mis Formatos</a>
            <a class="dropdown-item" (click)="verificarToken('GW9')"
              routerLink="afiliados">Afiliados</a>
            <a class="dropdown-item" (click)="cerrarsesion()">Cerrar sesión</a>
          </div>
        </div>
      </li>

    </ul>
  </div>
</nav>
