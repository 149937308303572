import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-grupo-tufesa',
  templateUrl: './grupo-tufesa.component.html',
  styleUrls: ['./grupo-tufesa.component.css']
})
export class GrupoTufesaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
