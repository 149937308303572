<div class="gw-container">
    <form [formGroup]="documentacionForm">
        <div class="gw-flex-titulos">
            <div>
                <h4>Confirmación</h4>
                <p class="gw-flex-titulo2">{{this.tipopaquete}}</p>
            </div>

        </div>
        <div class="titulos">
            <label class="lbltitulos">Remitente</label>
        </div>
        <div class="gw-flex">
            <div class="gw-flex-l">
                <label class="lbl-text">No. cliente</label>
                <input class="doc-lbl inputencabezados" type="text" formControlName="noEmpleado">
            </div>

            <div class="gw-flex-r">
                <label class="lbl-text">Fecha</label>
                <input class="doc-lbl inputencabezados" type="text" formControlName="fecha">
            </div>
        </div>

        <div class="div-form">
            <label class="lbl-text">Nombre</label>
            <input class="doc-lbl" type="text" formControlName="Rnombre"
                [ngClass]="{validacion: (doc.Rnombre.invalid || doc.Rnombre.hasError('IsEmpty')) && submitted}">
        </div>

        <div class="gw-flex">
            <div class="gw-flex-l">
                <label class="lbl-text">País</label>
                <input class="doc-lbl" type="text" formControlName="RselectPais"
                    [ngClass]="{validacion: (doc.RselectPais.invalid || doc.RselectPais.hasError('IsEmpty')) && submitted}">
            </div>

            <div class="gw-flex-r">
                <label class="lbl-text">Estado</label>
                <input class="doc-lbl" type="text" formControlName="RselectEstado"
                    [ngClass]="{validacion: (doc.RselectEstado.invalid || doc.RselectEstado.hasError('IsEmpty')) && submitted}">
            </div>
        </div>

        <div class="gw-flex">
            <div class="gw-flex-l">
                <label class="lbl-text">Ciudad</label>
                <input class="doc-lbl" type="text" formControlName="RselectCiudad"
                    [ngClass]="{validacion: (doc.RselectCiudad.invalid || doc.RselectCiudad.hasError('IsEmpty')) && submitted}">
            </div>

            <div class="gw-flex-r">
                <label class="lbl-text">Colonia</label>
                <input class="doc-lbl" type="text" formControlName="RselectColonia"
                    [ngClass]="{validacion: (doc.RselectColonia.invalid || doc.RselectColonia.hasError('IsEmpty')) && submitted}">
            </div>
        </div>

        <div class="div-form">
            <label class="lbl-text">Direccíon</label>
            <input type="text" formControlName="Rdireccion"
                [ngClass]="{validacion: (doc.Rdireccion.invalid || doc.Rdireccion.hasError('IsEmpty')) && submitted}">
        </div>
        <div class="gw-flex">
            <div class="gw-flex-l">
                <label class="lbl-text">Teléfono</label>
                <input type="text" formControlName="Rtelefono"
                    [ngClass]="{validacion: (doc.Rtelefono.invalid || doc.Rtelefono.hasError('IsEmpty')) && submitted}">
            </div>


            <div class="gw-flex-r">
                <label class="lbl-text">Código postal</label>
                <input type="text" formControlName="Rcp"
                    [ngClass]="{validacion: (doc.Rcp.invalid || doc.Rcp.hasError('IsEmpty')) && submitted}">

            </div>
        </div>

        <!-- destinatario -->
        <div class="titulos">
            <label class="lbltitulos">Destinatario</label>
        </div>
        <div class="div-form">
            <label class="lbl-text">Nombre</label>
            <input class="doc-lbl" type="text" formControlName="Dnombre"
                [ngClass]="{validacion: (doc.Dnombre.invalid || doc.Dnombre.hasError('IsEmpty')) && submitted}">
        </div>

        <div class="gw-flex">
            <div class="gw-flex-l">
                <label class="lbl-text">Estado</label>
                <input class="doc-lbl" type="text" formControlName="DselectEstado"
                    [ngClass]="{validacion: (doc.DselectEstado.invalid || doc.DselectEstado.hasError('IsEmpty')) && submitted}">
            </div>
            <div class="gw-flex-r">
                <label class="lbl-text">Ciudad</label>
                <input class="doc-lbl" type="text" formControlName="DselectCiudad"
                    [ngClass]="{validacion: (doc.DselectCiudad.invalid || doc.DselectCiudad.hasError('IsEmpty')) && submitted}">
            </div>
        </div>

        <div class="div-form">
            <label class="lbl-text">Colonia</label>
            <input class="doc-lbl" type="text" formControlName="DselectColonia"
                [ngClass]="{validacion: (doc.DselectColonia.invalid || doc.DselectColonia.hasError('IsEmpty')) && submitted}">
        </div>

        <div class="div-form">
            <label class="lbl-text">Direccíon</label>
            <input type="text" formControlName="Ddireccion"
                [ngClass]="{validacion: (doc.Ddireccion.invalid || doc.Ddireccion.hasError('IsEmpty')) && submitted}">
        </div>
        <div class="gw-flex">
            <div class="gw-flex-l">
                <label class="lbl-text">Teléfono</label>
                <input type="text" formControlName="Dtelefono"
                    [ngClass]="{validacion: (doc.Dtelefono.invalid || doc.Dtelefono.hasError('IsEmpty')) && submitted}">
            </div>

            <div class="gw-flex-r">
                <label class="lbl-text">Código postal</label>
                <input type="text" formControlName="Dcp"
                    [ngClass]="{validacion: (doc.Dcp.invalid || doc.Dcp.hasError('IsEmpty')) && submitted}">

            </div>
        </div>

        <!-- tipó -->
        <div class="titulos">
            <label class="lbltitulos">Tipo</label>
        </div>
        <div class="wrapper" *ngIf="arrtipos.length">
            <div class="table">
                <div class="row header">
                    <div class="cell titilo ">
                        Tipo
                    </div>
                    <div class="cell titilo">
                        Cantidad
                    </div>
                    <div class="cell titilo">
                        Peso
                    </div>
                    <div class="cell titilo">
                        Ancho
                    </div>
                    <div class="cell titilo">
                        Largo
                    </div>
                    <div class="cell titilo">
                        Alto
                    </div>
                </div>

                <div class="row" *ngFor="let valor = index let i of arrtipos">
                    <div class="cell" data-title="Tipo">
                        {{i.tipo}}
                    </div>
                    <div class="cell" data-title="Cantidad">
                        {{i.cantidad}}
                    </div>
                    <div class="cell" data-title="Peso">
                        {{i.peso}}
                    </div>
                    <div class="cell" data-title="Ancho">
                        {{i.ancho}}
                    </div>
                    <div class="cell" data-title="Largo">
                        {{i.largo}}
                    </div>
                    <div class="cell" data-title="Alto">
                        {{i.alto}}
                    </div>
                </div>
            </div>
        </div>

        <div class="titulos">
            <label class="lbltitulos">Detalle</label>
        </div>

        <div class="guiaDoc-container ">
            <label class="custom-radio-checkbox ">
                <!-- Input oculto -->
                <input class="custom-radio-checkbox__input" type="checkbox" name="genero" value="hombre"
                    formControlName="cobrar">
                <!-- Imagen en sustitucion -->
                <span class="custom-radio-checkbox__show custom-radio-checkbox__show--checkbox"></span>
                <!-- Texto -->
                <span class="custom-radio-checkbox__text">Por cobrar</span>
            </label>

            <label class="custom-radio-checkbox">
                <!-- Input oculto -->
                <input class="custom-radio-checkbox__input" type="checkbox" name="genero" value="hombre"
                    formControlName="recoleccion">
                <!-- Imagen en sustitucion -->
                <span class="custom-radio-checkbox__show custom-radio-checkbox__show--checkbox"></span>
                <!-- Texto -->
                <span class="custom-radio-checkbox__text">Recolección</span>
            </label>


            <label class="custom-radio-checkbox">
                <!-- Input oculto -->
                <input class="custom-radio-checkbox__input" type="checkbox" name="genero" value="hombre"
                    formControlName="acuse">
                <!-- Imagen en sustitucion -->
                <span class="custom-radio-checkbox__show custom-radio-checkbox__show--checkbox"></span>
                <!-- Texto -->
                <span class="custom-radio-checkbox__text">Acuse recibo</span>
            </label>
        </div>
        <div class="div-form">
            <label class="lbl-text">Servicio</label>
            <input class="doc-lbl" type="text" formControlName="valordec"
                [ngClass]="{validacion: (doc.valordec.invalid || doc.valordec.hasError('IsEmpty')) && submitted}">
        </div>
        <div class="div-form">
            <label class="lbl-text">Valor declarado</label>
            <input class="doc-lbl" type="text" formControlName="ValorDeclarado"
                [ngClass]="{validacion: (doc.ValorDeclarado.invalid || doc.ValorDeclarado.hasError('IsEmpty')) && submitted}">
        </div>

        <div class="div-form">
            <label class="lbl-text">Comentario</label>
            <input class="doc-lbl" type="text" formControlName="comentario"
                [ngClass]="{validacion: (doc.comentario.invalid || doc.comentario.hasError('IsEmpty')) && submitted}">
        </div>
    </form>
    <hr>

    <div class="titulos">
        <label class="lbltitulos">Guías seleccionadas</label>
    </div>

    <div class="wrapper" *ngIf="arrSolGuias.length">

        <div class="table">
            <div class="row header">
                <div class="cell titilo ">
                    Solicitud
                </div>
                <div class="cell titilo">
                    Cantidad
                </div>
                <div class="cell titilo">
                    Factura
                </div>
            </div>

            <div class="row" *ngFor="let valor = index let i of arrSolGuias">
                <div class="cell" data-title="Solicitud">
                    {{i.idsol}}
                </div>
                <div class="cell" data-title="Cantidad">
                    {{i.cant}}
                </div>
                <div class="cell" data-title="Factura">
                    {{i.fact}}
                </div>
            </div>
        </div>

    </div>
   <!-- <div class="guiaDoc-container">-->


    <!-- </div>-->
    <div style="margin-bottom: 22px;">
       <div class="container-codigo-cupon">
          <!-- <span>Cupón/Código de descuento:</span> -->
          <input type="text" class="input-codigo-cupon" formControlName="cupon" id="txtcupon" placeholder="Ingrese el código">
          <button class="btn-codigo-cupon" (click)="validarCupon()">Aplicar</button>
       </div>
       <div style="margin-top: 6px;">
          <span *ngIf="cuponValidacion === 'valido'" class="txt-cupon"><i class="fas fa-check-circle icon-txtcupon"></i> Cupón agregado correctamente - {{txtcuponmayusculas}}</span>
          <span *ngIf="cuponValidacion === 'novalido'" class="txt-cupon"><i class="fas fa-exclamation-triangle icon-txtcupon"></i>{{mensajecuponvacio}}</span>
       </div>
    </div>

    <div>
        <label class="lbltotalpagodescuento"  *ngIf="mostrarLabelDescuento" >Precio sin cupón: ${{precioinicialtachado}} MXN</label>
        <label class="lbltotalpago" style="padding-top: 0px;">Total a pagar: ${{imptotal}} MXN</label>
    </div>

    <div style="margin-top: 20px;">
      <label class="custom-radio-checkbox-pago" [ngClass]="{'checkbox-true': valcheckm == true}" style="margin-right: 18%;">
        <!-- Input oculto -->
        <input class="custom-radio-checkbox__input" type="checkbox" formControlName="fchkmostrador" id="chkmostrador">
        <!-- Imagen en sustitucion -->
        <span class="custom-radio-checkbox__show custom-radio-checkbox__show--checkbox"></span>
        <!-- Texto -->
        <span class="custom-radio-checkbox__text">Pagar en mostrador</span>
    </label>

    <label class="custom-radio-checkbox-pago" [ngClass]="{'checkbox-true': valcheckl == true}">
        <!-- Input oculto -->
       <input class="custom-radio-checkbox__input" type="checkbox" formControlName="fchkenlinea" id="chkenlinea">
        <!-- Imagen en sustitucion -->
        <span class="custom-radio-checkbox__show custom-radio-checkbox__show--checkbox"></span>
        <!-- Texto -->
        <span class="custom-radio-checkbox__text">Pagar en línea</span>
    </label>
    </div>

    <div class="gw-reverse-btn" style="margin-top: 10px;">
        <button name="Aceptar" class="gw-btn-continuar gw-btn-pasos" type="submit" (click)="finalizardoc()">Finalizar</button>
        <button class="btn-back gw-btn-pasos" type="button" (click)="regresarMenu()">Regresar</button>
    </div>

</div>
