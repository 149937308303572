<div class="guiasDoc-container">
    <h1>Herramientas digitales</h1>
    <p>Use esta sencilla herramienta para solicitar y usar guias.
    </p>
</div>
<div class="guiaDoc-menu u-flex">
    <button class="buttonMenu is-first" (click)="abrirHerramienta(1)">
        <img width="100%" height="100%" class=" lazyload itempaq" data-src="assets/iconos/paq.png"
            alt="Icono Tufesa Envios">
        <p class="p-tipos">Documentar envío</p>
    </button>
    <button class="buttonMenu is-second" (click)="abrirHerramienta(2)">
        <img width="100%" height="100%" class=" lazyload itempaq" data-src="assets/iconos/paq.png"
            alt="Icono Tufesa Envios">
        <p class="p-tipos">Solicitar guías</p>
    </button>
    <button class="buttonMenu is-third" (click)="abrirHerramienta(3)">
        <img width="100%" height="100%" class=" lazyload itempaq" data-src="assets/iconos/paq.png"
            alt="Icono Tufesa Envios">
        <p class="p-tipos">Historial</p>
    </button>
    <button class="buttonMenu is-fourth" (click)="abrirHerramienta(4)">
        <img width="100%" height="100%" class=" lazyload itempaq" data-src="assets/iconos/paq.png"
            alt="Icono Tufesa Envios">
        <p class="p-tipos">Historial facturas</p>
    </button>
</div>
   


    