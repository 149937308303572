import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { MensajePopup } from '../entidades/MensajePopup';

@Injectable({
  providedIn: 'root'
})
export class MensajeService {

  subject$ = new Subject<MensajePopup>();

  constructor() { }
}
