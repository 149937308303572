import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { LoLlevaService } from 'src/app/servicios/lo-lleva.service'
import { Router, ActivatedRoute } from '@angular/router';
import { GoogleTagManagerService } from '../../servicios/google-tag-manager.service';
import { AdministrarCuentaService } from 'src/app/servicios/administrar-cuenta.service';
import { user } from "../../entidades/gwuser";
import jwt_decode from "jwt-decode";
import { FormGroup, FormControl, Validators } from "@angular/forms";
 import { pagosService } from 'src/app/servicios/pagos.service';
import { Mensaje } from 'src/app/entidades/mensaje';
import { MensajeService } from 'src/app/servicios/mensaje.service';
import { MensajePopup } from 'src/app/entidades/MensajePopup';
import { MensajeVentaWeb } from 'src/app/entidades/mensaje-venta-web';
import { GuiaswebServiceService } from "../../servicios/guiasweb-service.service";


@Component({
  selector: 'app-envio-resumen',
  templateUrl: './envio-resumen.component.html',
  styleUrls: ['./envio-resumen.component.css']
})
export class EnvioResumenComponent implements OnInit {
  isConfirm: boolean = true;
  confirmacion: any;
  total: String = "";
  folio: number = 0;
  valorcheck: number = 0;

  datacuenta: any;
  msg: string;

  userGw: user;
  datagw: any;
  token: string;
  tokendatos: any;
  idcliente: number = -1;

  referencia: any;
  fechaCompra :string;
  isLoguedin: boolean;
  tipoventa: string;
  idsesion: number;

  razon_: string;
  rfc_: string;
  domfisc_: string;
  regfisc_: string;

  descargardatos: string;
  descargaxml: string;
  descargaPdf: string;
  

   // Definimos el formulario con sus campos y validaciones
    formulario = new FormGroup({
    razonsocial: new FormControl(""),
    rfc: new FormControl(""),
    domiciliofiscal: new FormControl(""),
    regimenfiscal: new FormControl(""),
  });

  constructor(
    private lollevaService: LoLlevaService,
    private router: Router,
    private route: ActivatedRoute,
    private googleService: GoogleTagManagerService,
    private administrarCuentaService: AdministrarCuentaService,
    private activateRoute: ActivatedRoute,
    private pagosservice: pagosService,
    private mensajeService: MensajeService,
    private GuiaswebServiceService: GuiaswebServiceService
  ) { 
    this.activateRoute.queryParams.subscribe(params => {
      try{
        this.referencia = params['rf'];
        this.datosTransaccion = JSON.parse(atob(this.referencia));
      }
      catch(e){
        //redirige a home
//        this.router.navigate(['**']);
        this.router.navigate(["/herramientasDigitales/login"],{queryParams:{to:'confirmacion'}});
      }
    });
  }

  ngOnInit(): void {
    
    if(sessionStorage.getItem("token")){
      this.token = sessionStorage.getItem("token");
      this.tokendatos = jwt_decode(this.token);
      this.isLoguedin = true;
      this.userGw = this.tokendatos;
      this.idcliente = this.userGw.idUser;
    }
    else {
      this.isLoguedin = false;
      //this.router.navigate(["/herramientasDigitales/login"]);
      this.router.navigate(["/herramientasDigitales/login"],{queryParams:{to:'confirmacion'}});
    }
   
    this.ventaFacturada();
  
    this.cargardata();

  }

  datosTransaccion = 
    {
      id: 0,
      numGuias: 0,
      costo: "",
      numAutorizacion: "",
      detalle: "",
      beneficios: [""]
    };

  objectdata = {
    idcliweb: 1,
    nombre: "Humberto alonso garcia quiroz",
    correo: "humbertogarciaq@gmail.com",
    telefono: "6441675927",
    cp: "85000",
    chktelefono: "",
    chkwhats: "",
    chkcorreo: "",
    chkninguno: "",
    reazon: "asasas",
    rfc: "sasas",
    domfisc: "asasas",
    regimenfiscal: "605",
    imagenes: [{ idimg: 7, ref1: 0, ref2: 0, tipo1: 0, tipo2: 0, img: "" }],
    documentos: [{ iddoc: "", tipo1: "", tipo2: "", img: "" }],
    sesion: [{ nummsg: "1", msg: "Bienvenido" }],
  };

  obtenerconfimarcion(num: number) {
    // this.lollevaService.GetConfirmacion(num).subscribe(
    //   datos => {
    //     this.confirmacion = datos;
    //     try {
    //       this.googleService.load(this.total, this.folio, this.confirmacion);
    //       localStorage.setItem("folio", this.confirmacion);
    //      } catch (Error)
    //      {
    //      }
    //     if(this.confirmacion.id == 1){
    //     this.isConfirm = true;
    //     this.total =  parseFloat(this.confirmacion.importe.toFixed(2));

    //    } else{
    //     this.isConfirm = false;
    //     }
    //   }
    // );
  }

  cargardata() {
    if (sessionStorage.getItem("token")) {
      this.token = sessionStorage.getItem("token");
      this.tokendatos = jwt_decode(this.token);
      this.userGw = this.tokendatos;
      this.idcliente = this.userGw.idUser;
      this.isLoguedin = true;
    } else {
      this.idcliente = -1;
      this.isLoguedin = false;
      this.router.navigate(["/herramientasDigitales/login"],{queryParams:{to:'confirmacion'}});
    }
    this.administrarCuentaService.cargarDatos(this.idcliente).then((data) => {
      this.datacuenta = data;
      this.objectdata = this.datacuenta;
    }); 
  }

 

  async agregarpmultipagos() {

    // let RsvIda = this.info.ireferencia,  // declara la variable con el codigo de reservacion de ida
    //   RsvReg = '',                              // declara la variable de reservacion de regreso
    //   Total =this.precio[this.activo].Costo;       // total de la reservacion

    // let QueryString = 'CodigoReserv=' + RsvIda + '&'; // declarar la variable con todos los parametros y agregamos la reservacion de ia

    // if (parseInt(RsvReg) === 0 || RsvReg === '') { // si no hay reservacion de regreso
    //   QueryString += 'CodigoReservReg=' + RsvIda + '&';
    // } else {
    //   QueryString += 'CodigoReservReg=' + RsvReg + '&';
    // } // si no pues la de regreso

    // QueryString += 't_servicio=099&Total=' + Total + '&'; // t_servicio es fijo, y agregar el total de la reservacion

    // QueryString += 'idioma=1&'; // idioma es fijo en 1

    // let moneda = 0; // variable para mandar el tipo de moneda

    // if (this.moneda === 'MXN') {  // si la corrida de ida es en pesos
    //   QueryString += 'moneda=1&'; // la moneda es 1
    //   moneda = 1;
    // } else {
    //   QueryString += 'moneda=2&'; // si no es dos
    //   moneda = 2;
    // }
    // QueryString += 'Email=' + this.correomultipagos; // agregar el correo
    // window.location.href = encodeURI(environment.URLProcesapagoMultipagos + '?' + QueryString); // aqui se manda a redireccionar a la pagina de pago

  }

  cambiarvalor() {
    
    if (this.valorcheck === 1) {
      this.valorcheck = 0;
      document.getElementById("circle").style.left = "1px";
      document.getElementById("switchFact").style.backgroundColor = "#ccc";
      document.getElementById("fiscaldata").style.display = "none";
      document.getElementById("fiscaldata2").style.display = "none";
      document.getElementById("btn_facturar").style.opacity = "0.5";
      document.getElementById("btn_facturar").style.cursor = "unset";
    } else {
        this.valorcheck = 1;
        document.getElementById("switchFact").style.backgroundColor =
          "#ff5b23";
        document.getElementById("circle").style.left = "26px";
        document.getElementById("fiscaldata").style.display = "block";
        document.getElementById("fiscaldata2").style.display = "block";
        document.getElementById("btn_facturar").style.userSelect = "auto";
        document.getElementById("btn_facturar").style.opacity = "1";
        document.getElementById("btn_facturar").style.cursor = "pointer";
    }
  }

  cambiarvalor2() {
    
    if (this.valorcheck === 1) {
      this.valorcheck = 0;
      document.getElementById("circle2").style.left = "1px";
      document.getElementById("switchFact2").style.backgroundColor = "#ccc";
      document.getElementById("fiscaldata2").style.display = "none";
      document.getElementById("btn_facturar2").style.opacity = "0.5";
      document.getElementById("btn_facturar2").style.cursor = "unset";
    } else {
        this.valorcheck = 1;
        document.getElementById("switchFact2").style.backgroundColor =
          "#ff5b23";
        document.getElementById("circle2").style.left = "26px";
        document.getElementById("fiscaldata2").style.display = "block";
        document.getElementById("btn_facturar2").style.userSelect = "auto";
        document.getElementById("btn_facturar2").style.opacity = "1";
        document.getElementById("btn_facturar2").style.cursor = "pointer";
    }
  }

  facturarVenta(){

    this.razon_ = this.formulario.get('razonsocial').value;
    this.rfc_ = this.formulario.get('rfc').value;
    this.domfisc_ = this.formulario.get('domiciliofiscal').value;
    this.regfisc_ = this.formulario.get('regimenfiscal').value;

    if(sessionStorage.getItem("token")){
      this.token = sessionStorage.getItem("token");
      this.tokendatos = jwt_decode(this.token);
      this.userGw = this.tokendatos;
      this.idcliente = this.userGw.idUser;
      // console.log(this.nameCliente);
    }
    try {
      // 4 GUARDAR venta en base de datos
      this.pagosservice
        .facturar(
          this.datosTransaccion.id,
          this.razon_,
          this.rfc_,
          this.domfisc_,
          this.regfisc_
        )
        .then (
          (msjWeb) => {
            if (msjWeb.nummsj > 0) {
              // this.estaGuardado = true;
              // this.mensaje.TxtMsj = msjWeb.txtmsj;
              this.mensajeService.subject$.next(
                new MensajePopup(msjWeb.txtmsj, true, false)
              );
              this.isConfirm = false;
              this.cargardataFacturada();
               document.getElementById("compra_noFacturada").style.display = "none";
            } 
            else {
              // btn.disabled = false;
              console.log("entro al else");
              this.mensajeService.subject$.next(
                new MensajePopup(msjWeb.txtmsj, false, false)
              );
            }
          },
          (error) => {
            // btn.disabled = false;
            this.mensajeService.subject$.next(
              new MensajePopup(
                'Lo sentimos, estamos fuera de servicio.',
                false,
                false
              )
            );
          }
        );
    } catch (e) {
      // btn.disabled = false;
    }
  }

  ventaFacturada() {
    if (sessionStorage.getItem("token")) {
      this.token = sessionStorage.getItem("token");
      this.tokendatos = jwt_decode(this.token);
      this.isLoguedin = true;
      this.userGw = this.tokendatos;
      this.idcliente = this.userGw.idUser;
    } else {
      this.idcliente = -1;
      this.isLoguedin = false;
      this.router.navigate(["/herramientasDigitales/login"]);
    }
    this.pagosservice.ventafacturada(this.datosTransaccion.id).then((data) => {
      if (data.nummsj > 0){
        this.isConfirm = false;
        this.descargaxml = data.urlxml;
        this.descargaPdf = data.urlpdf;
        this.fechaCompra = data.fecha;
        this.tipoventa=data.tipoventa;
        this.idsesion=data.idsesion;
        document.getElementById("vistaConfirmacion").style.display = "none";
        document.getElementById("compra_noFacturada").style.display = "none";
        if (this.tipoventa!="GWC")
        {
          document.getElementById("btn_guia1").style.display = "none";
          document.getElementById("btn_guia2").style.display = "none";
          document.getElementById("btn_guia3").style.display = "none";
        }

        console.log("facturado");
      }
      else{
        if(data.confirmado == "0"){
          document.getElementById("vistaConfirmacion").style.display = "block";
          // document.getElementById("compraFacturada").style.display = "none";
          document.getElementById("compra_noFacturada").style.display = "none";
          this.fechaCompra = data.fecha;
          this.tipoventa=data.tipoventa;
        this.idsesion=data.idsesion;
          console.log("vistaconfirmacion");
          console.log(data.nummsj);
          if (this.tipoventa!="GWC")
          {
            document.getElementById("btn_guia1").style.display = "none";
            document.getElementById("btn_guia2").style.display = "none";
            document.getElementById("btn_guia3").style.display = "none";
          }
        }
        else{
          document.getElementById("vistaConfirmacion").style.display = "none";
          // document.getElementById("compraFacturada").style.display = "none";
          document.getElementById("compra_noFacturada").style.display = "block";
          this.fechaCompra = data.fecha;
          this.tipoventa=data.tipoventa;
        this.idsesion=data.idsesion;
        if (this.tipoventa!="GWC")
        {
          document.getElementById("btn_guia1").style.display = "none";
          document.getElementById("btn_guia2").style.display = "none";
          document.getElementById("btn_guia3").style.display = "none";
        }
          console.log("no facturado");
        }
        this.isConfirm = true;
        this.fechaCompra = data.fecha;
      }
    }); 
  }

  cargardataFacturada(){
    this.pagosservice.ventafacturada(this.datosTransaccion.id).then((data) => {
      if (data.nummsj > 0){
        this.isConfirm = false;
        this.descargaxml = data.urlxml;
        this.descargaPdf = data.urlpdf;
        this.fechaCompra = data.fecha;
      }
    }); 
  }

  sacarFecha(){
    this.pagosservice.ventafacturada(this.datosTransaccion.id).then((data) => {
        this.fechaCompra = data.fecha;
        console.log(this.fechaCompra);
    }); 
  }

  async crearpdf() {
    var aux, aux1;
    aux = this.idsesion + "Guia-Tufesa";
    aux1 = this.idsesion;
    console.log("PDF");
    console.log(aux);
    console.log(aux1);
    await this.GuiaswebServiceService.facturapdf("-", aux, aux1, "2").then();
  }

}



