<div class="hero-container">
    <div class="main">
        <h1>Envíos locales</h1>
        <p>Recolecciones y entregas cumpliendo con los protocolos de higiene en tu ciudad</p>


        <label>Ciudades</label>
        <owl-carousel [options]="mySlideOptions" [items]="ciudades" [carouselClasses]="['owl-theme', 'row', 'sliding']">
            <div *ngFor="let ciudad of ciudades;let i= index">
                <div class="item city" id="{{i}}" (click)="selectCiudad(i, ciudad.Suc, 1)">
                    {{ciudad.Ciudad}}
                </div>
            </div>
        </owl-carousel>
        <div [formGroup]="colsForm" *ngIf="colonias?.length > 0 && searchCity" class="fade-in">
            <label>Colonia origen</label>
            <div class="slct-container">
                <ng-select [items]="colonias" bindLabel="Colonia" bindValue="idcol" placeholder="Seleccione"
                    appendTo="body" [multiple]="false" [clearable]="false" [editableSearchTerm]="true"
                    notFoundText="No se encontro" formControlName="origen"
                    [ngClass]="{'error' : (colsForm.get('origen').invalid || colsForm.get('origen').value === 0) && submitted1}">
                </ng-select>
            </div>


            <label>Colonia destino</label>
            <div class="slct-container">
                <ng-select [items]="colonias" bindLabel="Colonia" bindValue="idcol" placeholder="Seleccione"
                    appendTo="body" [multiple]="false" [clearable]="false" [editableSearchTerm]="true"
                    notFoundText="No se encontro" formControlName="destino"
                    [ngClass]="{'error' : (colsForm.get('destino').invalid || colsForm.get('destino').value === 0)  && submitted1}">
                </ng-select>
            </div>


            <div class="form">
                <input type="radio" name="transporte" value="moto" id="rbMoto" checked
                    (change)="handleChange($event.target.value)" formControlName="transporte">
                <label for="rbMoto">Motocicleta</label>

                <input type="radio" name="transporte" value="camioneta" id="rbCam"
                    (change)="handleChangeCam($event.target.value)" formControlName="transporte">
                <label for="rbCam">Camioneta</label>
                <div class="service" *ngIf="service === 'moto'; else camioneta;">
                    <img width="100%" height="100%" src="assets/iconos/moto-el.png" alt="motocicleta">
                    <div>
                        <h4>Envíos en motocicleta</h4>
                        <p>Máximo 5 Kg.</p>
                        <p>25cm Largo x 25cm Ancho x 25cm Alto</p>
                        <p>Servicio de <strong>7:00</strong> a <strong>18:00 hrs.</strong></p>
                    </div>
                </div>
                <ng-template #camioneta>
                    <div class="service">
                        <img width="100%" height="100%" src="assets/iconos/camioneta-el.png" alt="camioneta">
                        <div>
                            <h4>Envíos en camioneta</h4>
                            <p>Máximo 30 Kg.</p>
                            <p>40cm Largo x 40cm Ancho x 40cm Alto</p>
                            <p>Servicio <strong>programado.</strong></p>
                        </div>
                    </div>
                </ng-template>

            </div>

            <button id="Local1" class="btnContinuar" (click)="checkCols()">Continuar</button>

        </div>
        <div *ngIf="colonias?.length === 0 && searchCity" class="fade-in">
            <h3 class="white-text">Lo sentimos por el momento no contamos con servicio en su cuidad, esperanos pronto.
            </h3>
        </div>

        <a pageScroll [pageScrollOffset]="100" href="#works" class="btn-works">¿Comó funciona?</a>
    </div>
</div>
<div class="video">
    <h1>Medidas correspondientes</h1>
    <h5>¡Juntos en sana distancia <span>superaremos</span> las adversidades!</h5>
    <div class="video-responsive">
        <iframe class="lazyload" src="https://www.youtube.com/embed/urzoGpPzahs" frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
</div>

<div id="works" class="how-works">
    <h1>¿Qué es Envios locales? </h1>
    <p>Además del servicio de envíos nacionales e Internacionales que actualmente tenemos "Envíos Locales ” ofrece el
        servicio express de envíos dentro de tu ciudad, en donde podrás solicitar una motocicleta para el traslado en
        minutos de tus paquetes y sobres o si lo prefieres podrás solicitar una camioneta para artículos de mayor
        tamaño.</p>

    <h1>¿Como funciona?</h1>
    <h5>Es muy fácil solicitar un envío</h5>
    <ul>
        <li>Ingresa a la Pagina Web <a routerLink="/EnviosLocales">
                www.tufesaenvios.com/EnviosLocales</a></li>
        <li>Selecciona tu ciudad </li>
        <li>Ingresa el Origen </li>
        <li>Ingresa el Destino</li>
        <li>De acuerdo a las dimensiones y peso selecciona Motocicleta o Camioneta</li>
        <li>Selecciona Continuar para cotizar tu envió</li>
        <li>Selecciona “Solicitar servicio” y en breve recibirás una llamada para coordinar el envió</li>
    </ul>
</div>



<div class="qa-content">
    <h1>Preguntas frecuentes</h1>
    <div class="qa-question">
        <input id="q0" type="checkbox" class="panel">
        <label class="plus">+</label>
        <label for="q0" class="panel-title">¿Cuál es el horario de atención disponible?</label>
        <div class="panel-content">Para envíos locales ofrecemos servicio de lunes a viernes de 7:00 a 18:00 hrs y
            sabados de 7:00 a 14:00 hrs.</div>
    </div>
    <div class="qa-question">
        <input id="q1" type="checkbox" class="panel">
        <label class="plus">+</label>
        <label for="q1" class="panel-title">¿Dónde y cuando está disponible "Envíos locales”?</label>
        <div class="panel-content">El servicio está disponible en Cd. Obregón de 7:00 a 18:00 hrs para el servicio de
            motocicleta. Se deberá programar para el uso de la camioneta para la entrega.</div>
    </div>

    <div class="qa-question">
        <input id="q2" type="checkbox" class="panel">
        <label class="plus">+</label>
        <label for="q2" class="panel-title">No recibí mi pedido en la hora indicada ¿Qué puedo hacer?</label>
        <div class="panel-content">Se deberá poner en contacto con el centro de operaciones (Sucursal paquetería OBR).
        </div>
    </div>

    <div class="qa-question">
        <input id="q3" type="checkbox" class="panel">
        <label class="plus">+</label>
        <label for="q3" class="panel-title">¿Con qué opciones de pago cuentan?</label>
        <div class="panel-content">Las opciones de pago disponibles : Efectivo, Tarjeta de Crédito y Tarjeta de Debito.
            El pago lo puede realizar el remitente o el destinatario.</div>
    </div>

    <div class="qa-question">
        <input id="q4" type="checkbox" class="panel">
        <label class="plus">+</label>
        <label for="q4" class="panel-title">¿Cómo realizo un cambio de punto de recolección y/o entrega?</label>
        <div class="panel-content">Se debe poner en contacto con el centro de operaciones con anticipación para
            notificar al conductor de entregas. De ser necesario, se aplicarán ajustes al precio indicado.</div>
    </div>

    <div class="qa-question">
        <input id="q5" type="checkbox" class="panel">
        <label class="plus">+</label>
        <label for="q5" class="panel-title">No recibí llamada de confirmación de recolección.</label>
        <div class="panel-content">Se puede presentar por fallas en el sistema de recepción. Entonces, deberá ponerse en
            contacto con el centro de operaciones para confirmar la solicitud.</div>
    </div>

    <div class="qa-question">
        <input id="q6" type="checkbox" class="panel">
        <label class="plus">+</label>
        <label for="q6" class="panel-title">No recibí llamada de notificación de entrega.</label>
        <div class="panel-content">El remitente será notificado de que se ha entregado el paquete, de lo contrario,
            podrá ponerse en contacto con su destinatario o informarse en el centro de operaciones.</div>
    </div>

    <div class="qa-question">
        <input id="q7" type="checkbox" class="panel">
        <label class="plus">+</label>
        <label for="q7" class="panel-title">¿Que pasa si mi paquete sufre una incidencia, o el conductor sufre un
            accidente?</label>
        <div class="panel-content">Será contactado por el centro de operaciones para recabar un acta de hechos y
            proceder con indemnización del mismo.</div>
    </div>



</div>

<div class="how-works">
    <h1>Puntos a considerar</h1>

    <ul>
        <li>El servicio aplica solo del punto de recolección y punto de entrega.</li>
        <li>El cobro inicia cuando el paquete es entregado al chofer y termina cuando es entregado a destinatario.</li>
        <li>El cobro es libre de maniobras de carga y descarga (el cliente debe tener las herramientas para
            realizarlas).</li>
        <li>Las entregas son a piso de calle.</li>
    </ul>
</div>


<ng-template #modal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Datos personales</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="datosForm">
            <ng-container *ngFor="let p of precio; let i = index">
                <div class="price" id={{p.iduser}} (click)="cambiarservicio(i, p.idser)"
                    [ngClass]="activo === i ? 'activoON' : 'activoOFF'">
                    <p>{{p.Tarifa}}</p>
                    <h1>$ {{p.Costo}}</h1>
                    <p>{{p.Descripcion}}</p>
                </div>
            </ng-container>
            <label>Nombre</label>
            <input type="text" class="inp-text" [ngClass]="{'error' : datosForm.get('nombre').invalid && submitted2}"
                formControlName="nombre">
            <label>Teléfono</label>
            <input type="text" class="inp-text" maxlength="10"
                [ngClass]="{'error' : datosForm.get('telefono').invalid && submitted2}" formControlName="telefono">
            <label>Correo</label>
            <input type="text" class="inp-text" [ngClass]="{'error' : datosForm.get('correo').invalid && submitted2}"
                formControlName="correo">

            <div class="form1">
                <div class="form ">
                    <input type="checkbox" id="recib" formControlName="recibido">
                    <label class="form2" for="recib" placement="right">Términos y Condiciones <a  class= "terminos" routerLink = "/condiciones" (click)="this.closeModal()">Términos y Condiciones</a></label>
                </div>
            </div>
            <p [ngClass]="{'p-errorTermino' : datosForm.get('recibido').value === false  && submitted2 }" >Debes aceptar Términos y condiciones</p>
            <button id="Local2" class="btnContinuar" (click)="solicitarServicio()">Solicitar</button>
            <p class ="p-avisosp">Al dar click en solicitar aceptas haber leido el <a class= "terminos" routerLink ="/informacion/avisoPrivacidad" (click)="this.closeModal()">Aviso de privacidad.</a></p>
        </form>
    </div>
</ng-template>


<ng-template #modaltipo>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Confirmacion de pago</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="datosForm">

            <div class="price">
                <p>{{info.msg}}</p>
            </div>
            <div class="metodospago">
                <button id="LocalTarjeta" class="btnContinuar" (click)="agregarpmultipagos()" tooltip='pago con tarjeta'>pago con
                    tarjeta</button>
                <button id="LocalLlamada" class="btnContinuar" (click)="closeModal()" tooltip='Esperar la llamada'>Esperar
                    llamada</button>
            </div>
        </form>
    </div>
</ng-template>



<button id="btn-modal" (click)="openModal(modal)" hidden></button>
<button id="btn-modal2" (click)="openModal(modaltipo)" hidden></button>