<app-gw-menusuperior></app-gw-menusuperior>
<div class="gw-titulogeneral">




  <ng-container *ngIf="numpantalla == 1; else elseTemplate">
    <h1>Solicitar Guías</h1>
    <div class="gw-container">

      <form [formGroup]="docTipoForm">

        <!-- tipos -->

        <div class="doc-div">
          <div class="gw-flex">
            <div class="form-flex-1">
              <label class="lbl-text">Tipo</label>

              <div class="select">
                <select id="pais" formControlName="selectTipo"
                  [ngClass]="{validacion: (DocTipo.selectTipo.invalid || DocTipo.selectTipo.hasError('isEmpty')) && submittedTipo}"
                  required>
                  <option value="" disabled selected class="option s">Seleccione</option>
                  <option *ngFor="let i of listatipo" [value]="i">
                    {{i}}
                  </option>
                </select>
              </div>
              <div class="val-valido" [ngClass]="{invalido: (DocTipo.selectTipo.invalid)  && submittedTipo}">*
                Introduce el tipo
              </div>
            </div>

            <div class="form-flex-1">
              <label class="lbl-text">Cantidad</label>
              <input type="number" min="1" formControlName="cantidad" placeholder="00" (keydown)="cambiarFocus($event)"
                inputmode="decimal"
                [ngClass]="{validacion: (DocTipo.cantidad.invalid || DocTipo.cantidad.hasError('IsEmpty')) && submittedTipo}">
              <div class="val-valido" [ngClass]="{invalido: (DocTipo.cantidad.invalid)  && submittedTipo}">*
                Introduce la cantidad
              </div>
            </div>

            <div class="form-flex-1">
              <label class="lbl-text">Peso</label>
              <input type="number" min="1" formControlName="peso" placeholder="00 kg." (keydown)="cambiarFocus($event)"
                inputmode="decimal"
                [ngClass]="{validacion: (DocTipo.peso.invalid || DocTipo.peso.hasError('IsEmpty')) && submittedTipo}">
              <div class="val-valido" [ngClass]="{invalido: (DocTipo.peso.invalid)  && submittedTipo}">*
                Introduce el peso
              </div>
            </div>
          </div>



          <p class="p-dimensiones">Dimensión en centímentros</p>
          <div class="u-flex">
            <div class="form-flex-3">
              <label class="lbl-text">Largo</label>
              <input type="number" min="1" formControlName="largo" placeholder="00 Cm." (keydown)="cambiarFocus($event)"
                inputmode="decimal"
                [ngClass]="{validacion: (DocTipo.largo.invalid || DocTipo.largo.hasError('IsEmpty')) && submittedTipo}">
              <div class="val-valido" [ngClass]="{invalido: (DocTipo.largo.invalid)  && submittedTipo}">* Introduce el
                largo
              </div>
            </div>


            <div class="form-flex-2">
              <label class="lbl-text">Ancho</label>
              <input type="number" min="1" formControlName="ancho" placeholder="00 Cm." (keydown)="cambiarFocus($event)"
                inputmode="decimal"
                [ngClass]="{validacion: (DocTipo.ancho.invalid || DocTipo.ancho.hasError('IsEmpty')) && submittedTipo}">
              <div class="val-valido" [ngClass]="{invalido: (DocTipo.ancho.invalid)  && submittedTipo}">* Introduce el
                ancho
              </div>
            </div>


            <div class="form-flex-3">
              <label class="lbl-text">Alto</label>
              <input type="number" min="1" formControlName="alto" placeholder="00 Cm." (keydown)="cambiarFocus($event)"
                inputmode="decimal"
                [ngClass]="{validacion: (DocTipo.alto.invalid || DocTipo.alto.hasError('IsEmpty')) && submittedTipo}">
              <div class="val-valido" [ngClass]="{invalido: (DocTipo.alto.invalid)  && submittedTipo}">* Introduce el
                alto
              </div>
            </div>
          </div>





          <div class="form-hist">
            <label class="lbl-text">Tipo de guía</label> <img class="lazyload imgmenu imgInterrogacion "
              (click)="Abrirdescripcion()" data-src='assets/iconos/guiasweb/informacion.png' alt="Promociones" />
            <div class="select">
              <select id="pais" formControlName="selectTipopaq"
                [ngClass]="{validacion: (DocTipo.selectTipopaq.invalid || DocTipo.selectTipopaq.hasError('isEmpty')) && submittedTipo}"
                required>
                <option value="" disabled selected class="option s">Seleccione</option>
                <option *ngFor="let i of tipoEnvio" [value]="i.idTipoPaquete">
                  {{ i.TipoPaquete }}
                </option>
              </select>
            </div>
            <div class="val-valido" [ngClass]="{invalido: (DocTipo.selectTipopaq.invalid)  && submittedTipo}">*
              Introduce el tipo de paquete
            </div>
          </div>


          <div class="form-hist">
            <label class="lbl-text">Comentario</label>
            <input type="text" formControlName="comentario" maxlength="200">

          </div>


          <div class="form">

            <button name="Aceptar" class="gw-btn-continuar" type="submit" (click)="solicitar()">Solicitar</button>
          </div>


        </div>
      </form>



      <div class="historial" *ngIf="HistorialGuias">
        <h4 *ngIf="HistorialGuias.compras.length">Historial de solicitudes</h4>
        <div class="wrapper" *ngIf="HistorialGuias.compras.length">

          <div class="table">

            <div class="row header">
              <div class="cell titilo ">
                Fecha
              </div>
              <div class="cell titilo">
                Cantidad
              </div>
              <div class="cell titilo">
                Disponibles
              </div>
              <div class="cell titilo">
                Estado
              </div>
            </div>

            <div class="row" *ngFor="let valor = index let i of HistorialGuias.compras">
              <div class="cell" data-title="Fecha">
                {{i.fecha}}
              </div>
              <div class="cell" data-title="Cantidad">
                {{i.cantidad}}
              </div>
              <div class="cell" data-title="Disponibles">
                {{i.disp}}
              </div>
              <div class="cell" data-title="Estado">
                {{i.estado}}
              </div>
            </div>


          </div>
        </div>

      </div>
      <div class="gw-hist-div">
        <a class="gw-nav-item"  (click)="cambiarpantalla(1)">Ver detalle de las
          solicutudes</a>
      </div>
    </div>

  </ng-container>
  <ng-template #elseTemplate>
    <app-gw-solicitudguia-detalle></app-gw-solicitudguia-detalle>
  </ng-template>
</div>


<ng-template #modal>
  <div>
    <div class="modal-header">
      <h3 class="modal-title pull-left">Tipos de guía</h3>


      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <label class="lbl-text-tipos">Prepago</label>
      <p class="lbl-text-tipos1">Obtén un costo preferencial al realizar envíos por medio de guías prepago. Solicita tu
        cotizacion en apartado de solicitudes o al correo contacto@tufesapack.com. </p>
      <label class="lbl-text-tipos">Consumo</label>
      <p class="lbl-text-tipos1">Envía a través de un crédito pre estipulado, el cual se pagara a final de la fecha
        establecida en contrato con la empresa.</p>
      <label class="lbl-text-tipos">Sin costo</label>
      <p class="lbl-text-tipos1">Envíos realizados por la empresa de manera interna.</p>
    </div>
  </div>
</ng-template>



<button id="btn-modal" (click)="openModal(modal)" hidden></button>
