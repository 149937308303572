    <div class="container">
        <h1 class="txt-section">Condiciones</h1>
        <div class="politicaText">
            <ul>
                <li> El boleto es válido solo para la fecha y hora señalada en su compra. No aplica descuento sobre descuento. Recuerde que no puede cancelar ningún boleto comprado con tarjeta de crédito o débito.</li>
                <li>Todos los menores de 18 años deben ir acompañados de un adulto o con un documento oficial que acredite y otorgue el permiso de los padres o tutores permitiendole viajar solo.</li>
                <li>Por favor lleve una identificación oficial de las personas que viajaran, al momento de recoger su boleto.</li>
            </ul>
        </div>

        <div class="politicaText">
            <ul>
                <li>Puede recoger su boleto en la siguiente dirección:</li>
                <li>Hidalgo s/n entre No Reelección y Pesqueira, Colonia Centro C.P. 85800. Tel. 01 (642) 421 - 3410</li>
                <li>Presentese a taquilla una hora antes de su salida y solicite su boleto impreso, así como cualquier cambio, ajuste o aclaración en el servicio contratado, de lo contrario perderá su viaje.</li>
                <li>Le recordamos que en caso de perder el viaje a causa de su ausencia también perderá su importe. Igualmente en caso de hacer su compra con Prepago.</li>
            </ul>
        </div>

        <div class="politicaText">
            <div class="subTitle">Política de la empresa</div>
            <ul>
                <li>Para hacer válido su descuento debe presentar su credencial con fecha de vigencia válida e incluida o algún documento oficial que lo acredite (INSEN, Maestro, Estudiante) al momento de recibir su boleto, además debe portarla durante su
                    viaje, ya que pueda ser requerida por algún inspector.</li>
                <li>En el caso de las Credenciales de Maestros y Estudiantes, estas deberán estar validadas por la SEP (Secretaría de Educación Pública) para ser sujetos a las promociones que se emitan.</li>
            </ul>
        </div>

        <div class="politicaText">
            <div class="subTitle">Aviso Importante</div>
            <ul>
                <li>Abordando autobús en México el peso máximo (por Ley) de equipaje permitido sin costo será de 25 Kg (55.12 Lbs), abordando desde EUA el peso máximo de equipaje permitido sin costo será de 80 Lbs (36.29 Kg).</li>
                <li>No se permiten mascotas abordo.</li>
            </ul>
        </div>

        <div class="politicaText">
            <div class="subTitle">For travel originating in the United States or travel with passenger boarding in the United States</div>
            <ul>
                <li>Tufesa USA LLC (Tufesa) is currently operating under a Consent Agreement with the Federal Motor Carrier Safety Administration, until it is able to achieve full compliance with the Americans with Disabilities Act regulations, 49 C.F.R.
                    part 37, subpart H. In accordance with the terms of the Agreement, Tufesa shall provide accessible service with at least 24-hour notice. In the event 24-hour notice is not provided, Tufesa shall provide accessible service as soon as
                    possible, but no later than the original departure time unless the request was made within 8 hours of departure. In the event a request is made within 8 hours of departure, the carrier must provide service within 8 hours of such request
                    if seats were available to other passengers, not requiring accessible service, at the time of request..</li>
                <li>For further clarification TUFESA USA, LLC. 1614 N. 27th Ave. Phoenix, AZ 85009</li>
                <li>For more information please visit: <a href="https://www.tufesainternacional.com">https://www.tufesainternacional.com.</a></li>
            </ul>
        </div>
    </div>