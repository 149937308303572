<div class="informacion-util contenedor">
    <div class="infTitle">
        <h5>Información útil</h5>
    </div>
    <div class="container infoContainer">
        <div class="tipContainer">
            <div id="iutipseguridad" class="col" routerLink="/informacion/tipsseguridad">
                <img id="img_deskop" width="35%" height="35%" class="lazyload fade-in" data-src="assets/iconos/info-util/icon_tipsSeg.webp" alt="Icono Tufesa Envios">
                <img id="img_mobil" width="35%" height="35%" class="lazyload fade-in" data-src="assets/iconos/info-util/mobil/icon_tipsSeg.webp" alt="Icono Tufesa Envios">
                <p class="infHeader">Tips de seguridad</p>
                <p class="infBody">Información útil para tus envíos</p>
            </div>
        </div>
        <div class="tipContainer">
            <div id="iupoliticasrecepcionoenvio" class="col" routerLink="/informacion/politicas">
                <img id="img_deskop" width="24%" height="24%" class="lazyload imgspace fade-in" data-src="assets/iconos/info-util/icon_politicasRec.webp" alt="Icono Tufesa Envios">
                <img id="img_mobil" width="24%" height="24%" class="lazyload imgspace fade-in" data-src="assets/iconos/info-util/mobil/icon_politicasRec.webp" alt="Icono Tufesa Envios">
                <p class="infHeader">Políticas de recepción y/o envío</p>
                <p class="infBody">Envíos Restringidos</p>
            </div>
        </div>
        <div class="tipContainer">
            <div id="iuenviosalextranjero" class="col" routerLink="/informacion/envios">
                <img id="img_deskop" class="imgspace" width="32%" height="24%" class="lazyload imgspace fade-in" data-src="assets/iconos/info-util/icon_envEx.webp" alt="Icono Tufesa Envios">
                <img id="img_mobil" class="imgspace" width="32%" height="24%" class="lazyload imgspace fade-in" data-src="assets/iconos/info-util/mobil/icon_envEx.webp" alt="Icono Tufesa Envios">
                <p class="infHeader">Envíos al extranjero</p>
                <p class="infBody">Artículos prohibidos Exportaciones</p>
            </div>
        </div>
    </div>
</div>
