import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ViewportScroller } from "@angular/common";

@Component({
  selector: "app-index",
  templateUrl: "./index.component.html",
  styleUrls: ["./index.component.css"],
})
export class IndexComponent implements OnInit {
  constructor(private router: Router, private scroller: ViewportScroller) {}

  ngOnInit() {
    // setTimeout(this.showPromo, 2000);
    // setTimeout(this.showslide, 2000);
    // setTimeout(this.shownewslide, 2000);

    if (window.innerWidth <= 1027) {
        setTimeout(this.shownewslidemovilscreen, 2000);
        }

    if (window.innerWidth >= 1027) {
        setTimeout(this.shownewslide, 2000);
    }

  }

  section1() {
    document.getElementById("section1").scrollIntoView({ behavior: "smooth" });
  }
  section2() {
    document.getElementById("section2").scrollIntoView({ behavior: "smooth" });
  }
  section3() {
    document.getElementById("section3").scrollIntoView({ behavior: "smooth" });
  }
  section4() {
    document.getElementById("section4").scrollIntoView({ behavior: "smooth" });
  }

  // showPromo() {
  //   document.getElementById("cont_promo").style.visibility = "visible";
  //   document.getElementById("cont_promo").style.height = "90px";
  //   document.getElementById("cont_promo").style.top = "10px";
  //   document.getElementById("cont_txt").style.opacity = "1";
  //   document.getElementById("img_mini").style.height = "50px";
  // }

  // showslide() {
  //   document.getElementById("cont_slide").style.visibility = "visible";
  //   document.getElementById("cont_slide").style.height = "90px";
  //   document.getElementById("cont_txt_slide").style.opacity = "1";
  //   document.getElementById("img_mini_slide").style.height = "50px";
  // }
  shownewslide() {
     document.getElementById("slide_guiasweb").style.visibility = "visible";
     document.getElementById("slide_guiasweb").style.transform = 'translateX(0)';
  }
  shownewslidemovilscreen() {
    document.getElementById("popop_guiasweb_movil").style.visibility = "visible";
    document.getElementById("popop_guiasweb_movil").style.height = "118px";
    document.getElementById("popop_guiasweb_movil").style.top = "10px";
    // document.getElementById("cont_txt").style.opacity = "1";
    // document.getElementById("img_mini").style.height = "50px";
    // document.getElementById("slide_guiasweb").style.visibility = "visible";
    // document.getElementById("slide_guiasweb").style.transform = 'translateX(0)';
 }

  closeBtn() {
    document.getElementById("cont_promo").style.display = "none";
  }
  closeBtnslide() {
    document.getElementById("cont_slide").style.display = "none";
  }
  closeBtnGuiaslide() {
    document.getElementById("slide_guiasweb").style.display = "none";
  }
  closeBtnpopupguiasmovil(event: Event){
    console.log("click cerrar");
    document.getElementById("popop_guiasweb_movil").style.display = "none";
    event.stopPropagation();
  }
}
