import { Component, OnInit } from '@angular/core';
import { recolecciondom } from "../../servicios/recoleccion-dom.service";
import { user } from "../../entidades/gwuser";
import jwt_decode from "jwt-decode";

@Component({
  selector: 'app-codigo-promocion',
  templateUrl: './codigo-promocion.component.html',
  styleUrls: ['./codigo-promocion.component.css']
})
export class CodigoPromocionComponent implements OnInit {
  iduser: number;
  codigo: string;
  idpromo: number;
  datacode: any;
  datacanje: any;
  codigoInput: string = null;
  mensaje: string;

  userGw: user;
  datagw: any;
  token: string;
  tokendatos: any;
  idcliente: number=-1;

  constructor(private recolecciondom: recolecciondom) {
   
   }

  ngOnInit(): void {
    this.token = sessionStorage.getItem("token");
    this.tokendatos = jwt_decode(this.token);
    this.userGw = this.tokendatos;
    this.idcliente = this.userGw.idUser;
  }

  //arreglo con los tipos de guia
  guiasData = [
    {
      idpromo: 0,
      cant: 0,
      titulo: "",
      condiciones: [],
    },
  ]


validarcodigo(){
  
  this.recolecciondom.getValidacionCode(this.idcliente, this.codigoInput).then((data) => {
  this.datacode = data;

    if(Number(this.datacode.nummsj) > 0){
      this.showMensaje(this.datacode.mensaje);
      this.guiasData = this.datacode.list;
      document.getElementById("container_promos").style.visibility = "visible";
      document.getElementById("codemsj").style.color = "#444444";
      document.getElementById("codemsj").style.fontWeight = "normal";
      document.getElementById("container_promos").style.height = "410px";
    }

    else {
      this.showMensaje(this.datacode.mensaje);
      document.getElementById("container_promos").style.visibility= "unset";
      document.getElementById("codemsj").style.color = "#f05a28";
      document.getElementById("codemsj").style.fontWeight = "bold";
      document.getElementById("container_promos").style.height = "0px";
    }
  });
  
}

canjearcodigo(idpromo: number){
  this.recolecciondom.canjearCode(this.idcliente,this.codigoInput, idpromo).then((data) => {
  this.datacanje = data;
    this.showMensaje(this.datacanje.mensaje);
    if(Number(this.datacanje.nummensaje) === 1){
    document.getElementById("container_promos").style.display = "none";
    document.getElementById("confirma").style.visibility = "visible";
    document.getElementById("confirma").style.height = "200px";
  }
  else{
    this.closePopup();
  }
  });
}
 showMensaje(mensaje:string){
  this.mensaje = mensaje;
 }
 
 closePopup(){
  document.getElementById("confirma").style.visibility = "hidden";
  document.getElementById("confirma").style.height = "0px";
 }

}
