<div class="div-menu">
    <ul class="gw-navbar-nav desk">
        <a class="gw-nav-item item1 gw-nav-link" [ngClass]="{pageAct: Doc == true}"
            routerLink="/herramientasDigitales/documentacion/menu">
            Documentar envío
        </a>

        <a class="gw-nav-item item2 gw-nav-link" [ngClass]="{pageAct: Sol == true}"
            routerLink="/herramientasDigitales/guias">
            Solicitar guías
        </a>

        <a class="gw-nav-item  item3 gw-nav-link" [ngClass]="{pageAct: His == true}"
            routerLink="/herramientasDigitales/historial">
            Historial
        </a>

        <a class="gw-nav-item item4 gw-nav-link" [ngClass]="{pageAct: Fac == true}"
            routerLink="/herramientasDigitales/facturas">
            Historial Factura
        </a>

        <a class="gw-nav-item item5 gw-nav-link" [ngClass]="{pageAct: Tbl == true}"
            routerLink="/herramientasDigitales/listado-recoleccion">
            Listado de recolección
        </a>
    </ul>

    <ul class="gw-navbar-nav mobile">
        <a class="gw-nav-item item1 gw-nav-link" [ngClass]="{pageAct: Doc == true}"
            routerLink="/herramientasDigitales/documentacion/menu">
            Documentar
        </a>

        <a class="gw-nav-item item2 gw-nav-link" [ngClass]="{pageAct: Sol == true}"
            routerLink="/herramientasDigitales/guias">
            Solicitar
        </a>

        <a class="gw-nav-item  item3 gw-nav-link" [ngClass]="{pageAct: His == true}"
            routerLink="/herramientasDigitales/historial">
            Historial
        </a>

        <a class="gw-nav-item item4 gw-nav-link" [ngClass]="{pageAct: Fac == true}"
            routerLink="/herramientasDigitales/facturas">
            Factura
        </a>

        <a class="gw-nav-item item5 gw-nav-link" [ngClass]="{pageAct: Tbl == true}"
            routerLink="/herramientasDigitales/listado-recoleccion">
            Listado
        </a>

    </ul>
</div>



