import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { cotizaAvanzado } from '../entidades/cotizaAvanzado';

@Injectable({
  providedIn: 'root'
})
export class CotizacionService {

  private origenSource = new BehaviorSubject<string>('');
  origen = this.origenSource.asObservable();

  private destinoSource = new BehaviorSubject<string>('');
  destino = this.destinoSource.asObservable();

  private cotizaSource = new BehaviorSubject(new cotizaAvanzado());
  cotizacion = this.cotizaSource.asObservable();

  constructor(
    private http: HttpClient
  ) { }

  public setOrigen(value){
    this.origenSource.next(value);
  }
  public setDestino(value){
    this.destinoSource.next(value);
  }

  getCotizacion(origen, destino, peso, uuid, correo){
    let url = `${environment.URL_APIPAQ}/obtenerCotizacion?cpo=${origen}&cpd=${destino}&Peso=${peso}&uuid=${uuid}&correo=${correo}`
    return this.http.get(url);
  }

  regsolcotizacion(opr , idcot , apellido , nombre , correo , telefono , numref, tipo){
    let url = `${environment.URL_APIPAQ}/regsolcotizacion?opr=${opr}&idcot=${idcot}&apellido=${apellido}&nombre=${nombre}&correo=${correo}&telefono=${telefono}&numref=${numref}&tipo=${tipo}`
    return this.http.get(url);
  }

  getCotizacionAvanzada(origen, destino, tipoPaquete, cantidad, largo, alto, ancho, peso, scobrar, sseguro, monto, sdomicilio, dirdom, sreco, dirreco, sembalaje, sacuse, embalaje, uuid, correo) {
    let url = `${environment.URL_APIPAQ}/cotizacionAvanzada?origen=${origen}&destino=${destino}&tipoPaquete=${tipoPaquete}&cantidad=${cantidad}&largo=${largo}&alto=${alto}&ancho=${ancho}&peso=${peso}&scobrar=${scobrar}&sseguro=${sseguro}&monto=${monto}&sdomicilio=${sdomicilio}&dirdom=${dirdom}&sreco=${sreco}&dirreco=${dirreco}&sembalaje=${sembalaje}&sacuse=${sacuse}&embalaje=${embalaje}&uuid=${uuid}&correo=${correo}`

    this.http.get<cotizaAvanzado>(url).subscribe(
      datos =>{
        this.cotizaSource.next(datos);
      }
    )
  }

  getCiudades(){
    let url = `${environment.URL_APIPAQ}/obtenerSucursales`
    return this.http.get(url);
  }

   getdirectorio(cp, tipo){
    let url = `${environment.URL_API}/logDirectorioDirecciones3?codigo=${cp}&tipo=${tipo}`
    return this.http.get(url);
   }

   getregcorr( uuid){
    let url = `${environment.URL_APIPAQ}/getevreg?uuid=${uuid}`
    return this.http.get(url);
  }

}
