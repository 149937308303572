export class tipo {
    tipo: string;
    idtipo: string;
    cantidad: string;
    peso: string;
    largo: string;
    ancho: string;
    alto: string;
    precio: number;
}

