import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CrearDocumentosService {

  constructor(private http: HttpClient) { }

  cargarFormatosDigitales_tfp(usr: string, opr: string): Observable<any[]>{
    let url = `${environment.URL_APIPAQbeta}/cargarFormatosDigitales_tfp?usr=${usr}&opr=`
    return this.http.get<any>(url)
  }  
  cargarFormatoDigitalEncabezados_tfp(usr: string, idforma: string): Observable<any[]> {
    let url = `${environment.URL_APIPAQbeta}/cargarFormatoDigitalEncabezados_tfp?usr=${usr}&idforma=${idforma}`
    return this.http.get<any>(url)
  }

  crearFormatoDigital(body: any): Observable<any[]>{
   let url = `${environment.URL_APIPAQbeta}/Oprcuestionario_tfp_CrearFormatoDigital`
   return this.http.post<any>(url,body);
  }



}
