import { Component, OnInit , TemplateRef} from '@angular/core';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import { Rastreo } from "../../entidades/rastreo";
import { RastreoService } from "../../servicios/rastreo.service";
import { ColoniasDspEntDm } from '../../entidades/solicitud';
import { Ciudades } from '../../entidades/ciudades';
import { Estados } from '../../entidades/estados';
import { SolicitudEntregaService } from "../../servicios/solicitud-entrega.service";
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { Tools } from '../../entidades/tools';
import { Subscription } from 'rxjs';
import { ResumenSolicitudCS } from 'src/app/entidades/resumen_solicitud';

@Component({
  selector: 'app-rastreo-solicitud',
  templateUrl: './rastreo-solicitud.component.html',
  styleUrls: ['./rastreo-solicitud.component.css']
})
export class RastreoSolicitudComponent implements OnInit {
  respuestaApi: ResumenSolicitudCS;
  colonias: ColoniasDspEntDm[];
  coloniaseleccionada: ColoniasDspEntDm;
  datosRastreo: Rastreo;
  ciudades: Ciudades[] = [];
  estados: Estados[] = [];
  modalRef: BsModalRef;
  estadoSeleccionado: Estados;
  ciudadSeleccionada: Ciudades;

  solicitudForm: FormGroup;
  submitted = false;

  respuestaSolSubscription: Subscription;
  rastreoSeleccionadoSubscription: Subscription;
  coloniasSubscription: Subscription;
  estadosSubscription: Subscription;
  ciudadesSubscription: Subscription;

  constructor(
    private rastreoService: RastreoService,
    private solicitudService: SolicitudEntregaService,
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    private router: Router
  ) { }

  ngOnDestroy(): void {
    this.respuestaSolSubscription.unsubscribe();
    this.rastreoSeleccionadoSubscription.unsubscribe();
    this.coloniasSubscription.unsubscribe();
    this.estadosSubscription.unsubscribe();
    this.ciudadesSubscription.unsubscribe();

  }

  ngOnInit() {
    this.respuestaSolSubscription = this.solicitudService.respuestaSol.subscribe(
      respuesta => this.respuestaApi = respuesta
    )

    this.rastreoSeleccionadoSubscription = this.rastreoService.rastreoSeleccionado.subscribe(
      rastreo => 
      {
        this.datosRastreo = rastreo
        if(Object.keys(this.datosRastreo).length === 0){
          this.router.navigate(['/paqueteria/rastreo/captura']);
        }
      }
      
    )

    this.coloniasSubscription = this.rastreoService.colonias.subscribe(
      respuesta => this.colonias = respuesta
    );

    this.estadosSubscription = this.rastreoService.estados.subscribe(
      estados => this.estados = estados
    );

    this.ciudadesSubscription = this.rastreoService.ciudades.subscribe(
      ciudades => this.ciudades = ciudades
    );

    if (this.colonias.length > 0 ) this.coloniaseleccionada = this.colonias[0];
    if (this.estados.length > 0) this.estadoSeleccionado = this.estados[0];
    if (this.ciudades.length > 0) this.ciudadSeleccionada = this.ciudades[0];

    this.solicitudForm = this.formBuilder.group(
      {
        estado: [],
        ciudad: [],
        colonia: [],
        nombre: ['', Validators.required],
        correo: ['', [Validators.required, Validators.email]],
        telefono: ['', Validators.required],
        direccion: ['', Validators.required],
        referencia: [ '', Validators.required]
      }
    );

  }

  get s() { return this.solicitudForm.controls; }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  seleccionarCiudad(ciudad: Ciudades) {
    this.ciudadSeleccionada = ciudad;
  }

  seleccionarEstado(estado: Estados) {
    this.estadoSeleccionado = estado;
  }

  seleccionarColonia(colonia: ColoniasDspEntDm) {
    this.coloniaseleccionada = colonia;
  }

  onChange(deviceValue) {
    var btn = <HTMLInputElement> document.getElementById("button-basic");
    for(var i = 0; i < this.colonias.length ; i++){
      if(deviceValue === this.colonias[i].asenta){
        btn.innerHTML = this.colonias[i].asenta;
        this.coloniaseleccionada = this.colonias[i];
      }
    }
}


  solicitud(template: TemplateRef<any>) {

    var codigo = this.datosRastreo.Rastreo;
    var getEstado = this.estadoSeleccionado.id;
    var getCiudad =  this.ciudadSeleccionada.id;
    var getColonia = this.coloniaseleccionada.idcol;

    this.submitted=true;

    if (this.solicitudForm.invalid) {
      return;
    }

    this.submitted = false;

    this.solicitudService.solicitar(codigo, this.solicitudForm.value.nombre, this.solicitudForm.value.correo, this.solicitudForm.value.telefono,
    getEstado, getCiudad, getColonia, this.solicitudForm.value.direccion,
    this.solicitudForm.value.referencia)
    .subscribe( respuestaApi => {
      this.respuestaApi = respuestaApi;
      this.respuestaApi.nombre = this.solicitudForm.value.nombre;
      this.respuestaApi.correo = this.solicitudForm.value.correo;
      this.respuestaApi.telefono = this.solicitudForm.value.telefono;
      this.respuestaApi.direccion = this.solicitudForm.value.direccion;
      this.respuestaApi.colonia = this.coloniaseleccionada.asenta;
      this.respuestaApi.ciudad = this.ciudadSeleccionada.nombre;
      this.respuestaApi.estado = this.estadoSeleccionado.nombre;
      this.respuestaApi.referencia = this.solicitudForm.value.referencia;
      this.solicitudService.guardarResumen(respuestaApi);
      this.router.navigate(['/paqueteria/rastreo/confirmacion']);
      this.onTop();
    });
  }

  volverStatus() {
    this.router.navigate(['/paqueteria/rastreo/captura']);
    this.onTop();
  }

  onKeydown(event) {
    var input = this.s.telefono.value;
    input = input.replace(/[\W\s\._\-]+/g, '');

    if (!new Tools().isValidKeyNumber(event)) {
      event.preventDefault();
      return;
    }
  }

  onTop() {
    document.body.scrollTop = 0;
  }

}
