import { Component, OnInit } from "@angular/core";
import { FormGroup, Validators, FormBuilder, FormControl, } from "@angular/forms";
import { GuiaswebServiceService } from "../../servicios/guiasweb-service.service";
import { HttpErrorResponse } from "@angular/common/http";
import { MensajePopup } from "src/app/entidades/MensajePopup";
import { MensajeService } from "src/app/servicios/mensaje.service";
import { Router } from "@angular/router";
import { cuentaverificada } from "../../entidades/cuentaverificada";

@Component({
  selector: "app-gw-cambiarpassword",
  templateUrl: "./gw-cambiarpassword.component.html",
  styleUrls: ["./gw-cambiarpassword.component.css"],
})
export class GwCambiarpasswordComponent implements OnInit {
  passForm: FormGroup;
  submitted: boolean;
  verificado: cuentaverificada;
  data: any;
  tipopass: string = "password";
  passimg: string = "assets/iconos/guiasweb/verfalse.png";

  constructor(
    private GuiaswebServiceService: GuiaswebServiceService,
    private router: Router,
    private mensajeService: MensajeService,
    private formBuider: FormBuilder
  ) {
    this.passForm = formBuider.group(
      {
        contrasena: new FormControl("", [
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(
            "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9!¡?¿()#:;.,-_%&/@<>|]{8,}$"
          ),
        ]),
        contrasena2: new FormControl("", [
          Validators.required,
          Validators.pattern(
            "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9!¡?¿()#:;.,-_%&/@<>|]{8,}$"
          ),
        ]),
      },
      {
        validators: this.MustMatch("contrasena", "contrasena2"),
      }
    );
  }

  ngOnInit(): void {
    this.GuiaswebServiceService.cuentaVerf.subscribe((datos) => {
      this.verificado = datos;
      if (Object.keys(this.verificado).length == 0) {
        this.router.navigate(["/herramientasDigitales/restablecer/contrasena"]);
      }
    });
  }
  get f() {
    return this.passForm.controls;
  }

  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  cambiarFocus(e, id) {
    if (e.keyCode === 13) {
      e.preventDefault();

      if (id.id == "entrar") {
      } else {
        id.focus();
      }
    }
  }

  mostrarpassword() {
    if (this.passimg == "assets/iconos/guiasweb/verfalse.png") {
      this.passimg = "assets/iconos/guiasweb/vertrue.png";
      this.tipopass = "text";
    } else {
      this.passimg = "assets/iconos/guiasweb/verfalse.png";
      this.tipopass = "password";
    }
  }

  async verificarpassword(e) {
    e.preventDefault();
    this.submitted = true;

    if (this.passForm.valid) {
      await this.GuiaswebServiceService.cambiarcontrasena(
        this.verificado.id,
        this.passForm.controls.contrasena.value,
        this.verificado.idcodigo
      )
        .then((d) => {
          this.data = d;

          if (this.data.NumMsj <= 0) {
            this.mensajeService.subject$.next(
              new MensajePopup(this.data.TxtMsj, false, false)
            );
          } else {
            this.mensajeService.subject$.next(
              new MensajePopup(this.data.TxtMsj, true, true)
            );

            this.router.navigate(["/herramientasDigitales/login"]);
          }
        })
        .catch((error: HttpErrorResponse) => {
          if (error.error._Message == undefined) {
            this.mensajeService.subject$.next(
              new MensajePopup(
                "ocurrio un error inesperado al iniciar sesión en tu cuenta",
                false,
                false
              )
            );
          } else {
            this.mensajeService.subject$.next(
              new MensajePopup(error.error._Message, false, false)
            );
          }
        });
    }
  }
}
