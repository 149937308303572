import { Component, OnInit } from '@angular/core';
import { WindowScrollService } from 'src/app/servicios/window-scroll.service';
import { Router, NavigationStart } from '@angular/router';
import { RastreoService } from 'src/app/servicios/rastreo.service';
import { user } from '../../entidades/gwuser';
import jwt_decode from 'jwt-decode';
import { GuiaswebServiceService } from '../../servicios/guiasweb-service.service';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  scroll: number = 0;
  url = '';
  isLogged: boolean = false;
  userGw: user;
  datosusr: any;
  cadena: string;
  token: any;
  constructor(
    private scrollService: WindowScrollService,
    private rastreoService: RastreoService,
    private router: Router,
    private GuiaswebServiceService: GuiaswebServiceService,
  ) {

    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        let hd = document.getElementById('nav');
        if (event.url !== '/' && event.url.toLocaleLowerCase() !== '/envioslocales') {
          //comparacion si esta en la ubicacion root y envios locales
          hd.style.backgroundColor = '#fff';
          hd.style.transition = 'all 300ms ease';
          this.url = event.url;
        } else {
          hd.style.backgroundColor = '#fff';
          hd.style.transition = 'all 300ms ease';
          this.url = '';
        }
      }
    });

    this.scrollService.scroll$.subscribe(pos => {
      this.scroll = pos;
      let nav = document.getElementById('nav');
      if (this.scroll > 10) {
        //color navbar cuando baja
        nav.style.backgroundColor = '#fff';
        nav.style.transition = 'all 300ms ease-in';
      } else if (this.scroll === 0) {
        if (!this.url) {
          //color navbar fijo
          nav.style.backgroundColor = '#fff';
          nav.style.transition = 'all 300ms ease-out';
        } else {
          nav.style.backgroundColor = '#fff';
          nav.style.transition = 'all 300ms ease-out';
        }

      }
    });


  }

  async ngOnInit() {

    // var gwc =
    if (!(sessionStorage.getItem('token') == undefined || sessionStorage.getItem('token') == "" || sessionStorage.getItem('token') == null)) {

      this.token = jwt_decode(sessionStorage.getItem('token'));
      this.userGw = this.token;
      if (this.userGw == null) {
        this.GuiaswebServiceService.setnumlogin(false);
      }
      else {
        await this.GuiaswebServiceService.setnumlogin(true);
        await this.GuiaswebServiceService.datosUsrLogin(this.userGw)


      }

    }


    this.GuiaswebServiceService.numlg.subscribe(
      datos => {
        this.isLogged = datos;
      }
    );


    this.GuiaswebServiceService.usrLgn.subscribe(
      async datos => {
        this.datosusr = datos;

        var bandera
        bandera = Object.keys(this.datosusr).length === 0;


        if (!bandera) {
          if (this.datosusr.name.length > 8) {
            this.cadena = this.datosusr.name.substr(0, 8).trim();
            this.cadena = this.cadena + "..."
            await this.cadena;
          }
          else {
            this.cadena = this.datosusr.name;
          }
        }
      }
    );

  }

  cambioIdioma() {

  }


  cerrarsesion() {
    sessionStorage.removeItem('token')
    localStorage.clear();
    this.closeMenu('');
    this.router.navigate(['/herramientasDigitales/login']);
    this.GuiaswebServiceService.setnumlogin(false);
  }


  closeMenu(id) {
    document.getElementById("btnMenu").click();
    if (id === 'rastreo') {
      this.rastreoService.clean_packs();
    }

  }

  verificarToken(id) {
    if (sessionStorage.getItem('token') == undefined || sessionStorage.getItem('token') == "" || sessionStorage.getItem('token') == null) {
      this.router.navigate(['/herramientasDigitales/login']);
    }
    switch (id) {
      case 'GW1':
        this.router.navigate(['/herramientasDigitales/documentacion/menu']);
        this.closeMenu('');
        break;
      case 'GW2':
        this.router.navigate(['/herramientasDigitales/guias']);
        this.closeMenu('');
        break;
      case 'GW3':
        this.router.navigate(['/herramientasDigitales/facturas']);
        this.closeMenu('');
        break;
      case 'GW4':
        this.router.navigate(['/herramientasDigitales/historial']);
        this.closeMenu('');
        break;
      case 'GW5':
        this.router.navigate(['/herramientasDigitales/listado-recoleccion']);
        this.closeMenu('');
        break;
        case 'GW6':
          this.router.navigate(['/herramientasDigitales/administrar-cuenta']);
          this.closeMenu('');
          break;  
          case 'GW7':
          this.router.navigate(['/historial-compra']);
          this.closeMenu('');
          break;  

      default:
        break;
    }

  }

}
