import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { GuiaswebServiceService } from "../../servicios/guiasweb-service.service";
import { environment } from "src/environments/environment";
import { documentacion } from "../../entidades/gwdocumentacion";
import { Router } from "@angular/router";
import { user } from "../../entidades/gwuser";
import { MensajePopup } from "src/app/entidades/MensajePopup";
import jwt_decode from "jwt-decode";
import { HttpErrorResponse } from "@angular/common/http";
import { MensajeService } from "src/app/servicios/mensaje.service";
import  Swal  from 'sweetalert2';

@Component({
  selector: "app-gw-doc-remitente",
  templateUrl: "./gw-doc-remitente.component.html",
  styleUrls: ["./gw-doc-remitente.component.css"],
})
export class GwDocRemitenteComponent implements OnInit {
  submitted: boolean;
  pais: any;
  ciudad: any;
  estado: any;
  colonia: any;
  idpais: number;
  idciudad: number;
  idestado: number;
  idcolonia: number;
  remitenteForm: FormGroup;
  doc: documentacion;
  datagw: any;
  userGw: user;
  tipopaquete: string;

  token: string;
  tokendatos: any;

  constructor(
    private GuiaswebServiceService: GuiaswebServiceService,
    private router: Router,
    private mensajeService: MensajeService
  ) {}

  ngOnInit(): void {
    this.validarsesion();

    this.token = sessionStorage.getItem("token");
    this.tokendatos = jwt_decode(this.token);
    this.userGw = this.tokendatos;
    this.tipopaquete = decodeURIComponent(
      escape(window.atob(sessionStorage.getItem("gwTipopaq")))
    );
    //creamos formulario
    this.RemitenteForm();
    //traemos los datos del la documentacion
    var docaux = decodeURIComponent(
      escape(window.atob(sessionStorage.getItem("gwDocumentacion")))
    );
    this.doc = JSON.parse(docaux);

    this.remitenteForm.controls.nombre.setValue(this.userGw.name);
    this.remitenteForm.controls.telefono.setValue(this.userGw.tel);

    // this.cargarPais();
    this.cargardatosGuardatos();

    document.getElementById("nombre").focus();
    window.scrollTo(0, 0);
  }

  RemitenteForm() {
    this.remitenteForm = new FormGroup({
      nombre: new FormControl("", [Validators.required]),
      selectPais: new FormControl("", [Validators.required]),
      selectEstado: new FormControl("", [Validators.required]),
      selectCiudad: new FormControl("", [Validators.required]),
      selectColonia: new FormControl("", [Validators.required]),
      sucursalSeleccionado: new FormControl({ value: false, disabled: true }),
      recoleccionSeleccionado: new FormControl({ value: false, disabled: true }),
      direccion: new FormControl("", [Validators.required]),
      telefono: new FormControl("", [
        Validators.required,
        Validators.maxLength(10),
        Validators.pattern("[0-9]{10}"),
      ]),
      cp: new FormControl({ value: null, disabled: true }),
    });
  }

  get Rem() {
    return this.remitenteForm.controls;
  }

  validarsesion() {
    if (
      sessionStorage.getItem("token") == undefined ||
      sessionStorage.getItem("token") == "" ||
      sessionStorage.getItem("token") == null
    ) {
      this.mensajeService.subject$.next(
        new MensajePopup("Su sesión ha expirado", false, false)
      );
      this.router.navigate(["/herramientasDigitales/login"]);
    }

    if (
      sessionStorage.getItem("gwTipopaq") == undefined ||
      sessionStorage.getItem("gwTipopaq") == "" ||
      sessionStorage.getItem("gwTipopaq") == null ||
      sessionStorage.getItem("gwDocumentacion") == undefined ||
      sessionStorage.getItem("gwDocumentacion") == "" ||
      sessionStorage.getItem("gwDocumentacion") == null
    ) {
      this.mensajeService.subject$.next(
        new MensajePopup("Su sesión ha expirado", false, false)
      );
      this.router.navigate(["/herramientasDigitales/documentacion/menu"]);
    }
  }

  //cargamos los datos si regremos de una pantalla más adelante
  cargardatosGuardatos() {

    if (this.doc.remitente.pais != 0 && this.doc.remitente.estado != 0) {
        this.idpais = this.doc.remitente.pais;
        this.idestado = this.doc.remitente.estado;
        this.idciudad = this.doc.remitente.ciudad;
        this.idcolonia = this.doc.remitente.colonia;
        //  this.cargarPais();
         this.cargarEstado();
         this.cargarCiudad();
        if(this.doc.remitente.NombreColonia == "OCURRE"){
           //  // Habilitar los campos
           this.remitenteForm.get("sucursalSeleccionado").enable();
           this.remitenteForm.get("recoleccionSeleccionado").enable();

           // Establecer valores
           this.remitenteForm.get("sucursalSeleccionado").setValue(true);
           this.remitenteForm.get("recoleccionSeleccionado").setValue(false);

           // Forzar la actualización de la validez
           this.remitenteForm.get("sucursalSeleccionado").updateValueAndValidity();
           this.remitenteForm.get("recoleccionSeleccionado").updateValueAndValidity();
        }

        this.cargarColonia();
        this.remitenteForm.controls.nombre.setValue(this.doc.remitente.nombre);
        this.remitenteForm.controls.selectPais.setValue(this.doc.remitente.pais);
        this.remitenteForm.controls.selectEstado.setValue(this.doc.remitente.estado);
        this.remitenteForm.controls.selectCiudad.setValue(this.doc.remitente.ciudad);
        this.remitenteForm.controls.selectColonia.setValue(this.doc.remitente.colonia);
        this.remitenteForm.controls.direccion.setValue(this.doc.remitente.direccion);
        this.remitenteForm.controls.telefono.setValue(this.doc.remitente.tel);
        this.remitenteForm.controls.cp.setValue(this.doc.remitente.cp);
    } else {
        this.cargarPais();
    }
  }

  async cargarPais() {
    await this.GuiaswebServiceService.obtenerPais(
      "ORG",
      this.doc.idsesion,
      this.token
    )
      .then((d) => {
        this.pais = d;
        this.remitenteForm.get('selectPais').setValue(this.pais[0].idpais);
        // alert(this.pais[0].pais);

        this.paisChanged('1');
      //   // Seleccionar automáticamente el país en el formulario
      //   if (this.pais.length > 0) {
      //   // Obtén el control selectPais usando el método Rem
      //   const selectPaisControl = this.Rem.selectPais;
      //   if (selectPaisControl) {
      //     // Elige el primer país de la lista como predeterminado
      //     selectPaisControl.setValue(this.pais[0].idpais);
      //   }
      // }
      })
      .catch((error: HttpErrorResponse) => {
        if (error.error.ExceptionMessage == "invalid_token") {
          this.mensajeService.subject$.next(
            new MensajePopup("Su sesión ha expirado", false, false)
          );
          this.router.navigate(["/herramientasDigitales/login"]);
        } else {
          this.mensajeService.subject$.next(
            new MensajePopup(
              "ocurrio un error inesperado, inténtelo de nuevo más tarde",
              false,
              false
            )
          );
        }
      });
  }

  async cargarEstado() {
    await this.GuiaswebServiceService.obtenerEstado(
      this.idpais,
      "ORG",
      this.doc.idsesion,
      this.token
    )
      .then((d) => {
        this.estado = d;
      })
      .catch((error: HttpErrorResponse) => {
        if (error.error.ExceptionMessage == "invalid_token") {
          this.mensajeService.subject$.next(
            new MensajePopup("Su sesión ha expirado", false, false)
          );
          this.router.navigate(["/herramientasDigitales/login"]);
        } else {
          this.mensajeService.subject$.next(
            new MensajePopup(
              "ocurrio un error inesperado, inténtelo de nuevo más tarde",
              false,
              false
            )
          );
        }
      });
  }

  async cargarCiudad() {
    await this.GuiaswebServiceService.obtenerCiudades(
      this.idestado,
      "ORG",
      this.doc.idsesion,
      this.token
    )
      .then((d) => {
        this.ciudad = d;
      })
      .catch((error: HttpErrorResponse) => {
        if (error.error.ExceptionMessage == "invalid_token") {
          this.mensajeService.subject$.next(
            new MensajePopup("Su sesión ha expirado", false, false)
          );
          this.router.navigate(["/herramientasDigitales/login"]);
        } else {
          this.mensajeService.subject$.next(
            new MensajePopup(
              "ocurrio un error inesperado, inténtelo de nuevo más tarde",
              false,
              false
            )
          );
        }
      });
  }

  async cargarColonia() {
    await this.GuiaswebServiceService.obtenerColonias(
      this.idestado,
      this.idciudad,
      "ORG",
      this.doc.idsesion,
      this.token
    )
      .then((d) => {

           this.colonia = d;

        if(this.remitenteForm.get('sucursalSeleccionado').value == true && this.remitenteForm.get('recoleccionSeleccionado').value == true){
          // alert("Solo uno");
          this.remitenteForm.get('sucursalSeleccionado').setValue(false);
          this.remitenteForm.get('recoleccionSeleccionado').setValue(false);
          this.colonia = [];
          this.remitenteForm.controls.selectColonia.setValue("");
          return
       }

       if(this.remitenteForm.get('sucursalSeleccionado').value == false && this.remitenteForm.get('recoleccionSeleccionado').value == false){
          this.remitenteForm.get('sucursalSeleccionado').setValue(false);
          this.remitenteForm.get('recoleccionSeleccionado').setValue(false);
          this.colonia = [];
          this.remitenteForm.controls.selectColonia.setValue("");
          return
        }

        if(this.remitenteForm.get('sucursalSeleccionado').value == true && this.remitenteForm.get('recoleccionSeleccionado').value == false){
          //  this.colonia = d;
           // Conservar solo el primer elemento del array
           const optionOcurre = this.colonia[0];
           this.colonia.splice(1); // Eliminar los elementos después del primer elemento
           // Asignar el array modificado al FormControl
           this.remitenteForm
             .get("selectColonia")
             .setValue(optionOcurre.idcol);
             this.remitenteForm.get('selectColonia').setValue(this.colonia.length > 0 ? this.colonia[0].idcol : null);
          //  this.remitenteForm.controls.selectColonia.setValue(-1);
             this.remitenteForm.controls.direccion.setValue(
             this.colonia[0].colonia);
             this.remitenteForm.controls.cp.setValue("0");
             this.remitenteForm.controls.cp.disable();
          //  this.coloniaChanged(-1);
             this.remitenteForm.controls.direccion.disable();
        }

        if(this.remitenteForm.get('sucursalSeleccionado').value == false && this.remitenteForm.get('recoleccionSeleccionado').value == true){
          //  this.colonia = d;
           if (Object.keys(this.colonia).length > 1) {
               // Eliminar el primer elemento del array
               this.colonia.shift();
               // Asignar el array modificado al FormControl
               this.remitenteForm.get('selectColonia').setValue(this.colonia.length > 0 ? this.colonia[0].idcol : null);
               this.remitenteForm.controls.direccion.setValue('');
               return
             } else {
                 this.remitenteForm.get('selectColonia').setValue(this.colonia.length > 0 ? this.colonia[0].idcol : null);
                 this.remitenteForm.controls.direccion.setValue(this.colonia[0].colonia);
                 this.remitenteForm.controls.cp.setValue("0");
                 this.remitenteForm.controls.cp.disable();
                 this.remitenteForm.controls.direccion.disable();
                 this.remitenteForm.get('sucursalSeleccionado').setValue(true);
                 this.remitenteForm.get('recoleccionSeleccionado').setValue(false);
                //  alert("Actualmente esta ciudad no tiene recolecciones");
                Swal.fire({
                  icon: 'info',
                  title: 'Informativo',
                  text: 'No se encontró cobertura de recolección.',
                  customClass: {
                    container: 'norecoleccion-modal'
                  }
                });
                return

             }


        }

        // if (this.colonia.length == 1) {
        //     this.remitenteForm.controls.selectColonia.setValue(-1);
        //     this.coloniaChanged(-1);
        //     this.remitenteForm.controls.direccion.disable();
        // }
      })
      .catch((error: HttpErrorResponse) => {
        if (error.error.ExceptionMessage == "invalid_token") {
          this.mensajeService.subject$.next(
            new MensajePopup("Su sesión ha expirado", false, false)
          );
          this.router.navigate(["/herramientasDigitales/login"]);
        } else {
          this.mensajeService.subject$.next(
            new MensajePopup(
              "ocurrio un error inesperado, inténtelo de nuevo más tarde",
              false,
              false
            )
          );
        }
      });
  }

  cambiarFocus(e) {
    if (
      !(
        (e.keyCode >= 48 && e.keyCode <= 57) ||
        e.keyCode == 8 ||
        e.keyCode == 9 ||
        e.keyCode == 13 ||
        e.keyCode == 38 ||
        e.keyCode == 40 ||
        e.keyCode == 37 ||
        e.keyCode == 39 ||
        (e.keyCode >= 96 && e.keyCode <= 105)
      )
    ) {
      e.preventDefault();
    }
  }

  solicitar() {
    this.submitted = true;
    if (this.remitenteForm.valid) {
    }
  }

  //creamos la sesion en el paso 2
  async guardarsesion_paso2() {
    this.submitted = true;
    this.validarsesion();
    if (this.remitenteForm.valid) {
      this.doc.paso = 2;
      this.llenarRemitente();
      await this.GuiaswebServiceService.GwoprsesionDetV2(this.doc, this.token)
        .then((d) => {
          this.datagw = d;
          // agregamos los datos para el paso 3
          this.doc.idsedet = this.datagw.idsedet;
          this.doc.idsesion = this.datagw.idsesion;
          this.doc.paso = 3;
          this.doc.pasoMAx = 3;
          sessionStorage.removeItem("gwDocumentacion");
          sessionStorage.setItem(
            "gwDocumentacion",
            window.btoa(unescape(encodeURIComponent(JSON.stringify(this.doc))))
          );
          //pasamos a la pantalla de destinatario
          this.GuiaswebServiceService.setplantilla(3);
        })
        .catch((error: HttpErrorResponse) => {
          if (error.error.ExceptionMessage == "invalid_token") {
            this.mensajeService.subject$.next(
              new MensajePopup("Su sesión ha expirado", false, false)
            );
            this.router.navigate(["/herramientasDigitales/login"]);
          } else {
            this.mensajeService.subject$.next(
              new MensajePopup(
                "ocurrio un error inesperado, inténtelo de nuevo más tarde",
                false,
                false
              )
            );
          }
        });
    }
  }

  paisChanged(id) {
    this.idpais = id;
    this.idestado = 0;
    this.idciudad = 0;
    this.idcolonia = 0;
    this.remitenteForm.controls.selectEstado.setValue("");
    this.remitenteForm.controls.selectCiudad.setValue("");
    this.remitenteForm.controls.selectCiudad.setValue("");
    this.remitenteForm.controls.selectColonia.setValue("");
    this.remitenteForm.controls.cp.setValue("");
    this.cargarEstado();
  }

  estadoChanged(id) {
    this.idestado = id;
    this.idciudad = 0;
    this.idcolonia = 0;
    this.remitenteForm.controls.selectCiudad.setValue("");
    this.remitenteForm.controls.selectCiudad.setValue("");
    this.remitenteForm.controls.selectColonia.setValue("");
    this.remitenteForm.controls.cp.setValue("");
    this.cargarCiudad();
  }

  ciudadChanged(id) {
    this.idciudad = id;
    this.idcolonia = 0;
    this.remitenteForm.controls.selectColonia.setValue("");
    this.remitenteForm.controls.cp.setValue("");
    // this.remitenteForm.get('sucursalSeleccionado').enable();
    // this.remitenteForm.get('recoleccionSeleccionado').enable();
    // this.remitenteForm.get('sucursalSeleccionado').setValue(true);

    // Habilitar los campos
    this.remitenteForm.get("sucursalSeleccionado").enable();
    this.remitenteForm.get("recoleccionSeleccionado").enable();

    // Establecer valores
    this.remitenteForm.get("sucursalSeleccionado").setValue(true);
    this.remitenteForm.get("recoleccionSeleccionado").setValue(false);

    // Forzar la actualización de la validez
    this.remitenteForm.get("sucursalSeleccionado").updateValueAndValidity();
    this.remitenteForm.get("recoleccionSeleccionado").updateValueAndValidity();

    this.cargarColonia();
  }

  coloniaChanged(id) {
    this.idcolonia = id;
    for (let i = 0; i < this.colonia.length; i++) {
      if (this.idcolonia == this.colonia[i].idcol) {
        if (this.idcolonia == -1) {
            this.remitenteForm.controls.direccion.setValue(
            this.colonia[i].colonia
          );
          this.remitenteForm.controls.direccion.disable();
        } else {
          this.remitenteForm.controls.direccion.enable();
        }
        this.remitenteForm.controls.cp.setValue(this.colonia[i].codigo);
      }
    }
  }

  limpiar() {
    this.remitenteForm.controls.nombre.setValue("");
    this.remitenteForm.controls.selectPais.setValue("");
    this.remitenteForm.controls.selectEstado.setValue("");
    this.remitenteForm.controls.selectCiudad.setValue("");
    this.remitenteForm.controls.selectColonia.setValue("");
    this.remitenteForm.controls.direccion.setValue("");
    this.remitenteForm.controls.telefono.setValue("");
    this.remitenteForm.controls.cp.setValue("");
    window.scrollTo(0, 0);
  }

  llenarRemitente() {
    this.doc.remitente.nombre = this.remitenteForm.controls.nombre.value;
    this.doc.remitente.pais = this.remitenteForm.controls.selectPais.value;
    this.doc.remitente.estado = this.remitenteForm.controls.selectEstado.value;
    this.doc.remitente.ciudad = this.remitenteForm.controls.selectCiudad.value;
    this.doc.remitente.colonia = this.remitenteForm.controls.selectColonia.value;
    this.doc.remitente.direccion = this.remitenteForm.controls.direccion.value;
    this.doc.remitente.tel = this.remitenteForm.controls.telefono.value;
    this.doc.remitente.cp = this.remitenteForm.controls.cp.value;

    // this.doc.remitente.NombrePais = this.remitenteForm.controls.selectPais.value;

    for (let i = 0; i < this.pais.length; i++) {
      if (this.remitenteForm.controls.selectPais.value == this.pais[i].idpais) {
          this.doc.remitente.NombrePais = this.pais[i].pais;
        break;
      }
    }

    for (let i = 0; i < this.estado.length; i++) {
      if (
        this.remitenteForm.controls.selectEstado.value == this.estado[i].idedo
      ) {
        this.doc.remitente.NombreEstado = this.estado[i].estado;
        break;
      }
    }

    for (let i = 0; i < this.ciudad.length; i++) {
      if (
        this.remitenteForm.controls.selectCiudad.value == this.ciudad[i].idcd
      ) {
        this.doc.remitente.Nombreciudad = this.ciudad[i].ciudad;
        break;
      }
    }

    for (let i = 0; i < this.estado.length; i++) {
      if (
        this.remitenteForm.controls.selectColonia.value == this.colonia[i].idcol
      ) {
        this.doc.remitente.NombreColonia = this.colonia[i].colonia;
        break;
      }
    }
  }

  regresarMenu() {
    this.router.navigate(["/herramientasDigitales/documentacion/menu"]);
  }

  imprimirEstadoCheckboxes(controlName: string) {
    console.log('Estado de sucursalSeleccionado:', this.remitenteForm.get('sucursalSeleccionado').value);
    console.log('Estado de recoleccionSeleccionado:', this.remitenteForm.get('recoleccionSeleccionado').value);

    if(controlName == "sucursalSeleccionado"){
      this.remitenteForm.get('recoleccionSeleccionado').setValue(false);
    }

    if(controlName == "recoleccionSeleccionado"){
       this.remitenteForm.get('sucursalSeleccionado').setValue(false);
       this.remitenteForm.controls.direccion.setValue("");
       this.remitenteForm.controls.direccion.enable();
    }

    this.cargarColonia();
  }

}
