import { Component, OnInit, TemplateRef, ViewChild, ɵConsole } from '@angular/core';
import { Router } from '@angular/router';
import { GuiaswebServiceService } from '../../servicios/guiasweb-service.service';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { user} from '../../entidades/gwuser';
import { documentacion } from '../../entidades/gwdocumentacion';
import jwt_decode from 'jwt-decode';
import { HttpErrorResponse } from '@angular/common/http';
import { MensajePopup } from 'src/app/entidades/MensajePopup';
import { MensajeService } from 'src/app/servicios/mensaje.service';
import { PlatformLocation } from '@angular/common';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';



@Component({
  selector: 'app-gw-menu-documentacion',
  templateUrl: './gw-menu-documentacion.component.html',
  styleUrls: ['./gw-menu-documentacion.component.css']
})
export class GwMenuDocumentacionComponent implements OnInit {
  tipos: any;
  failedtipo: boolean;

  doc = new documentacion();
  prepago: string;
  consumo: string;
  sincosto: string;
  mostrador: string;
  tipopaquete: string;

  userGwSubscription: Subscription;
  userGw: user;
  datagw: any;
  token: string;
  tokendatos: any;

  modalRef: BsModalRef;

  constructor(
    private GuiaswebServiceService: GuiaswebServiceService,
    private router: Router,
    private mensajeService: MensajeService,
    private modalService: BsModalService

  ) {

   }

  ngOnInit(): void {
    this.GuiaswebServiceService.setModuloG(1);


    //traemos los datos de la sesionstorage para agregarlos en la estrucuta doc
    sessionStorage.removeItem('gwDocumentacion');
    if(sessionStorage.getItem('token') == undefined || sessionStorage.getItem('token') == ""  || sessionStorage.getItem('token') == null ){
      this.router.navigate(['/herramientasDigitales/login']);
    }else{
      window.scrollTo(0,0);
      this.token = sessionStorage.getItem('token')
      this.tokendatos = jwt_decode(this.token);
      this.userGw = this.tokendatos;
      this.doc.idcliente = this.userGw.idUser;
      this.CargarTipoPaq();
    }
  }

  //cargamos las guias disponibles por cada tipo
  async CargarTipoPaq() {
    await this.GuiaswebServiceService.CargarTipoPaq(this.userGw.idUser, this.token).then(
      d => {
        // localStorage.setItem('token', d.TxtMsj);

        this.tipos = d;
        if (this.tipos[0].id < 0) {
          this.failedtipo = true;
        }
        else {
          this.failedtipo = false;
        }
      }
    ).catch((error: HttpErrorResponse) => {
      if (error.error.ExceptionMessage == "invalid_token") {
        this.mensajeService.subject$.next(new MensajePopup("Su sesión ha expirado", false, false));
        this.router.navigate(['/herramientasDigitales/login']);
        // this.router.navigate(['/login']);
      } else {
        this.mensajeService.subject$.next(new MensajePopup("ocurrio un error inesperado al iniciar sesión en tu cuenta", false, false));
      }
    });
  }






  //al dar click guardamos la sesion - paso 1
  abrirHerramienta(num) {

    switch (num) {
      case 1:
        //prepago
        if(this.tipos[1].total > 0){
        this.doc.tiposol = this.tipos[1].id
        this.tipopaquete = this.tipos[1].paquete
        this.guardarsesion();
        document.getElementById("consumo").classList.remove("nodisponibles");
        document.getElementById("consumo").classList.add("disponibles");
        }
        else{
          document.getElementById("consumo").classList.remove("disponibles");
          document.getElementById("consumo").classList.add("nodisponibles");
        }
        break;
      case 2:
        //consumo
        if(this.tipos[2].total > 0){
        this.doc.tiposol = this.tipos[2].id
        this.tipopaquete = this.tipos[2].paquete
        this.guardarsesion();}
        break;
      case 3:
        //sin costo
        if(this.tipos[3].total > 0){
        this.doc.tiposol = this.tipos[3].id
        this.tipopaquete = this.tipos[3].paquete
        this.guardarsesion();}
        break;
      case 4:
        // guia para mostrador

        this.doc.tiposol = this.tipos[4].id
        this.tipopaquete = this.tipos[4].paquete
        this.guardarsesion();
        break;

      default:
        break;
    }
  }
  //creamos la sesion en el paso 1
  async guardarsesion() {
    await this.GuiaswebServiceService.GwoprsesionDetV2(this.doc, this.token).then(
      d => {
        this.datagw = d

        // agregamos los datos para el paso 2
        this.doc.idsedet = this.datagw.idsedet;
        this.doc.idsesion = this.datagw.idsesion;
        this.doc.paso = 2;
        sessionStorage.setItem('gwDocumentacion',  window.btoa(unescape(encodeURIComponent(JSON.stringify(this.doc) ))));
        sessionStorage.setItem('gwTipopaq', window.btoa(unescape(encodeURIComponent(this.tipopaquete ))));

        //pasamos a la pantalla de remintente
        //this.router.navigate(['/herramientasDigitales/Documentacion/confirmacion']);
        this.router.navigate(['/herramientasDigitales/Documentacion']);
        this.GuiaswebServiceService.setplantilla(2);
      }
    ).catch((error: HttpErrorResponse) => {
      if (error.error.ExceptionMessage == "invalid_token") {
        this.mensajeService.subject$.next(new MensajePopup("Su sesión ha expirado", false, false));
        this.router.navigate(['/herramientasDigitales/login']);
        // this.router.navigate(['/login']);
      } else {
        this.mensajeService.subject$.next(new MensajePopup("ocurrio un error inesperado al iniciar sesión en tu cuenta", false, false));
      }
    });
  }

  Abrirdescripcion() {
    const btn = document.getElementById('btn-modal') as HTMLElement;
    btn.click();
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  closeModal() {
    this.modalRef.hide();
  }
 
}

