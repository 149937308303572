import { Component } from '@angular/core';
import { MensajeService } from './servicios/mensaje.service';
import { AppService } from './servicios/app.service';
import { MensajePopup } from './entidades/MensajePopup';
import { LoadingService } from './servicios/loading.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { User } from './entidades/gwusr';
import { login } from './entidades/login';
import { GuiaswebServiceService } from './servicios/guiasweb-service.service'
import jwt_decode from 'jwt-decode';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  isFirstLoad = true;
  isLoading = false;
  isLoadingFijo = false;
  animal = '';

  hideMenu = false;
  hideFooter = false;

  datosclien: User;
  userGw: User;
  token: any;
  gwc: any;
  toComponente: string = "";
  lgn: any;
  tmpidusr: '0';

  dtdevice:any;

  t = '';
  f = '';


  constructor(
    private mensajeService: MensajeService,
    private loadingService: LoadingService,
    private AppService: AppService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private GuiaswebServiceService: GuiaswebServiceService
  ) { }

  ngOnInit() {
    this.tmpidusr= '0';
    this.loadingService.loading.subscribe(isLoading => this.isLoading = isLoading);
    this.loadingService.loadingFijo.subscribe(loadingFijo => this.isLoadingFijo = loadingFijo);

    this.activatedRoute.queryParamMap.subscribe(queryParams => {
      this.t = queryParams.get("t");
      this.f = queryParams.get("f");

      if (this.t && this.f) {
        this.AppService.setVisit(this.t, this.f).subscribe(datos => console.log(datos));
        this.router.navigateByUrl('/');
      }
    })


    this.activatedRoute.queryParams.subscribe(params => {
      const mode = params['mode'];
      const Token = params['tklog'];
      // console.log(mode)

      if (mode === 'M') {
        environment.sitemode ='M'

        this.hideMenu = true;
        this.hideFooter = true;
      }

      if (environment.sitemode === 'M') {
        this.hideMenu = true;
        this.hideFooter = true;
      }


      if (Token != '' && Token !=  undefined) {
        // console.log(Token);
        sessionStorage.setItem('token', Token);

        this.gwc = jwt_decode(sessionStorage.getItem('token'));
        this.userGw = this.gwc;

        this.GuiaswebServiceService.datosUsrLogin(this.userGw);

        this.GuiaswebServiceService.setnumlogin(true);
        localStorage.setItem('login', 'true');
        localStorage.setItem ('idUsuario',this.gwc.idUser);
        this.tmpidusr= this.gwc.idUser

      }
    });



    const tfdvKey = 'tfdv';


    if (!localStorage.getItem(tfdvKey)) {
      try {
        // Llama a AppService.setdevice y almacena el resultado en this.dtdevice
        this.AppService.setdevice(this.tmpidusr.toString(), "")
          .toPromise()
          .then((data) => {
            this.dtdevice = data;

            if (this.dtdevice.u !== "") {
              localStorage.setItem(tfdvKey, this.dtdevice.u);
            }
          })
          .catch((error) => {
            console.error('Error al obtener el dispositivo:', error);
          });
      } catch (error) {
        console.error('Error inesperado:', error);
      }
    }


  }

  ngAfterContentInit(): void {
    //this.viajeService.setIsFirstLoad(false);
    this.isFirstLoad = false;
  }


  onActivate(event) {
    window.scroll(0, 0);
    //or document.body.scrollTop = 0;
    //or document.querySelector('body').scrollTo(0,0)
  }
}
