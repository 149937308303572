<div class="container_herramienta">
    <img src="assets/img/web/envia.webp" class="img_toAnimate">
    <!-- <div class="volver" routerLink="**">Volver al Inicio</div> -->
    <div class="alinearIzq">
<div class="gwlogin-container" [formGroup]="registroForm">
        <div class="left">
            <h1>Bienvenidos</h1>
            <p>Inicie sesión.</p>

            <div class="text" [ngClass]="{validacion: (f.user1.invalid || f.user1.hasError('IsEmpty')) && submitted}">
                <label>Usuario</label>
                <input type="text" id="usr" placeholder="Usuario, teléfono o correo" (keyup)="cambiarFocus($event, password)" formControlName="user1">
            </div>

            <div class="text" [ngClass]="{validacion: (f.pass1.invalid || f.pass1.hasError('IsEmpty')) && submitted}">
                <label>Contraseña</label>
                <input type="password" placeholder="Contraseña"  #password (keyup)="cambiarFocus($event, btnEntrar)" formControlName="pass1">
            </div>
            <span *ngIf="failedLogin">Al parecer algun dato no es correcto, verifica tu usuario y/o contraseña e intentalo de nuevo.</span>

            <button (click)="login($event)" #btnEntrar id="entrar">Entrar</button>

           <p class="gw-textSecond">¿Eres nuevo?<span id="idspan" class="gw-textSecond gw-span" (click)="CrearCuenta()"> Crear tu cuenta</span></p>
           <p class="gw-textSecond"><span class="gw-textSecond gw-span" (click)="Cambiarcontrasena()">¿Olvidaste tu contraseña?</span></p>
        </div>
    </div>
</div>
</div>