import { Component, OnInit, ElementRef, AfterViewInit, ViewChild } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { DomSanitizer } from "@angular/platform-browser";
import { DocumentosService } from 'src/app/servicios/documentos.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SignatureComponent } from '../signature/signature.component';
import { user } from "../../entidades/gwuser";
import jwt_decode from "jwt-decode";
import { MensajeService } from 'src/app/servicios/mensaje.service';
import { MensajePopup } from 'src/app/entidades/MensajePopup';
import  Swal  from 'sweetalert2';

// interface Opcion {
//   valor: string;
//   etiqueta: string;
// }

// interface Pregunta {
//   pregunta: string;
//   tipoPregunta: string;
//   respuesta: string;
//   opciones?: Opcion[];
// }

@Component({
  selector: 'app-documentos',
  templateUrl: './documentos.component.html',
  styleUrls: ['./documentos.component.css']
})


export class DocumentosComponent implements OnInit, AfterViewInit {
  @ViewChild(SignatureComponent) signatureComponent: SignatureComponent;
  currentStep = 1;
  formGroup: FormGroup;
  @ViewChild('myInput', { static: false }) myInput: ElementRef;


  userGw: user;
  // datagw: any;
  token: string;
  tokendatos: any;
  // idcliente: number=-1;
  // nameCliente: any;

  public archivos: any[];
  public previsualizacion: string;
  public dtDocumento: any = [];
  public dtDocAdjuntos: any = [];
  public numeroGrupos: number = 0;
  public grupos: number[] = Array.from({ length: this.numeroGrupos }, (_, index) => index + 1);
  public grupoActual: number = 1;
  public miInput:any;
  mostrarDiv: boolean = false;
  public respuestaval:string = "";
  public primergrupo:string = "";
  public dtDocumentoVal:any = [] ;
  public dtDocumentoValFooter:any = [] ;
  public idDocumento: string;
  showFormInfo: boolean = true;
  showFormAnswers: boolean = false;
  btnRegresar: boolean = true;
  btnFinalizar: boolean = true;
  btnBackPage: boolean = true;
  public numeroPregunta: number = 1;
  showModalSignature: boolean = false;
  showModalFinCuestionario: boolean = false;
  showModalCodigoVerificacion: boolean = true;
  mensajeOkFinCuestionario: string;
  public idseccionfirma: string;
  public seccionfirma: string;
  public codigoAcceso: string = "";
  // public filesSaved: any[];
  filesSaved: { [id: string]: any[] } = {};
  idsArray: string[] = [];

  // ngAfterViewInit() {
  //   this.myInput.nativeElement.focus();
  // }

  // formData: {
  //   formatopreguntas: Pregunta[];
  // } = {
  //   formatopreguntas: [
  //     {
  //       pregunta: '¿Cuál es tu color favorito?',
  //       tipoPregunta: 'radio',
  //       respuesta: '',
  //       opciones: [
  //         { valor: 'rojo', etiqueta: 'Rojo' },
  //         { valor: 'verde', etiqueta: 'Verde' },
  //         { valor: 'azul', etiqueta: 'Azul' }
  //       ]
  //     },
  //     {
  //       pregunta: '¿Cuál es tu fruta favorita?',
  //       tipoPregunta: 'select',
  //       respuesta: '',
  //       opciones: [
  //         { valor: 'manzana', etiqueta: 'Manzana' },
  //         { valor: 'naranja', etiqueta: 'Naranja' },
  //         { valor: 'plátano', etiqueta: 'Plátano' }
  //       ]
  //     },
  //     {
  //       pregunta: '¿Te gusta programar?',
  //       tipoPregunta: 'radio',
  //       respuesta: '',
  //       opciones: [
  //         { valor: 'si', etiqueta: 'Sí' },
  //         { valor: 'no', etiqueta: 'No' }
  //       ]
  //     }
  //   ]
  // };

  constructor(private formBuilder: FormBuilder,
              private sanitizer: DomSanitizer,
              private documentosService: DocumentosService,
              private mensajeService: MensajeService,
              private rutaActiva: ActivatedRoute,
              private router: Router )
              {
    this.formGroup = this.formBuilder.group({
      nombre: ['', Validators.required],
      apellido: ['', Validators.required],
      correo: ['', [Validators.required, Validators.email]],
      telefono: [''],
      monto: ['', Validators.required],
      fecha: ['', Validators.required],
      adjunto: [null, Validators.required],
      tipo: ['', Validators.required]
    });
  }

  ngOnInit(): void {

    this.VerificarToken();
    this.token = sessionStorage.getItem('token')
    this.tokendatos = jwt_decode(this.token);
    this.userGw = this.tokendatos;

    const url = window.location.href.toLowerCase();

    console.log(this.userGw);


    // this.rutaActiva.params.subscribe(params => {
    //   this.idDocumento = params.code;
    //   if (this.idDocumento !== undefined) {
    //     this.obtenerFormatoDigital();

    //   } else {
    //     this.router.navigate(['/']);
    //   }
    // });

  }

  ngAfterViewInit() {

    this.signatureComponent.datobaseChanged.subscribe((datobase: string) => {
      // console.log(datobase);

      if(this.seccionfirma == "FOO"){
        const formatofooterEncontrado = this.dtDocumento.formatofooter.find(item => item.idfooter === this.idseccionfirma);

        // Verifica si se encontró el formatofooter con el idfooter deseado
        if (formatofooterEncontrado) {
           // Asigna datobase a formatofooter.valor
           formatofooterEncontrado.valor = datobase;
           }
        }

        if(this.seccionfirma == "PRE"){
          const formatoPreguntaEncontrado = this.dtDocumento.formatopreguntas.find(item => item.idpregunta === this.idseccionfirma);

          // Verifica si se encontró el formatofooter con el idfooter deseado
          if (formatoPreguntaEncontrado ) {
             // Asigna datobase a formatofooter.valor
             formatoPreguntaEncontrado.respuesta = datobase;
             }
         }
        // console.log(this.dtDocumento);
    });

    this.signatureComponent.closeModalChanged.subscribe((datoclosemodal: boolean) => {
      //  console.log(datoclosemodal);
       this.closeModalSignature();

    });
  }

  cargarDocumento(){

    let datosCodigo = {
      opr: "VCO",
      codigo: this.codigoAcceso,
      usr: this.userGw.idUser
    }

     this.documentosService.validarCodigoAcceso(datosCodigo).subscribe((response: any) => {

      console.log(response);

        if (response && parseInt(response.nummsg) > 0) {

            Swal.fire({
              icon: "success",
              title: response.txtmsg,
              showConfirmButton: false,
              timer: 1500
          });

             this.rutaActiva.params.subscribe(params => {
             this.idDocumento = params.code;
             if (this.idDocumento !== undefined) {
                 this.obtenerFormatoDigital();


               //  this.cargaFilesFormatosDigitales();
             } else {
              this.router.navigate(['/']);
            }
         });

           this.showModalCodigoVerificacion = false;

          //  this.router.navigate([`/documentos/${id}`]);

         } else  if (response && parseInt(response.nummsg) < 0) {

              Swal.fire({
              icon: "error",
              title: "!ERROR¡",
              text: response.txtmsg,
          });

       }

      }, error => {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "ERROR",
          text: error,
        });
      })



    //   this.rutaActiva.params.subscribe(params => {
    //   this.idDocumento = params.code;
    //   if (this.idDocumento !== undefined) {
    //     this.obtenerFormatoDigital();

    //   } else {
    //     this.router.navigate(['/']);
    //   }
    // });

    // this.showModalCodigoVerificacion = false;
  }

  VerificarToken() {
    if (sessionStorage.getItem('token') == undefined || sessionStorage.getItem('token') == "" || sessionStorage.getItem('token') == null) {
      this.mensajeService.subject$.next(new MensajePopup("Su sesión ha expirado", false, false));
      this.router.navigate(['/herramientasDigitales/login']);
      return;
    }
  }

  clearSignature(){
    this.signatureComponent.clearSignature();
  }

  openModalSignature(seccion,idseccion) {
    this.clearSignature();
    this.showModalSignature = true;
    this.idseccionfirma = idseccion;
    this.seccionfirma = seccion;
  }

  closeModalSignature() {
    this.showModalSignature = false;
  }

  closeModalCodigoSeguridad(){
    this.router.navigate(['/mis-documentos']);
    return
  }

  visualizar() {
    // console.log(this.dtDocumento);
  }

  previousStep() {
    const currentIndex = this.grupos.indexOf(this.grupoActual);
    if (currentIndex > 0) {
      this.grupoActual = this.grupos[currentIndex - 1];
    }
  }

  nextStep() {
    const currentIndex = this.grupos.indexOf(this.grupoActual)
    if (currentIndex < this.grupos.length - 1) {
      this.grupoActual = this.grupos[currentIndex + 1];
    }
  }

  previousStepYGuardar() {
    this.previousStep();
    this.guardarProgresoFormatoDigital();
    // console.log(`grupoactual: ${this.grupoActual}`);

  }

  lastGrupo(){
    if(this.validarFooters()){
        this.nextStepYGuardar();
        this.showResumen();
     }

   }

  nextStepYGuardar() {

    if(this.validarGrupos()){
      this.nextStep();
      this.guardarProgresoFormatoDigital();
      // console.log(`grupoactual: ${this.grupoActual}`);
    }

  }

  obtenerFormatoDigital() {
    this.documentosService.cargarDocumentos(this.idDocumento).then((data) => {
      this.dtDocumento = JSON.parse(JSON.stringify(data));
      this.dtDocAdjuntos = JSON.parse(JSON.stringify(data));

      console.dir("Este es mi primer objeto"+ this.dtDocumento);

      this.btnBackPage = false;

      if(this.dtDocumento.status === "FIN"){
         this.showFormAnswers = true;
         this.showFormInfo = false;
         this.btnRegresar = false;
         this.btnFinalizar = false;
         this.btnBackPage = true;
       }

      this.obtenerMaxGrupo();

       // Verificar si formatopreguntas es definido y no es nulo
       if (
        this.dtDocumento.formatopreguntas
      ) {
        // Filtrar los ids de preguntas de tipo "File"
        const preguntasFile = this.dtDocumento.formatopreguntas.filter(
          (pregunta) => pregunta && pregunta.tipoPregunta === "File"
        );

        // Verificar si preguntasFile es un array y tiene elementos
        if (Array.isArray(preguntasFile) && preguntasFile.length > 0) {
          // Obtener los ids de preguntas de tipo "File"
          // const idsFile = preguntasFile.map(
          //   (pregunta) => pregunta.idpregunta
          // );

          const idsFile = preguntasFile.map(pregunta => pregunta.idcp);

          // Enviar los ids uno por uno a la función cargaFilesFormatosDigitales
          for (let i = 0; i < idsFile.length; i++) {
            console.log(idsFile[i]);
            this.idsArray.push(idsFile[i]);
            this.cargaFilesFormatosDigitales(idsFile[i]);
          }
        }
      }


    }, (error) => {
      console.log(error);
    });
  }

  crearDocumentoValFooter(){
      // var grp  = 0;
      for (let i = 0; i <= this.dtDocumento.formatofooter.length-1; i++) {
        this.dtDocumentoValFooter.push(
          {idfooter: this.dtDocumento.formatofooter[i].idfooter, valido :false, msg:""}
        )
        //  if(i === 0){
        //    grp= this.dtDocumento.formatofooter[i].grupo;

        //    this.grupos.push(grp)

        //  }else{
        //   if (grp !=  this.dtDocumento.formatofooter[i].grupo){
        //       grp= this.dtDocumento.formatofooter[i].grupo;
        //       if (this.grupos.indexOf(grp) !== -1) {
        //       } else {
        //         this.grupos.push(grp);
        //       }
        //   }
        //  }

      }

      // this.grupoActual = this.grupos[0];
      // this.primergrupo = this.grupos[0].toString();

      // this.grupos.push(-1);

      // this.numeroGrupos = (this.grupos.length );


    }


  obtenerMaxGrupo() {

    this.crearDocumentoValFooter();
    var grp  = 0;
    for (let i = 0; i <= this.dtDocumento.formatopreguntas.length-1; i++) {
      this.dtDocumentoVal.push(
        {idpregunta: this.dtDocumento.formatopreguntas[i].idpregunta, valido :false, msg:""}
      )
       if(i === 0){
         grp= this.dtDocumento.formatopreguntas[i].grupo;

         this.grupos.push(grp)

       }else{
        if (grp !=  this.dtDocumento.formatopreguntas[i].grupo){
            grp= this.dtDocumento.formatopreguntas[i].grupo;
            if (this.grupos.indexOf(grp) !== -1) {
            } else {
              this.grupos.push(grp);
            }
        }
       }

    }

    this.grupoActual = this.grupos[0];
    this.primergrupo = this.grupos[0].toString();

    this.grupos.push(-1);

    this.numeroGrupos = (this.grupos.length );

    // let gruposTotal = 0;
    // this.dtDocumento.formatopreguntas.forEach((pregunta: any) => {
    //   const grupo = parseInt(pregunta.grupo, 10);
    //   if (grupo > gruposTotal) {
    //     gruposTotal = grupo;
    //   }
    // });
    // this.numeroGrupos = (gruposTotal + 1)
    // this.grupos = Array.from({ length: this.numeroGrupos }, (_, index) => index + 1);
    // console.log(`grupo: ${this.numeroGrupos}`);
  }

  guardarProgresoFormatoDigital() {
    this.dtDocumento.status = 'MDF';
    this.documentosService.guardarProgresoFormatoDigital(this.dtDocumento).subscribe((response) => {
      // console.log(response);
      if (this.grupoActual == 4) {
         // console.log("guardar los adjuntos aqui");
          this.guardarDocsAdjuntos();
      }
    }, (error) => {
      console.log(error);
    })

  }

  validarNumeros(event: any) {

    let valorInput: string = event.target.value;
    valorInput = valorInput.replace(/[^0-9]/g, '');
    // this.inputregimenfiscal = valorInput;
    // Actualiza el valor del input según la propiedad
    if (valorInput !== event.target.value) {
      event.target.value = '';
    }
  }

  showResumen(){
    this.showFormAnswers = true;
    this.showFormInfo = false;
  }

  goMisDocumentos(){
    this.router.navigate(['/mis-documentos']);
    return
  }

  hideResumen(){
    this.showFormAnswers = false;
    this.showFormInfo = true;
  }

  finalizarFormatoDigital() {
    this.dtDocumento.status = 'FIN';
    this.documentosService.finalizarCuestionarioFormatoDigital(this.dtDocumento).subscribe((response) => {

      // alert(response.txtmsg);

      this.mensajeOkFinCuestionario = response.txtmsg;

      if(response.nummsg = '3'){
        this.showModalFinCuestionario = true;
       }

      // this.showModalFinCuestionario = true;

      // this.router.navigate(['/mis-documentos']);

    }, (error) => {
      console.log(error);
    });
  }

  guardarDocsAdjuntos() {
    this.dtDocAdjuntos.status = 'MDF'
    this.documentosService.guardarDocsAdjuntos(this.dtDocAdjuntos).subscribe(response => {
      // console.log(response);
    }, error => {
      console.log(error);
    })
  }

  parseInt(value: string): number {
    return parseInt(value);
  }

  handleFileChange2(event: any, pregunta: any): void {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const allowedExtensions = ['.pdf','.png', '.jpg','.doc', '.docx'];
      const fileExtension = selectedFile.name.substr(selectedFile.name.lastIndexOf('.')).toLowerCase();

      if (allowedExtensions.includes(fileExtension)) {
        // El archivo es válido, realiza la lógica de procesamiento
        // console.log('Archivo válido:', selectedFile.name);


        const reader = new FileReader();

        reader.onload = (e: any) => {
          const base64Data = e.target.result.split(',')[1];
          pregunta.respuesta = selectedFile.name;

        };

        // Lee el contenido del archivo como base64
        reader.readAsDataURL(selectedFile);
      } else {
        // Archivo no válido, muestra un mensaje de error
        // console.log('Archivo no válido');
        event.target.value = null;
        // Muestra un mensaje al usuario si es necesario
        alert('El archivo seleccionado no es válido. Por favor selecciona un archivo con la extensión .pdf, .png, .doc o .docx.');
        // Realiza otras acciones como limpiar el input o mostrar un mensaje de error en la interfaz
      }
    }
  }

  handleFileChange(event: any, id: any){
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const allowedExtensions = ['.pdf','.png', '.jpg','.doc', '.docx'];
      const fileExtension = selectedFile.name.substr(selectedFile.name.lastIndexOf('.')).toLowerCase();

      if (allowedExtensions.includes(fileExtension)) {
        // El archivo es válido, realiza la lógica de procesamiento
        // console.log('Archivo válido:', selectedFile.name);

        const index = this.dtDocumento.formatopreguntas.findIndex(item => item.idpregunta === id);

        if (index !== -1) {
          this.dtDocumento.formatopreguntas[index].respuesta = selectedFile.name;
        }

      } else {
        // Archivo no válido, muestra un mensaje de error
        // console.log('Archivo no válido');
        event.target.value = null;
        // Muestra un mensaje al usuario si es necesario
        alert('El archivo seleccionado no es válido. Por favor selecciona un archivo con la extensión .pdf, .png, .doc o .docx.');
        // Realiza otras acciones como limpiar el input o mostrar un mensaje de error en la interfaz
      }
    }
  }

  handleFileInputChange(event: any, pregunta: any): void {
    this.handleFileChange(event, pregunta.idpregunta);
    this.handleFileDocs(event,pregunta.idpregunta, pregunta.idcp);
  }

    handleFileDocs(event: any, id: any, idrsppre: any) {
    const selectedFile = event.target.files[0];

    if(selectedFile){
      const maxSizeInBytes = 2 * 1024 * 1024;
      const fileSize = selectedFile.size;

      if(fileSize <= maxSizeInBytes){
         console.log("tamaño del archivo"+ fileSize);
         console.log(`Tamaño del archivo: ${fileSize}`);
      }else{
        Swal.fire({
          icon: 'info',
          title: 'TAMAÑO EXCEDIDO',
          text: 'El tamaño de tu archivo es mayor a 2MB.'
       });
       return
      }
    }

    if (selectedFile) {
      const allowedExtensions = ['.pdf'];

      const fileExtension = selectedFile.name.substr(selectedFile.name.lastIndexOf('.')).toLowerCase();

      if (allowedExtensions.includes(fileExtension)) {
        console.log('Valid file:', selectedFile.name);

        const reader = new FileReader();

        reader.onload = (e: any) => {
          const base64Data = e.target.result.split(',')[1];

          const index = this.dtDocAdjuntos.formatopreguntas.findIndex(item => item.idpregunta === id);

          if (index !== -1) {
            this.documentosService.oprFilesFormatosDigitales("ALT", idrsppre, "1", id, selectedFile.name, base64Data, fileExtension, "1").subscribe(
              (response) => {
                console.log(response);
                this.cargaFilesFormatosDigitales(idrsppre);

              },
              (error) => {
                console.log(error);
              }
            );
          }
        };

        // Read the content of the file as base64
        reader.readAsDataURL(selectedFile);
      } else {
        console.log('Invalid file');
        event.target.value = null;
        alert('The selected file is not valid. Please select a file with the extension .pdf.');
      }
    }
  }

  // cargaFilesFormatosDigitales(id:string){


  //   this.documentosService.cargaFilesFormatosDigitales(id).then((response:any) => {
  //     console.log("Este es la perra respuesta", response);
  //     this.filesSaved = response;
  //   },err => {
  //     console.log(err)
  //   })

  // }


  cargaFilesFormatosDigitales(id: string) {
    this.documentosService.cargaFilesFormatosDigitales(id).then((response: any) => {
      console.log("Este es la perra respuesta", response);
      this.filesSaved[id] = response;
      console.log(this.filesSaved);
    }, err => {
      console.log(err);
    });
  }


  // handleFileDocs(event: any, id: any) {
  //   const selectedFile = event.target.files[0];

  //   if (selectedFile) {
  //     const allowedExtensions = ['.pdf'];
  //     const fileExtension = selectedFile.name.substr(selectedFile.name.lastIndexOf('.')).toLowerCase();

  //     if (allowedExtensions.includes(fileExtension)) {
  //       // The file is valid, perform processing logic
  //       // console.log('Valid file:', selectedFile.name);

  //       const reader = new FileReader();

  //       reader.onload = (e: any) => {
  //         const base64Data = e.target.result.split(',')[1];

  //         const index = this.dtDocAdjuntos.formatopreguntas.findIndex(item => item.idpregunta === id);

  //         if (index !== -1) {
  //           this.dtDocAdjuntos.formatopreguntas[index].respuesta = base64Data; // Store base64-encoded data
  //         }
  //       };

  //       // Read the content of the file as base64
  //       reader.readAsDataURL(selectedFile);
  //     } else {
  //       // Invalid file, show an error message
  //       // console.log('Invalid file');
  //       event.target.value = null;
  //       alert('The selected file is not valid. Please select a file with the extension .pdf, .png, .doc, or .docx.');
  //     }
  //   }
  // }

  validarInputs(){
    for (let i = 0; i < this.dtDocumento.formatopreguntas.length; i++) {

       if(this.dtDocumento.formatopreguntas[i].grupo == "1" ){
          this.miInput = document.getElementById(this.dtDocumento.formatopreguntas[i].idpregunta) as HTMLInputElement;
          if (this.miInput.value === '') {
              if(this.miInput.type == "Input"){
                 this.mostrarDiv = true;
                 return
                }
              if(this.miInput.type == "text"){
                 this.mostrarDiv = true;
                 return
                }
              // console.log('El input está vacío.');
          } else {
              // console.log('El input no está vacío. Su valor es: ' + this.miInput.value);
          }
        }

        if(this.dtDocumento.formatopreguntas[i].grupo == "2" ){
           this.miInput = document.getElementById(this.dtDocumento.formatopreguntas[i].idpregunta) as HTMLInputElement;
           if (this.miInput.value === '') {
              //  console.log('El input está vacío.');
           } else {
              //  console.log('El input no está vacío. Su valor es: ' + this.miInput.value);
           }
        }

        if(this.dtDocumento.formatopreguntas[i].grupo == "3" ){
          // console.log(this.dtDocumento.formatopreguntas[i].idpregunta)
           this.miInput = document.getElementById(this.dtDocumento.formatopreguntas[i].idpregunta) as HTMLInputElement;
           if (this.miInput.value === '') {
              //  console.log('El input está vacío.');
           } else {
              //  console.log('El input no está vacío. Su valor es: ' + this.miInput.value);
           }
        }

    }

  }

  guardarValidacionFooters(idfooter,mensaje,validacion){
    // console.log(this.dtDocumentoValFooter);
    for (let i = 0; i <= this.dtDocumentoValFooter.length-1; i++) {
      if (this.dtDocumentoValFooter[i].idfooter == idfooter){
          this.dtDocumentoValFooter[i].msg = mensaje;
          this.dtDocumentoValFooter[i].validacion = validacion;
          break
      }
    }
  }

  guardarValidacion(idpregunta,mensaje,validacion){
    for (let i = 0; i <= this.dtDocumentoVal.length-1; i++) {
      if (this.dtDocumentoVal[i].idpregunta == idpregunta){
          this.dtDocumentoVal[i].msg = mensaje;
          this.dtDocumentoVal[i].validacion = validacion;
          break
      }
    }
  }

  validaInput(idpregunta){
    for (let i = 0; i <= this.dtDocumentoVal.length-1; i++) {
      if (this.dtDocumentoVal[i].idpregunta == idpregunta){
          return this.dtDocumentoVal[i].msg;
      }
    }

    return  "OK" ;

  }

  validaInputFooter(idpregunta){
    for (let i = 0; i <= this.dtDocumentoValFooter.length-1; i++) {
      if (this.dtDocumentoValFooter[i].idfooter == idpregunta){
          return this.dtDocumentoValFooter[i].msg;
      }
    }

    return  "OK" ;

  }

  validarFooters(){

    for (let i = 0; i <= this.dtDocumento.formatofooter.length-1; i++) {

         if (this.dtDocumento.formatofooter[i].valor === '') {
             this.respuestaval = "*El campo " + this.dtDocumento.formatofooter[i].nombre + " no se ha capturado" ;
             this.mostrarDiv = true;
            //  alert(this.respuestaval);
             this.guardarValidacionFooters(this.dtDocumento.formatofooter[i].idfooter,this.respuestaval,false);
            //  console.log(this.mostrarDiv);
             return false;
          }
         else {
              if (this.dtDocumento.formatofooter[i].tipo === 'text') {


              } else  if (this.dtDocumento.formatofooter[i].tipo === 'email') {
                if(this.validarCorreoElectronico(this.dtDocumento.formatofooter[i].valor) == false){
                   this.respuestaval = "*El campo " + this.dtDocumento.formatofooter[i].nombre + " no cumple con el formato de correo" ;
                   this.mostrarDiv = true;
                   this.guardarValidacionFooters(this.dtDocumento.formatofooter[i].idfooter,this.respuestaval,false);
                  //  alert(this.respuestaval);
                   return false;
                }

              } else  if (this.dtDocumento.formatofooter[i].tipo === 'currency') {
                // console.log("Este dato trae: "+this.dtDocumento.formatofooter[i].nombre);
                if(this.dtDocumento.formatofooter[i].valor === null){
                  this.respuestaval = "*El campo " + this.dtDocumento.formatofooter[i].nombre + " no se ha capturado " ;
                  this.mostrarDiv = true;
                  this.guardarValidacionFooters(this.dtDocumento.formatofooter[i].idfooter,this.respuestaval,false);
                 //  alert(this.respuestaval);
                  return false;
                }

              } else  if (this.dtDocumento.formatofooter[i].tipo === 'date') {


              } else  if (this.dtDocumento.formatofooter[i].tipo === 'numeric') {
                //  console.log(this.dtDocumento.formatofooter[i].nombre);
                 if(this.dtDocumento.formatofooter[i].valor <= -1){
                  this.respuestaval = "*El campo " + this.dtDocumento.formatofooter[i].nombre + " no debe tener números negativos" ;
                  this.mostrarDiv = true;
                  this.guardarValidacionFooters(this.dtDocumento.formatofooter[i].idfooter,this.respuestaval,false);
                 //  alert(this.respuestaval);
                  return false;
               }

              } else  if (this.dtDocumento.formatofooter[i].tipo === 'phone') {
                if(this.dtDocumento.formatofooter[i].valor.length < 13){
                   this.respuestaval = "*El campo " + this.dtDocumento.formatofooter[i].nombre + " no cumple con los digitos necesarios" ;
                   this.mostrarDiv = true;
                   this.guardarValidacionFooters(this.dtDocumento.formatofooter[i].idfooter,this.respuestaval,false);
                  //  alert(this.respuestaval);
                   return false;
                }

              } else  if (this.dtDocumento.formatofooter[i].tipo === 'textlong') {


              } else  if (this.dtDocumento.formatofooter[i].tipo === 'firma') {
                //  alert(this.dtDocumento.formatofooter[i].valor);

              } else  if (this.dtDocumento.formatofooter[i].tipo === 'Input') {


              }else  if (this.dtDocumento.formatofooter[i].tipo === 'select') {
                if(this.dtDocumento.formatofooter[i].valor <= -1){
                  this.respuestaval = "*El campo " + this.dtDocumento.formatofooter[i].nombre + " no tiene ninguna opcion seleccionada" ;
                  this.mostrarDiv = true;
                  this.guardarValidacionFooters(this.dtDocumento.formatofooter[i].idfooter,this.respuestaval,false);
                 //  alert(this.respuestaval);
                  return false;
               }

              }

         }
         this.guardarValidacionFooters(this.dtDocumento.formatofooter[i].idfooter,"OK",true);
   }
    return true
  }

  validarGrupos(){
   if(this.grupoActual === this.numeroGrupos){
      // Validar footer
      return false;
   }else{
      // Validar preguntas
      for (let i = 0; i <= this.dtDocumento.formatopreguntas.length-1; i++) {

        if(this.dtDocumento.formatopreguntas[i].grupo == this.grupoActual ){

           if (this.dtDocumento.formatopreguntas[i].respuesta === '') {
               this.respuestaval = "*El campo " + this.dtDocumento.formatopreguntas[i].pregunta + " no se ha capturado" ;
               this.mostrarDiv = true;
              //  alert(this.respuestaval);
               this.guardarValidacion(this.dtDocumento.formatopreguntas[i].idpregunta,this.respuestaval,false);
               return false;

           } else {
                if (this.dtDocumento.formatopreguntas[i].tipoPregunta === 'text') {


                } else  if (this.dtDocumento.formatopreguntas[i].tipoPregunta === 'email') {
                  if(this.validarCorreoElectronico(this.dtDocumento.formatopreguntas[i].respuesta) == false){
                     this.respuestaval = "*El campo " + this.dtDocumento.formatopreguntas[i].pregunta + " no cumple con el formato de correo" ;
                     this.mostrarDiv = true;
                     this.guardarValidacion(this.dtDocumento.formatopreguntas[i].idpregunta,this.respuestaval,false);
                    //  alert(this.respuestaval);
                     return false;
                  }

                } else  if (this.dtDocumento.formatopreguntas[i].tipoPregunta === 'currency') {
                  // console.log("Este dato trae: "+this.dtDocumento.formatopreguntas[i].pregunta);
                  if(this.dtDocumento.formatopreguntas[i].respuesta === null){
                    this.respuestaval = "*El campo " + this.dtDocumento.formatopreguntas[i].pregunta + " no se ha capturado " ;
                    this.mostrarDiv = true;
                    this.guardarValidacion(this.dtDocumento.formatopreguntas[i].idpregunta,this.respuestaval,false);
                   //  alert(this.respuestaval);
                    return false;
                  }

                } else  if (this.dtDocumento.formatopreguntas[i].tipoPregunta === 'date') {


                } else  if (this.dtDocumento.formatopreguntas[i].tipoPregunta === 'numeric') {
                  //  console.log(this.dtDocumento.formatopreguntas[i].pregunta);
                   if(this.dtDocumento.formatopreguntas[i].respuesta <= -1){
                    this.respuestaval = "*El campo " + this.dtDocumento.formatopreguntas[i].pregunta + " no debe tener números negativos" ;
                    this.mostrarDiv = true;
                    this.guardarValidacion(this.dtDocumento.formatopreguntas[i].idpregunta,this.respuestaval,false);
                   //  alert(this.respuestaval);
                    return false;
                 }

                } else  if (this.dtDocumento.formatopreguntas[i].tipoPregunta === 'phone') {
                  if(this.dtDocumento.formatopreguntas[i].respuesta.length < 13){
                     this.respuestaval = "*El campo " + this.dtDocumento.formatopreguntas[i].pregunta + " no cumple con los digitos necesarios" ;
                     this.mostrarDiv = true;
                     this.guardarValidacion(this.dtDocumento.formatopreguntas[i].idpregunta,this.respuestaval,false);
                    //  alert(this.respuestaval);
                     return false;
                  }

                } else  if (this.dtDocumento.formatopreguntas[i].tipoPregunta === 'textlong') {


                } else  if (this.dtDocumento.formatopreguntas[i].tipoPregunta === 'firma') {
                  //  alert(this.dtDocumento.formatopreguntas[i].respuesta);

                } else  if (this.dtDocumento.formatopreguntas[i].tipoPregunta === 'Input') {


                }  else  if (this.dtDocumento.formatopreguntas[i].tipoPregunta === 'select') {
                   if(this.dtDocumento.formatopreguntas[i].respuesta <= -1){
                    this.respuestaval = "*El campo " + this.dtDocumento.formatopreguntas[i].pregunta + " no tiene ninguna opcion seleccionada" ;
                    this.mostrarDiv = true;
                    this.guardarValidacion(this.dtDocumento.formatopreguntas[i].idpregunta,this.respuestaval,false);
                   //  alert(this.respuestaval);
                    return false;
                 }


                }

           }
           this.guardarValidacion(this.dtDocumento.formatopreguntas[i].idpregunta,"OK",true);
         }

     }

   }

    return true;
  }


  validarCorreoElectronico(correo){
    // Expresión regular para validar el formato del correo electrónico
    var regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Validar el correo utilizando la expresión regular
    if (regex.test(correo)) {
      // console.log('El correo electrónico es válido.');
      return true;
    } else {
      // console.log('El correo electrónico no es válido.');
      return false;
    }
  }

  // onInputChange(event: any) {
  //   const inputValue = event.target.value;
  //   const numericValue = parseFloat(inputValue);

  //   if ( inputValue.length > 13) {
  //     event.preventDefault(); // Evitar la entrada de caracteres no numéricos o más de 13 dígitos
  //   }
  // }

  onInputChange(event: any) {
    let inputValue = event.target.value;
    let inputName = event.target.name;

  if(inputName == "phone"){
    // console.log("es phonee");
     // Eliminar cualquier caracter que no sea un número
     inputValue = inputValue.replace(/[^0-9+]/g, '');

     // Limitar la longitud a 13 dígitos
     if (inputValue.length > 13) {
       inputValue = inputValue.substring(0, 13);
     }

     // Actualizar el valor del input
     event.target.value = inputValue;
  }

  if(inputName == "currency"){
    // console.log("es currency");
     // Eliminar cualquier caracter que no sea un número
     inputValue = inputValue.replace(/[^0-9.]/g, '');

     // Limitar la longitud a 13 dígitos
     if (inputValue.length > 13) {
       inputValue = inputValue.substring(0, 13);
     }

     // Actualizar el valor del input
     event.target.value = inputValue;
   }

  }

  descargarArchivo(id){
     const url = `https://ventas.tufesa.com.mx/WSRestWEBJSONPaqalpha/descargaFilesFormatosDigitales?idFile=${id}`;
     window.open(url);

    // this.documentosService.descargaFilesFormatosDigitales(id).then(response => {
    //   console.log(response);

    // },(err) =>{
    //   console.log(err);
    // })

  }

  eliminarArchivoProceso(id:string, idrsppre: string){

    Swal.fire({
      title: 'ELIMINAR ARCHIVO',
      text: "¿Seguro que desea eliminar el documento?",
      icon: 'question',
      iconColor: '#8C8C8C',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Eliminar'
    }).then((result) => {
      if (result.isConfirmed) {
           this.eliminarArchivo(id,idrsppre);
      }
    })
  }

  eliminarArchivo(id:string, idrsppre: string){

    this.documentosService.oprFilesFormatosDigitales("BJA", "1", "1", id, "nombredearchivo", "base64Data", "fileExtension", id).subscribe(
      (response) => {

        if(parseInt(response.nummsg,10) > 0){
           Swal.fire({
            // position: "top-end",
            icon: "success",
            title: response.txtmsg,
            showConfirmButton: false,
            timer: 1500
          });

          this.cargaFilesFormatosDigitales(idrsppre);

        }else{

          Swal.fire({
            // position: "top-end",
            icon: "error",
            title: response.txtmsg,
            showConfirmButton: false,
            timer: 1500
          });
        }

        // this.cargaFilesFormatosDigitales();
      },
      (error) => {
        console.log(error);
        Swal.fire({
          title: "ERROR",
          text: error,
          icon: "error"
        });
      }
    );

  }

  // handleFileDocs(event: any, id: any, idrsppre: any) {
  //   const selectedFile = event.target.files[0];

  //   if (selectedFile) {
  //     const allowedExtensions = ['.pdf'];

  //     const fileExtension = selectedFile.name.substr(selectedFile.name.lastIndexOf('.')).toLowerCase();

  //     if (allowedExtensions.includes(fileExtension)) {
  //       console.log('Valid file:', selectedFile.name);

  //       const reader = new FileReader();

  //       reader.onload = (e: any) => {
  //         const base64Data = e.target.result.split(',')[1];

  //         const index = this.dtDocAdjuntos.formatopreguntas.findIndex(item => item.idpregunta === id);

  //         if (index !== -1) {
  //           this.documentosService.oprFilesFormatosDigitales("ALT", idrsppre, "1", id, selectedFile.name, base64Data, fileExtension).subscribe(
  //             (response) => {
  //               console.log(response);
  //             },
  //             (error) => {
  //               console.log(error);
  //             }
  //           );
  //         }
  //       };

  //       // Read the content of the file as base64
  //       reader.readAsDataURL(selectedFile);
  //     } else {
  //       console.log('Invalid file');
  //       event.target.value = null;
  //       alert('The selected file is not valid. Please select a file with the extension .pdf.');
  //     }
  //   }
  // }

  // inputCurrency(event: any) {
  //   let inputValue = event.target.value;

  //   // Eliminar cualquier caracter que no sea un número
  //   inputValue = inputValue.replace(/[^0-9.]/g, '');

  //   // Limitar la longitud a 13 dígitos
  //   if (inputValue.length > 13) {
  //     inputValue = inputValue.substring(0, 13);
  //   }

  //   // Actualizar el valor del input
  //   event.target.value = inputValue;
  // }








  // if (this.currentStep === 1 && this.formGroup.controls.nombre.invalid || this.formGroup.controls.apellido.invalid || this.formGroup.controls.tipo.invalid) {
  //   this.formGroup.controls.nombre.markAsTouched();
  //   this.formGroup.controls.apellido.markAsTouched();
  //   this.formGroup.controls.tipo.markAsTouched();

  //   return;
  // }

  // if (this.currentStep === 2 && this.formGroup.controls.correo.invalid || this.formGroup.controls.telefono.invalid) {
  //   this.formGroup.controls.correo.markAsTouched();
  //   this.formGroup.controls.telefono.markAsTouched();
  //   return;
  // }
  // if (this.currentStep === 3 && (this.formGroup.controls.monto.invalid || this.formGroup.controls.fecha.invalid || this.formGroup.controls.adjunto.invalid)) {
  //   this.formGroup.controls.monto.markAsTouched();
  //   this.formGroup.controls.fecha.markAsTouched();
  //   this.formGroup.controls.adjunto.markAsTouched();
  //   return;
  // }
  // this.currentStep++;

  // submitForm() {
  //   if (this.formGroup.valid) {
  //     console.log(this.formGroup.value);
  //   }
  // }


  // // handleFileChange(event: Event) {
  // //   const file = (event.target as HTMLInputElement).files[0];
  // //   this.formGroup.patchValue({ adjunto: file });
  // // }

  // capturarfile(event) {
  //   const archivoCapturado = event.target.files[0];
  //   this.extraerBase64(archivoCapturado).then((imagen: any) => {
  //     this.previsualizacion = imagen.base;
  //     console.log(imagen);
  //   });
  //   this.archivos.push(archivoCapturado);
  // }

  // extraerBase64 = async ($event: any) =>
  // new Promise((resolve, reject) => {
  //   try {
  //     const unsafeImg = window.URL.createObjectURL($event);
  //     const image = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeImg);
  //     const reader = new FileReader();
  //     reader.readAsDataURL($event);
  //     reader.onload = () => {
  //       resolve({
  //         base: reader.result,
  //         base2: reader.result,
  //       });
  //     };
  //     reader.onerror = (error) => {
  //       resolve({
  //         base: null,
  //         base2: null,
  //       });
  //     };
  //   } catch (e) {
  //     return null;
  //   }
  // });



}
