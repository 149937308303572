<div class="container_full">

    <div class="container-secciones">

      <div class="top-container-headers">
        <label style="font-weight: 700; font-size: 15px;">{{ dtDocumento.nombre}}</label>
        <label for="" class="idrspclass">Folio: {{ dtDocumento.idrsp }}</label>
      </div>
      <div class="container-full-sections">
        <!-- CABECERAS -->
        <!-- <div class="container-headers">
          <div style="display: flex; flex-direction: column;" *ngFor="let header of dtDocumento.formatoencabezados; let i = index">
            <label class="truncate-text" style="font-size: 14px; margin-bottom: 0px; font-weight: 700;">{{ header.nombre }}</label>
            <label class="truncate-text" style="font-size: 12px;"> {{header.valor}}</label>
            <img src="{{header.valor}}" alt="">
          </div>
        </div> -->

        <div class="container-headers">
          <div style="display: flex; flex-direction: column;" *ngFor="let header of dtDocumento.formatoencabezados; let i = index">
            <label class="truncate-text" style="font-size: 14px; margin-bottom: 0px; font-weight: 700;">{{ header.nombre }}</label>

            <!-- Verificar si el tipo es "firma" -->
            <ng-container *ngIf="header.tipo === 'Firma'">
              <!-- Mostrar la imagen en lugar del texto para el tipo "firma" -->
              <img [src]="header.valor" alt="Firma" style="width: 100px;">
            </ng-container>

            <!-- Si no es "firma", mostrar el valor de manera predeterminada -->
            <label *ngIf="header.tipo !== 'Firma'" class="truncate-text" style="font-size: 12px;"> {{header.valor}}</label>
          </div>
        </div>


        <!-- FIN CABECERAS -->

        <div class="form-info" *ngIf="showFormInfo">
          <!-- GRUPO -->
          <div>
            <div *ngFor="let grupo of dtDocumento.formatopreguntas; let i = index">
              <ng-container *ngIf=" grupo.grupo === grupoActual">
                <div *ngIf=" i === 0 || grupo.grupoTitulo !== dtDocumento.formatopreguntas[i - 1]?.grupoTitulo">
                  <b>
                    <p> {{ grupo.grupoTitulo }}</p>
                  </b>
                  <p> {{ grupo.grupoSubtitulo }}</p>
                </div>
              </ng-container>
            </div>
           <!-- <hr style="background-color: #aaaaaa; border: 0px;"> -->
           <!-- FIN GRUPO -->

           <!-- PREGUNTAS -->
           <div class="container-question" *ngFor="let preguntas of dtDocumento.formatopreguntas; let i = index" >
             <ng-container *ngIf=" preguntas.grupo === grupoActual">
               <label>{{ preguntas.pregunta }}</label>
               <br/>
               <input
                 *ngIf="preguntas.tipoPregunta === 'input' || preguntas.tipoPregunta === 'Input'"
                  type="text"
                  class="cls_input"
                  id="{{ preguntas.idpregunta }}"
                  [(ngModel)]="preguntas.respuesta"
                  placeholder="{{ preguntas.pregunta }}"
                  (ngModelChange)="visualizar()"
               />
               <input
                 *ngIf="preguntas.tipoPregunta === 'Date'"
                  type="date"
                  class="cls_input"
                  id="{{ preguntas.idpregunta }}"
                  [(ngModel)]="preguntas.respuesta"
                  placeholder="{{ preguntas.pregunta }}"
                  (ngModelChange)="visualizar()"
               />
               <!-- <div class="container-input" *ngIf="preguntas.tipoPregunta === 'email'">
                 <span class="icon-input">@</span>
                 <input
                  type="email"
                  class="input-withicon"
                  [(ngModel)]="preguntas.respuesta"
                  placeholder="{{ preguntas.pregunta }}"
                  (ngModelChange)="visualizar()"
                  />
               </div> -->
               <input
                 *ngIf="preguntas.tipoPregunta === 'Email'"
                  type="email"
                  class="cls_input"
                  id="{{ preguntas.idpregunta }}"
                  [(ngModel)]="preguntas.respuesta"
                  placeholder="{{ preguntas.pregunta }}"
                  (ngModelChange)="visualizar()"
               />
               <input
               *ngIf="preguntas.tipoPregunta === 'Numeric'"
               type="number"
               class="cls_input"
               id="{{ preguntas.idpregunta }}"
               [(ngModel)]="preguntas.respuesta"
               placeholder="{{ preguntas.pregunta }}"
               (ngModelChange)="visualizar()"
               />
               <input
               *ngIf="preguntas.tipoPregunta === 'Phone'"
               type="text"
               name="phone"
               class="cls_input"
               id="{{ preguntas.idpregunta }}"
               placeholder="ej.(55) 1234-5678"
               (input)="onInputChange($event)"
               [(ngModel)]="preguntas.respuesta"
               placeholder="{{ preguntas.pregunta }}"
               (ngModelChange)="visualizar()"
               />
               <input
               *ngIf="preguntas.tipoPregunta === 'Text'"
               type="text"
               class="cls_input"
               id="{{ preguntas.idpregunta }}"
               [(ngModel)]="preguntas.respuesta"
               placeholder="{{ preguntas.pregunta }}"
               (ngModelChange)="visualizar()"
               />

               <textarea
               *ngIf="preguntas.tipoPregunta === 'Textlong'"
                name=""
                style="height: 80px; resize: none; padding: 5px 10px;"
                cols="30"
                rows="10"
                class="cls_input"
                id="{{ preguntas.idpregunta }}"
                [(ngModel)]="preguntas.respuesta"
                placeholder="{{ preguntas.pregunta }}"
                (ngModelChange)="visualizar()" >
               </textarea>

               <!-- <div class="container-input" *ngIf="preguntas.tipoPregunta === 'select'">
                 <select class="" style="text-align:left; font-size:15px;">
                   <option value="-1" selected="">Seleccione</option>
                   <option value="0">Ticket</option>
                 </select>
                </div> -->

                <div *ngIf="preguntas.tipoPregunta === 'Select'">
                  <div class="select">
                     <select class="select-class" id="{{ preguntas.idpregunta }}" [(ngModel)]="preguntas.respuesta" (ngModelChange)="visualizar()">
                       <option value="-1">Seleccione</option>
                       <option [value]="options.respuesta" *ngFor="let options of preguntas.formatopreguntarsp">{{ options.respuesta }}</option>
                     </select>
                  </div>
                </div>

               <div class="container-input" *ngIf="preguntas.tipoPregunta === 'Currency'">
                 <span class="icon-input">$</span>
                 <input
                  type="text"
                  name="currency"
                  inputmode="numeric"
                  class="input-withicon"
                  step="0.01"
                  placeholder="0.00"
                  (input)="onInputChange($event)"
                  id="{{ preguntas.idpregunta }}"
                  [(ngModel)]="preguntas.respuesta"
                  (ngModelChange)="visualizar()"
                  />
               </div>
               <div class="container-signature" *ngIf="preguntas.tipoPregunta === 'Firma'">
                 <div class="btnSignature-class" *ngIf="preguntas.respuesta === ''" (click)="openModalSignature('PRE',preguntas.idpregunta)">
                     <label style="margin-bottom: 0px;"> Capturar firma </label>
                     <img src="assets/iconos/signature.png" alt="" style="width: 24px; margin-left: 10px;">
                 </div>
                    <div class="containerSignature-class" *ngIf="preguntas.respuesta != ''" (click)="openModalSignature('PRE',preguntas.idpregunta)">
                      <img src="{{ preguntas.respuesta }}" alt="" style="width: 200px;">
                    </div>
               </div>

              <div class="container-input-file" *ngIf="preguntas.tipoPregunta === 'File'">
                <div class="container-btn-file">
                  <label class="txt-btn-file">Adjuntar tus imagenes o archivos</label>
                  <label class="subtxt-btn-file">Solamente .pdf (maximo 2MB)</label>
                  <input type="file" accept=".pdf" id="{{ preguntas.idpregunta }}"
                    (change)="handleFileInputChange($event, preguntas)" />
                </div>

                <div *ngFor="let id of idsArray">
                  <div class="container-items-file" *ngIf="id === preguntas.idcp">
                    <div class="item-file" *ngFor="let item of filesSaved[id]">
                      <div class="container-datatxt">
                        <img src="assets/iconos/archivogray.png" alt="" style="width: 20px;">
                        <label style="margin-left: 15px;">{{item.nombreFile}}</label>
                      </div>
                      <div class="container-buttons">
                        <img src="assets/iconos/descargar.png" alt="Descargar" style="width: 20px;"
                          (click)="descargarArchivo(item.idFile)">
                        <img src="assets/iconos/cerrarxgray2.png" alt="Eliminar" style="width: 13px; height: 13px;"
                          (click)="eliminarArchivoProceso(item.idFile,item.idrsppre)">
                      </div>
                    </div>
                  </div>
                </div>

              </div>

               <!-- <input
               *ngIf="preguntas.tipoPregunta === 'File'"
                type="file"
                accept=".pdf, .png, .jpg, .doc, .docx"
                id="{{ preguntas.idpregunta }}"
                (change)="handleFileInputChange($event, preguntas)"
               /> -->

             <div *ngIf="validaInput(preguntas.idpregunta) !== 'OK'" class="error-message">
               {{ validaInput(preguntas.idpregunta) }}
             </div>

             </ng-container>
           </div>
           <!-- FIN PREGUNTAS -->

           <!-- FOOTER -->
           <div *ngFor="let footer of dtDocumento.formatofooter">
             <ng-container>
             <div style="margin-bottom: 14px;" *ngIf="grupoActual === -1 ">
               <label>{{ footer.nombre }}</label>

               <input
                 *ngIf="footer.tipo === 'Input'"
                  type="text"
                  class="cls_input"
                  id="{{ footer.idfooter }}"
                  [(ngModel)]="footer.valor"
                  placeholder="{{ footer.nombre }}"
               />
               <input
                 *ngIf="footer.tipo === 'Text'"
                  type="text"
                  class="cls_input"
                  id="{{ footer.idfooter }}"
                  [(ngModel)]="footer.valor"
                  placeholder="{{ footer.nombre }}"
               />
               <input
                 *ngIf="footer.tipo === 'Date'"
                  type="date"
                  class="cls_input"
                  id="{{ footer.idfooter }}"
                  [(ngModel)]="footer.valor"
               />
               <input
                 *ngIf="footer.tipo === 'Email'"
                  type="email"
                  class="cls_input"
                  id="{{ footer.idfooter }}"
                  [(ngModel)]="footer.valor"
                  placeholder="{{ footer.nombre }}"
               />
               <input
                 *ngIf="footer.tipo === 'Numeric'"
                  type="number"
                  class="cls_input"
                  id="{{ footer.idfooter }}"
                  [(ngModel)]="footer.valor"
                  placeholder="{{ footer.nombre }}"
               />
               <!-- <input
                 *ngIf="footer.tipo === 'phone'"
                  type="tel"
                  class="cls_input"
                  id="{{ footer.idfooter }}"
                  [(ngModel)]="footer.valor"
                /> -->

                <input
                *ngIf="footer.tipo === 'Phone'"
                type="text"
                name="phone"
                class="cls_input"
                id="{{ footer.idfooter }}"
                placeholder="ej.(55) 1234-5678"
                (input)="onInputChange($event)"
                [(ngModel)]="footer.valor"
                placeholder="{{ footer.nombre }}"
                (ngModelChange)="visualizar()"
                />


                <!-- <textarea
                *ngIf="footer.tipo === 'textlong'"
                 name=""
                 style="height: 80px; resize: none; padding: 5px 10px;"
                 cols="30"
                 rows="10"
                 class="cls_input"
                 id="{{ footer.idfooter }}"
                 [(ngModel)]="footer.valor">
                 placeholder="{{ footer.nombre }}"
                (ngModelChange)="visualizar()" >
                </textarea> -->

                <textarea
                *ngIf="footer.tipo === 'Textlong'"
                 name=""
                 style="height: 80px; resize: none; padding: 5px 10px;"
                 cols="30"
                 rows="10"
                 class="cls_input"
                 id="{{ footer.idfooter }}"
                 [(ngModel)]="footer.valor"
                 placeholder="{{ footer.nombre }}"
                 (ngModelChange)="visualizar()" >
                </textarea>

                <!-- <input
                *ngIf="footer.tipo === 'currency'"
                 type="text"
                 class="cls_input"
                 id="{{ footer.idfooter }}"
                 [(ngModel)]="footer.valor"
                /> -->

                <div class="container-input" *ngIf="footer.tipo === 'Currency'">
                 <span class="icon-input">$</span>
                 <input
                  type="text"
                  name="currency"
                  inputmode="numeric"
                  class="input-withicon"
                  step="0.01"
                  placeholder="0.00"
                  (input)="onInputChange($event)"
                  id="{{ footer.idfooter }}"
                  [(ngModel)]="footer.valor"
                  (ngModelChange)="visualizar()"
                  />
                </div>

               <div *ngIf="footer.tipo === 'Select'">
                 <div class="select">
                    <select class="select-class" id="{{ footer.idfooter }}" [(ngModel)]="footer.valor" (ngModelChange)="visualizar()">
                      <option value="-1">Seleccione</option>
                      <option [value]="options.respuesta" *ngFor="let options of footer.catalogofooterrsp">{{ options.respuesta }}</option>
                    </select>
                 </div>
               </div>

                <!-- <div class="container-signature" *ngIf="footer.tipo === 'firma'">
                   <div class="btnSignature-class" (click)="openModalSignature('FOO',footer.idfooter)">
                       <label style="margin-bottom: 0px;"> Capturar firma </label>
                       <img src="assets/iconos/signature.png" alt="" style="width: 24px; margin-left: 10px;">
                   </div>
                </div> -->
                <div class="container-signature" *ngIf="footer.tipo === 'Firma'">
                 <div class="btnSignature-class" *ngIf="footer.valor === ''" (click)="openModalSignature('FOO', footer.idfooter)">
                     <label style="margin-bottom: 0px;"> Capturar firma </label>
                     <img src="assets/iconos/signature.png" alt="" style="width: 24px; margin-left: 10px;">
                 </div>
                    <div class="containerSignature-class" *ngIf="footer.valor !== ''" (click)="openModalSignature('FOO', footer.idfooter)">
                      <img src="{{ footer.valor }}" alt="" style="width: 113px;">
                    </div>
                </div>
                <input
                *ngIf="footer.tipo === 'File'"
                 type="file"
                 accept=".pdf, .png, .jpg, .doc, .docx"
                 id="{{ footer.idfooter }}"
                />

                <div *ngIf="validaInputFooter(footer.idfooter) !== 'OK'" class="error-message">
                 {{ validaInputFooter(footer.idfooter) }}
                </div>

               </div>
             </ng-container>
           </div>

           <!-- FIN FOOTER -->

           <!-- BOTONES -->
           <!-- <div class="container_btns">
             <button
               *ngIf="grupoActual.toString() !== primergrupo"
               class="btn_prev"
               (click)="previousStepYGuardar()"
             >
               <img src="assets/iconos/arrowleft.png" />
             </button>
             <button
               *ngIf="grupoActual !== -1"
                class="btn_next"
                id="btn_active"
                (click)="nextStepYGuardar()"
             >
               <img src="assets/iconos/arrowright.png" />
             </button>
             <button
               *ngIf="grupoActual === -1"
                class="btn_next"
                (click)="lastGrupo()"
             >
               <img src="assets/iconos/arrowright.png" />
             </button>
           </div> -->
           <!-- FIN BOTONES -->

          </div>

       </div>


      <!-- RESUMEN DE RESPUESTAS -->
      <div class="form-info" *ngIf="showFormAnswers">
         <div class="container-title-resumen"><label class="title-resumen">RESUMEN</label></div>
         <hr style="background-color: #aaaaaa; border: 0px; margin-top: 2px;">
         <div style="display: flex;" *ngFor="let documento of dtDocumento.formatopreguntas; let i = index;">
            <div><p style="margin-bottom: 0px; font-weight: 600;">{{ i + 1 }}.-</p></div>
         <div style="display: flex; flex-direction: column;" >
             <p class="question-class">{{ documento.pregunta }}</p>
            <p class="answer-class">{{ documento.respuesta }}</p>
         </div>
      <!-- <div></div> -->
      </div>

      <!-- <hr style="background-color: #aaaaaa; margin-top: 2px; margin-right: auto; margin-left: auto; width: 30%;"> -->
      <hr style="background-color: #aaaaaa; border: 0px; margin-top: 2px;">

      <div style="display: flex;" *ngFor="let documento of dtDocumento.formatofooter; let i = index;">
      <!-- <div><p style="margin-bottom: 0px; font-weight: 600;">{{ i + 1 }}.-</p></div> -->

      <div style="width: 100%;" *ngIf="documento.tipo === 'Firma'" >
          <p class="question-class">{{ documento.nombre}}</p>
          <img src="{{ documento.valor }}" alt="" style="width: 200px;">
      </div>

      <div style="width: 100%;" *ngIf="documento.tipo !== 'Firma'" >
        <p class="question-class">{{ documento.nombre}}</p>
        <p class="answer-class">{{ documento.valor}}</p>
      </div>

      </div>

      <!-- <hr style="background-color: #aaaaaa; border: 0px; margin-top: 2px;"> -->

      <!-- <div class="container-btnfinalizar">
        <button class="btn-finalizar" (click)="hideResumen()"> Regresar </button>
        <button class="btn-finalizar" style="margin-left: 6px;" (click)="finalizarFormatoDigital()"> Finalizar </button>
      </div> -->

      </div>
      <!-- FIN RESUMEN DE RESPUESTAS -->


    </div>

      <!-- <img
       _ngcontent-rvm-c247=""
       src="assets/img/web/envia.webp"
       class="img_toAnimate"
     />  -->

     <div class="container_btns" *ngIf="showFormInfo">
      <button
        *ngIf="grupoActual.toString() !== primergrupo"
        class="btn_prev"
        (click)="previousStepYGuardar()"
      >
        <img src="assets/iconos/arrowleft.png" />
      </button>
      <button
        *ngIf="grupoActual !== -1"
         class="btn_next"
         id="btn_active"
         (click)="nextStepYGuardar()"
       >
         <img src="assets/iconos/arrowright.png" />
       </button>
       <button
         *ngIf="grupoActual === -1"
          class="btn_next"
          (click)="lastGrupo()"
       >
         <img src="assets/iconos/arrowright.png" />
       </button>
     </div>

    <div class="container-btnfinalizar" *ngIf="showFormAnswers">
      <button class="btn-finalizar" *ngIf="btnRegresar"  (click)="hideResumen()"> Regresar </button>
      <button class="btn-finalizar" *ngIf="btnFinalizar" style="margin-left: 6px;" (click)="finalizarFormatoDigital()"> Finalizar </button>
      <button class="btn-finalizar" *ngIf="btnBackPage"  (click)="goMisDocumentos()"> Regresar </button>
    </div>

    </div>
</div>

<!-- MODAL FIRMA -->
<div class="modal-container" [ngClass]="{'show': showModalSignature}">
  <div class="modal-content">
    <span class="close-button" (click)="closeModalSignature()">&times;</span>
    <!-- <h2 style="margin-bottom: 0px; font-weight: 700; text-align: center;">FIRMA</h2> -->
    <!-- <hr style="background-color: #aaaaaa; border: 0px; margin-top: 2px; width: 100%;"> -->
    <p>Capture su firma.</p>
    <app-signature></app-signature>
  </div>
</div>

<!-- MODAL FIN CUESTIONARIO -->
<div class="modal-container" [ngClass]="{'show': showModalFinCuestionario}">
  <div class="modal-content-message">
    <!-- <span class="close-button" (click)="closeModalSignature()">&times;</span> -->
    <h2 style="margin-bottom: 0px; font-weight: 700; text-align: center;">Operación exitosa</h2>
    <hr style="background-color: #aaaaaa; border: 0px; margin-top: 2px; width: 100%;">
    <!-- <p style="margin: 30px 0px;">{{ mensajeOkFinCuestionario }}</p> -->
    <img  class="imagen" src="assets/iconos/comprobado.png" alt="exito">
    <div style="padding: 0px 50px;">
      <p>El cuestionario se ha finalizado correctamente!</p>
    </div>
    <button routerLink="/mis-documentos" class="btnModalFin-class">Aceptar</button>
  </div>
</div>

<!-- MODAL CODIGO DE VERIFICACION -->
<div class="modal-container" [ngClass]="{'show': showModalCodigoVerificacion}">
  <div class="modal-content-message" style="position: relative;">
    <span class="close-button" (click)="closeModalCodigoSeguridad()">&times;</span>
    <h2 style="margin-bottom: 0px; font-weight: 700; text-align: center;">Código enviado</h2>
    <hr style="background-color: #aaaaaa; border: 0px; margin-top: 2px; width: 100%;">
    <!-- <p style="margin: 30px 0px;">{{ mensajeOkFinCuestionario }}</p> -->
    <img  class="imagen" src="assets/iconos/sendemailplano.png" alt="exito">
    <div style="padding: 0px 50px;">
      <p>Hemos enviado un código de verificación a tu correo electrónico. Por favor, introdúcelo en el siguiente campo para continuar.</p>
      <input [(ngModel)]="codigoAcceso" id="input-codigo" class="cls_input" style="text-align: center; font-size: 20px !important; font-weight: 700; color: rgb(85, 85, 85);" type="text" placeholder="Código de verificación" maxlength="4" (input)="validarNumeros($event)">
    </div>
    <button (click)="cargarDocumento()" class="btnModalFin-class" style="font-size: 14px;">Continuar</button>
  </div>
</div>



  <!-- <button (click) = "obtenerFormatoDigital()" >CLick formato digital</button>
  <button (click) = "llenarFormatoDigital()" >Click llenar formato digital</button> -->

  <!-- <form class="form-info" [formGroup]="formGroup" (ngSubmit)="submitForm()">

    <div class="step_cont" *ngIf="currentStep === 1">
      <h2>Paso 1: Información personal</h2>

      <label class="lbl-text">Tipo</label>
      <select id="#myInput" class="cls_input" formControlName="tipo">
        <option value="">Seleccione un tipo</option>
        <option value="tipo1">Tipo 1</option>
        <option value="tipo2">Tipo 2</option>
        <option value="tipo3">Tipo 3</option>
      </select>
      <div *ngIf="formGroup.controls.tipo.touched && formGroup.controls.tipo.invalid">
        <div class="errorNotif" *ngIf="formGroup.controls.tipo.errors?.required">Debe seleccionar un tipo.</div>
      </div>

      <label class="lbl-text">Nombre</label>
      <input type="text" class="cls_input" formControlName="nombre" placeholder="Nombre">
      <div *ngIf="formGroup.controls.nombre.invalid && formGroup.controls.nombre.touched">
        <div class="errorNotif" *ngIf="formGroup.controls.nombre.errors?.required">El nombre es requerido.</div>
      </div>

      <label class="lbl-text">Apellido</label>
      <input type="text" class="cls_input" formControlName="apellido" placeholder="Apellido">
      <div *ngIf="formGroup.controls.apellido.invalid && formGroup.controls.apellido.touched">
        <div class="errorNotif" *ngIf="formGroup.controls.apellido.errors?.required">El apellido es requerido.</div>
      </div>
      <div class="container_btns">
        <button class="btn_next" id="btn_active" (click)="nextStep()"
          [disabled]="!formGroup.controls.nombre.valid || !formGroup.controls.apellido.valid"><img
            src="assets/iconos/arrowright.png"></button>
      </div>

    </div>

    <div class="step_cont" *ngIf="currentStep === 2">
      <h2>Paso 2: Información de contacto</h2>

      <label class="lbl-text">Correo electrónico</label>
      <input type="email" class="cls_input" formControlName="correo" placeholder="Correo electrónico">
      <div *ngIf="formGroup.controls.correo.invalid && formGroup.controls.correo.touched">
        <div class="errorNotif" *ngIf="formGroup.controls.correo.errors?.required">El correo es requerido.</div>
        <div class="errorNotif" *ngIf="formGroup.controls.correo.errors?.email">Ingrese un correo válido.</div>
      </div>

      <label class="lbl-text">Teléfono</label>
      <input type="tel" class="cls_input" formControlName="telefono" placeholder="Teléfono (10 digitos)" maxlength="10">
      <div *ngIf="formGroup.controls.telfono.invalid && formGroup.controls.telefono.touched">
        <div class="errorNotif" *ngIf="formGroup.controls.telefono.errors?.required">El telefono es requerido.</div>
      </div>
      <div class="container_btns">
        <button class="btn_prev" (click)="previousStep()"><img src="assets/iconos/arrowleft.png"></button>
        <button id="btn_active" class="btn_next" (click)="nextStep()" [disabled]="!formGroup.controls.correo.valid"><img
            src="assets/iconos/arrowright.png"></button>
      </div>
    </div>

    <div *ngIf="currentStep === 3">
      <h2>Paso 3: Otros datos</h2>

      <label class="lbl-text">Monto</label>
      <input type="text" class="cls_input" formControlName="monto" placeholder="Monto">
      <div *ngIf="formGroup.controls.monto.invalid && formGroup.controls.monto.touched">
        <div class="errorNotif" *ngIf="formGroup.controls.monto.errors?.required">El monto es requerido.</div>
      </div>

      <label class="lbl-text">Fecha</label>
      <input type="date" class="cls_input" formControlName="fecha" placeholder="Fecha">
      <div *ngIf="formGroup.controls.fecha.invalid && formGroup.controls.fecha.touched">
        <div class="errorNotif" *ngIf="formGroup.controls.fecha.errors?.required">La fecha es requerida.</div>
      </div>

      <label class="lbl-text">Adjunto</label>
      <input type="file" formControlName="adjunto" (change)="handleFileChange($event)">
      <div *ngIf="formGroup.controls.adjunto.touched && formGroup.controls.adjunto.invalid">
        <div class="errorNotif" *ngIf="formGroup.controls.adjunto.errors?.required">Debe adjuntar un archivo.</div>
      </div>

      <div class="adjuntaImg">
        <div *ngIf="previsualizacion" class="cont_img">
          <img class="vistaPrevia" [src]="previsualizacion">

        </div>
        <span class="spanImg">Adjunta tu imagen</span>
        <br><br>
      </div>
      <input type="file" id="ine_frente" (change)="capturarfile($event)">
      <div class="container_btns">
        <button class="btn_prev" (click)="previousStep()"><img src="assets/iconos/arrowleft.png"></button>
        <button class="btn_next" id="btn_active" (click)="submitForm()" [disabled]="formGroup.invalid"><img
            src="assets/iconos/arrowright.png"></button>
      </div>
    </div>

  </form>
</div> -->

  <!-- <div class="container_full">
  <img src="assets/img/web/envia.webp" class="img_toAnimate">
  <div class="form-info" *ngFor="let pregunta of formData.formatopreguntas">
    <label>{{ pregunta.pregunta }}</label>
    <ng-container *ngIf="pregunta.tipoPregunta === 'radio'">
      <div *ngFor="let opcion of pregunta.opciones">
        <input type="radio" name="{{ pregunta.pregunta }}" [(ngModel)]="pregunta.respuesta" [value]="opcion.valor" /> {{ opcion.etiqueta }}
      </div>
    </ng-container>
    <ng-container *ngIf="pregunta.tipoPregunta === 'select'">
      <select [(ngModel)]="pregunta.respuesta">
        <option *ngFor="let opcion of pregunta.opciones" [value]="opcion.valor">{{ opcion.etiqueta }}</option>
      </select>
    </ng-container>
  </div>
</div> -->
