import { Component, OnInit, TemplateRef, ViewChild, ɵConsole } from '@angular/core';
import { LoLlevaService } from 'src/app/servicios/lo-lleva.service'
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MyValidators } from 'src/app/entidades/Validators';
import { MensajeService } from 'src/app/servicios/mensaje.service';
import { MensajePopup } from 'src/app/entidades/MensajePopup';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-lo-lleva',
  templateUrl: './lo-lleva.component.html',
  styleUrls: ['./lo-lleva.component.css']
})
export class LoLlevaComponent implements OnInit {
  colsForm: FormGroup;
  datosForm: FormGroup;
  ciudades: any;
  colonias: any;
  cityId;
  searchCity = false;
  service = 'moto';
  modalRef: BsModalRef;
  findCity = false;
  submitted1 = false;
  submitted2 = false;
  precio: any;
  activo: number = 0;
  idser: number = 1;
  info: any;

  nombremultipagos: string;
  telefonomultipagos: string;
  correomultipagos: string;
  nombrecompleto: string;
  moneda: string =  'MXN';

  mySlideOptions = {
    loop: true,
    margin: 10,
    nav: true,
    navClass: ['prev-slide', 'next-slide'],
    navText: ['<', '>'],
    dots: false,
    responsive: {
      0: {
        items: 1
      },
      768: {
        items: 3
      }
    }
  };

  constructor(
    private lollevaService: LoLlevaService,
    private modalService: BsModalService,
    private mensajeService: MensajeService
  ) { }



  ngOnInit() {
    this.lollevaService.getCiudades().subscribe(
      datos => {
        this.ciudades = datos;
      }
    );

    this.colsForm = new FormGroup({
      origen: new FormControl(0, [Validators.required, MyValidators.isEmpty]),
      destino: new FormControl(0, [Validators.required, MyValidators.isEmpty]),
      transporte: new FormControl('moto', [Validators.required, MyValidators.isEmpty])
    });

    this.datosForm = new FormGroup({
      nombre: new FormControl('', [Validators.required, MyValidators.isEmpty]),
      telefono: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.maxLength(10), MyValidators.isEmpty]),
      correo: new FormControl('', [Validators.email, Validators.required, MyValidators.isEmpty]),
      recibido: new FormControl(false , [Validators.required]),
    })


  }


  ngAfterContentChecked(): void {
    if (!this.findCity) {

      let id;
      Array.from(document.getElementsByClassName('city')).forEach(
        (element) => {
          if (element.textContent === " Cd. Obregon ") {
            this.findCity = true;
            this.selectCiudad(element.id, 'OBR', 0);
          }
        }
      );

    }
  }

  selectCiudad(value, cityId, tipo) {
    Array.from(document.getElementsByClassName('city')).forEach(
      (element) => {
        element.classList.remove('seleccionado');
      }
    );

    // se obtiene la parada seleccionada
    const city = document.getElementById(value) as HTMLElement;
    city.classList.add('seleccionado');

    this.cityId = cityId;
    this.getColonias(this.cityId);
    if (tipo === 1) this.solicitarServicioLog();

  }

  getColonias(suc) {
    this.searchCity = true;
    this.lollevaService.getColonias(suc).subscribe(
      datos => {
        this.colonias = datos;
      }
    )
  }

  solicitarServicio() {
    this.submitted2 = true;
    if (this.datosForm.valid && this.datosForm.value.recibido == true) {

      this.lollevaService.solicitarServicio(this.cityId, this.colsForm.value.origen, this.colsForm.value.destino, this.colsForm.value.transporte === 'moto' ? 1 : 2, this.datosForm.value.nombre.trim(), this.datosForm.value.telefono.trim(), this.datosForm.value.correo.trim(), this.idser).subscribe(
        datos => {
          this.info = datos;
          this.nombremultipagos = this.datosForm.value.nombre;
          this.correomultipagos = this.datosForm.value.correo;
          this.telefonomultipagos = this.datosForm.value.telefono;
          this.datosForm.reset();
          this.colsForm.controls.origen.setValue('');
          this.colsForm.controls.destino.setValue('');
          this.colsForm.controls.transporte.setValue('moto');


          this.submitted1 = false;
          this.submitted2 = false;
          this.closeModal();
          const btn = document.getElementById('btn-modal2') as HTMLElement;
          btn.click();
        }
      );

    }
  }

  solicitarServicioLog() {
    this.lollevaService.solicitarServicioLog(this.cityId).subscribe(datos =>
       console.log(datos));
  }

  checkCols() {
    this.submitted1 = true;

    if (this.colsForm.valid) {
      if (this.colsForm.value.origen !== 0 && this.colsForm.value.destino !== 0) {
        const btn = document.getElementById('btn-modal') as HTMLElement;
        btn.click();
        this.cotizar(this.cityId, this.colsForm.value.origen, this.colsForm.value.destino, this.colsForm.value.transporte === 'moto' ? 1 : 2);
      }
    }

  }

  handleChange(value) {
    this.service = value;
    this.cambiarservicio(0,1);
  }

  handleChangeCam(value) {
    this.service = value;
    this.cambiarservicio(0,2);
  }


  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  closeModal() {
    this.modalRef.hide();
  }

  cotizar(suc, origen, destino, tpu) {
    this.lollevaService.getCotizacion(suc, origen, destino, tpu).subscribe(
      datos => {
        this.precio = datos;
        this.precio = this.precio.costos;
      }
    );
  }

  cambiarservicio(id, idseraux) {
    this.activo = id;
    this.idser = idseraux;

  }


  async agregarpmultipagos() {

    let RsvIda = this.info.ireferencia,  // declara la variable con el codigo de reservacion de ida
      RsvReg = '',                              // declara la variable de reservacion de regreso
      Total =this.precio[this.activo].Costo;       // total de la reservacion

    let QueryString = 'CodigoReserv=' + RsvIda + '&'; // declarar la variable con todos los parametros y agregamos la reservacion de ia

    if (parseInt(RsvReg) === 0 || RsvReg === '') { // si no hay reservacion de regreso
      QueryString += 'CodigoReservReg=' + RsvIda + '&';
    } else {
      QueryString += 'CodigoReservReg=' + RsvReg + '&';
    } // si no pues la de regreso

    QueryString += 't_servicio=099&Total=' + Total + '&'; // t_servicio es fijo, y agregar el total de la reservacion

    QueryString += 'idioma=1&'; // idioma es fijo en 1

    let moneda = 0; // variable para mandar el tipo de moneda

    if (this.moneda === 'MXN') {  // si la corrida de ida es en pesos
      QueryString += 'moneda=1&'; // la moneda es 1
      moneda = 1;
    } else {
      QueryString += 'moneda=2&'; // si no es dos
      moneda = 2;
    }
    QueryString += 'Email=' + this.correomultipagos; // agregar el correo
    window.location.href = encodeURI(environment.URLProcesapagoMultipagos + '?' + QueryString); // aqui se manda a redireccionar a la pagina de pago

  }
}
