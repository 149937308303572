
import { Component,OnInit,TemplateRef,ViewChild,ElementRef } from '@angular/core';
import { FormGroup,FormBuilder,Validators,FormControl } from '@angular/forms';
 import { pagosService } from 'src/app/servicios/pagos.service';
import { CustomValidators } from 'src/app/custom-validators';
import { environment } from 'src/environments/environment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { Router } from '@angular/router';
import { Mensaje } from 'src/app/entidades/mensaje';
import { MensajeService } from 'src/app/servicios/mensaje.service';
import { MensajePopup } from 'src/app/entidades/MensajePopup';
import { Tools } from 'src/app/entidades/tools';
import { LoadingService } from 'src/app/servicios/loading.service';
import { MobilPayment } from 'src/app/entidades/mobil-payment';
import { LocalstorageService } from 'src/app/servicios/localstorage.service';
import { MensajeWeb } from 'src/app/entidades/mensaje-web';
import { ActivatedRoute, Params } from '@angular/router';
import { user } from "../../entidades/gwuser";
import jwt_decode from "jwt-decode";


@Component({
  selector: 'app-captura-pago',
  templateUrl: './captura-pago.component.html',
  styleUrls: ['./captura-pago.component.css']
})
export class CapturaPagoComponent implements OnInit {
  conektaForm: FormGroup;
  submitted = false;
  modalRef: BsModalRef;
  emailconekta: string;
  telefono = 'Teléfono';
  correo = 'Correo';
  nombreCnk: string;
  apellidocnk: string;
  terminosConekta: string;
  key: string;
  estaGuardado: boolean;
  tarjeta: string;
  nombre: string;
  nombrecompleto: string;
  mensaje: Mensaje;
  datosPago: MobilPayment;

  listaPaisCodigoTel = environment.listaPaisCodigoTel;
  paisSelected = '+52 MX';
  codigoTel: any;
  telefonoCnk: string ="";

  ticket: string= "";
  idrefer: number= 0;
  moneda: string="";
  publicidad: boolean;
  referencia: any;

userGw: user;
datagw: any;
token: string;
tokendatos: any;
idcliente: number=-1;
isLoguedin: boolean;

  datosTransaccion = 
  {
    id: 0,
    costo: "",
    numGuias: 0,
    tipo: "",
    numAutorizacion: ""
  };


  constructor(    
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
    private pagosservice: pagosService,
    private router: Router,
    private mensajeService: MensajeService,
    private loadingService: LoadingService,
    private localStorageService: LocalstorageService,
    private oprVentaWeb: pagosService,    
    private activateRoute: ActivatedRoute){

      
        this.activateRoute.queryParams.subscribe(params => {
          try{
            this.referencia = params['rf'];
            this.datosTransaccion = JSON.parse(atob(this.referencia));
          }
          catch (e){
            //redirige a login
            this.router.navigate(['**']);
          }
        });
    }

  ngOnInit(): void {
    if(sessionStorage.getItem("token")){
      this.token = sessionStorage.getItem("token");
      this.tokendatos = jwt_decode(this.token);
      this.isLoguedin = true;
      this.userGw = this.tokendatos;
      this.idcliente = this.userGw.idUser;
    }
     else {
      this.isLoguedin = false;
      this.router.navigate(["/herramientasDigitales/login"]);
    }
    
    this.conektaForm = this.formBuilder.group({

      cnknombre: [
        '',
        [Validators.required, CustomValidators.noWhitespaceValidator],
      ],

      cnkapellido: [
        '',
        [Validators.required, CustomValidators.noWhitespaceValidator],
      ],

      cnkcorreo: [
        '',
        [
          Validators.email,
          Validators.required,
          CustomValidators.noWhitespaceValidator,
        ],
      ],
      cnktelefono: [
        '',
        [
          Validators.required, Validators.pattern("^[0-9]*$"),
          Validators.minLength(10),
          Validators.maxLength(10),
          CustomValidators.noWhitespaceValidator,
        ],
      ],
      cnktarjhambiente: [
        '',
        [Validators.required, CustomValidators.noWhitespaceValidator],
      ],
      cnktarjbancaria: [
        '',
        [
          Validators.required, Validators.pattern("^[0-9]*$"),
          Validators.minLength(15),
          Validators.maxLength(16),
          CustomValidators.noWhitespaceValidator,
        ],
      ],
      cnkmes: ['', [Validators.required, Validators.maxLength(2)]],
      cnkano: ['', [Validators.required, Validators.maxLength(4)]],
      cnkcvc: ['', [Validators.required, Validators.maxLength(4)]],
      cnkcalle: [
        '',
  
      ],
      cnkcolonia: [
        '',

      ],
      cnkciudad: [
        '',

      ],
      cnkestado: [
        '',
  
      ],

      cnkcp: [
        '',
        [

        ],
      ],
      cnkpais: [
        '',

      ],
      terminosConekta: [true],
    });
   }

 

 


  cambiarFocus(e, id) {
    if (e.keyCode === 13) {
      e.preventDefault();
      id.focus();
    }
  }
  getlanguage(): any {
    return environment.language;
  }

  closeModal() {
    this.modalRef.hide();
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  goTerms(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  mostrarError() {
    const err = <HTMLInputElement>document.getElementById('conektaErros');

    this.mensajeService.subject$.next(
      new MensajePopup(err.innerText, false, false)
    );
  }

  get f() {
    return this.conektaForm.controls;
  }



  onKeydown(event) {
    let input = this.f.cnktelefono.value;
    input = input.replace(/[\W\s\._\-]+/g, '');

    if (!new Tools().isValidKeyNumber(event)) {
      event.preventDefault();
      return;
    }
  }

  procesapago(numref) {

   
  
    let tokid: string;
    const htmltokenid = <HTMLInputElement>(
      document.getElementById('conektaTokenId')
    );

    // 15 OBTENER EL TOKKENID QUE SE GENERO DEL JS
    
    tokid = htmltokenid.value;
 
    
    const cnknombre = this.f.cnknombre.value;
    const cnkapellido = this.f.cnkapellido.value;
    const cnkcorreo = this.f.cnkcorreo.value;
    const cnktelefono = this.f.cnktelefono.value;
    const cnktarjhambiente = this.f.cnktarjhambiente.value;
    const cnktarjbancaria = this.f.cnktarjbancaria.value;
    const cnkmes = this.f.cnkmes.value;
    const cnkano = this.f.cnkano.value;
    const cnkcvc = this.f.cnkcvc.value;
    const cnkcalle = this.f.cnkcalle.value;
    const cnkcolonia = this.f.cnkcolonia.value;
    const cnkciudad = this.f.cnkciudad.value;
    const cnkestado = this.f.cnkestado.value;
    const cnkcp = this.f.cnkcp.value;
    const cnkpais = this.f.cnkpais.value;

    // this.loadingService.changeLoadingFijo(true);
    const btn = <HTMLInputElement>document.getElementById('idconekta');
    btn.disabled = true;



    // 16 EJECUTAR BACKEND PARA PROCESAR EL PAGO CONEKTA
    this.pagosservice
      .procesarpagoconekta(
        numref,
        numref,
        tokid,
        cnkcorreo,
        cnktelefono,
        false,
        "ES",
        cnknombre,
        cnktarjbancaria,
        true
      )
      .then(
        (msjWeb) => {
          if (msjWeb.id > 0) {
            
            this.datosTransaccion.id = msjWeb.id;
            this.datosTransaccion.numAutorizacion = msjWeb.msj;
            // 18 MANDAR A CONFIRMACION DE COMPRA
            var cadpago="";
            cadpago =  btoa( JSON.stringify (this.datosTransaccion));
            this.router.navigate(["/confirmacion"],{queryParams:{rf:cadpago}});
            // console.log("Servicio exitoso");

          } else {
            if (btn) {
              btn.disabled = false;
            }
            this.mensajeService.subject$.next(
              new MensajePopup(msjWeb.msj, false, false)
            );
          }
        },
        (error) => {
          if (btn) {
            btn.disabled = false;
          }
          this.mensajeService.subject$.next(
            new MensajePopup(
              'Lo sentimos, estamos fuera de servicio.',
              false,
              false
            )
          );
        }
      );
  }
  
  generartoken(){
    this.codigoTel = this.paisSelected.replace(/[^0-9-]/g, '');
    let btn = <HTMLInputElement>document.getElementById('idconekta');
    btn.disabled = true;

         this.submitted = true;
       // OBTENER DATOS DEL FORM
       this.nombreCnk = this.f.cnknombre.value;
       this.apellidocnk = this.f.cnkapellido.value;
       this.emailconekta = this.f.cnkcorreo.value;
       this.telefono = this.f.cnktelefono.value;
       this.tarjeta = this.f.cnktarjbancaria.value;
       this.nombre = this.f.cnktarjhambiente.value;
       
    //validacion del form
    if (this.conektaForm.invalid) {
      btn.disabled = false;
      let input: HTMLElement;

      if (this.conektaForm.controls.cnknombre.invalid) {
        input = document.getElementsByName('cnknombre')[0];
      } else if (this.conektaForm.controls.cnkapellido.invalid) {
        input = document.getElementsByName('cnkapellido')[0];
      } else if (this.conektaForm.controls.cnkcorreo.invalid) {
        input = document.getElementsByName('cnkcorreo')[0];
      } else if (this.conektaForm.controls.cnktelefono.invalid) {
        input = document.getElementsByName('cnktelefono')[0];
      } else if (this.conektaForm.controls.cnktarjhambiente.invalid) {
        input = document.getElementsByName('cnktarjhambiente')[0];
      } else if (this.conektaForm.controls.cnktarjbancaria.invalid) {
        input = document.getElementsByName('cnktarjbancaria')[0];
      } else if (this.conektaForm.controls.cnkmes.invalid) {
        input = document.getElementsByName('cnkmes')[0];
      } else if (this.conektaForm.controls.cnkano.invalid) {
        input = document.getElementsByName('cnkano')[0];
      } else if (this.conektaForm.controls.cnkcvc.invalid) {
        input = document.getElementsByName('cnkcvc')[0];
      } else if (this.conektaForm.controls.cnkcalle.invalid) {
        input = document.getElementsByName('cnkcalle')[0];
      } else if (this.conektaForm.controls.cnkcolonia.invalid) {
        input = document.getElementsByName('cnkcolonia')[0];
      } else if (this.conektaForm.controls.cnkciudad.invalid) {
        input = document.getElementsByName('cnkciudad')[0];
      } else if (this.conektaForm.controls.cnkestado.invalid) {
        input = document.getElementsByName('cnkestado')[0];
      } else if (this.conektaForm.controls.cnkcp.invalid) {
        input = document.getElementsByName('cnkcp')[0];
      } else if (this.conektaForm.controls.cnkpais.invalid) {
        input = document.getElementsByName('cnkpais')[0];
      } else if (this.conektaForm.controls.terminosConekta.invalid) {
        input = document.getElementsByName('terminosConekta')[0];
      }

      if (input) {
        input.focus();
      }

      return;
    }

      this.submitted = false;

    const hdn = <HTMLInputElement>document.getElementById('conektapk');
    hdn.value = environment.conektapk;
    
    // 14 PROCESAR EL PAGO
    const btnGetToken = document.getElementById('ConektaGetToken') as HTMLInputElement;
    btnGetToken.click();
   }


   oprGetVentaWeb(){
    this.loadingService.changeLoading(true);
       // OBTENER DATOS DEL FORM
       this.nombreCnk = this.f.cnknombre.value;
       this.apellidocnk = this.f.cnkapellido.value;
       this.emailconekta = this.f.cnkcorreo.value;
       this.telefono = this.f.cnktelefono.value;
       this.tarjeta = this.f.cnktarjbancaria.value;
       this.nombre = this.f.cnktarjhambiente.value;

    if(sessionStorage.getItem("token")){
      this.token = sessionStorage.getItem("token");
      this.tokendatos = jwt_decode(this.token);
      this.userGw = this.tokendatos;
      this.idcliente = this.userGw.idUser;
      
    }
    try {
      // 4 GUARDAR venta en base de datos
      this.pagosservice
        .oprVentaWeb(
          'ALT',
          '0',
          this.datosTransaccion.id.toString(),
          '0',
          this.datosTransaccion.tipo,
          'CNK',
          this.idcliente.toString(),
          '0',
          this.telefonoCnk,
          this.emailconekta,
          this.datosTransaccion.costo,
          'TRJ',
          '0'
        )
        .then (
          (msjWeb) => {
            if (msjWeb.nummsj > 0) {
              

              //Aqui llamar al procesapago
              this.procesapago(msjWeb.refmsj);

            } else {
             
              this.mensajeService.subject$.next(
                new MensajePopup(msjWeb.txtmsj, false, false)
              );
            }
          },
          (error) => {
           
            this.mensajeService.subject$.next(
              new MensajePopup(
                'Lo sentimos, la transaccion no pudo ser procesada.',
                false,
                false
              )
            );
          }
        );
    } catch (e) {
   
    }
    this.loadingService.changeLoading(false);
   }

  

   getSubmitted() {
    return this.submitted;
  }
 }
