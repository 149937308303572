<div class="container_herramienta">
    <!-- <img src="assets/img/web/guias_web.webp" class="img_toAnimate"> -->
    <!-- <img src="assets/img/web/guias_web.webp" class="img_toAnimate2"> -->
    <div class="alinearCenter">
        <div class="container_img">
            <img class="img_cell" src="assets/img/promociones/celular-guiasdigitales.webp">
            <h3 class="title">Guias Digitales</h3>
            <img class="img_paq" src="assets/iconos/info-util/icon_tipsSeg.webp">
        </div>

        <div class="container_info">
            <div class="promo_boxes">
                <!-- <div class="box_promo" *ngFor="let guia of guiasPaquetes" (click)="obtenerDetalle(guia.id)">
                    <p class="num_guias">{{guia.numGuias}}</p>
                    <p class="txt_mini">{{guia.nombre}}</p>
                    <p class="preciopack">${{guia.costo}} <span class="mxn">MXN</span></p>
                    <p class="txt_mini">{{guia.descripcion1}}</p>
                    <p class="txt_mini" style="font-size: 8px;">{{guia.descripcion2}}</p>
                </div> -->


                        <!-- <div id="myCarousel" class="carousel slide" data-bs-ride="carousel">
                          <div class="carousel-inner" role="listbox">
                            <div class="carousel-item" *ngFor="let guia of guiasPaquetes; let i = index" [ngClass]="{'active': i === 0}">

                                        <div class="box_promo" (click)="obtenerDetalle(guia.id)">
                                            <p class="num_guias">{{guia.numGuias}}</p>
                                            <p class="txt_mini">{{guia.nombre}}</p>
                                            <p class="preciopack">${{guia.costo}} <span class="mxn">MXN</span></p>
                                            <p class="txt_mini">{{guia.descripcion1}}</p>
                                            <p class="txt_mini" style="font-size: 8px;">{{guia.descripcion2}}</p>
                                        </div>

                            </div>
                          </div>
                          <a class="carousel-control-prev bg-transparent w-aut" href="#myCarousel" role="button" data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                          </a>
                          <a class="carousel-control-next bg-transparent w-aut" href="#myCarousel" role="button" data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                          </a>
                        </div> -->


                        <div class="container-fluid">
                            <div id="carousel-example" class="carousel slide " data-ride="carousel" data-interval="false">
                                <div class="carousel-inner row w-100 mx-auto" role="listbox">
                                    <div class="carousel-item col-12 col-sm-6 col-md-4 col-lg-4" *ngFor="let guia of guiasPaquetes; let i = index" [ngClass]="{'active': i === 0}">
                                        <div class="box_promo" (click)="obtenerDetalle(guia.id)" [id]="'guia ' + guia.descripcion1">
                                            <p class="num_guias">{{guia.numGuias}}</p>
                                            <p class="txt_mini">{{guia.nombre}}</p>
                                            <p class="preciopack">{{ formatearCosto(guia.costo) }} <span class="mxn">MXN</span></p>
                                            <p class="txt_mini">{{guia.descripcion1}}</p>
                                            <p class="txt_mini" style="font-size: 8px;">{{guia.descripcion2}}</p>
                                        </div>
                                    </div>
                                </div>
                                <a class="carousel-control-prev" href="#carousel-example" role="button" data-slide="prev">
                                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span class="sr-only">Previous</span>
                                </a>
                                <a class="carousel-control-next" href="#carousel-example" role="button" data-slide="next">
                                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span class="sr-only">Next</span>
                                </a>
                            </div>
                        </div>

            </div>



            <p class="txt_info">Paquete de <span class="numGuias">{{guiasDescripcion.numGuias}}</span> guias</p>
            <p class="txt_detalle">
                {{guiasDescripcion.detalle}}
            </p>
                <p class="txt_detalle">Beneficios:</p>
            <div class="container_lista">
            <ul class="listado" *ngFor="let guia of guiasDescripcion.beneficios">
                <li>{{guia}}</li>
            </ul>
            </div>
            <div class="cont_promo">
                <p class="txt_promo">{{formatearCosto(guiasDescripcion.costo)}} <span class="mxn2">MXN</span> <br><p class="txt_numGuias">x {{guiasDescripcion.numGuias}}</p></p>
                <button class="btn_comprar" (click)="comprarGuias()">Comprar</button>
            </div>
        </div>

    </div>

</div>
