<div [formGroup]="heroForm" class="container-bar" style="z-index: 2;">
	<div class="icon-social">
		<a (click)="opMenu(2)" class="fa-solid fa-box" id="opt_rastreo">
			<span id="title">Rastreo</span>
		</a>
		<a (click)="opMenu(0)" class="fa-solid fa-calculator" id="opt_cotizar">
			<span id="title">Cotizar</span>
		</a>
		<a (click)="opMenu(1)" class="fa-solid fa-people-carry-box" id="opt_recoleccion">
			<span id="title">Recolección</span>
		</a>
		<a (click)="opMenu(3)" class="fa-solid fa-percent" id="opt_promociones">
			<span id="title">Promociones</span>
		</a>
		<a (click)="opMenu(4)" class="fa-solid fa-arrow-right-to-bracket" id="opt_login">
			<span id="title">Acceso</span>
		</a>
		<a (click)="opMenu(5)" class="fa-solid fa-location-dot" style="border-radius: 0 0 10px 0" id="opt_cobertura">
			<span id="title">Cobertura</span>
		</a>
	</div>
</div>

<div style="z-index: 0;" *ngIf="selected == 'rastreo' ">
	<app-rastreo></app-rastreo>
</div>
<div style="z-index: 0;" *ngIf="selected == 'cotizacion' ">
	<app-cotizacion-form></app-cotizacion-form>
</div>
<div style="z-index: 0;" *ngIf="selected == 'recoleccion' ">
	<app-paqueteria-recoleccion></app-paqueteria-recoleccion>
</div>
<div style="z-index: 0;" *ngIf="selected == 'promociones' ">
	<app-promociones></app-promociones>
</div>

<div style="z-index: 0;" *ngIf="selected == 'login' ">
	<app-gw-user-login></app-gw-user-login>
</div>

<div style="z-index: 0;" *ngIf="selected == 'cobertura' ">
	<app-cobertura></app-cobertura>
</div>


