<div class="confirmacion-container desk">
    <div class="confirmacion-body">
        <div class="img-container">
            <img   (click)="cambiardepantalla(2)" [ngClass]="{imgmenugris: Remitente == true}" width="100%" height="100%" class="lazyload imgmenu" data-src='assets/iconos/guiasweb/usuario.png' alt="Promociones"/>
        </div>
        <div >
            <span  (click)="cambiardepantalla(2)" [ngClass]="{disponible: Remitente == true}"  class="confirmacion-title ngcla ">Remitente</span>
        </div>
        <div class="col step-seat dot">
            <p>
                <span >
                    <span [ngClass]="{dispSpan: destinatario == true}"  class="dots"></span>
                    <span [ngClass]="{dispSpan: destinatario == true}"  class="dots"></span>
                 
                </span>
            </p>
        </div>
        <div class="img-container" >
             <img (click)="cambiardepantalla(3)"  [ngClass]="{imgmenugris: destinatario == true}" width="100%" height="100%" class="lazyload imgmenu" data-src='assets/iconos/guiasweb/usrnara.png' alt="Promociones"/>
        </div>
        <div>
            <span (click)="cambiardepantalla(3)" [ngClass]="{disponible: destinatario == true}" class="confirmacion-title">Destinatario</span>
        </div>
        <div class="col step-seat dot">
            <p >
                <span>
                    <span [ngClass]="{dispSpan: tipo == true}"  class="dots"></span>
                    <span [ngClass]="{dispSpan: tipo == true}"  class="dots"></span>
                 
                </span>
            </p>
        </div>
        <div class="img-container">
            <img  (click)="cambiardepantalla(4)" [ngClass]="{imgmenugris: tipo == true}" width="100%" height="100%" class="lazyload imgmenu" data-src='assets/iconos/guiasweb/mtipo.png' alt="Promociones"/>
        </div>
        <div>
            <span (click)="cambiardepantalla(4)" [ngClass]="{disponible: tipo == true}"   class="confirmacion-title">Tipo</span>
        </div>
        <div class="col step-seat dot">
            <p >
                <span >
                    <span [ngClass]="{dispSpan: detalle == true}"  class="dots"></span>
                    <span [ngClass]="{dispSpan: detalle == true}"  class="dots"></span>
                </span>
            </p>
        </div>
        <div class="img-container">
            <img  (click)="cambiardepantalla(5)" [ngClass]="{imgmenugris: detalle == true}" width="100%" height="100%" class="lazyload imgmenu" data-src='assets/iconos/guiasweb/detalle1.png' alt="Promociones"/>
        </div>
        <div>
            <span (click)="cambiardepantalla(5)" [ngClass]="{disponible: detalle == true}"  class="confirmacion-title">Detalle</span>
        </div>
        <div class="col step-seat dot">
            <p >
                <span >
                    <span [ngClass]="{dispSpan: confirmacion == true}"  class="dots"></span>
                    <span [ngClass]="{dispSpan: confirmacion == true}"  class="dots"></span>
                    
                </span>
            </p>
        </div>
        <div class="img-container">
            <img  (click)="cambiardepantalla(6)" [ngClass]="{imgmenugris: confirmacion == true}" width="100%" height="100%" class="lazyload imgmenu" data-src='assets/iconos/guiasweb/mconfirmacion.png' alt="Promociones"/>
        </div>
        <div>
            <span (click)="cambiardepantalla(6)" [ngClass]="{disponible: confirmacion == true}" class="confirmacion-title">Confirmación</span>
        </div>
    </div>
</div>



<!-- mobile -->

<div class="confirmacion-container mobile">
    <div class="confirmacion-body">
        <div class="img-container">
            <img [ngClass]="{imgmenugris: Remitente == true}" (click)="cambiardepantalla(2)" width="100%" height="100%" class="lazyload imgmenu" data-src='assets/iconos/guiasweb/usuario.png' alt="Promociones"/>
        </div>
        
        <div class="col step-seat dot">
            <p >
                <span >
                     <span [ngClass]="{dispSpan: destinatario == true}"  class="dots"></span>
                     <span [ngClass]="{dispSpan: destinatario == true}"  class="dots"></span>
                     <span [ngClass]="{dispSpan: destinatario == true}"  class="dots"></span>
                     
                </span>
            </p>
        </div>
        <div class="img-container">
            <img  [ngClass]="{imgmenugris: destinatario == true}" (click)="cambiardepantalla(3)" width="100%" height="100%" class="lazyload imgmenu" data-src='assets/iconos/guiasweb/usrnara.png' alt="Promociones"/>
        </div>
        <div class="col step-seat dot">
            <p >
                <span >
                     <span [ngClass]="{dispSpan: tipo == true}"  class="dots"></span>
                     <span [ngClass]="{dispSpan: tipo == true}"  class="dots"></span>
                     <span [ngClass]="{dispSpan: tipo == true}"  class="dots"></span>
                     
                </span>
            </p>
        </div>
        <div class="img-container">
            <img [ngClass]="{imgmenugris: tipo == true}" (click)="cambiardepantalla(4)" width="100%" height="100%" class="lazyload imgmenu" data-src='assets/iconos/guiasweb/mtipo.png' alt="Promociones"/>
        </div>

       
        <div class="col step-seat dot">
            <p >
                <span >
                     <span [ngClass]="{dispSpan: detalle == true}"  class="dots"></span>
                     <span [ngClass]="{dispSpan: detalle == true}"  class="dots"></span>
                     <span [ngClass]="{dispSpan: detalle == true}"  class="dots"></span>
                </span>
            </p>
        </div>
        <div class="img-container">
            <img  [ngClass]="{imgmenugris: detalle == true}" (click)="cambiardepantalla(5)" width="100%" height="100%" class="lazyload imgmenu" data-src='assets/iconos/guiasweb/detalle1.png' alt="Promociones"/>
        </div>
       
        <div class="col step-seat dot">
            <p >
                <span >
                     <span [ngClass]="{dispSpan: confirmacion == true}"  class="dots"></span>
                     <span [ngClass]="{dispSpan: confirmacion == true}"  class="dots"></span>
                     
                     <span [ngClass]="{dispSpan: confirmacion == true}"  class="dots"></span>
                </span>
            </p>
        </div>
        <div class="img-container">
            <img [ngClass]="{imgmenugris: confirmacion == true}" (click)="cambiardepantalla(6)" width="100%" height="100%" class="lazyload imgmenu" data-src='assets/iconos/guiasweb/mconfirmacion.png' alt="Promociones"/>
        </div>
        
    </div>
</div>

