 <div class="container_all">
    <div class="main-container">
        <ng-container *ngIf="isConfirm; else elseTemplate">
            <div class='confirmacion' id="vistaConfirmacion">
                <div class='titu'>
                    <div class="wrapper"> <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"> <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/> <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                    </svg>
                    </div>
                    <h1>Transacción exitosa</h1>

                    <div class="form">
                        <p class="lbl-text">Fecha: {{fechaCompra}}</p>
                    </div>
                    <div class="form">
                        <p class="lbl-text">Folio: {{datosTransaccion.id}}</p>
                        <p class="lbl-text">Num. de autorización: {{datosTransaccion.numAutorizacion}}</p>
                    </div>
                </div>
                <label class="Ctexto"> Datos del cliente</label>
                <h3 class="Ctexto2"> {{objectdata.nombre}}
                    <br> 
                    <span> {{objectdata.telefono}}</span>
                    <br> 
                    <span> {{objectdata.correo}}</span>
                </h3>

                <label class="Ctexto">Detalle de la compra</label>
                <h3 class="Ctexto2">Numero de guias : <span>{{datosTransaccion.numGuias}}</span>
                <br>
                <span>Incluye: {{datosTransaccion.detalle}}</span>
                </h3>
                <div class="titulo">
                    <p>Total:</p>
                    <p class='importe2'>${{datosTransaccion.costo}} MXN</p>
                </div>
                <div>
                    <div class="cont_check">
                        <p>Solicitar factura</p>
                        <div class="switch" id="switchFact" (click)="cambiarvalor()">
                            <div class="slider" id="circle"></div>
                        </div>
                    </div>
                </div>
                <div class="container_form" id="fiscaldata">
                <form [formGroup]="formulario">
                <div class="datosfiscales" >
                    <h2>Datos fiscales</h2>
                
                    <div class="editabledata" id="readonly">
                    <div class="frm-element">
                        <label>Razón Social</label>
                        <input formControlName="razonsocial" maxlength="100" type="text" [(ngModel)]="objectdata.reazon">
                    </div>
                    <div class="frm-element rfc">
                        <label>RFC</label>
                        <input formControlName="rfc" maxlength="100" type="text" [(ngModel)]="objectdata.rfc">
                    </div>
                    
                    <div class="frm-element domfisc">
                        <label>Domicilio Fiscal</label>
                        <input formControlName="domiciliofiscal" maxlength="100" type="text" [(ngModel)]="objectdata.domfisc">
                    </div>

                    <div class="frm-element">
                        <label>Régimen fiscal:</label>
                        <select class="slct_regimen" id="regimen" name="regimenes" id="no-select" [(ngModel)]="objectdata.regimenfiscal" formControlName="regimenfiscal">
                            <option value="601">601-General de Ley Personas Morales</option>
                            <option value="603">603-Personas Morales con Fines no Lucrativos</option>
                            <option value="605">605-Sueldos y Salarios e Ingresos Asimilados a Salarios</option>
                            <option value="606">606-Arrendamiento</option>
                            <option value="607">607-Régimen de Enajenación o Adquisición de Bienes</option>
                            <option value="608">608-Demás ingresos</option>
                            <option value="610">610-Residentes en el Extranjero sin Establecimiento Permanente en México</option>
                            <option value="611">611-Ingresos por Dividendos (socios y accionistas)</option>
                            <option value="612">612-Personas Físicas con Actividades Empresariales y Profesionales</option>
                            <option value="614">614-Ingresos por intereses</option>
                            <option value="615">615-Régimen de los ingresos por obtención de premios</option>
                            <option value="616">616-Sin obligaciones fiscales</option>
                            <option value="620">620-Sociedades Cooperativas de Producción que optan por diferir sus ingresos</option>
                            <option value="621">621-Incorporación Fiscal</option>
                            <option value="622">622-Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras</option>
                            <option value="623">623-Opcional para Grupos de Sociedades</option>
                            <option value="624">624-Coordinados</option>
                            <option value="625">625-Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas</option>
                            <option value="626">626-Régimen Simplificado de Confianza</option>
                        </select>
                    </div>
                </div>
                </div>
                </form>
            </div>  

              
                <button type="submit"  class="btn-next" id="btn_facturar" (click)="facturarVenta()">Facturar</button>
                <button type="submit"  class="btn-next" id="btn_guia1" (click)="crearpdf()">Descargar Guia</button>
                <div class="div-terminos">

                    <a class="terminos" routerLink="/informacion/avisoPrivacidad">Aviso de privacidad </a>
                    <a class="terminos" routerLink="/contrato/adhesion">Contrato de Adhesión</a>
                </div>
            </div>
        </ng-container>


        <!-- SI LA FACTURA YA FUE EMITIDA, ENTRA A ESTE TEMPLATE --> 
       <ng-template #elseTemplate>
            <div id="compraFacturada">
            <div class='confirmacion'>
                <div class='titu'>
                    
                    <h1>Historial de compra facturada</h1>
                  
                    <div class="form">
                        <p class="lbl-text">Fecha: {{fechaCompra}}</p>
                    </div>
                    <div class="form">
                        <p class="lbl-text">Folio: {{datosTransaccion.id}}</p>
                        <p class="lbl-text">Num. de autorización: {{datosTransaccion.numAutorizacion}}</p>
                    </div>
                </div>
                <label class="Ctexto"> Datos del cliente</label>
                <h3 class="Ctexto2"> {{objectdata.nombre}}
                    <br> 
                    <span> {{objectdata.telefono}}</span>
                    <br> 
                    <span> {{objectdata.correo}}</span>
                </h3>
               

                <label class="Ctexto">Detalle de la compra</label>
                <h3 class="Ctexto2">Numero de guias : <span>{{datosTransaccion.numGuias}}</span>
                <br>
                <span>Incluye: {{datosTransaccion.detalle}}</span>
                </h3>
            
                
                <div class="titulo">
                    <p>Total:</p>
                    <p class='importe2'>${{datosTransaccion.costo}} MXN</p>
                </div>

                <div class="datosFacturas">
                    <h2>Obten tu factura</h2>
   
                    <a href="{{descargaxml}}" Target="_blank"><button class="btn_getFactura">Descargar XML</button></a>
                    <a href="{{descargaPdf}}" Target="_blank"><button class="btn_getFactura">Descargar PDF</button></a>
                    <button type="submit"  class="btn-next" id="btn_guia2" (click)="crearpdf()">Descargar Guia</button>
          
                </div>
                
                <div class="div-terminos">
                    <a class="terminos" routerLink="/informacion/avisoPrivacidad">Aviso de privacidad </a>
                    <a class="terminos" routerLink="/contrato/adhesion">Contrato de Adhesión</a>
                </div>
            </div>
        </div>
        </ng-template>



        <div class="compraRealizada" id="compra_noFacturada">
            <div class='confirmacion'>
                <div class='titu'>
                    
                    <h1>Historial de la compra</h1>
                
                    <div class="form">
                        <p class="lbl-text">Fecha: {{fechaCompra}}</p>
                    </div>
                    <div class="form">
                        <p class="lbl-text">Folio: {{datosTransaccion.id}}</p>
                        <p class="lbl-text">Num. de autorización: {{datosTransaccion.numAutorizacion}}</p>
                    </div>
                </div>
                <label class="Ctexto"> Datos del cliente</label>
                <h3 class="Ctexto2"> {{objectdata.nombre}}
                    <br> 
                    <span> {{objectdata.telefono}}</span>
                    <br> 
                    <span> {{objectdata.correo}}</span>
                </h3>

                <label class="Ctexto">Detalle de la compra</label>
                <h3 class="Ctexto2">Numero de guias : <span>{{datosTransaccion.numGuias}}</span>
                <br>
                <span>Incluye: {{datosTransaccion.detalle}}</span>
                </h3>
            
 
                
                <div class="titulo">
                    <p>Total:</p>
                    <p class='importe2'>${{datosTransaccion.costo}} MXN</p>
                </div>
                <div>
                    <div class="cont_check">
                        <p>Solicitar factura</p>
                        <div class="switch2" id="switchFact2" (click)="cambiarvalor2()">
                            <div class="slider2" id="circle2"></div>
                        </div>
                    </div>
                </div>
                <div class="container_form" id="fiscaldata2">
                <form [formGroup]="formulario">
                <div class="datosfiscales" >
                    <h2>Datos fiscales</h2>
                
                    <div class="editabledata" id="readonly">
                    <div class="frm-element">
                        <label>Razón Social</label>
                        <input formControlName="razonsocial" maxlength="100" type="text" [(ngModel)]="objectdata.reazon">
                    </div>
                    <div class="frm-element rfc">
                        <label>RFC</label>
                        <input formControlName="rfc" maxlength="100" type="text" [(ngModel)]="objectdata.rfc">
                    </div>
                    
                    <div class="frm-element domfisc">
                        <label>Domicilio Fiscal</label>
                        <input formControlName="domiciliofiscal" maxlength="100" type="text" [(ngModel)]="objectdata.domfisc">
                    </div>

                    <div class="frm-element">
                        <label>Régimen fiscal:</label>
                        <select class="slct_regimen" id="regimen" name="regimenes" id="no-select" [(ngModel)]="objectdata.regimenfiscal" formControlName="regimenfiscal">
                            <option value="601">601-General de Ley Personas Morales</option>
                            <option value="603">603-Personas Morales con Fines no Lucrativos</option>
                            <option value="605">605-Sueldos y Salarios e Ingresos Asimilados a Salarios</option>
                            <option value="606">606-Arrendamiento</option>
                            <option value="607">607-Régimen de Enajenación o Adquisición de Bienes</option>
                            <option value="608">608-Demás ingresos</option>
                            <option value="610">610-Residentes en el Extranjero sin Establecimiento Permanente en México</option>
                            <option value="611">611-Ingresos por Dividendos (socios y accionistas)</option>
                            <option value="612">612-Personas Físicas con Actividades Empresariales y Profesionales</option>
                            <option value="614">614-Ingresos por intereses</option>
                            <option value="615">615-Régimen de los ingresos por obtención de premios</option>
                            <option value="616">616-Sin obligaciones fiscales</option>
                            <option value="620">620-Sociedades Cooperativas de Producción que optan por diferir sus ingresos</option>
                            <option value="621">621-Incorporación Fiscal</option>
                            <option value="622">622-Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras</option>
                            <option value="623">623-Opcional para Grupos de Sociedades</option>
                            <option value="624">624-Coordinados</option>
                            <option value="625">625-Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas</option>
                            <option value="626">626-Régimen Simplificado de Confianza</option>
                        </select>
                    </div>
                </div>
                </div>
                </form>
            </div>  

             
                <button type="submit"  class="btn-next" id="btn_facturar2" (click)="facturarVenta()">Facturar</button>
                <button type="submit"  class="btn-next" id="btn_guia3" (click)="crearpdf()">Descargar Guia</button>
                <div class="div-terminos">

                    <a class="terminos" routerLink="/informacion/avisoPrivacidad">Aviso de privacidad </a>
                    <a class="terminos" routerLink="/contrato/adhesion">Contrato de Adhesión</a>
                </div>
            </div>
        </div>

    </div>
</div> 