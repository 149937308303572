<div class="container_herramienta">
    <img src="assets/img/web/delivery.webp" class="img_toAnimate">
    <div class="alinearIzq">
        <h1 class="txt-section">Delivery</h1>
        <p>
            Nuestro servicio especializado enfocado en entregas a domicilio en tiempo, confiable y seguro.
            <br><br>
            Contamos con las mejores herramientas:
            <br><br>
            <b>Traslado de Mercancías:</b> Contamos con rutas foráneas 
            dedicadas a transportar su mercancía hasta su bodega.
            <br><br>
            <b>Primera Milla:</b> Sistema programado de recolecciones para 
            concentrar la mercancía en su bodega o sucursal.
            <br><br>
            <b>Entregas Última Milla:</b> Servicio de entrega dedicado en unidades 
            de reparto, desde su bodega o sucursal hasta el cliente final.
            <br><br>
            <b>Media Milla:</b> Entrega en localidades fuera del centro de 
            distribución.
            <br><br>
            <b>Monitoreo de rutas:</b> Personal especializado en el seguimiento y 
            monitoreo a las rutas de reparto en tiempo real, con el fin de lograr 
            la eficiencia en las entregas.
            <br><br>
            Contamos con cobertura en la zona Pacífico con nuestro servicio de 
            Delivery, desde Baja California, hasta Jalisco.
            <br><br>
            Nuestra estructura presenta el equipo y el personal especializado 
            para realizar sus entregas en el tiempo y momento correcto, así 
            como cubrir cualquier necesidad que pudiera presentar en sus 
            envíos de mercancía, seguimiento y monitoreo, con la seguridad que 
            nuestra empresa respalda.
        </p>
    </div>
</div>