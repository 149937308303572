import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { GuiaswebServiceService } from '../../servicios/guiasweb-service.service'
import { HttpErrorResponse } from '@angular/common/http';
import { MensajePopup } from 'src/app/entidades/MensajePopup';
import { MensajeService } from 'src/app/servicios/mensaje.service';
import { Router } from '@angular/router';
import {cuentaverificada } from '../../entidades/cuentaverificada'

@Component({
  selector: 'app-gw-restablecerpassword',
  templateUrl: './gw-restablecerpassword.component.html',
  styleUrls: ['./gw-restablecerpassword.component.css']
})
export class GwRestablecerpasswordComponent implements OnInit {
  passForm: FormGroup;
  submitted: boolean;
  data: cuentaverificada
  constructor(
    private GuiaswebServiceService: GuiaswebServiceService,
    private router: Router,
    private mensajeService: MensajeService
  ) { }

  ngOnInit(): void {
    this.loginForm();
  }


  get f() { return this.passForm.controls; }

  loginForm() {
    this.passForm = new FormGroup({
      pass: new FormControl('', [Validators.required])
    });
  }

  cambiarFocus(e, id) {
    if (e.keyCode === 13) {
      e.preventDefault();

      if (id.id == "entrar") {
        this.login(e);

      }
      else {
        id.focus();
      }

    }
  }

  async login(e) {
    e.preventDefault();
    this.submitted = true;

    if (this.passForm.valid) {
      await this.GuiaswebServiceService.verificarCuenta(this.passForm.controls.pass.value).then(
        d => {

          this.data = d;

          if (this.data.NumMsj <= 0) {
            this.mensajeService.subject$.next(new MensajePopup(this.data.TxtMsj, false, false));

          }
          else {
             this.GuiaswebServiceService.setCuentaverificada(this.data);
             this.mensajeService.subject$.next(new MensajePopup(this.data.TxtMsj, true, true));
             this.router.navigate(['/herramientasDigitales/verificar/cuenta']);

          }
        }

      ).catch((error: HttpErrorResponse) => {
        if (error.error._Message == undefined) {
          this.mensajeService.subject$.next(new MensajePopup("ocurrio un error inesperado al iniciar sesión en tu cuenta", false, false));
        } else {
          this.mensajeService.subject$.next(new MensajePopup(error.error._Message, false, false));
        }
      });

    }
  }
}
