import * as CryptoJS from 'src/assets/Scripts/crypto-js.js';


export class Tools {
    public isValidKeyNumber(event): boolean {
        if (
            (!(event.keyCode >= 48 && event.keyCode <= 57) && !(event.keyCode >= 96 && event.keyCode <= 105) && ([8, 9, 46, 37, 38, 39, 40].indexOf(event.keyCode)  === -1))
            || event.shiftKey || event.controlKey || ([32].indexOf(event.keyCode)  > -1)
          ) {
          return false;
        }

        return true;
    }
}

export class Crypto {
 //innuendo
scr = 'QW5kIHdoYXRldmVyIHdpbGwgYmUgd2lsbCBiZQ==';

encryptAES(data: any) : string {
      try {
          return CryptoJS.AES.encrypt(JSON.stringify(data), this.scr).toString();
      } catch (e) {
          //// // console.log(e);
          return null;
      }
  }

  decryptAES(data: string) : any {
      try {
          const bytes = CryptoJS.AES.decrypt(data, this.scr);
          if (bytes.toString()) {
              return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          }
          return data;
      } catch (e) {
          //// // console.log(e);
          return null;
      }
  }

  encryptMD5(cad: string) : string {
      try {
          return CryptoJS.MD5(cad);
      } catch (e) {
          //// // console.log(e);
          return null;
      }
  }
}



