import { Component, OnInit } from '@angular/core';
import { ResumenSolicitudCS } from '../../entidades/resumen_solicitud';
import { SolicitudEntregaService } from '../../servicios/solicitud-entrega.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-rastreo-confirmacion',
  templateUrl: './rastreo-confirmacion.component.html',
  styleUrls: ['./rastreo-confirmacion.component.css']
})
export class RastreoConfirmacionComponent implements OnInit {
  resumenSolicitud: ResumenSolicitudCS;

  respuestaSolSubscription: Subscription;

  constructor(
    private ResumenEntrega: SolicitudEntregaService,
    private router: Router
  )  { }

  ngOnDestroy(): void {
    this.respuestaSolSubscription.unsubscribe();

  }

  ngOnInit() {
    this.respuestaSolSubscription = this.ResumenEntrega.respuestaSol.subscribe(
      respuesta => 
      {
        this.resumenSolicitud = respuesta
        if(Object.keys(this.resumenSolicitud).length === 0){
          this.router.navigate(['/paqueteria/rastreo/captura']);
        }
      }
    );
  }

  volverInicio() {
    this.router.navigate(['/paqueteria/rastreo/captura']);
    this.onTop();
  }

  onTop() {
    document.body.scrollTop = 0;
  }
}
