<div class="container_herramienta">
    <div class="alinearCtr">
        <div class="content_left">
            <h1 class="title">¡Obtén tu promoción!</h1>
            <div class="container_form">
                <form [formGroup]="formulario">
                    <div class="datosContacto">
                        <h2>Captura los siguientes datos</h2>
                        <div class="frm-element form-group">
                            <label>Nombre</label>
                            <input autofocus id="nombre" formControlName="nombre" class="form-control" maxlength="50" type="text" placeholder="Tu nombre completo" title="" [(ngModel)]="nombre" required>
                            <div *ngIf="userF.nombre.invalid && (userF.nombre.dirty || userF.nombre.touched)" class="text-danger">
                                <p *ngIf="userF.nombre.errors.required">El nombre es requerido.</p>
                              </div>
                        </div>
                        <div class="frm-element form-group">
                            <label>Correo</label>
                            <input id="correo" formControlName="correo" class="form-control" maxlength="50" type="email" placeholder="Ej. ejemplocorreo@gmail.com" [(ngModel)]="correo" title="Por favor ingresa tu correo@ejemplo.com" required>
                            <div *ngIf="userF.correo.invalid && (userF.correo.dirty || userF.correo.touched)" class="text-danger">
                                <p *ngIf="userF.correo.errors.required">El correo es requerido.</p>

                                <p *ngIf="formulario.get('correo').errors.pattern">Email inválido (Ej. ejemplocorreo@gmail.com).</p>
                              </div>
                        </div>
                        <div class="frm-element form-group">
                            <label>Teléfono</label>
                            <input id="telefono" formControlName="telefono" class="form-control" maxlength="10" type="tel" pattern="[0-9]{10}" placeholder="10 Dígitos" [(ngModel)]="telefono"  title="Por favor ingresa tu telefono (10 digitos)" required>
                            <div *ngIf="userF.telefono.invalid && (userF.telefono.dirty || userF.telefono.touched)" class="text-danger">
                                <p *ngIf="userF.telefono.errors.required">El teléfono es requerido.</p>
                                <p *ngIf="formulario.get('telefono').errors.pattern">Teléfono inválido. Coloca 10 dígitos numéricos.</p>
                              </div>
                        </div>
                        <!-- <div class="frm-element form-group">
                            <label>¿Cómo te enteraste?</label>
                            <select id="slctoptn" class="slct_opciones form-control" name="opciones" formControlName="slctcomo" title="Selecciona como te enteraste" [(ngModel)]="via" required>
                                 
                                <option value="">Selecciona...</option>
                                <option value="Redes">Redes Sociales</option>
                                <option value="Sucursal">Sucursal</option>
                                <option value="Conocido">Conocido</option>
                                <option value="Lona">Lona/Espectacular</option>
                                <option value="Volante">Volante</option>
                                <option value="Otro">Otro</option>
                            </select>
                            <div *ngIf="userF.slctcomo.invalid && (userF.slctcomo.dirty || userF.slctcomo.touched)" class="text-danger">
                                <p *ngIf="userF.slctcomo.errors.required">Selecciona una opción.</p>
                              </div>
                        </div> -->
                    </div>
                    <button class="btn_obtener" (click)="submitForm()" type="submit" [disabled]="formulario.invalid">Obtener Código</button>
                </form>
                <label id="mensajeAlert">{{msg}}</label>
            </div>
        </div>

         <!-- <div class="contentRight"> -->
            <!-- <img [src]="via" alt="Imagen seleccionada"> -->

            <div class="contentRight owl-carousel owl-theme">
              <div class="item" *ngFor="let imagen of promoact.value">
                   <img [src]="imagen" alt="" srcset="">
              </div>
            </div>

    </div>
    <div class="container-info buttom">
        <p class="txt_leyenda">{{promoact.leyenda}}</p>
    </div>
</div>
