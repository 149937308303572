<div class="gw-container">
    <form [formGroup]="destinatarioForm">

        <div class="gw-flex-titulos">
          <div>
           <h4>Destinatario</h4>
           <p class="gw-flex-titulo2">{{this.tipopaquete}}</p>
         </div>
         <div>
           <button class="gw-btn-backpantalla" (click)="limpiar()" type="button">Limpiar</button>
         </div>
       </div>

        <div class="div-form">
            <label class="lbl-text">Nombre</label>
            <input class="doc-lbl"  formControlName="nombre" id="nombred"  maxlength="100" type="text" inputmode="text"
                [ngClass]="{validacion: (Des.nombre.invalid || Des.nombre.hasError('IsEmpty')) && submitted}">
            <div class="val-valido" [ngClass]="{invalido: (Des.nombre.invalid)  && submitted}">* Introduce el nombre
            </div>
        </div>

        <div class="gw-flex">
            <!-- <div class="gw-flex-l">
                <label class="lbl-text">País</label>
                <div class="select">
                    <select id="pais" formControlName="selectPais"
                        [ngClass]="{validacion: (Des.selectPais.invalid || Des.selectPais.hasError('isEmpty')) && submitted}"
                        required (change)="paisChanged($event.target.value)">
                        <option value="" disabled selected class="option s">Seleccione</option>
                        <option *ngFor="let i of pais" [value]="i.idpais">
                            {{ i.pais }}
                        </option>
                    </select>
                </div>
                <div class="val-valido" [ngClass]="{invalido: (Des.selectPais.invalid)  && submitted}">* Introduce el país
                </div>
            </div> -->

            <div class="gw-flex-l">
                <label class="lbl-text">Estado</label>
                <div class="select">
                    <select id="pais" formControlName="selectEstado"
                        [ngClass]="{validacion: (Des.selectEstado.invalid || Des.selectEstado.hasError('isEmpty')) && submitted}"
                        required (change)="estadoChanged($event.target.value)">
                        <option value="" disabled selected class="option s">Seleccione</option>
                        <option *ngFor="let i of estado" [value]="i.idedo">
                            {{ i.estado }}
                        </option>
                    </select>
                </div>
                <div class="val-valido" [ngClass]="{invalido: (Des.selectEstado.invalid)  && submitted}">* Introduce el estado
                </div>
            </div>

            <div class="gw-flex-r">
              <label class="lbl-text">Ciudad</label>
              <div class="select">
                  <select id="pais" formControlName="selectCiudad"
                      [ngClass]="{validacion: (Des.selectCiudad.invalid || Des.selectCiudad.hasError('isEmpty')) && submitted}"
                      required (change)="ciudadChanged($event.target.value)">
                      <option value="" disabled selected class="option s">Seleccione</option>
                      <option *ngFor="let i of ciudad" [value]="i.idcd">
                          {{ i.ciudad }}
                      </option>
                  </select>
              </div>
              <div class="val-valido" [ngClass]="{invalido: (Des.selectCiudad.invalid)  && submitted}">* Introduce la ciudad
              </div>
          </div>
        </div>

        <div class="gw-flex">
            <!-- <div class="gw-flex-l">
                <label class="lbl-text">Ciudad</label>
                <div class="select">
                    <select id="pais" formControlName="selectCiudad"
                        [ngClass]="{validacion: (Des.selectCiudad.invalid || Des.selectCiudad.hasError('isEmpty')) && submitted}"
                        required (change)="ciudadChanged($event.target.value)">
                        <option value="" disabled selected class="option s">Seleccione</option>
                        <option *ngFor="let i of ciudad" [value]="i.idcd">
                            {{ i.ciudad }}
                        </option>
                    </select>
                </div>
                <div class="val-valido" [ngClass]="{invalido: (Des.selectCiudad.invalid)  && submitted}">* Introduce la ciudad
                </div>
            </div> -->

            <label class="lbl-text" style="position:absolute">Entrega</label>
            <div class="gw-flex-l" style="display: flex; align-items: center; padding-top: 12px">
              <div class="checkbox-wrapper-4">
                <input class="inp-cbx" id="sucursalchkbox" type="checkbox" formControlName="sucursalSeleccionado" (click)="imprimirEstadoCheckboxes('sucursalSeleccionado')">
                <label class="cbx" for="sucursalchkbox"><span>
                <svg width="12px" height="10px">

                </svg></span><span>Sucursal</span></label>
                <svg class="inline-svg">
                  <symbol id="check-4" viewBox="0 0 12 10">
                    <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                  </symbol>
                </svg>
              </div>
              <div class="checkbox-wrapper-4">
                <input class="inp-cbx" id="domiciliochkbox" type="checkbox" formControlName="domicilioSeleccionado" (click)="imprimirEstadoCheckboxes('domicilioSeleccionado')">
                <label class="cbx" for="domiciliochkbox"><span>
                <svg width="12px" height="10px">

                </svg></span><span>Domicilio</span></label>
                <svg class="inline-svg">
                  <symbol id="check-4" viewBox="0 0 12 10">
                    <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                  </symbol>
                </svg>
              </div>
            </div>

            <div class="gw-flex-r">
                <label class="lbl-text">Colonia</label>
                <div class="select">
                    <select id="pais" formControlName="selectColonia"
                        [ngClass]="{validacion: (Des.selectColonia.invalid || Des.selectColonia.hasError('isEmpty')) && submitted}"
                        required (change)="coloniaChanged($event.target.value)">
                        <option value="" disabled selected class="option s">Seleccione</option>
                        <option *ngFor="let i of colonia" [value]="i.idcol">
                            {{ i.colonia }}
                        </option>
                    </select>
                </div>
                <div class="val-valido" [ngClass]="{invalido: (Des.selectColonia.invalid)  && submitted}">* Introduce la colonia
                </div>
            </div>
        </div>

        <div class="div-form">
            <label class="lbl-text">Direccíon</label>
            <input formControlName="direccion" type="text" inputmode="text" autocomplete="street-address" maxlength="200"
                [ngClass]="{validacion: (Des.direccion.invalid || Des.direccion.hasError('IsEmpty')) && submitted}">
            <div class="val-valido" [ngClass]="{invalido: (Des.direccion.invalid)  && submitted}">* Introduce la direccion
            </div>
        </div>
        <div class="gw-flex">
            <div class="gw-flex-l">
                <label class="lbl-text">Teléfono</label>
                <input formControlName="telefono" (keydown)="cambiarFocus($event)" maxlength="10" minlength="10" type="text" inputmode="tel" autocomplete="tel"
                    [ngClass]="{validacion: (Des.telefono.invalid || Des.telefono.hasError('IsEmpty')) && submitted}" >
                <div class="val-valido" [ngClass]="{invalido: (Des.telefono.invalid && Des.telefono.value == '')  && submitted}">* Intenta con un teléfono valido
                </div>
                <div class="val-valido" [ngClass]="{invalido: (Des.telefono.value != '' && Des.telefono.invalid)  && submitted}">* Solo puedes ingresar 10 dígitos
                </div>
            </div>

            <div class="gw-flex-r">
                <label class="lbl-text">Código postal</label>
                <input type="text" formControlName="cp"
                    [ngClass]="{validacion: (Des.cp.value == null || Des.cp.invalid || Des.cp.hasError('IsEmpty')) && submitted}">
            </div>
        </div>

        <div class="gw-reverse-btn">
          <button name="Aceptar" class="gw-btn-continuar gw-btn-pasos" type="submit"
              (click)="guardarsesion_paso3()">Continuar</button>
          <button class="btn-back gw-btn-pasos"  type="button"  (click)="regresarMenu()">Regresar</button>
      </div>
    </form>
</div>
