
import { Component, OnInit, TemplateRef, ViewChild, ɵConsole } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { GuiaswebServiceService } from '../../servicios/guiasweb-service.service';
import { Router } from '@angular/router';
import { MensajePopup } from 'src/app/entidades/MensajePopup';
import { documentacion } from '../../entidades/gwdocumentacion';
import { user } from '../../entidades/gwuser';
import { MensajeService } from 'src/app/servicios/mensaje.service';
import { HttpErrorResponse } from '@angular/common/http';
import jwt_decode from 'jwt-decode';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-gw-solicitarguias',
  templateUrl: './gw-solicitarguias.component.html',
  styleUrls: ['./gw-solicitarguias.component.css']
})
export class GwSolicitarguiasComponent implements OnInit {

  //variables tipo de paquete
  tipoEnvio: any;
  arrtipos = [];
  arrtiposAux = [];
  docTipoForm: FormGroup;
  submittedTipo: boolean;
  doc: documentacion;
  datagw: any;
  data: any;
  listatipo: string[] = ["Sobre", "Caja", "Tarima", "Otro"];
  userGw: user;
  HistorialGuias: any;
  numpantalla =  1;

  modalRef: BsModalRef;

  token: string;
  tokendatos: any;

  constructor(
    private GuiaswebServiceService: GuiaswebServiceService,
    private router: Router,
    private mensajeService: MensajeService,
    private modalService: BsModalService,
  ) { }

  async ngOnInit() {
    this.GuiaswebServiceService.setModuloG(2);
    window.scroll();
    //obtenemos los datos del cliente logueado
    //creamos el formulario
    this.DocumentacionTipoForm();
    this.VerificarToken()
    this.token = sessionStorage.getItem('token')
    this.tokendatos = jwt_decode(this.token);
    this.userGw = this.tokendatos;
    //obtenemos el tipo de paquete
    this.obtener_TipoGuias();
    //cargamos el historial
    this.obtenerHistorialGuias();

    this.GuiaswebServiceService.setPlantillaSol(1);

    this.GuiaswebServiceService.numPsol.subscribe(
      datos => {

        if (datos == 0) {
          this.router.navigate(['/herramientasDigitales/documentacion/menu']);
        }
        this.numpantalla = datos;
      }
    );
    window.scrollTo(0, 0);
  }

  VerificarToken() {
    if (sessionStorage.getItem('token') == undefined || sessionStorage.getItem('token') == "" || sessionStorage.getItem('token') == null) {
      this.mensajeService.subject$.next(new MensajePopup("Su sesión ha expirado", false, false));
      this.router.navigate(['/herramientasDigitales/login']);
      return;
    }
  }

  //form de tipo
  DocumentacionTipoForm() {
    this.docTipoForm = new FormGroup({
      //tipo
      selectTipo: new FormControl('', [Validators.required]),
      cantidad: new FormControl('', [Validators.required, Validators.pattern("^([0-9])*$")]),
      peso: new FormControl('', [Validators.required, Validators.pattern("^([0-9])*$")]),
      largo: new FormControl('', [Validators.required, Validators.pattern("^([0-9])*$")]),
      ancho: new FormControl('', [Validators.required, Validators.pattern("^([0-9])*$")]),
      alto: new FormControl('', [Validators.required, Validators.pattern("^([0-9])*$")]),
      selectTipopaq: new FormControl('', [Validators.required]),
      comentario: new FormControl(),
    });
  }

  get DocTipo() { return this.docTipoForm.controls; }

  //cargamos los tipos de paquete
  async obtener_TipoGuias() {
    await this.GuiaswebServiceService.obtenerTipoGuias(this.token).then(
      d => {

        this.tipoEnvio = d;
      }
    ).catch((error: HttpErrorResponse) => {
      if (error.error.ExceptionMessage == "invalid_token") {
        this.mensajeService.subject$.next(new MensajePopup("Su sesión ha expirado", false, false));
        this.router.navigate(['/herramientasDigitales/login']);
        // this.router.navigate(['/login']);
      } else {
        this.mensajeService.subject$.next(new MensajePopup("ocurrio un error inesperado, intente de nuevo", false, false));
      }
    });
  }

  //funcion para solicitar guias
  async solicitar() {
    window.scrollTo(0, 0);
    this.VerificarToken();
    this.submittedTipo = true;
    if (this.docTipoForm.valid) {
      await this.GuiaswebServiceService.oprSolicitud(this.userGw.idUser, this.docTipoForm.controls.selectTipo.value, this.docTipoForm.controls.cantidad.value, this.docTipoForm.controls.peso.value, this.docTipoForm.controls.largo.value, this.docTipoForm.controls.ancho.value, this.docTipoForm.controls.alto.value, this.docTipoForm.controls.comentario.value, "", this.docTipoForm.controls.selectTipopaq.value, this.token).then(
        d => {

          this.data = d;


          if (this.data.id > 0) {

            this.mensajeService.subject$.next(new MensajePopup(this.data.msg, true, true));
            this.limpiarCamposTipo();
            this.obtenerHistorialGuias();
            window.scrollTo(0, 0);

          } else {
            this.mensajeService.subject$.next(new MensajePopup(this.data.msg, false, false));

          }
        }
      ).catch((error: HttpErrorResponse) => {
        if (error.error.ExceptionMessage == "invalid_token") {
          this.mensajeService.subject$.next(new MensajePopup("Su sesión ha expirado", false, false));
          this.router.navigate(['/herramientasDigitales/login']);
          // this.router.navigate(['/login']);
        } else {
          this.mensajeService.subject$.next(new MensajePopup("ocurrio un error inesperado, intente de nuevo", false, false));
        }
      });
    }
  }
  // funcion para cargar historial
  async obtenerHistorialGuias() {
    this.VerificarToken();
    await this.GuiaswebServiceService.GwgetsolWeb(this.userGw.idUser).then(
      d => {

        this.HistorialGuias = d;
      }

    ).catch((error: HttpErrorResponse) => {
      if (error.error.ExceptionMessage == "invalid_token") {
        this.mensajeService.subject$.next(new MensajePopup("Su sesión ha expirado", false, false));
        this.router.navigate(['/herramientasDigitales/login']);
        // this.router.navigate(['/login']);
      } else {
        this.mensajeService.subject$.next(new MensajePopup("ocurrio un error inesperado, intente de nuevo", false, false));
      }
    });
  }
  // funcion para limpiar campos
  limpiarCamposTipo() {

    this.docTipoForm.controls.selectTipo.setValue('');
    this.docTipoForm.controls.cantidad.setValue('');
    this.docTipoForm.controls.peso.setValue('');
    this.docTipoForm.controls.largo.setValue('');
    this.docTipoForm.controls.ancho.setValue('');
    this.docTipoForm.controls.alto.setValue('');
    this.docTipoForm.controls.selectTipo.setValue('');
    this.docTipoForm.controls.selectTipopaq.setValue('');
    this.docTipoForm.controls.comentario.setValue('');
    this.submittedTipo = false;
  }

  cambiarFocus(e) {

    if (!((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode == 8) || (e.keyCode == 9) || (e.keyCode == 13) || (e.keyCode == 38) || (e.keyCode == 40) || (e.keyCode == 37) || (e.keyCode == 39) || e.keyCode >= 96 && e.keyCode <= 105)) {

      e.preventDefault();
    }

  }

  Abrirdescripcion() {
    const btn = document.getElementById('btn-modal') as HTMLElement;
    btn.click();

  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  closeModal() {
    this.modalRef.hide();
  }

  cambiarpantalla(num){
      if(num == 1){
        this.numpantalla = 2
      }else{
        this.numpantalla = 1
      }
  }


}


