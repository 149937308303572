import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { recolecciondom } from "../../servicios/recoleccion-dom.service";
import { MensajePopup } from "../../entidades/MensajePopup";
import { IfStmt } from "@angular/compiler";

@Component({
  selector: "app-contacto",
  templateUrl: "./contacto.component.html",
  styleUrls: ["./contacto.component.css"],
})
export class ContactoComponent implements OnInit {
  contacto: FormGroup;
  submitted = false;
  correo: string;
  telefono: string;
  mensaje: string;

  constructor(
    private formBuilder: FormBuilder,
    private recolecciondom: recolecciondom
  ) {}

  ngOnInit() {
    this.contacto = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      telefono: ["", [Validators.required, Validators.minLength(10)]],
      mensaje: ["", [Validators.required, Validators.minLength(6)]],
    });
  }
  get f() {
    return this.contacto.controls;
  }

  onSubmit() {
    if (this.contacto.invalid) {
      this.submitted = true;
      return;
    }
    this.recolecciondom
      .getDataContacto(this.mensaje, this.correo, this.telefono)
      .then((data) => {
        // console.log(data.NumMsj);
        if (data.NumMsj === 0) {
          // console.log(data.NumMsj);
          this.toggleModalconfirm();
        }
      });
  }

  toggleModalconfirm() {
    var mdl = document.getElementById("modal-confirm2");
    if (mdl.style.display === "block") {
      document.getElementById("modal-confirm2").style.display = "none";
      mdl.style.opacity = "0";
      mdl.style.transition = "all 0.3s ease-out;";
    } else {
      mdl.style.opacity = "1";
      mdl.style.transition = "all 0.4s ease-in;";
      document.getElementById("modal-confirm2").style.display = "block";
    }
  }
  limpiarcampos() {
    this.submitted = false;
    this.contacto.controls.email.setValue("");
    this.contacto.controls.telefono.setValue("");
    this.contacto.controls.mensaje.setValue("");
  }
}
