<div class="container_herramienta">
  <div class="alinearIzq">
    <div class="gwlogin-container" [formGroup]="passForm">
      <div class="left">
        <h1>Comprueba tu Identidad</h1>
        <p>Escribe la dirección de correo electrónico o el número de teléfono móvil asociado a tu cuenta.</p>

        <div class="form-div">
          <div class="text" [ngClass]="{validacion: (f.pass.invalid || f.pass.hasError('IsEmpty')) && submitted}">
            <input type="text" id="usr" placeholder="Correo o teléfono" (keyup)="cambiarFocus($event, btnEntrar)"
              formControlName="pass">
          </div>
          <div class="val-valido" [ngClass]="{invalido: f.pass.invalid && submitted}">* Introduce un correo o teléfono
          </div>
        </div>
        <button (click)="login($event)" #btnEntrar id="entrar">Continuar</button>
        <p class="textSecond">Si cambiaste tu teléfono o correo asociado a tu cuenta, le recomendamos mandar un email
          a:<span class="posttitulo">
            contacto@tufesapack.com</span></p>
      </div>
    </div>
  </div>
</div>
