
<div class="container_herramienta">
  <img src="assets/img/web/recoleccion.webp" class="img_toAnimate">
  <div class="alinearIzq">

<form [formGroup]="recoleccionForm" class="recolecciondomContainer" >
    <h1>Recolección a domicilio</h1>
    <p>Solicita una recolección en donde estes.</p>
    <div class="inputContainer"  >
    <div class="doms_frecs" id="doms_frecs" >
      <div class="container_frecs" id="container_frecs" *ngIf="listdomfrecuente.length != 0">
        <p class="txt_domfrec">Domicilios frecuentes</p>
        <!--Contenido de la ficha-->
        <div  class="ficha" *ngIf="isLoguedin === true">
          <p style="margin: 10px 0px 10px 15px;">Últimos domicilios</p>
            <div  class="div_scroll" id="div_scroll">
              
              <div class="cont-ficha" *ngFor="let idfrec of listdomfrecuente; let i = index">
                    <input type="checkbox" id="expend" />
                <div class="medium-12 small-12 columns smalldesc" id="isShow">
                  <div id="info_ficha">
                    <img class="img-box" src="assets/iconos/mapa_dir.png">
                    
                    <p class="p_txt" id="name_ficha">{{idfrec.Nombre}}</p>
                    <p class="p_txt">{{idfrec.ciudad}}, <span style="color: #6d6e70;">{{idfrec.Estado}}</span></p>
                    <div class="show_hide_info" [ngClass]="{'info_show':activeIndex === i+1, 'info_hide':activeIndex != i+1}">
                    <p class="p_txt">C.P. 85150</p>
                    <p class="p_txt">{{idfrec.correo}}</p>
                    <p class="p_txt">{{idfrec.Telefono}}</p>
                    <p class="p_txt">{{idfrec.Colonia}}</p>
                    <p class="p_txt">{{idfrec.direccion}}</p>
                    <p class="p_txt">{{idfrec.ref}}</p>
                  
                    <div class="cont-sel-sol">
                      <div class="modal modal-confirm" id="modal-confirm">
                        <div id="dialog">
                        ¿Desea eliminar el domicilio frecuente?
                        <br>
                        <br>
                        <div class="buttonsConfirm">
                            <button class="btn_confirm" id="btn_confirm" (click)="oprDeleteDomFrec()" (click)="evaluarFrecuentes()" >Aceptar</button>
                            <button class="btn_cancel" (click)="toggleModalconfirm()">Cancelar</button>
                          </div>
                          </div>
                      </div>
                      <div class="modal modal-confirm" id="modal-confirm2">
                        <div id="dialog">
                        ¿Desea seleccionar el domicilio frecuente?
                        <br>
                        <br>
                        <div class="buttonsConfirm">
                          <button class="btn_confirm" (click)="oprSeleccionarFrec()" (click)="mostrarFormulario('cont-form')">Aceptar</button>
                          <button class="btn_cancel" (click)="toggleModalconfirm2()">Cancelar</button>
                        </div>
                          </div>
                      </div>
                      <div class="modal modal-confirm" id="modal-confirm3">
                        <div id="dialog">
                        ¿Desea solicitar recolección con el domicilio frecuente?
                        <br>
                        <br>
                        <div class="buttonsConfirm">
                          <button class="btn_confirm" (click)="solicitardomfrec(activeIndex)" (click)="toggleModalconfirm3()">Aceptar</button>
                          <button class="btn_cancel" (click)="toggleModalconfirm3()">Cancelar</button>
                        </div>
                          </div>
                      </div>
                      <button title="Eliminar domicilio frecuente" id="elimFrecuente"  (click)="seleccionariddiropr(idfrec.iddirR)" ><img class="img-elim" src="assets/iconos/eliminar.png">
                      </button>
                      <button title="Seleccionar este domicilio frecuente" class="btn_selec" type="submit"  (click)="seleccionariddiropr2(idfrec.iddirR)" >Seleccionar</button>
                      <button title="Solicitar recolección con este domicilio frecuente" class="btn_solicitar" type="submit"   (click)="seleccionariddiropr3(idfrec.iddirR)">Solicitar</button>
                    </div>
                  </div>
                  </div>
                  </div>
                <div class="cont-flecha" id="cont-flecha" (click)="toggleMostrar(i+1, i+1)">
                    <label id="btn_down" class="flecha">
                    <a class="img-flecha">
                      <img src="assets/iconos/flecha-hacia-arriba.png" id="imgchange" class="btn_down" [ngClass]="{'btn_down':activeIndex === i+1, 'btn_down_rotate':activeIndex != i+1}">
                    </a>
                    </label>
                </div>
                <!-- MODAL DE CONFIRMACIÓN -->
                </div>
            </div>
            <button id="nuevoFrecuente" name="nuevoFrecuente" type="submit"  (click)="mostrarFormulario('cont-form')" (click)="limpiarcamposfrec()" class="btn_nuevoFrecuente">
              Otro domicilio
            </button>
            <!--desactivado por default-->
            <button id="nuevoFrecuente2" name="nuevoFrecuente" type="submit" (click)="mostrarbtn_frec('cont-form')" (click)="limpiarcamposfrec()" class="btn_nuevoFrecuente2">
              Otro domicilio
            </button>
          </div>
        </div>
    </div>
         <!--termina contenido de la ficha-->
         
        <div id="cont-form" class="container_form">
          <div class="form">
            <div>
              <label>Nombre</label>
              <input type="text"  formControlName="nombre"
              [ngClass]="{validacion: (a.nombre.invalid || a.nombre.hasError('isEmpty')) && submitted}"
              required >
          </div>
          <div class="space oneline">
              <label>Correo</label>
              <input type="email" formControlName="correo"
              [ngClass]="{validacion: (a.correo.invalid || a.correo.hasError('isEmpty')) && submitted}"
              required >
          </div>
          <div class="space oneline2">
              <label>Teléfono</label>
              <input type="text"  formControlName="telefono"  maxlength="10"
              [ngClass]="{validacion: (a.telefono.invalid || a.telefono.hasError('isEmpty')) && submitted}"
              required >
          </div>

          
          <div class="form oneline">
            <div class="space">
                <label class="lbl-text">Estado</label>
              </div>
                <div class="select">
                  <select id="estado"  name="estado" formControlName="estado" (change)="estadoChanged($event.target.value)"
                  [ngClass]="{validacion: (a.estado.invalid || a.estado.hasError('isEmpty')) && submitted}"
                  required >
                    <option value="" disabled selected class="option s">Seleccione</option>
                    <option *ngFor="let i of cadestados" [value]="i.idedo">
                      {{i.estado}} 
                    </option>
                  </select>
                </div>
              </div>

          <div class="form oneline2">
              <label class="lbl-text">Ciudad</label>
              <div class="select">
                <select id="ciudad" name="ciudad" formControlName="ciudad" (change)="ciudadChanged($event.target.value)"
                [ngClass]="{validacion: (a.ciudad.invalid || a.ciudad.hasError('isEmpty')) && submitted}"
                required >
                  <option value="" disabled selected class="option s">Seleccione</option>
                  <option *ngFor="let i of cadciudades" [value]="i.idcd" >
                    {{i.ciudad}}
                  </option>
                </select>
              </div>
          </div>

          <div class="form">
              <label class="lbl-text">Colonia</label>
              <div class="select">
                <select id="colonia" name="colonia" formControlName="colonia" (change)="coloniaChanged($event.target.value)"
                [ngClass]="{validacion: (a.colonia.invalid || a.colonia.hasError('isEmpty')) && submitted}"
                required >
                  <option value="" disabled selected class="option s">Seleccione</option>
                  <option *ngFor="let i of cadcolonias" [value]="i.idcol">
                    {{i.colonia}}
                  </option>
                </select>
              </div>
          </div>

          
          <div>
              <label>Direccción</label>
              <input type="text"  formControlName="direccion"
              [ngClass]="{validacion: (a.direccion.invalid || a.direccion.hasError('isEmpty')) && submitted}"
              required >
          </div>
          <div class="space">
              <label>Referencia</label>
              <input type="text"  formControlName="referencia"
              [ngClass]="{validacion: (a.referencia.invalid || a.referencia.hasError('isEmpty')) && submitted}"
              required >
          </div>

          <div class="form-btn" style="text-align: right;">
              <button  id="newFrecuente" name="nuevoFrecuente" class="btn_nuevoFrecuente3" type="submit" (click)="oprNewDomFrec(obj_newFrec,'ALT')" (click)="limpiarcamposfrec()">
                Guardar en frecuentes
              </button>
              <button id="Recoleeccion" name="Aceptar" class="btnContinuar" type="submit" (click)="solicitar()" style="display: inline-block;">
                  Solicitar
              </button>
        </div>
       </div>

    </div>
  </div>
  </form>

  <ng-template #modal>
    <div class="modal-header">
        <h4 class="mdl_title">Recolección a domicilio</h4>

        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
       <p class= "recdm-titulo">Número de solicitud</p>
       <p class= "recdm-numero">{{idsol}}</p>

        <p class= "p-datos recdm-nombre">{{nombre}}</p>
        <p class= "p-datos">{{correo}}</p>
        <p class= "p-datos">{{telefono}}</p>
        <p class= "p-datos">{{colonia}}</p>
        <p class= "p-datos">{{ciudad}},{{estado}}</p>
        <p class= "p-datos">{{direccion}}</p>
        <p class= "p-datos">{{referencia}}</p>
        <p class= "p-datos p-leyenda">Nuestro personal de atención a cliente se pondrá en contacto con usted a la brevedad para darle seguimiento a su solicitud.</p>

        <button id="solicitar" name="Aceptar" class="btnContinuar" type="submit" (click)="limpiarcampos()">
          Nueva solicitud
      </button>
    </div>

</ng-template>

<button id="btn-modal" (click)="openModal(modal)" hidden></button>

</div>
</div>
