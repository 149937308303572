import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { ReloadiframeService } from 'src/app/servicios/reloadiframe.service';


@Component({
  selector: 'app-directorio',
  templateUrl: './directorio.component.html',
  styleUrls: ['./directorio.component.css']
})
export class DirectorioComponent implements OnInit {

  url : any;

  constructor(
    private sanitizer: DomSanitizer,
    private reloadIframeS: ReloadiframeService
  ) { }

  ngOnInit() {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(environment.URLTools + "/directorio?lang=" + environment.language + '&v=' + environment.version);
    this.reloadIframeS.subject$.subscribe(
      d => {
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(environment.URLTools + "/directorio?lang=" + environment.language + '&v=' + environment.version);
      }
    );
  }
}
