export class cotizaAvanzado {
    costoBase: string = '0';
    cobrar: string = '0';
    seguro: string = '0';
    domicilio: string = '0';
    recoleccion: string = '0';
    embalaje: string = '0';
    acuse: string = '0';
    origen: string = 'Origen';
    destino: string = 'Destino';
    idcotizacion: string = "0";
    dsg: any;
    dirDomicilio: string = "";
    dirRecoleccion: string = "";

}
