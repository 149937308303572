import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GuiaswebServiceService } from '../../servicios/guiasweb-service.service';
import { Subscription } from 'rxjs';
import { User } from '../../entidades/gwusr';

@Component({
  selector: 'app-gw-menu-principal',
  templateUrl: './gw-menu-principal.component.html',
  styleUrls: ['./gw-menu-principal.component.css']
})
export class GwMenuPrincipalComponent implements OnInit {

  userGwSubscription: Subscription;
  userGw: User;
 

  constructor(
    private GuiaswebServiceService: GuiaswebServiceService,
    private router: Router
  ) { }

  ngOnInit(): void {
    
  }

  abrirHerramienta(num) {
    switch (num) {
      case 1:
        this.router.navigate(['Documentacion/menu']);
        break;
      case 2:
        this.router.navigate(['/herramientasDigitales/menu']);
        break;
      case 3:
        this.router.navigate(['/herramientasDigitales/menu']);
        break;
      case 4:
      // this.router.navigate(['/herramientasDigitales/menu']);
        break;

      default:
        break;
    }


  }

}
