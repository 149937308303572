import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { user } from "../../entidades/gwuser";
import jwt_decode from "jwt-decode";
import { ActivatedRoute, Params } from '@angular/router';


@Component({
  selector: 'app-detalle-transaccion',
  templateUrl: './detalle-transaccion.component.html',
  styleUrls: ['./detalle-transaccion.component.css']
})
export class DetalleTransaccionComponent implements OnInit {

isLoguedin: boolean;
costoTotal: string;

userGw: user;
datagw: any;
token: string;
tokendatos: any;
idcliente: number=-1;
nameCliente: any;

referencia: any;

datosTransaccion = 
    {
      id: 0,
      numGuias: 0,
      costo: "",
      detalle: "",
      tipo: "",
      beneficios: [""]
    };

  constructor(private router: Router, private activateRoute: ActivatedRoute) {
    this.activateRoute.queryParams.subscribe(params => {
      try{
        this.referencia = params['rf'];
        this.datosTransaccion = JSON.parse(atob(this.referencia));
      }
      catch(e){
        //redirige a login
        this.router.navigate(['**']);
      }
    });
   }

  ngOnInit(): void {
    // this.costoTotal = localStorage.getItem("totalGuia");
    if(sessionStorage.getItem("token")){
      this.token = sessionStorage.getItem("token");
      this.tokendatos = jwt_decode(this.token);
      this.isLoguedin = true;
      this.userGw = this.tokendatos;
      this.idcliente = this.userGw.idUser;
      this.nameCliente = this.userGw.name;
      
      // console.log(this.nameCliente);
    }
    else{
      this.isLoguedin = false;
      this.router.navigate(["/herramientasDigitales/login"]);
    }
  }

  continuarCompra(){
    if(sessionStorage.getItem("token")){
      this.token = sessionStorage.getItem("token");
      this.tokendatos = jwt_decode(this.token);
      this.isLoguedin = true;
      this.userGw = this.tokendatos;
      this.idcliente = this.userGw.idUser;
      this.nameCliente = this.userGw.name;
      // console.log(this.nameCliente);
      var cadpago="";
      cadpago =  btoa( JSON.stringify (this.datosTransaccion));
      this.router.navigate(["/captura-pago"],{queryParams:{rf:cadpago}});
    }
    else{
      this.isLoguedin = false;
      this.router.navigate(["/herramientasDigitales/login"]);
    }
  }
  
}
