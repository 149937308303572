export class cuenta {
    // la llave primaria se debe de llamar id o UsuarioId (claseId)
    usuario: string ="";
    contrasena: string ="";
    correo: string ="";
    rfc: string ="";
    tel: string ="";
    // direccion: string ="";
    cp: string ="";
    
}

