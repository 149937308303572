import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { documentacion } from "../../entidades/gwdocumentacion";
import { GuiaswebServiceService } from "../../servicios/guiasweb-service.service";
import { Router } from "@angular/router";
import { tipo } from "../../entidades/gwtipo";
import { MensajeService } from "src/app/servicios/mensaje.service";
import { MensajePopup } from "src/app/entidades/MensajePopup";
import { HttpErrorResponse } from "@angular/common/http";
import  Swal  from 'sweetalert2';

@Component({
  selector: "app-gw-historial-det",
  templateUrl: "./gw-historial-det.component.html",
  styleUrls: ["./gw-historial-det.component.css"],
})

export class GwHistorialDetComponent implements OnInit {
  documentacionForm: FormGroup;
  arrtipos = [];
  arrSolGuias = [];
  submitted: boolean;
  d: documentacion;
  guia: any;
  dato: any;

  token: string;
  tokendatos: any;
  motivo:string = "";
  respuesta:any;
  folio:string;
  usr:string;
  showbtncancel:boolean = true;
  showbtndownload:boolean = true;

  constructor(
    private GuiaswebServiceService: GuiaswebServiceService,
    private router: Router,
    private mensajeService: MensajeService
  ) {}

  ngOnInit(): void {
    this.GuiaswebServiceService.setModuloG(3);
    if (sessionStorage.getItem("token") == undefined) {
      this.router.navigate(["/herramientasDigitales/login"]);
    } else {
      this.DocumentacionForm();
      var docaux = sessionStorage.getItem("HistorialDet");
      this.guia = JSON.parse(docaux);
      this.folio = this.guia.nocontrato;
      this.usr = this.guia.idcliente;
      if(this.guia.estado === "CANCELADO"){
         this.showbtncancel = false;
         this.showbtndownload = false;
        }

      this.llenarDocumentacion();
      window.scrollTo(0, 0);
    }
  }

  async cancelarGuiaDetalleProcess(){
    // Swal.fire({
    //   title: "¿DESEA CANCELAR LA GUÍA?",
    //   text: "Una vez cancelada no se podra utilizar.",
    //   icon: "question",
    //   showCancelButton: true,
    //   cancelButtonText: "Regresar",
    //   confirmButtonColor: "#3085d6",
    //   cancelButtonColor: "#d33",
    //   confirmButtonText: "Cancelar Guia"
    // }).then((result) => {
    //   if (result.isConfirmed) {
    //       this.cancelarGuiaDetalle();
    //   }
    // });

    // const { value: text } = await Swal.fire({

    //   input: "textarea",
    //   inputLabel: "Message",
    //   inputPlaceholder: "Type your message here...",
    //   inputAttributes: {
    //     "aria-label": "Type your message here"
    //   },
    //   title: "¿DESEA CANCELAR LA GUÍA?",
    //   text: "Una vez cancelada no se podra utilizar.",
    //   icon: "question",
    //   showCancelButton: true,
    //   cancelButtonText: "Regresar",
    //   confirmButtonColor: "#3085d6",
    //   cancelButtonColor: "#d33",
    //   confirmButtonText: "Cancelar Guia"
    // }).then((result) => {
    //   this.motivo = text;
    //     if (result.isConfirmed) {
    //         this.cancelarGuiaDetalle();
    //     }
    //   });


    // if (text) {
    //   Swal.fire(text);
    // }

    const result = await Swal.fire({
      input: "textarea",
      inputLabel: "Motivo (opcional)",
      inputPlaceholder: "Escribe tu motivo de cancelacion...",
      inputAttributes: {
        "aria-label": "Type your message here"
      },
      title: "¿DESEA CANCELAR LA GUÍA?",
      text: "Una vez cancelada no se podrá utilizar.",
      icon: "question",
      showCancelButton: true,
      cancelButtonText: "Regresar",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Cancelar Guía"
    });

    if (result.isConfirmed) {
      const text = result.value;
      this.motivo = text;
      this.cancelarGuiaDetalle();
    }


  }

   async cancelarGuiaDetalle(){

    await this.GuiaswebServiceService.cancelarGuia( this.usr, this.folio, this.motivo)
      .then((d) => {
        this.respuesta = d;
        console.log(this.respuesta);

        if(this.respuesta.NumMsj > 0 ){

           Swal.fire({
            title: "¡GUÍA CANCELADA!",
            icon: "success",
            text: this.respuesta.TxtMsj,
            confirmButtonText: "Continuar"

          }).then((result) => {

            if (result.isConfirmed) {

                this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                  this.router.navigate(['/herramientasDigitales/historial']);
                  });
                }
          });

        }else{
          Swal.fire({
            title: "ERROR",
            icon: "error",
            text: this.respuesta.TxtMsj,
            confirmButtonText: "Continuar"

          }).then((result) => {

            if(result.isConfirmed) {
               this.GuiaswebServiceService.setplantillaHist(1);
              }
          });
        }

        // nummsj - si es mayor a 0 es exitosa, si es -1 es error
        // txtmsj - mensaje de servicio
        // No. contrato =  folio
      })
      .catch((error: HttpErrorResponse) => {
        if (error.error.ExceptionMessage == "invalid_token") {
          this.mensajeService.subject$.next(
            new MensajePopup("Su sesión ha expirado", false, false)
          );
          this.router.navigate(["/herramientasDigitales/login"]);
        } else {
          this.mensajeService.subject$.next(
            new MensajePopup(
              "ocurrio un error inesperado al iniciar sesión en tu cuenta",
              false,
              false
            )
          );
        }
      });
  }

  //creamos la sesion en el paso 5
  async cargarGuiacompleta() {
    await this.GuiaswebServiceService.cargarGuiacompleta(
      this.d.idsesion,
      this.d.idcliente,
      this.token
    )
      .then((d) => {
        this.guia = d;
        this.llenarDocumentacion();
      })
      .catch((error: HttpErrorResponse) => {
        if (error.error.ExceptionMessage == "invalid_token") {
          this.mensajeService.subject$.next(
            new MensajePopup("Su sesión ha expirado", false, false)
          );
          this.router.navigate(["/herramientasDigitales/login"]);
        } else {
          this.mensajeService.subject$.next(
            new MensajePopup(
              "ocurrio un error inesperado al iniciar sesión en tu cuenta",
              false,
              false
            )
          );
        }
      });
  }

  VerificarToken() {
    if (
      sessionStorage.getItem("token") == undefined ||
      sessionStorage.getItem("token") == "" ||
      sessionStorage.getItem("token") == null
    ) {
      this.mensajeService.subject$.next(
        new MensajePopup("Su sesión ha expirado", false, false)
      );
      this.router.navigate(["/herramientasDigitales/login"]);
      return;
    }
  }

  //creamos la sesion en el paso 5
  async finalizardoc() {
    this.VerificarToken();
    await this.GuiaswebServiceService.finalizardoc(
      this.d.idsedet,
      this.d.idsesion,
      this.d.idcliente,
      this.token
    )
      .then((d) => {
        this.dato = d;
        if (this.dato.id > 0) {
          this.crearpdf();
          this.mensajeService.subject$.next(
            new MensajePopup(this.dato.msg, true, true)
          );
          this.router.navigate(["/herramientasDigitales/documentacion/menu"]);
        } else {
          this.mensajeService.subject$.next(
            new MensajePopup(this.dato.msg, false, false)
          );
        }
      })
      .catch((error: HttpErrorResponse) => {
        if (error.error.ExceptionMessage == "invalid_token") {
          this.mensajeService.subject$.next(
            new MensajePopup("Su sesión ha expirado", false, false)
          );
          this.router.navigate(["/herramientasDigitales/login"]);
          // this.router.navigate(['/login']);
        } else {
          this.mensajeService.subject$.next(
            new MensajePopup(
              "ocurrio un error inesperado al iniciar sesión en tu cuenta",
              false,
              false
            )
          );
        }
      });
  }

  async crearpdf() {
    var aux;
    aux = this.guia.idguia + "Guia-Tufesa";
    aux = "359Guia-Tufesa";

    await this.GuiaswebServiceService.facturapdf(
      "-",
      aux,
      this.guia.idguia,
      "1"
    ).then();
  }

  DocumentacionForm() {
    this.documentacionForm = new FormGroup({
      noEmpleado: new FormControl({ value: null, disabled: true }),
      fecha: new FormControl({ value: null, disabled: true }),
      estado: new FormControl({ value: null, disabled: true }),
      hora: new FormControl({ value: null, disabled: true }),
      idguia: new FormControl({ value: null, disabled: true }),
      nocontrato: new FormControl({ value: null, disabled: true }),

      //remintente
      Rnombre: new FormControl({ value: null, disabled: true }),
      RselectPais: new FormControl({ value: null, disabled: true }),
      RselectEstado: new FormControl({ value: null, disabled: true }),
      RselectCiudad: new FormControl({ value: null, disabled: true }),
      RselectColonia: new FormControl({ value: null, disabled: true }),
      Rdireccion: new FormControl({ value: null, disabled: true }),
      Rtelefono: new FormControl({ value: null, disabled: true }),
      Rcp: new FormControl({ value: null, disabled: true }),
      //Destinatario
      Dnombre: new FormControl({ value: null, disabled: true }),
      DselectPais: new FormControl({ value: null, disabled: true }),
      DselectEstado: new FormControl({ value: null, disabled: true }),
      DselectCiudad: new FormControl({ value: null, disabled: true }),
      DselectColonia: new FormControl({ value: null, disabled: true }),
      Ddireccion: new FormControl({ value: null, disabled: true }),
      Dtelefono: new FormControl({ value: null, disabled: true }),
      Dcp: new FormControl({ value: null, disabled: true }),

      selectTipo: new FormControl({ value: null, disabled: true }),
      cantidad: new FormControl({ value: null, disabled: true }),
      peso: new FormControl({ value: null, disabled: true }),
      largo: new FormControl({ value: null, disabled: true }),
      ancho: new FormControl({ value: null, disabled: true }),
      alto: new FormControl({ value: null, disabled: true }),

      cobrar: new FormControl({ value: null, disabled: true }),
      recoleccion: new FormControl({ value: null, disabled: true }),
      acuse: new FormControl({ value: null, disabled: true }),
      ValorDeclarado: new FormControl({ value: null, disabled: true }),
      comentario: new FormControl({ value: null, disabled: true }),
    });
  }
  get doc() {
    return this.documentacionForm.controls;
  }

  llenarDocumentacion() {
    this.documentacionForm.controls.noEmpleado.setValue(this.guia.idcliente);
    this.documentacionForm.controls.fecha.setValue(this.guia.fecha);
    this.documentacionForm.controls.estado.setValue(this.guia.estado);
    this.documentacionForm.controls.nocontrato.setValue(this.guia.nocontrato);

    this.documentacionForm.controls.Rnombre.setValue(
      this.guia.remitente.nombre
    );
    this.documentacionForm.controls.RselectPais.setValue(
      this.guia.remitente.pais
    );
    this.documentacionForm.controls.RselectEstado.setValue(
      this.guia.remitente.estado
    );
    this.documentacionForm.controls.RselectCiudad.setValue(
      this.guia.remitente.ciudad
    );
    this.documentacionForm.controls.RselectColonia.setValue(
      this.guia.remitente.colonia
    );
    this.documentacionForm.controls.Rdireccion.setValue(
      this.guia.remitente.direccion
    );
    this.documentacionForm.controls.Rtelefono.setValue(this.guia.remitente.tel);
    this.documentacionForm.controls.Rcp.setValue(this.guia.remitente.cp);

    this.documentacionForm.controls.Dnombre.setValue(
      this.guia.destinatario.nombreD
    );
    this.documentacionForm.controls.DselectPais.setValue(
      this.guia.destinatario.paisD
    );
    this.documentacionForm.controls.DselectEstado.setValue(
      this.guia.destinatario.estadoD
    );
    this.documentacionForm.controls.DselectCiudad.setValue(
      this.guia.destinatario.ciudadD
    );
    this.documentacionForm.controls.DselectColonia.setValue(
      this.guia.destinatario.coloniaD
    );
    this.documentacionForm.controls.Ddireccion.setValue(
      this.guia.destinatario.direccionD
    );
    this.documentacionForm.controls.Dtelefono.setValue(
      this.guia.destinatario.telD
    );
    this.documentacionForm.controls.Dcp.setValue(this.guia.destinatario.cpD);

    var tiposaux = [];
    var cantidadaux = [];
    var pesoaux = [];
    var largoaux = [];
    var anchoaux = [];
    var altoaux = [];

    tiposaux = this.guia.doctipo.tipo.split(",");
    cantidadaux = this.guia.doctipo.cantidad.split(",");
    pesoaux = this.guia.doctipo.peso.split(",");
    largoaux = this.guia.doctipo.largo.split(",");
    anchoaux = this.guia.doctipo.ancho.split(",");
    altoaux = this.guia.doctipo.alto.split(",");

    for (let i = 0; i < tiposaux.length; i++) {
      const t = new tipo();
      t.tipo = tiposaux[i];
      t.cantidad = cantidadaux[i];
      t.peso = pesoaux[i] + " kg.";
      t.largo = largoaux[i] + " cm.";
      t.ancho = anchoaux[i] + " cm.";
      t.alto = altoaux[i] + " cm.";
      this.arrtipos.push(t);
    }

    if (this.guia.detalles.cobrar == 1) {
      this.documentacionForm.controls.cobrar.setValue(true);
    } else {
      this.documentacionForm.controls.cobrar.setValue(false);
    }
    if (this.guia.detalles.recoleccion == 1) {
      this.documentacionForm.controls.recoleccion.setValue(true);
    } else {
      this.documentacionForm.controls.recoleccion.setValue(false);
    }
    if (this.guia.detalles.acuse == 1) {
      this.documentacionForm.controls.acuse.setValue(true);
    } else {
      this.documentacionForm.controls.acuse.setValue(false);
    }

    this.documentacionForm.controls.ValorDeclarado.setValue(
      this.guia.detalles.valordec
    );
    this.documentacionForm.controls.comentario.setValue(
      this.guia.detalles.comentario
    );

    this.arrSolGuias = this.guia.lstsolc;
  }

  regresar() {
    this.GuiaswebServiceService.setplantillaHist(1);
  }
}
