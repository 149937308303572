
  <canvas #signatureCanvas
  (mousedown)="startDrawing($event)"
  (mousemove)="draw($event)"
  (mouseup)="endDrawing()"
  >
</canvas>

  <hr style="background-color: #aaaaaa; border: 0px; margin-top: 2px; width: 88%; margin-left: auto; margin-right: auto;">
  <div style="display: flex; justify-content: center;">
    <div class="containerbtn-signature" (click)="clearSignature()">
      <!-- <label>Eliminar</label> -->
      <img src="assets/iconos/eliminar.png" alt="Borrar firma"  title="Borrar firma" style="width: 24px; margin-left: 10px; cursor: pointer;">
    </div>
    <div class="containerbtn-signature" (click)="saveSignature()">
      <!-- <label>Guardar</label> -->
      <img src="assets/iconos/save4.png" alt="Guardar firma" title="Guardar firma" style="width: 23px; margin-left: 10px; cursor: pointer; margin-top: 3px;">
    </div>
  </div>

<!-- <button (click)="clearSignature()">Borrar firma</button> -->
<!-- <button (click)="saveSignature()">Guardar firma</button> -->
