import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tufesapack-horas',
  templateUrl: './tufesapack-horas.component.html',
  styleUrls: ['./tufesapack-horas.component.css']
})
export class TufesapackHorasComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
