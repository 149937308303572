import { Component, OnInit, Input, ViewChild, TemplateRef } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { MensajeService } from '../../servicios/mensaje.service';
import { LoadingService } from '../../servicios/loading.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-mensaje',
  templateUrl: './mensaje.component.html',
  styleUrls: ['./mensaje.component.css'],
})
export class MensajeComponent implements OnInit {
  @Input('inputMensaje') mensaje: string;
  @Input('inputIdmensaje') idmensaje: string;
  @Input('inputExito') exito: boolean;
  @Input('inputError') error: boolean;

  @ViewChild('templateexito', {static: false}) Texito: TemplateRef<any>;
  @ViewChild('templateerror', {static: false}) Terror: TemplateRef<any>;

  mensajeSubscription: Subscription;

  constructor(
    private modalService: BsModalService,
    private mensajeService: MensajeService,
    private loadinService: LoadingService
  ) { }

  ngOnDestroy(): void {
    this.mensajeSubscription.unsubscribe();

  }

  ngOnInit() {
    this.mensajeSubscription = this.mensajeService.subject$.subscribe(data => {
      if (this.mensaje === data.mensaje) {
        return;
      }

      this.mostrarmensaje(data.mensaje, data.exito);
      this.loadinService.changeLoadingFijo(false);
    });
  }

  @Input('inputModalRef') modalRef: BsModalRef;

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  closeModal() {
    this.mensaje = '';
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }

  mostrarmensaje(mensaje: string, exito: boolean) {
    this.exito = exito;
    this.mensaje = mensaje;

    if (this.exito === true) {
      this.openModal(this.Texito);
      return;
    } else if (this.exito === false) {
      this.openModal(this.Terror);
      return;
    }

  }

}
