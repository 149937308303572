<div class="container_herramienta">
  <img src="assets/img/web/cotiza.webp" class="img_toAnimate">
  <div class="alinearIzq">
    <div class="container-contenido">
      <div class="formulario" id="">
        <div [formGroup]="cotizaForm" novalidate="" class="main fade-in">

          <h1>Cotizar envío</h1>
          <h6 style="font-family: 'termina-regular', sans-serif; font-size: 14px;margin-bottom: 10px;color: #6d6e70;">
            Use esta sencilla herramienta para obtener una tarifa estimada.</h6>
          <div class="inputs">
            <div class="sucursal">
              <label>Origen</label>
              <ng-select
                [ngClass]="{'error' : (cotizaForm.get('origen').invalid || cotizaForm.get('origen').value === '0') && submitted }"
                [items]="ciudades" bindLabel="sucursal" bindValue="cp" appendTo="body" [multiple]="false"
                [clearable]="false" [editableSearchTerm]="false" [virtualScroll]="true" notFoundText="No se encontro"
                formControlName="origen">
              </ng-select>
            </div>

            <div class="sucursal">
              <label>Destino</label>
              <ng-select
                [ngClass]="{'error' : (cotizaForm.get('origen').invalid || cotizaForm.get('origen').value === '0') && submitted }"
                [items]="ciudades" bindLabel="sucursal" bindValue="cp" appendTo="body" [multiple]="false"
                [clearable]="false" [editableSearchTerm]="false" [virtualScroll]="true" notFoundText="No se encontro"
                formControlName="destino">
              </ng-select>
            </div>
            <div class="formbtncotizar">
              <button class="btnCotizar" id="cotizar" (click)="getCotizacion()">
                Cotizar
                <svg  id="cotizar1" class="material-icons" height="24" viewBox="0 0 24 24"
                  width="24" xmlns="http://www.w3.org/2000/svg">
                  <path  d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" fill="#FFFF"
                    id="cotizar2">
                  </path>
                  <path  d="M0 0h24v24H0z" fill="none" id="cotizar3"></path>
                </svg>
              </button>
            </div>
          </div>
        </div>

      </div>

    </div>
  </div>
</div>
