<!--ESPAÑOL-->
<div>
    <ng-template #templateerror class="modal-dialog">
      <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel">Lo sentimos.</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <img  class="imagen" src="assets/iconos/error.png" alt="error">
        <p>{{mensaje}}</p>
        <button type="button" class="btnContinuar" data-dismiss="modal" (click)="closeModal()">
          Aceptar
        </button>
      </div>
    </ng-template>
  
    <ng-template #templateexito class="modal fade">
      <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel">Atención</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
  
      <div class="modal-body">
        <img  class="imagen" src="assets/iconos/comprobado.png" alt="exito">
        <p>{{mensaje}}</p>
        <button type="button" class="btnContinuar" data-dismiss="modal" (click)="closeModal()">
          Aceptar
        </button>
      </div>
  
    </ng-template>
  </div>