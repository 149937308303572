

import { MensajeService } from 'src/app/servicios/mensaje.service';
import { MensajePopup } from 'src/app/entidades/MensajePopup';
import { BsDatepickerConfig, BsLocaleService } from "ngx-bootstrap/datepicker";
import { defineLocale, formatDate } from "ngx-bootstrap/chronos";
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Component, OnInit, TemplateRef, ViewChild, ɵConsole } from '@angular/core';
import { esLocale } from "ngx-bootstrap/locale"; // esLocale, es(idiomimport { formatDate } from 'ngx-bootstrap/chronos';
import { user } from '../../entidades/gwuser';
import { GuiaswebServiceService } from '../../servicios/guiasweb-service.service';
import { RastreoService } from '../../servicios/rastreo.service';
import jwt_decode from 'jwt-decode';
import { HttpErrorResponse } from '@angular/common/http';

import { Router } from '@angular/router';

defineLocale('es', esLocale);

@Component({
  selector: 'app-gw-historial',
  templateUrl: './gw-historial.component.html',
  styleUrls: ['./gw-historial.component.css']
})
export class GwHistorialComponent implements OnInit {
  userGw: user;
  finicial: string;
  ffinal: string;
  historial: any;
  numpantalla: number = 1;
  initialValues1: any;
  initialValues2: any;
  numeroRastreo: any;
  movements: any;

  modalRef: BsModalRef;

  token: string;
  tokendatos: any;

  // fecha
  datePickerConfig: Partial<BsDatepickerConfig>;
  locale = "es";
  fechaini: string = '';
  fechafin: string = '';
  fechafin2: string = '';
  fch1: Date = new Date();
  fch2: Date = new Date();
  minDate: Date = new Date();
  showMeridian: any;
  isMeridian: any;
  showSpinners: any;


  constructor(
    private GuiaswebServiceService: GuiaswebServiceService,
    private localeService: BsLocaleService,
    private rastreoService: RastreoService,
    private router: Router,
    private modalService: BsModalService,
    private mensajeService: MensajeService,
  ) {
    this.localeService.use("es");
    this.datePickerConfig = Object.assign(
      {},
      {
        containerClass: "theme-orange",
        isAnimated: true,
        showWeekNumbers: false,
        dateInputFormat: "DD/MM/YYYY"
      }
    );
  }

  ngOnInit(): void {
    this.GuiaswebServiceService.setModuloG(3);
    window.scroll();

    this.VerificarToken();
      this.token = sessionStorage.getItem('token')
      this.tokendatos = jwt_decode(this.token);
      this.userGw = this.tokendatos;

      this.GuiaswebServiceService.setplantillaHist(1);

      this.GuiaswebServiceService.numP.subscribe(
        datos => {

          if (datos == 0) {
            this.router.navigate(['/herramientasDigitales/documentacion/menu']);
          }
          this.numpantalla = datos;
        }
      );

      this.fechaini = formatDate(this.fch1, "YYYY-MM-DD");;
      this.fechafin = formatDate(this.fch1, "YYYY-MM-DD");;
      window.scrollTo(0,0);


  }

  VerificarToken() {
    if (sessionStorage.getItem('token') == undefined || sessionStorage.getItem('token') == "" || sessionStorage.getItem('token') == null) {
      this.mensajeService.subject$.next(new MensajePopup("Su sesión ha expirado", false, false));
      this.router.navigate(['/herramientasDigitales/login']);
      return;
    }
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  closeModal() {
    this.modalRef.hide();
  }


  // este guarda la fecha seleccionada
  onValueChangefchini(value: Date): void {
    let fch: any;

    if (value) {
      fch = formatDate(value, "YYYY-MM-DD");
      this.fechaini = fch;
      this.fechafin = this.fechaini
      this.fch2 = value;
    }


    this.minDate = value;

  }

  onValueChangefchfin(value: Date): void {
    let fch: any;

    if (value) {
      fch = formatDate(value, "YYYY-MM-DD");
      this.fechafin = fch;
    }

  }

  obtener() {
    window.scrollTo(0, 0);
    this.fechaini = this.fechaini.replace('-', '');
    this.fechaini = this.fechaini.replace('-', '');

    this.fechafin = this.fechafin.replace('-', '');
    this.fechafin = this.fechafin.replace('-', '');

    this.cargarguias();

  }


  async cargarguias() {
    this.VerificarToken();
    await this.GuiaswebServiceService.gwHistorialGuia(this.userGw.idUser, this.fechaini, this.fechafin, this.token).then(
      d => {

        this.historial = d;
      }
    ).catch((error: HttpErrorResponse) => {
      if (error.error.ExceptionMessage == "invalid_token") {
        this.mensajeService.subject$.next(new MensajePopup("Su sesión ha expirado", false, false));
        this.router.navigate(['/herramientasDigitales/login']);
      } else {
        this.mensajeService.subject$.next(new MensajePopup("ocurrio un error inesperado, intente de nuevo", false, false));
      }
    });
  }


  verdetalle(num) {
    sessionStorage.setItem('HistorialDet', JSON.stringify(num));
    this.GuiaswebServiceService.setplantillaHist(2);
  }


  historialRastreo(idRastreo) {
    this.numeroRastreo = idRastreo;
    this.track()
    const btn = document.getElementById('btn-modal') as HTMLElement;
    btn.click();
  }

  track() {
    this.VerificarToken();
    this.rastreoService.track_pack(this.numeroRastreo).subscribe(
      data => {

        this.movements = data;
        if (this.movements[0].msgnum === 0) {
          this.rastreoService.save_packs(this.movements);
        } else {
          this.mensajeService.subject$.next(new MensajePopup(this.movements[0].msgtxt, false, false));
        }
      }

    )
  }
}







