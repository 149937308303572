import { Component, OnInit } from '@angular/core';
import { GuiaswebServiceService } from '../../servicios/guiasweb-service.service'


@Component({
  selector: 'app-gw-menusuperior',
  templateUrl: './gw-menusuperior.component.html',
  styleUrls: ['./gw-menusuperior.component.css']
})
export class GwMenusuperiorComponent implements OnInit {
  Doc: boolean = false;
  Sol: boolean = false;
  His: boolean = false;
  Fac: boolean = false;
  Tbl: boolean = false;

  numeropantalla: number;
  constructor(
    private GuiaswebServiceService: GuiaswebServiceService,
  ) { }

  ngOnInit(): void {
    this.GuiaswebServiceService.numMG.subscribe(
      datos => {
       this.numeropantalla = datos;
       this.cambiarpaso(this.numeropantalla);
      }
    );
  }


  cambiarpaso(plantilla) {
    switch (plantilla) {
      case 1: {
        this.Doc = true;
        this.Sol = false;
        this.His = false;
        this.Fac = false;
        this.Tbl = false;
        break;
      }
      case 2: {
        this.Doc = false;
        this.Sol = true;
        this.His = false;
        this.Fac = false;
        this.Tbl = false;
        break;
      }
      case 3: {
        this.Doc = false;
        this.Sol = false;
        this.His = true;
        this.Fac = false;
        this.Tbl = false;
        break;
      }
      case 4: {
        this.Doc = false;
        this.Sol = false;
        this.His = false;
        this.Fac = true;
        this.Tbl = false;
        break;
      }
      case 5: {
        this.Doc = false;
        this.Sol = false;
        this.His = false;
        this.Fac = false;
        this.Tbl = true;
        break;
      }      
      
      default: {
        //statements;
        break;
      }
    }
  }

}
