import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { ReloadiframeService } from 'src/app/servicios/reloadiframe.service';

@Component({
  selector: 'app-aviso-privacidad',
  templateUrl: './aviso-privacidad.component.html',
  styleUrls: ['./aviso-privacidad.component.css'],
  preserveWhitespaces: false
})
export class AvisoPrivacidadComponent implements OnInit {
  url: any;

  constructor(
    private sanitizer: DomSanitizer,
    private reloadIframeS: ReloadiframeService
  ) { }

  ngOnInit() {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(environment.URLTools + "/avisoprivacidad?lang=" + environment.language + '&v=' + environment.version);
    this.reloadIframeS.subject$.subscribe(
      d => {
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(environment.URLTools + "/avisoprivacidad?lang=" + environment.language + '&v=' + environment.version);
      }
    );
  }
}

