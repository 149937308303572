<div class="container_herramienta">
    <img src="assets/img/web/cotiza-2.webp" class="img_toAnimate">
    <div class="alinearIzq">
        <h1 class="txt-section">Códigos de promoción</h1>
        <div class="container_boxes">
            <div class="input_box">
                <input class="promo_code" type="text" id="code" placeholder="Introduce tu código"
                    [(ngModel)]="codigoInput">
                <img src="assets/iconos/busqueda.png" class="search_icon">
                <button class="btn_canjear" (click)="validarcodigo()">Canjear
                    <svg class="material-icons" height="24" viewBox="0 0 24 24" width="24"
                        xmlns="http://www.w3.org/2000/svg">
                        <path _ngcontent-vuf-c10="" d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" fill="#FFFF">
                        </path>
                        <path _ngcontent-vuf-c10="" d="M0 0h24v24H0z" fill="none" id="cotizar3"></path>
                    </svg>
                </button>
            </div>
            <br>
            <p class="msj_code" id="codemsj">{{mensaje}}</p>
            <div id="container_promos" class="cont_promos">
                <div class="box_promo" id="promosbox" *ngFor="let guias of guiasData">
                    <h1 class="num_guias">{{guias.cant}}</h1>
                    <h3 style="margin: 10px;">{{guias.titulo}}</h3>
                    <div class="condiciones_box">
                        <div *ngFor="let condiciones of guias.condiciones">
                            <p>{{condiciones.condicion}}</p>
                        </div>
                    </div>
                    <p style="margin-top: 25px;">Aplican restricciones</p>
                    <button class="btn_obtener" (click)="canjearcodigo(guias.idpromo)">Obtener</button>
                </div>
            </div>
        </div>
        <div class="confirmacion" id="confirma">
            <!-- <div class="cont_close">
                <div class="btn_close">x</div>
            </div> -->
            <p  style="margin-top: 25px;">¡Tu código fue obtenido exitosamente! Puedes verlo disponible en la sección "Cortesía"</p>
            <a (click)="closePopup()" routerLink="/herramientasDigitales/documentacion/menu"><button class="btn_aceptar">Aceptar</button></a>
        </div>
    </div>
   
</div>
