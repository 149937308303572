<div class="container_herramienta">
    <img src="assets/img//web/contacto.webp" class="img_toAnimate">
    <div class="alinearIzq">
        <div class="container_frm">
            <div class="starter-template">
                <div class="row">
                    <div class="col-md-12 mt-5">
                        <h1>Contacto</h1>
                        <h3>Expresa tu opinión, sugerencias, quejas y demás en esta parte del sitio. Es de vital
                            importancia para seguir ofreciéndole un servicio de calidad.</h3>
                        <form [formGroup]="contacto" (ngSubmit)="onSubmit()">
                            <div class="form-group">
                                <label>Email</label>
                                <input [(ngModel)]="correo" type="text" formControlName="email" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                    <div *ngIf="f.email.errors.required">El email es obligatorio</div>
                                    <div *ngIf="f.email.errors.email">Por favor, ingresa tu Email/ Email valido</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Teléfono</label>
                                <input [(ngModel)]="telefono" type="text" formControlName="telefono"
                                    class="form-control" [ngClass]="{ 'is-invalid': submitted && f.telefono.errors }"
                                    maxlength="10" />
                                <div *ngIf="submitted && f.telefono.errors" class="invalid-feedback">
                                    <div *ngIf="f.telefono.errors.required">Por favor, ingresa el telefono (10 digitos)
                                    </div>
                                    <div *ngIf="f.telefono.errors.telefono">Por favor, ingresa el telefono (10 digitos)
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="mensaje">Mensaje</label>
                                <textarea [(ngModel)]="mensaje" class="form-control message" formControlName="mensaje"
                                    rows="3" [ngClass]="{ 'is-invalid': submitted && f.mensaje.errors }"></textarea>
                                <div *ngIf="submitted && f.mensaje.errors" class="invalid-feedback">
                                    <div *ngIf="f.mensaje.errors.required">Por favor, ingresa el Mensaje ( 6 Caracteres
                                        mínimo )</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <button [disabled] class="btn btn_aceptar">Aceptar</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal modal-confirm" id="modal-confirm2">
        <div id="dialog">
            Mensaje enviado satisfactoriamente.
            <br>
            <br>
            <div class="buttonsConfirm">
                <button class="btn_confirm" (click)="toggleModalconfirm()" (click)="limpiarcampos()">Aceptar</button>
            </div>
        </div>
    </div>
</div>