import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { GuiaswebServiceService } from '../../servicios/guiasweb-service.service';
import { environment } from "src/environments/environment";
import { documentacion } from "../../entidades/gwdocumentacion";
import { Router } from "@angular/router";
import { user } from "../../entidades/gwuser";
import { MensajePopup } from "src/app/entidades/MensajePopup";
import jwt_decode from "jwt-decode";
import { HttpErrorResponse } from "@angular/common/http";
import { MensajeService } from "src/app/servicios/mensaje.service";
import  Swal  from 'sweetalert2';

@Component({
  selector: "app-gw-doc-destinatario",
  templateUrl: "./gw-doc-destinatario.component.html",
  styleUrls: ["./gw-doc-destinatario.component.css"],
})
export class GwDocDestinatarioComponent implements OnInit {
  submitted: boolean;
  pais: any;
  ciudad: any;
  estado: any;
  colonia: any;
  idpais: number;
  idciudad: number;
  idestado: number;
  idcolonia: number;
  destinatarioForm: FormGroup;
  doc: documentacion;
  datagw: any;
  tipopaquete: string;

  token: string;
  tokendatos: any;

  constructor(
    private GuiaswebServiceService: GuiaswebServiceService,
    private router: Router,
    private mensajeService: MensajeService
  ) {}

  ngOnInit(): void {
    this.validarsesion();

    this.token = sessionStorage.getItem("token");
    this.tokendatos = jwt_decode(this.token);

    this.DestinatarioForm();
    this.tipopaquete = decodeURIComponent(
      escape(window.atob(sessionStorage.getItem("gwTipopaq")))
    );
    var docaux = decodeURIComponent(
      escape(window.atob(sessionStorage.getItem("gwDocumentacion")))
    );

    this.doc = JSON.parse(docaux);

    // this.cargarPais();
    this.cargardatosGuardatos();

    document.getElementById("nombred").focus();
    window.scrollTo(0, 0);
  }

  DestinatarioForm() {
    this.destinatarioForm = new FormGroup({
      nombre: new FormControl("", [Validators.required]),
      selectPais: new FormControl("", [Validators.required]),
      selectEstado: new FormControl("", [Validators.required]),
      selectCiudad: new FormControl("", [Validators.required]),
      selectColonia: new FormControl("", [Validators.required]),
      sucursalSeleccionado: new FormControl({ value: false, disabled: true }),
      domicilioSeleccionado: new FormControl({ value: false, disabled: true }),
      direccion: new FormControl("", [Validators.required]),
      telefono: new FormControl("", [
        Validators.required,
        Validators.pattern("[0-9]{10}"),
      ]),
      cp: new FormControl({ value: null, disabled: true }),
    });
  }

  get Des() {
    return this.destinatarioForm.controls;
  }

  validarsesion() {
    if (
      sessionStorage.getItem("token") == undefined ||
      sessionStorage.getItem("token") == "" ||
      sessionStorage.getItem("token") == null
    ) {
      this.mensajeService.subject$.next(
        new MensajePopup("Su sesión ha expirado", false, false)
      );
      this.router.navigate(["/herramientasDigitales/login"]);
    }

    if (
      sessionStorage.getItem("gwTipopaq") == undefined ||
      sessionStorage.getItem("gwTipopaq") == "" ||
      sessionStorage.getItem("gwTipopaq") == null ||
      sessionStorage.getItem("gwDocumentacion") == undefined ||
      sessionStorage.getItem("gwDocumentacion") == "" ||
      sessionStorage.getItem("gwDocumentacion") == null
    ) {
      this.mensajeService.subject$.next(
        new MensajePopup("Su sesión ha expirado", false, false)
      );
      this.router.navigate(["/herramientasDigitales/documentacion/menu"]);
    }
  }

  //cargamos los datos si regremos de una pantalla más adelante
  cargardatosGuardatos() {
    if (
      this.doc.destinatario.ciudad != 0 &&
      this.doc.destinatario.estado != 0
    ) {
      this.idpais = 1;
      this.idestado = this.doc.destinatario.estado;
      this.idciudad = this.doc.destinatario.ciudad;
      this.idcolonia = this.doc.destinatario.colonia;
      // this.cargarPais();
      this.cargarEstado();
      this.cargarCiudad();
      if(this.doc.remitente.NombreColonia == "OCURRE"){
        //  // Habilitar los campos
        this.destinatarioForm.get("sucursalSeleccionado").enable();
        this.destinatarioForm.get("domicilioSeleccionado").enable();

        // Establecer valores
        this.destinatarioForm.get("sucursalSeleccionado").setValue(true);
        this.destinatarioForm.get("domicilioSeleccionado").setValue(false);

        // Forzar la actualización de la validez
        this.destinatarioForm.get("sucursalSeleccionado").updateValueAndValidity();
        this.destinatarioForm.get("domicilioSeleccionado").updateValueAndValidity();
     }
      this.cargarColonia();
      this.destinatarioForm.controls.nombre.setValue(this.doc.destinatario.nombre);
      this.destinatarioForm.controls.selectPais.setValue(1);
      this.destinatarioForm.controls.selectEstado.setValue(this.doc.destinatario.estado);
      this.destinatarioForm.controls.selectCiudad.setValue(this.doc.destinatario.ciudad);
      this.destinatarioForm.controls.selectColonia.setValue(this.doc.destinatario.colonia);
      this.destinatarioForm.controls.direccion.setValue(this.doc.destinatario.direccion);
      this.destinatarioForm.controls.telefono.setValue(this.doc.destinatario.tel);
      this.destinatarioForm.controls.cp.setValue(this.doc.destinatario.cp);
    }
     else {
      this.cargarPais();
    }
  }

  cambiarFocus(e) {
    if (
      !(
        (e.keyCode >= 48 && e.keyCode <= 57) ||
        e.keyCode == 8 ||
        e.keyCode == 9 ||
        e.keyCode == 13 ||
        e.keyCode == 38 ||
        e.keyCode == 40 ||
        e.keyCode == 37 ||
        e.keyCode == 39 ||
        (e.keyCode >= 96 && e.keyCode <= 105)
      )
    ) {
      e.preventDefault();
    }
  }

  async cargarPais() {
    await this.GuiaswebServiceService.obtenerPais(
      "DST",
      this.doc.idsesion,
      this.token
    ).then((d) => {
      this.pais = d;
      this.destinatarioForm.get('selectPais').setValue(this.pais[0].idpais);
        // alert(this.pais[0].pais);
        this.paisChanged('1');
    });
  }

  async cargarEstado() {
    await this.GuiaswebServiceService.obtenerEstado(
      this.idpais,
      "DST",
      this.doc.idsesion,
      this.token
    ).then((d) => {
      this.estado = d;
    });
  }

  async cargarCiudad() {
    await this.GuiaswebServiceService.obtenerCiudades(
      this.idestado,
      "DST",
      this.doc.idsesion,
      this.token
    ).then((d) => {
      this.ciudad = d;
    });
  }

  async cargarColonia() {
    await this.GuiaswebServiceService.obtenerColonias(
      this.idestado,
      this.idciudad,
      "DST",
      this.doc.idsesion,
      this.token
    ).then((d) => {
      this.colonia = d;

      if(this.destinatarioForm.get('sucursalSeleccionado').value == true && this.destinatarioForm.get('domicilioSeleccionado').value == true){
          // alert("Solo uno");
          this.destinatarioForm.get('sucursalSeleccionado').setValue(false);
          this.destinatarioForm.get('domicilioSeleccionado').setValue(false);
          this.colonia = [];
          this.destinatarioForm.controls.selectColonia.setValue("");
         return
      }

      if(this.destinatarioForm.get('sucursalSeleccionado').value == false && this.destinatarioForm.get('domicilioSeleccionado').value == false){
          this.destinatarioForm.get('sucursalSeleccionado').setValue(false);
          this.destinatarioForm.get('domicilioSeleccionado').setValue(false);
          this.colonia = [];
          this.destinatarioForm.controls.selectColonia.setValue("");
         return
      }

      if(this.destinatarioForm.get('sucursalSeleccionado').value == true && this.destinatarioForm.get('domicilioSeleccionado').value == false){
          this.colonia = d;
         // Conservar solo el primer elemento del array
          const optionOcurre = this.colonia[0];
          this.colonia.splice(1); // Eliminar los elementos después del primer elemento
          // Asignar el array modificado al FormControl
          this.destinatarioForm.get("selectColonia").setValue(optionOcurre.idcol);
          this.destinatarioForm.get('selectColonia').setValue(this.colonia.length > 0 ? this.colonia[0].idcol : null);
          // this.remitenteForm.controls.selectColonia.setValue(-1);
          this.destinatarioForm.controls.direccion.setValue(
          this.colonia[0].colonia);
          this.destinatarioForm.controls.cp.setValue("0");
          this.destinatarioForm.controls.cp.disable();
          // this.coloniaChanged(-1);
          this.destinatarioForm.controls.direccion.disable();
       }

       if(this.destinatarioForm.get('sucursalSeleccionado').value == false && this.destinatarioForm.get('domicilioSeleccionado').value == true){
        this.colonia = d;
        if (Object.keys(this.colonia).length > 1) {
            // Eliminar el primer elemento del array
            this.colonia.shift();
            // Asignar el array modificado al FormControl
            this.destinatarioForm.get('selectColonia').setValue(this.colonia.length > 0 ? this.colonia[0].idcol : null);
            this.destinatarioForm.controls.direccion.setValue('');
            return
          } else {
              this.destinatarioForm.get('selectColonia').setValue(this.colonia.length > 0 ? this.colonia[0].idcol : null);
              this.destinatarioForm.controls.direccion.setValue(this.colonia[0].colonia);
              this.destinatarioForm.controls.cp.setValue("0");
              this.destinatarioForm.controls.cp.disable();
              this.destinatarioForm.controls.direccion.disable();
              this.destinatarioForm.get('sucursalSeleccionado').setValue(true);
              this.destinatarioForm.get('domicilioSeleccionado').setValue(false);
              // alert("Actualmente esta ciudad no tiene recolecciones a domicilio");
              Swal.fire({
                icon: 'info',
                title: 'Informativo',
                text: 'No se encontró cobertura a domicilio.',
                customClass: {
                  container: 'norecoleccion-modal'
                }
              });
              return
          }
       }

      // if (this.colonia.length == 1) {
      //   this.destinatarioForm.controls.selectColonia.setValue(-1);
      //   this.coloniaChanged(-1);

      //   this.destinatarioForm.controls.direccion.disable();
      // }
    });
  }

  solicitar() {
    this.submitted = true;
    if (this.destinatarioForm.valid) {
    }
  }

  //creamos la sesion en el paso 3
  async guardarsesion_paso3() {
    this.validarsesion();
    this.submitted = true;
    if (this.destinatarioForm.valid) {
      this.doc.paso = 3;
      this.llenarDestinatario();
      await this.GuiaswebServiceService.GwoprsesionDetV2(this.doc, this.token)
        .then((d) => {
          this.datagw = d;

          // agregamos los datos para el paso 4
          this.doc.idsedet = this.datagw.idsedet;
          this.doc.idsesion = this.datagw.idsesion;
          this.doc.paso = 4;
          sessionStorage.removeItem("gwDocumentacion");
          // sessionStorage.setItem('gwDocumentacion', JSON.stringify(this.doc));
          sessionStorage.setItem(
            "gwDocumentacion",
            window.btoa(unescape(encodeURIComponent(JSON.stringify(this.doc))))
          );
          //pasamos a la pantalla de tipo
          this.GuiaswebServiceService.setplantilla(4);
        })
        .catch((error: HttpErrorResponse) => {
          if (error.error.ExceptionMessage == "invalid_token") {
            this.mensajeService.subject$.next(
              new MensajePopup("Su sesión ha expirado", false, false)
            );
            this.router.navigate(["/herramientasDigitales/login"]);
          } else {
            this.mensajeService.subject$.next(
              new MensajePopup(
                "ocurrio un error inesperado al iniciar sesión en tu cuenta",
                false,
                false
              )
            );
          }
        });
    }
  }

  paisChanged(id) {
    this.idpais = id;
    this.idestado = 0;
    this.idciudad = 0;
    this.idcolonia = 0;
    this.destinatarioForm.controls.selectEstado.setValue("");
    this.destinatarioForm.controls.selectCiudad.setValue("");
    this.destinatarioForm.controls.selectCiudad.setValue("");
    this.destinatarioForm.controls.selectColonia.setValue("");
    this.destinatarioForm.controls.cp.setValue("");
    this.cargarEstado();
  }

  estadoChanged(id) {
    this.idestado = id;
    this.idciudad = 0;
    this.idcolonia = 0;
    this.destinatarioForm.controls.selectCiudad.setValue("");
    this.destinatarioForm.controls.selectCiudad.setValue("");
    this.destinatarioForm.controls.selectColonia.setValue("");
    this.destinatarioForm.controls.cp.setValue("");
    this.cargarCiudad();
  }

  ciudadChanged(id) {
    this.idciudad = id;
    this.idcolonia = 0;
    this.destinatarioForm.controls.selectColonia.setValue("");
    this.destinatarioForm.controls.cp.setValue("");
    // this.remitenteForm.get('sucursalSeleccionado').enable();
    // this.remitenteForm.get('recoleccionSeleccionado').enable();
    // this.remitenteForm.get('sucursalSeleccionado').setValue(true);

    // Habilitar los campos
    this.destinatarioForm.get("sucursalSeleccionado").enable();
    this.destinatarioForm.get("domicilioSeleccionado").enable();

    // Establecer valores
    this.destinatarioForm.get("sucursalSeleccionado").setValue(true);
    this.destinatarioForm.get("domicilioSeleccionado").setValue(false);

    // Forzar la actualización de la validez
    this.destinatarioForm.get("sucursalSeleccionado").updateValueAndValidity();
    this.destinatarioForm.get("domicilioSeleccionado").updateValueAndValidity();
    this.cargarColonia();
  }

  coloniaChanged(id) {
    this.idcolonia = id;

    for (let i = 0; i < this.colonia.length; i++) {
      if (this.idcolonia == this.colonia[i].idcol) {
        if (this.idcolonia == -1) {
          this.destinatarioForm.controls.direccion.setValue(
            this.colonia[i].colonia
          );
          this.destinatarioForm.controls.direccion.disable();
        } else {
          this.destinatarioForm.controls.direccion.enable();
        }

        this.destinatarioForm.controls.cp.setValue(this.colonia[i].codigo);
      }
    }
  }

  limpiar() {
    this.destinatarioForm.controls.nombre.setValue("");
    this.destinatarioForm.controls.selectPais.setValue("");
    this.destinatarioForm.controls.selectEstado.setValue("");
    this.destinatarioForm.controls.selectCiudad.setValue("");
    this.destinatarioForm.controls.selectColonia.setValue("");
    this.destinatarioForm.controls.direccion.setValue("");
    this.destinatarioForm.controls.telefono.setValue("");
    this.destinatarioForm.controls.cp.setValue("");
    window.scrollTo(0, 0);
  }

  llenarDestinatario() {
    this.doc.destinatario.nombre = this.destinatarioForm.controls.nombre.value;
    this.doc.destinatario.estado =
      this.destinatarioForm.controls.selectEstado.value;
    this.doc.destinatario.ciudad =
      this.destinatarioForm.controls.selectCiudad.value;
    this.doc.destinatario.colonia =
      this.destinatarioForm.controls.selectColonia.value;
    this.doc.destinatario.direccion =
      this.destinatarioForm.controls.direccion.value;
    this.doc.destinatario.tel = this.destinatarioForm.controls.telefono.value;
    this.doc.destinatario.cp = this.destinatarioForm.controls.cp.value;

    for (let i = 0; i < this.estado.length; i++) {
      if (
        this.destinatarioForm.controls.selectEstado.value ==
        this.estado[i].idedo
      ) {
        this.doc.destinatario.NombreEstado = this.estado[i].estado;
        break;
      }
    }

    for (let i = 0; i < this.ciudad.length; i++) {
      if (
        this.destinatarioForm.controls.selectCiudad.value == this.ciudad[i].idcd
      ) {
        this.doc.destinatario.Nombreciudad = this.ciudad[i].ciudad;
        break;
      }
    }

    for (let i = 0; i < this.estado.length; i++) {
      if (
        this.destinatarioForm.controls.selectColonia.value ==
        this.colonia[i].idcol
      ) {
        this.doc.destinatario.NombreColonia = this.colonia[i].colonia;
        break;
      }
    }
  }

  regresarMenu() {
    this.GuiaswebServiceService.setplantilla(2);
  }

  imprimirEstadoCheckboxes(controlName: string) {
    console.log('Estado de sucursalSeleccionado:', this.destinatarioForm.get('sucursalSeleccionado').value);
    console.log('Estado de domicilioSeleccionado:', this.destinatarioForm.get('domicilioSeleccionado').value);

    if(controlName == "sucursalSeleccionado"){
      this.destinatarioForm.get('domicilioSeleccionado').setValue(false);
    }

    if(controlName == "domicilioSeleccionado"){
       this.destinatarioForm.get('sucursalSeleccionado').setValue(false);
       this.destinatarioForm.controls.direccion.setValue("");
       this.destinatarioForm.controls.direccion.enable();
    }

     this.cargarColonia();
  }

}

