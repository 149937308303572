<div class="container_herramienta">
    <div class="alinearIzq">

        <form [formGroup]="solicitudForm" (ngSubmit)="solicitud(template)">
            <section class="RastreoRequestWrap form">
                <section class="RastreoSelects">
                    <h1>Solicitud de entrega</h1>
                    <div class="RastreoInputs">
                        <div class="RastreoName">
                            <label>Estado</label>
                            <div class="select select2">
                                <select formControlName="estado">
                                    <option id="selectState" *ngFor="let estado of estados"
                                        (click)="seleccionarEstado(estado)">
                                        {{estado.nombre}}
                                    </option>
                                </select>

                            </div>
                        </div>
                        <div class="RastreoName">
                            <label>Ciudad</label>
                            <div class="select select2">
                                <select formControlName="ciudad">
                                    <option id="selectCity" *ngFor="let ciudad of ciudades"
                                        (click)="seleccionarCiudad(ciudad)">
                                        {{ciudad.nombre}}</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="RastreoInputs">
                        <div class="RastreoName">
                            <label>Colonia</label>
                            <div class="select">
                                <select id="select" (change)="onChange($event.target.value)">
                                    <option value="" disabled selected>Seleccione</option>
                                    <option *ngFor="let res of colonias" value="res.asenta">{{res.asenta}}</option>
                                </select>
                            </div>
                        </div>

                        <div class="RastreoName">
                            <label>Nombre</label>
                            <input type="text" placeholder="Nombre" maxlength="250" class="RastreoRequestInpt"
                                formControlName="nombre">
                            <div *ngIf="submitted && s.nombre.errors">
                                <div *ngIf="s.nombre.errors.required" class="is-invalid">
                                    * Capture su nombre
                                </div>
                            </div>
                        </div>
                    </div>

                </section>

                <div class="RastreoInputs">
                    <div class="RastreoRequest">
                        <label>Correo</label>
                        <input type="email" placeholder="Correo" maxlength="250" class="RastreoRequestInpt"
                            formControlName="correo">
                        <div *ngIf="submitted && s.correo.errors">
                            <div *ngIf="s.correo.errors.required" class="is-invalid">
                                * Capture su correo eléctronico
                            </div>
                        </div>
                    </div>
                    <div class="RastreoRequest">
                        <label>Teléfono</label>
                        <input type="tel" placeholder="Teléfono" maxlength="14" class="RastreoRequestInpt"
                            formControlName="telefono" (keydown)="onKeydown($event)">
                        <div *ngIf="submitted && s.telefono.errors">
                            <div *ngIf="s.telefono.errors.required" class="is-invalid">
                                * Capture su número de teléfono
                            </div>
                        </div>
                    </div>
                </div>


                <label>Dirección</label>
                <input type="text" placeholder="Direccion" maxlength="250" class="RastreoRequestInpt"
                    formControlName="direccion">
                <div *ngIf="submitted && s.direccion.errors">
                    <div *ngIf="s.direccion.errors.required" class="is-invalid">
                        * Capture su direccion
                    </div>
                </div>


                <label>Referencia</label>
                <input type="text" placeholder="Referencia" maxlength="250" class="RastreoRequestInpt"
                    formControlName="referencia">
                <div *ngIf="submitted && s.referencia.errors">
                    <div *ngIf="s.referencia.errors.required" class="is-invalid">
                        * Capture su referencia
                    </div>
                </div>

                <div class="botones">
                    <button class="btnBack" (click)="volverStatus()" id="btnRastreoSolBack">Regresar</button>
                    <button class="btnContinuar" id="btnRastreoSolNext">Continuar</button>
                </div>

            </section>
        </form>

        <ng-template #template>
            <div class="modal-header">
                <h4 class="modal-title pull-left">Mensaje</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()"
                    id="btnRastreoSolCloseModal">
                    <span class="close" aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                {{respuestaApi.msgtxt}}
            </div>
        </ng-template>

    </div>
</div>