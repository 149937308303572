import { remitente } from "./gwremitente";
import { destinatario } from "./gwdestinatario";
import { tipo } from "./gwtipo";
import {detalle} from "./gwdetalle"

export class documentacion {
    idsedet: number = 0 ;
    idsesion: number = 0 ;
    idcliente: number = 0;
    tiposol: number = 0;
    paso:  number = 1 ;
    pasoMAx: number = 1;
    remitente = new remitente() ;
    destinatario = new destinatario();
    tipo : tipo[] =  [];
    detalle =  new detalle(); 
}
