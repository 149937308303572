import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
// import { BsDropdownModule } from 'ngx-bootstrap';
import { DatepickerModule, BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { TimepickerModule } from "ngx-bootstrap/timepicker";
//import angular material para crear tablas
import { MatCardModule } from "@angular/material/card";
import { MatTableModule } from "@angular/material/table";
import { CommonModule } from '@angular/common';
import {MatPaginatorIntl,MatPaginatorModule,} from "@angular/material/paginator";
// import Agm de google maps
import { AgmCoreModule } from "@agm/core";
// filtro
import { Ng2SearchPipeModule } from "ng2-search-filter";
//import para carousel
import { OwlModule } from "ngx-owl-carousel";



import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import {HttpClientModule,HTTP_INTERCEPTORS,HttpClient,} from "@angular/common/http";
import { DeviceDetectorModule } from "ngx-device-detector";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { Ng2PageScrollModule } from "ng2-page-scroll";
import { NgSelectModule } from "@ng-select/ng-select";
import { InViewportModule } from "ng-in-viewport";

// COMPONENTES
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./componentes/header/header.component";
import { MenuComponent } from "./componentes/menu/menu.component";
import { HeroComponent } from "./componentes/hero/hero.component";
import { ChartsComponent } from "./componentes/charts/charts.component";
import { BrandImpactComponent } from "./componentes/brand-impact/brand-impact.component";
import { AboutUsComponent } from "./componentes/about-us/about-us.component";
import { FooterComponent } from "./componentes/footer/footer.component";
import { InformacionUtilComponent } from "./componentes/informacion-util/informacion-util.component";
import { PreguntasFrecuentesComponent } from "./componentes/preguntas-frecuentes/preguntas-frecuentes.component";
import { TituloComponent } from "./componentes/titulo/titulo.component";
import { IndexComponent } from "./componentes/index/index.component";
import { TipsSeguridadComponent } from "./componentes/tips-seguridad/tips-seguridad.component";
import { AvisoPrivacidadComponent } from "./componentes/aviso-privacidad/aviso-privacidad.component";
import { BuzonComponent } from "./componentes/buzon/buzon.component";
import { DirectorioComponent } from "./componentes/directorio/directorio.component";
import { BolsaTrabajoComponent } from "./componentes/bolsa-trabajo/bolsa-trabajo.component";
import { CondicionesComponent } from "./componentes/condiciones/condiciones.component";
import { FacturacionComponent } from "./componentes/facturacion/facturacion.component";
import { PaqueteriaRastreoComponent } from "./componentes/paqueteria-rastreo/paqueteria-rastreo.component";
import { PaqueteriaRecoleccionComponent } from "./componentes/paqueteria-recoleccion/paqueteria-recoleccion.component";
import { PuntoEnvioComponent } from "./componentes/punto-envio/punto-envio.component";
import { SeleccionSucursalComponent } from "./componentes/seleccion-sucursal/seleccion-sucursal.component";
import { CotizacionResumenComponent } from "./componentes/cotizacion-resumen/cotizacion-resumen.component";
import { CoberturaComponent } from "./componentes/cobertura/cobertura.component";
import { CotizacionComponent } from "./componentes/cotizacion/cotizacion.component";
import { ServiciosComponent } from "./componentes/servicios/servicios.component";
import { PoliticasComponent } from "./componentes/politicas/politicas.component";
import { EnviosExtranjeroComponent } from "./componentes/envios-extranjero/envios-extranjero.component";
import { EnvioResumenComponent } from "./componentes/envio-resumen/envio-resumen.component";
import { GuiasClientesComponent } from "./componentes/guias-clientes/guias-clientes.component";
import { GwUserLoginComponent } from "./componentes/gw-user-login/gw-user-login.component";

import { GwMenuDocumentacionComponent } from "./componentes/gw-menu-documentacion/gw-menu-documentacion.component";
import { GwHistorialComponent } from "./componentes/gw-historial/gw-historial.component";
import { GwFacturasComponent } from "./componentes/gw-facturas/gw-facturas.component";
import { ContratoAdhesionComponent } from "./componentes/contrato-adhesion/contrato-adhesion.component";
import { ListadoRecoleccionComponent } from "./componentes/listado-recoleccion/listado-recoleccion.component";
import { VerificaComponent } from "./componentes/verifica/verifica.component";

// SERVICIOS
import { WindowScrollService } from "src/app/servicios/window-scroll.service";
import { DestinoService } from "src/app/servicios/destino.service";
import { NumericDirective } from "./numeric.directive";
import { ChatComponent } from "./componentes/chat/chat.component";
import { LoLlevaComponent } from "./componentes/lo-lleva/lo-lleva.component";
import { MensajeComponent } from "./componentes/mensaje/mensaje.component";
import { RastreoComponent } from "./componentes/rastreo/rastreo.component";
import { RastreoSolicitudComponent } from "./componentes/rastreo-solicitud/rastreo-solicitud.component";
import { RastreoConfirmacionComponent } from "./componentes/rastreo-confirmacion/rastreo-confirmacion.component";
import { LoadingComponent } from "./componentes/loading/loading.component";
import { LoadingService } from "./servicios/loading.service";
import { httpInterceptor } from "./httpInterceptor";
import { GuiaswebServiceService } from "./servicios/guiasweb-service.service";
import { GwDocRemitenteComponent } from "./componentes/gw-doc-remitente/gw-doc-remitente.component";
import { GwDocDestinatarioComponent } from "./componentes/gw-doc-destinatario/gw-doc-destinatario.component";
import { GwDocTipoComponent } from "./componentes/gw-doc-tipo/gw-doc-tipo.component";
import { GwDocDetalleComponent } from "./componentes/gw-doc-detalle/gw-doc-detalle.component";
import { GwDocCompletaComponent } from "./componentes/gw-doc-completa/gw-doc-completa.component";
import { GwDocEstructuraComponent } from "./componentes/gw-doc-estructura/gw-doc-estructura.component";
import { GwMenusuperiorComponent } from "./componentes/gw-menusuperior/gw-menusuperior.component";
import { GwMenuConfirmacionComponent } from "./componentes/gw-menu-confirmacion/gw-menu-confirmacion.component";
import { GwSolicitarguiasComponent } from "./componentes/gw-solicitarguias/gw-solicitarguias.component";
import { GwUsrRegistroComponent } from "./componentes/gw-usr-registro/gw-usr-registro.component";
import { GwCompararpassComponent } from "./componentes/gw-compararpass/gw-compararpass.component";
import { GwHistorialDetComponent } from "./componentes/gw-historial-det/gw-historial-det.component";
import { GwRestablecerpasswordComponent } from "./componentes/gw-restablecerpassword/gw-restablecerpassword.component";
import { GwVerificarcuentaComponent } from "./componentes/gw-verificarcuenta/gw-verificarcuenta.component";
import { GwCambiarpasswordComponent } from "./componentes/gw-cambiarpassword/gw-cambiarpassword.component";
import { GwSolicitudguiaDetalleComponent } from "./componentes/gw-solicitudguia-detalle/gw-solicitudguia-detalle.component";
import { HerramientasMenuComponent } from "./componentes/herramientas-menu/herramientas-menu.component";
import { RastrearEnvioComponent } from "./componentes/rastrear-envio/rastrear-envio.component";
import { CotizacionFormComponent } from "./componentes/cotizacion-form/cotizacion-form.component";
import { RecoleccionComponent } from "./componentes/recoleccion/recoleccion.component";
import { PromocionesComponent } from "./componentes/promociones/promociones.component";
import { ContactoComponent } from './componentes/contacto/contacto.component';
import { MensajeriaComponent } from './componentes/mensajeria/mensajeria.component';
import { PaqueteriaComponent } from './componentes/paqueteria/paqueteria.component';
import { CargaComponent } from './componentes/carga/carga.component';
import { DeliveryComponent } from './componentes/delivery/delivery.component';
import { GrupoTufesaComponent } from './componentes/grupo-tufesa/grupo-tufesa.component';
import { NuestrasMarcasComponent } from './componentes/nuestras-marcas/nuestras-marcas.component';
import { GuiasWebComponent } from './componentes/guias-web/guias-web.component';
import { CodigoPromocionComponent } from './componentes/codigo-promocion/codigo-promocion.component';
import { AdministrarCuentaComponent } from './componentes/administrar-cuenta/administrar-cuenta.component';
import { DetalleTransaccionComponent } from './componentes/detalle-transaccion/detalle-transaccion.component';
import { CapturaPagoComponent } from './componentes/captura-pago/captura-pago.component';
import { TupromoComponent } from './componentes/tupromo/tupromo.component';
import { ConfirmacionComponent } from './componentes/confirmacion/confirmacion.component';
import { HistorialCompraComponent } from './componentes/historial-compra/historial-compra.component';
import { DocumentosComponent } from './componentes/documentos/documentos.component';
import { MisformatosComponent } from './componentes/misformatos/misformatos.component';
import { SignatureComponent } from './componentes/signature/signature.component';
import { AfiliadosComponent } from './componentes/afiliados/afiliados.component';
import { CrearMisFormatosComponent } from './componentes/crear-mis-formatos/crear-mis-formatos.component';
import { AniversarioComponent } from './componentes/aniversario/aniversario.component';
import { Tupromo2Component } from './componentes/tupromo2/tupromo2.component';
import { TufesapackHorasComponent } from './componentes/tufesapack-horas/tufesapack-horas.component';
 



@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    MenuComponent,
    HeroComponent,
    ChartsComponent,
    BrandImpactComponent,
    AboutUsComponent,
    FooterComponent,
    InformacionUtilComponent,
    PreguntasFrecuentesComponent,
    TituloComponent,
    IndexComponent,
    TipsSeguridadComponent,
    AvisoPrivacidadComponent,
    BuzonComponent,
    DirectorioComponent,
    BolsaTrabajoComponent,
    CondicionesComponent,
    FacturacionComponent,
    PaqueteriaRastreoComponent,
    PaqueteriaRecoleccionComponent,
    PuntoEnvioComponent,
    SeleccionSucursalComponent,
    CoberturaComponent,
    CotizacionComponent,
    NumericDirective,
    CotizacionResumenComponent,
    ServiciosComponent,
    PoliticasComponent,
    EnviosExtranjeroComponent,
    ChatComponent,
    LoLlevaComponent,
    MensajeComponent,
    EnvioResumenComponent,
    RastreoComponent,
    RastreoSolicitudComponent,
    RastreoConfirmacionComponent,
    LoadingComponent,
    GuiasClientesComponent,
    GwUserLoginComponent,
    ContratoAdhesionComponent,
    GwMenuDocumentacionComponent,
    GwHistorialComponent,
    GwFacturasComponent,
    GwDocRemitenteComponent,
    GwDocDestinatarioComponent,
    GwDocTipoComponent,
    GwDocDetalleComponent,
    GwDocCompletaComponent,
    GwDocEstructuraComponent,
    GwMenusuperiorComponent,
    GwMenuConfirmacionComponent,
    GwSolicitarguiasComponent,
    GwUsrRegistroComponent,
    GwCompararpassComponent,
    GwHistorialDetComponent,
    GwRestablecerpasswordComponent,
    GwVerificarcuentaComponent,
    GwCambiarpasswordComponent,
    GwSolicitudguiaDetalleComponent,
    HerramientasMenuComponent,
    RastrearEnvioComponent,
    CotizacionFormComponent,
    RecoleccionComponent,
    PromocionesComponent,
    ListadoRecoleccionComponent,
    VerificaComponent,
    ContactoComponent,
    MensajeriaComponent,
    PaqueteriaComponent,
    CargaComponent,
    DeliveryComponent,
    GrupoTufesaComponent,
    NuestrasMarcasComponent,
    GuiasWebComponent,
    CodigoPromocionComponent,
    AdministrarCuentaComponent,
    DetalleTransaccionComponent,
    CapturaPagoComponent,
    TupromoComponent,
    ConfirmacionComponent,
    HistorialCompraComponent,
    DocumentosComponent,
    MisformatosComponent,
    SignatureComponent,
    AfiliadosComponent,
    CrearMisFormatosComponent,
    AniversarioComponent,
    Tupromo2Component,
    TufesapackHorasComponent,
    
  ],

  imports: [
    // BsDropdownModule.forRoot(),
    DeviceDetectorModule.forRoot(),
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    DatepickerModule.forRoot(),
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    Ng2PageScrollModule,
    OwlModule,
    NgSelectModule,
    InViewportModule,
    TimepickerModule,
    MatCardModule,
    MatTableModule,
    MatPaginatorModule,
    AgmCoreModule.forRoot({apiKey: "AIzaSyCyf5AM3wZYVE2fpG1Raus1Gbbuvdot79w",}),
    BrowserModule,
    CommonModule,
    FormsModule,
    Ng2SearchPipeModule,
  ],
  providers: [
    WindowScrollService,
    DestinoService,
    GuiaswebServiceService,
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: (service: LoadingService) => new httpInterceptor(service),
      multi: true,
      deps: [LoadingService],
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

export class AppMaterialModule {}
