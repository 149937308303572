import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-bolsa-trabajo',
  templateUrl: './bolsa-trabajo.component.html',
  styleUrls: ['./bolsa-trabajo.component.css']
})
export class BolsaTrabajoComponent implements OnInit {
  administrativo: boolean;
  operadores: boolean;
  index: boolean = true;  

  constructor() { }

  ngOnInit() {
  }

  mostrarAdministrativo() {
    this.administrativo = true; 
    this.index = false;
    this.operadores = false;
  }

  mostrarOperadores() {
    this.administrativo = false; 
    this.index = false;
    this.operadores = true;
  }


}
