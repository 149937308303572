<h1 class="txt-section">Contrato de Adhesión</h1>
<div class="container">
  <div class="politicaText">
    <div class="subTitle">CONTRATO DE ADHESIÓN POR SERVICIO DE PAQUETERÍA</div>
    <ul>
      <p>CONTRATO DE ADHESIÓN PARA LA PRESTACIÓN DE SERVICIOS DE PAQUETERIA Y MENSAJERÍA QUE CELEBRAN POR
        UNA PARTE “AUTOTRANSPORTES TUFESA, S.A. DE C.V.” A QUIÉN EN LO SUCESIVO SE LE DONIMINARÁ
        PROVEEDOR, Y, POR OTRA PARTE, EL REMITENTE DEL PAQUETE QUE SE INDENTIFICA EN EL ANVERSO DEL
        PRESENTE DOCUMENTO, A QUIÉN EN LO SUCESIVO SE LE DENOMINARÁ CONSUMIDOR, AL TENOR DE LAS
        SIGUIENTES DECLARACIONES Y CLÁUSULAS:</p>
    </ul>
  </div>

  <div class="politicaText">
    <div class="subTitle">DECLARACIONES</div>
    <p>I. DECLARA EL “PROVEEDOR”:</p>
    <ol type="1">
      <li>Ser una persona moral constituida según escritura pública 8,111, pasada ante la fe del notario público
        número 44 el Lic. Ramón de León García, en la ciudad de Guaymas, Sonora, registrado con el número
        6733 ante el registro público de la propiedad en Guaymas, Sonora, en fecha 30 de junio de 1994. Que su
        apoderado legal acredita su personalidad con escritura pública 34,821, de fecha 04 de enero del 2012,
        otorgada ante la fe del Lic. Gabriel I. Alfaro Rivera, Notario Público 18 en Cd. Obregón, Sonora.</li>
      <li>Contar con objeto social de mensajería y envíos, y con los recursos humanos y financieros para obligarse
        en los términos del presente contrato.</li>
      <li>Tener su domicilio fiscal en Calle Sufragio Efectivo #440-Sur, en la Col. Centro, en Cd. Obregón, Sonora;
        C.P. 85000. RFC: ATU-940621-PU7.</li>
      <li>Para dudas y aclaraciones señala el teléfono (644)4102444, y el correo electrónico:
        atencionaclientes@tufesa.com.mx, puntualizando que la atención es de lunes a domingo de las 07:00 horas
        a las 21:00 horas.</li>
    </ol>

  </div>

  <div class="politicaText">
    <p>II. DECLARA EL CONSUMIDOR:</p>
    <ul>
      <li type="1">Que su nombre, domicilio, teléfono, correo electrónico y R.F.C. constan en el anverso de este
        contrato, que
        es su deseo sujetarse voluntariamente y en pleno uso de su capacidad legal, a las condiciones de servicio de
        paquetería y mensajería que se establecen en el presente contrato y que son las mismas referidas en el
        denominando carta porte o factura.</li>
    </ul>

  </div>

  <div class="politicaText">
    <div class="subTitle">CLÁUSULAS</div>
    <ul>
      <li>PRIMERA. - Este contrato de servicio de paquetería y mensajería encuentra fundamento en los artículos 85 y 86
        la
        Ley Federal de Protección al Consumidor y en el Artículo 16 del Reglamento de Paquetería y Mensajería, y tiene
        por objeto establecer las bases legales por las que el PROVEEDOR se obliga ante el CONSUMIDOR a realizar el
        porte de paquetes y mensajería debidamente envueltos o con embalaje, dentro de su cobertura y alcance, acorde
        a las disposiciones establecidas en el citado ordenamiento federal, así como en las condiciones previstas en el
        presente contrato, con el objetivo de hacer llegar y entregar los envíos al destino indicado por el CONSUMIDOR,
        bajo las condiciones especificadas en la Carta Porte o Guía de servicio consignada en el anverso de este
        documento, y que deberá contener los requisitos que para tal efecto precisa el artículo 20 del Reglamento antes
        referido. Para el caso de la copia emitida al CONSUMIDOR, ésta podrá ser expedida de forma electrónica o
        impresa, en cuyo caso se considerará como base del servicio aquí regulado.</li>
      <li>SEGUNDA. - El PROVEEDOR utilizará la flota vehicular propia que comprende autobuses, vehículos de bajo
        tonelaje
        y/o tractocamiones, para la transportación de los envíos contratados, incluso podrá contratar a terceros para
        cumplir con el compromiso de servicio ofrecido, en cuyo caso mantendrá la responsabilidad directa del servicio
        ante el CONSUMIDOR.</li>
      <li>TERCERA. - Para hacer llegar el envío al consignatario, el PROVEEDOR pone a disposición del CONSUMIDOR la
        modalidad de OCURRE Y DOMICILIADO. El servicio OCURRE será de oficina a oficina del PROVEEDOR. El servicio
        DOMICILIADO será para entrega directa al domicilio señalado del destinatario.</li>
      <li>CUARTA. - El CONSUMIDOR se obliga a pagar la tarifa y los impuestos del servicio solicitado y a declarar con
        veracidad el contenido de su envío. Los envíos legalmente restringidos son los consignados en el artículo 18 del
        Reglamento de Paquetería y Mensajería que son los que se señalan a continuación:</li>
    </ul>
  </div>

  <div class="politicaText">

    <ol type="a">
      <li>Billetes o anuncios de lotería extranjera;</li>
      <li>Materiales y residuos a los que hace referencia el Reglamento para el Transporte Terrestre de Materiales
        y Residuos Peligrosos, salvo que se obtenga la autorización correspondiente de la autoridad competente;</li>
      <li>Psicotrópicos y estupefacientes, salvo que su posesión o traslado sea lícita conforme a las disposiciones
        legales aplicables;</li>
      <li>Armas de fuego y explosivos;</li>
      <li>Animales o perecederos, cuando no se cumplan las condiciones de higiene y seguridad adecuadas, de
        acuerdo con la normatividad aplicable;</li>
      <li>Dinero o títulos de crédito al portador o negociables;</li>

      <li>Cualquier otro bien cuyo tránsito requiera de permiso específico o bien lo restrinja alguna ley en
        particular, sin que se cuente con dicho permiso específico, y,</li>
      <li>Cualquier otro bien que prohíban las leyes específicas de la materia.</li>
    </ol>
  </div>

  <div class="politicaText">
    <p>Por su parte EL CONSUMIDOR manifiesta conocer cuáles son esos envíos restringidos, por lo que será el único
      responsable en caso de que su envío no se ajuste a los bienes permitidos por ley y por las políticas del
      PROVEEDOR.</p>
  </div>

  <div class="politicaText">
    <p>CUARTA Bis. - Tratándose sobre incumplimiento del presente contrato de adhesión de las obligaciones que no se
      establezcan dentro del mismo, el PROVEEDOR estará obligado a realizar en favor del CONSUMIDOR el pago por
      concepto de pena convencional que será por el monto del 20% calculado en base al valor que el CONSUMIDOR
      pagó por la contratación del servicio, señalándose que no sé sumará una dicha pena convencional a la
      indemnización contenida dentro del segundo párrafo de la cláusula octava del presente.</p>
  </div>

  <div class="politicaText">
    <p>QUINTA. - El PROVEEDOR publicará en el interior de sus instalaciones y a la vista del CONSUMIDOR, las políticas
      de
      servicio, bienes restringidos para envío, condiciones de tiempo y plazos de entrega según el servicio contratado,
      así como los costos de cada servicio de envío según distancia y peso.</p>
  </div>

  <div class="politicaText">
    <p>Como regla general, todo envío contratado dentro un rango no mayor a 600 kilómetros de distancia, será
      entregado en un plazo no mayor a dos días hábiles. Envíos con destino mayor a los 600 km de distancia, podrá
      causar un retraso de entrega estimado en no más de un día hábil por cada 150 kilómetros adicionales.</p>
  </div>

  <div class="politicaText">
    <p>SEXTA. - Por motivos de seguridad y calidad en el servicio, al momento de recibir el paquete para documentación,
      el PROVEEDOR podrá solicitar al CONSUMIDOR que abra el paquete y muestre el contenido de su envío, pero una
      vez embalado, el paquete tendrá el carácter de inviolable, no obstante, el PROVEEDOR deberá proceder a la
      apertura y reconocimiento de los paquetes ante orden expresa emitida por autoridad competente, en cuyo caso
      no habrá responsabilidad para el PROVEEDOR.</p>
  </div>

  <div class="politicaText">
    <p>La responsabilidad del PROVEEDOR inicia desde la recepción o recolección del paquete y termina hasta su entrega
      al destinatario o al tercero autorizado, o bien, cuando el paquete le sea retirado en cumplimiento de una orden de
      autoridad competente. La reclamación correspondiente estará sujeta en cuanto a su forma y términos a lo
      dispuesto dentro del artículo 99 de la Ley Federal de Protección al Consumidor.</p>
  </div>

  <div class="politicaText">
    <p>El CONSUMIDOR está de acuerdo en que una vez que el destinatario reciba la paquetería enviada, éste deberá
      revisarla para confirmar que se encuentra en las condiciones físicas esperadas, y en caso de presentar alguna
      anomalía hacerla saber inmediatamente al PROVEEDOR, ya que una vez recibido y abandonada las instalaciones
      con el paquete, culmina toda responsabilidad del PROVEEDOR con el servicio.</p>
  </div>

  <div class="politicaText">
    <p>SÉPTMA. - El CONSUMIDOR será responsable por el uso delictivo o ilícito que éste hiciera del servicio de
      contratado, por lo que es su obligación incluir en su envío toda documentación que acredite la legal estancia de
      su
      mercancía en el país, por lo que deslinda al PROVEEDOR de cualquier irregularidad derivada del envío de
      mercancía de procedencia extranjera.</p>
  </div>

  <div class="politicaText">
    <p>OCTAVA - El PROVEEDOR será responsable por el retraso, pérdida, robo, extravío, daños, faltantes, destrucción de
      los paquetes o por cualquier otro daño o perjuicio que pueda originarse con motivo de la prestación del servicio
      de
      paquetería y mensajería, excepto en casos fortuitos o de fuerza mayor en términos de la legislación aplicable y en
      lo particular, de acuerdo con los términos establecidos en la propia Carta de Porte o Guía.
      La indemnización por cualquiera de los eventos señalados en el párrafo anterior será el equivalente a 15 (quince)
      UMA vigentes al momento de la contratación. El consumidor deberá de contratar por su cuenta un seguro para la
      protección del contenido de su envío.</p>
  </div>

  <div class="politicaText">
    <p>NOVENA. - El PROVEEDOR pondrá a disposición el servicio tipo OCURRE, con el que el CONSUMIDOR podrá enviar
      su paquete a la sucursal de El PROVEEDOR en otra ciudad para que el Destinatario lo recoja ahí mismo y para ello
      tendrá 10 días (diez) hábiles posteriores a la fecha recepción del paquete en la sucursal. Si el DESTINATARIO
      reclamar el paquete a partir del día 11 (once), deberá pagar al PROVEEDOR el 3% (tres por ciento) sobre el costo
      del envío, por concepto de resguardo y almacenaje excedente. El paquete solo se tendrá en resguardo un plazo
      máximo de 30 (treinta) días naturales posteriores a la fecha y hora de la documentación de origen. Si el paquete
      no es reclamado por el destinatario o por el CONSUMIDOR, el PROVEEDOR quedará eximido de toda
      responsabilidad sobre el paquete.</p>
  </div>

  <div class="politicaText">
    <p>El PROVEEDOR pondrá a disposición el servicio a DOMICILIO, con el que el CONSUMIDOR podrá enviar su paquete
      a determinado domicilio para su entrega personal al DESTINATARIO. En este servicio el PROVEEDOR quedará
      eximido de toda responsabilidad si la dirección del domicilio señalada por el CONSUMIDOR en la Carta Porte o
      Guía no es correcta, o bien el teléfono otorgado no sea correcto o no contesten, incluso si no hubiera persona
      mayor de edad que pueda recibir el paquete en el domicilio señalado, el PROVEEDOR realizará el intento de hacer
      la entrega una vez más, pero en caso de que no se pueda entregar en esa segunda oportunidad, se resguardará el
      paquete en las oficinas del PROVEEDOR por un tiempo máximo de 3 (tres) días hábiles para que sea reclamado, de
      no reclamarse se regresará a la sucursal de origen donde el CONSUMIDOR podrá pasar a recoger su paquete,
      debiendo pagar el costo de la reexpedición hacia el origen, así como el costo por almacenaje calculado sobre la
      base expuesta en el primer párrafo de la presente cláusula.</p>
  </div>

  <div class="politicaText">
    <p>DÉCIMA. - El PROVEEDOR no tendrá responsabilidad en la prestación del servicio de paquetería y mensajería por:
      IPor
      acto o disposición de cualquier autoridad judicial o administrativa; II. Cuando se haya entregado el Paquete en
      los términos pactados en el domicilio del Destinatario; III. Por pérdida o daño del Paquete por caso fortuito o
      fuerza mayor; IV. Por paquetes decomisados por autoridad competente; V. Por paquetes dañados por empaque
      inadecuado que no haya sido realizado por el PROVEEDOR; VI. Cuando por causa de un embalaje defectuoso el
      paquete se sufra deterioro parcial o total, en el entendido de que el embalaje en todo momento será
      responsabilidad del CONSUMIDOR, y VII. Por retraso, pérdida o daño que sufran los paquetes cuando el Remitente
      hubiese proporcionado información incorrecta o incompleta en la Carta de Porte o Guía y ello hubiese sido la
      causa del retraso en la entrega, pérdida o daño del Paquete.</p>
  </div>

  <div class="politicaText">
    <p>DÉCIMA PRIMERA. - La Procuraduría Federal del Consumidor es competente en la vía administrativa para resolver
      cualquier controversia que se suscite sobre la interpretación o cumplimiento del presente contrato. Sin perjuicio
      de lo anterior, las partes se someten a la jurisdicción de los tribunales de Cd. Obregón, Sonora, México,
      renunciando expresamente a cualquier otra jurisdicción que pudiera corresponderles, por razón de sus domicilios
      presentes o futuros o por cualquier razón.</p>
  </div>

  <div class="politicaText">
    <p>El presente contrato de adhesión fue celebrado entre el PROVEEDOR y el CONSUMIDOR en fecha
      ____________________.</p>
  </div>

  <div class="politicaText">
    <p>EL PRESENTE CONTRATO DE ADHESIÓN FUE REGISTRADO EN EL REGISTRO PÚBLICO DE CONTRATOS DE
      ADHESIÓN DE LA PROCURADURÍA FEDERAL DEL CONSUMIDOR, BAJO EL NÚMERO 3211-2021 DE FECHA 25
      DE JUNIO DE 2021. CUALQUIER VARIACIÓN DEL PRESENTE CONTRATO EN PERJUICIO DEL “CONSUMIDOR”
      Y/O CLIENTE, SE TENDRÁ POR NO PUESTA.</p>
  </div>

  <div class="politicaText">
    <p>El consumidor acepta SI o NO que transmita a terceros con fines mercadotécnicos o publicitarios, la
      información proporcionada por él con motivo del presente Contrato y SI o NO acepta o no que se le
      envíe publicidad sobre bienes, productos o servicios.</p>
  </div>
</div>
<div class="container container2">
  <div class="politicaText">
    <img src="./assets/img/contratoadhesion/contratodeadhesion.jpg" alt="Contrato de adhesión">
  </div>
  <div class="sello">
    <img class="alineadoTextoImagenCentro" src="./assets/img/contratoadhesion/qrcontratoadhesion.jpg"
      alt="Contrato de adhesión">
    <h5>Cadena original del documento</h5>
    <p>SHA-256 | rYD+1r4fI///qEuPG9ck8g259rSa+j3m5J3+bFXaceo= | 1625239943 | CARLOS HERNANDEZ ISLAS</p>
    <h5>Sello digital</h5>
    <p>cllEKzFyNGZJLy8vcUV1UEc5Y2s4ZzI1OXJTYStqM201SjMrYkZYYWNlbz18MTYyNTIzOTk0Mw==</p>
  </div>
</div>
