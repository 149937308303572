import { Component, OnInit, HostListener } from '@angular/core';
import { DocumentosService } from 'src/app/servicios/documentos.service';
import { user } from "../../entidades/gwuser";
import jwt_decode from "jwt-decode";
import { MensajeService } from 'src/app/servicios/mensaje.service';
import { MensajePopup } from 'src/app/entidades/MensajePopup';
import { Router } from '@angular/router';
import  Swal  from 'sweetalert2';

import { stringify } from 'querystring';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';

@Component({
  selector: 'app-afiliados',
  templateUrl: './afiliados.component.html',
  styleUrls: ['./afiliados.component.css']
})
export class AfiliadosComponent implements OnInit {

  isSectionVisible: boolean = false;
    // Lista de ítems con sus valores
    items = [
      { label: 'Activo', value: 'activo' },
      { label: 'Bloqueado', value: 'bloqueado' },
      { label: 'Inactivo', value: 'inactivo' },
      { label: 'Caduco', value: 'caduco' },
    ];

  estatus:string ='';

  showSectionAfiliados: boolean = true;
  showSectionResumen: boolean = false;
  // showSectionUseRestringido: boolean = false;

  imagenOriginal: string = 'assets/iconos/borrar.png';
  imagenNueva: string = 'assets/iconos/borrarRojo.png';
  // imagenNueva: string = 'assets/iconos/eliminar.png';
  imagenActual: string = this.imagenOriginal;

  dataAfiliadp: any = {};
  dataRelacionAfiliado:any;
  lsttipocodigo:any;
  userGw: user;
  datagw: any;
  token: string;
  tokendatos: any;
  idcliente: number = -1;
  afiliadoidcod: string = "-1";
  // afiliadoidtipocod: string;
  afiliadoidtipocod: string = "-1";
  currentHoveredItem: any = null;
  relnombre: string;
  relcorreo: string;
  relTelefn: string;
  correoElectronico: string = '';
  fechainiciohistorial: string;
  fechafinhistorial: string;
  datahistoryresumen : any;
  monedero:string;
  porcentaje:string;
  filaSeleccionada: any;
  codeSelected: any;
  searchCode: string = '';
  tipoCodeSelected: any;
  // showSearchsection:boolean = false;
  // showCodeTxt:boolean = true;


  constructor( private documentosService: DocumentosService,
               private router: Router,
               private mensajeService: MensajeService
              ) { }

  ngOnInit(): void {
    this.CargarAfiliadotiposCodigo();
    this.Cargardataafiliado();
  }

  CargarAfiliadotiposCodigo(){
    if (sessionStorage.getItem("token")) {
        this.token = sessionStorage.getItem("token");
        this.tokendatos = jwt_decode(this.token);
        this.userGw = this.tokendatos;
        this.idcliente = this.userGw.idUser;
    } else {
        this.mensajeService.subject$.next(new MensajePopup("Su sesión ha expirado", false, false));
        this.router.navigate(['/herramientasDigitales/login']);
        return;
    }

    if (this.idcliente ==-1){
        this.mensajeService.subject$.next(new MensajePopup("Su sesión ha expirado", false, false));
        this.router.navigate(['/herramientasDigitales/login']);
        return;
    }

    this.documentosService.CargarAfiliadotiposCodigo(this.idcliente.toString()).then((data) => {
      this.lsttipocodigo = JSON.parse(JSON.stringify(data));
        // console.log(this.lsttipocodigo );

    }, (error) => {
        console.log(error);
    });

  }

   // Método para mostrar/ocultar la sección
   toggleSection() {
    this.isSectionVisible = !this.isSectionVisible;
  }

  // Método para manejar la selección del ítem
  selectItem(item: any) {
    console.log('Item seleccionado:', item.label, 'con valor:', item.value);
    this.estatus = item.value;
    this.searchCodeMethod();

    // Ocultar la sección después de seleccionar un ítem
    this.isSectionVisible = false;
  }

   // Detectar clics en cualquier parte del documento
   @HostListener('document:click', ['$event'])
   onDocumentClick(event: MouseEvent) {
     const clickedElement = event.target as HTMLElement;
     const isInsideButtonOrSection = clickedElement.closest('.btn-toggle') || clickedElement.closest('.item-list');

     if (!isInsideButtonOrSection) {
       this.isSectionVisible = false; // Oculta la sección si haces clic fuera de la sección o el botón
     }
   }

  // selectButton(buttonType: string) {
  //   if (this.selectedButtonFilter === buttonType) {
  //       this.selectedButtonFilter = ''; // Si el botón ya está seleccionado, deseleccionarlo
  //   } else {
  //       this.selectedButtonFilter = buttonType; // Selecciona el nuevo botón
  //   }
  // }

  GenerarNuevoCodigo(){
    if(this.afiliadoidtipocod <= '-1'){
      Swal.fire({
        icon: 'info',
        title: 'TIPO DE CÓDIGO NO SELECCIONADO!',
        text: 'Seleccione un tipo de código a generar'
      });
      return
      }

    Swal.fire({
      title: 'GENERAR CÓDIGO',
      text: "¿Seguro que desea generar código?",
      icon: 'question',
      iconColor: '#8C8C8C',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Generar'
    }).then((result) => {
      if (result.isConfirmed) {
          this.GenerarNuevoCodigoProceso();
      }
    })
  }

  GenerarNuevoCodigoProceso(){

    if (sessionStorage.getItem("token")) {
      this.token = sessionStorage.getItem("token");
      this.tokendatos = jwt_decode(this.token);
      this.userGw = this.tokendatos;
      this.idcliente = this.userGw.idUser;
    } else {
        this.mensajeService.subject$.next(new MensajePopup("Su sesión ha expirado", false, false));
        this.router.navigate(['/herramientasDigitales/login']);
        return;
    }

    if (this.idcliente ==-1){
        this.mensajeService.subject$.next(new MensajePopup("Su sesión ha expirado", false, false));
        this.router.navigate(['/herramientasDigitales/login']);
        return;
    }

    var dtarsp : any;
    // console.log(this.afiliadoidcod)
    this.documentosService.oprAfiliadoCodigos( "ALT",this.idcliente.toString(), "0", this.afiliadoidtipocod, '' , "1"  ).then((data) => {
        dtarsp = JSON.parse(JSON.stringify(data));
        // console.log(dtarsp);
        this.Cargardataafiliado('newcode');
        Swal.fire('CÓDIGO GENERADO!', dtarsp.msgtxt,'success');

    }, (error) => {
      console.log(error);
    });

  }

  soloNumeros(event: any): void {
    let valorCampo = event.target.value;
    valorCampo = valorCampo.replace(/[^0-9]/g, '');
    event.target.value = valorCampo;
  }

  // validarCorreoElectronico(correo: string): boolean {
  //   const regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  //   return regex.test(correo);
  // }

  validarCorreoElectronico(): boolean {
    const regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    return regex.test(this.relcorreo);
  }

  Agregarrelacion(){
    this.afiliadoidcod = this.codeSelected;
    if(this.afiliadoidcod <= '-1'){
      Swal.fire({
        icon: 'info',
        title: 'CÓDIGO NO SELECCIONADO!',
        text: 'Seleccione un código para agregar a un afiliado'
      });
      return
      }

    if(this.relnombre == '' || this.relnombre == undefined || this.relnombre == null ){
       Swal.fire({
         icon: 'info',
         title: 'CAMPO NOMBRE VACIO!',
         text: 'Es necesario capturar el nombre en el campo'
      });
      return
      }

      if(this.relTelefn == '' || this.relTelefn == undefined || this.relTelefn == null ){
        Swal.fire({
          icon: 'info',
          title: 'CAMPO TELÉFONO VACIO!',
          text: 'Es necesario capturar el número de teléfono'
       });
       return
       }

       if(this.relTelefn.length < 9){
        Swal.fire({
          icon: 'info',
          title: 'CAMPO TELÉFONO INCORRECTO!',
          text: 'El número de teléfono esta incompleto, debe tener 10 caracteres'
       });
       return
       }

       if(this.relcorreo == '' || this.relcorreo == undefined || this.relcorreo == null ){
        Swal.fire({
          icon: 'info',
          title: 'CAMPO CORREO ELECTRÓNICO VACIO!',
          text: 'Es necesario capturar el correo electrónico'
       });
       return
       }

        if (this.validarCorreoElectronico()) {

        } else {
          Swal.fire({
            icon: 'info',
            title: 'CAMPO CORREO ELECTRÓNICO INVALIDO!',
            text: 'Es necesario corregir la estructura del correo electrónico'
         });
         return
        }


    Swal.fire({
      title: 'AGREGAR RELACIÓN A CÓDIGO?',
      text: "¿Seguro que desea agregar relación?",
      icon: 'question',
      iconColor: '#8C8C8C',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Agregar'
     }).then((result) => {
        if (result.isConfirmed) {
            this.Agregarrelacionproceso();
        }
     })
  }

  Agregarrelacionproceso(){

    if (sessionStorage.getItem("token")) {
      this.token = sessionStorage.getItem("token");
      this.tokendatos = jwt_decode(this.token);
      this.userGw = this.tokendatos;
      this.idcliente = this.userGw.idUser;
    } else {
        this.mensajeService.subject$.next(new MensajePopup("Su sesión ha expirado", false, false));
        this.router.navigate(['/herramientasDigitales/login']);
        return;
    }

    if (this.idcliente ==-1){
        this.mensajeService.subject$.next(new MensajePopup("Su sesión ha expirado", false, false));
        this.router.navigate(['/herramientasDigitales/login']);
        return;
    }

    var dtarsp : any;
    // console.log(this.afiliadoidcod)
    this.documentosService.oprAfiliadosRelacion( "ALT","0", this.idcliente.toString(), this.afiliadoidcod, this.relnombre, this.relTelefn, this.relcorreo ).then((data) => {
       dtarsp = JSON.parse(JSON.stringify(data));
      //  this.CargarAfiliadosRelacion(this.afiliadoidcod);
      //  this.Cargardataafiliado();
       this.limpiarTabla();
       this.afiliadoidcod = "-1";
       this.relnombre = '';
       this.relTelefn = '';
       this.relcorreo = '';
       Swal.fire('AGREGADO CORRECTO!', dtarsp.msgtxt,'success');
    }, (error) => {
      console.log(error);
    });

  }

  limpiarTabla() {
      this.dataRelacionAfiliado = [];
  }

  bloquearcodigo(idcode){
    this.afiliadoidcod = idcode;
    if(this.afiliadoidcod <= '-1' || this.afiliadoidcod == null || this.afiliadoidcod == undefined ){
      Swal.fire({
        icon: 'info',
        title: 'CÓDIGO NO SELECCIONADO!',
        text: 'Seleccione un código para bloquear'
      });
      return
      }

    Swal.fire({
      title: 'BLOQUEAR CÓDIGO',
      text: "¿Seguro que desea bloquear el código?",
      icon: 'question',
      iconColor: '#8C8C8C',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Bloquear'
    }).then((result) => {
      if (result.isConfirmed) {
          this.bloquearcodigoproceso();
      }
    })
  }

  bloquearcodigoproceso(){
    if (sessionStorage.getItem("token")) {
      this.token = sessionStorage.getItem("token");
      this.tokendatos = jwt_decode(this.token);
      this.userGw = this.tokendatos;
      this.idcliente = this.userGw.idUser;
    } else {
        this.mensajeService.subject$.next(new MensajePopup("Su sesión ha expirado", false, false));
        this.router.navigate(['/herramientasDigitales/login']);
        return;
    }

    if (this.idcliente ==-1){
        this.mensajeService.subject$.next(new MensajePopup("Su sesión ha expirado", false, false));
        this.router.navigate(['/herramientasDigitales/login']);
        return;
    }

    var dtarsp : any;
    // console.log(this.afiliadoidcod)
    this.documentosService.oprAfiliadoCodigos( "ACT",this.idcliente.toString(), this.afiliadoidcod, "0", '' , "4"  ).then((data) => {
       dtarsp = JSON.parse(JSON.stringify(data));
        // console.log(dtarsp);
        this.Cargardataafiliado();
        Swal.fire('CÓDIGO BLOQUEADO!', dtarsp.msgtxt,'success');

    }, (error) => {
      console.log(error);
    });


  }

  Cargardataafiliado(param?){
    if (sessionStorage.getItem("token")) {
        this.token = sessionStorage.getItem("token");
        this.tokendatos = jwt_decode(this.token);
        this.userGw = this.tokendatos;
        this.idcliente = this.userGw.idUser;
    } else {
        this.mensajeService.subject$.next(new MensajePopup("Su sesión ha expirado", false, false));
        this.router.navigate(['/herramientasDigitales/login']);
        return;
    }

    if (this.idcliente ==-1){
        this.mensajeService.subject$.next(new MensajePopup("Su sesión ha expirado", false, false));
        this.router.navigate(['/herramientasDigitales/login']);
        return;
    }

    this.documentosService.CargarDatosAfiliado(this.idcliente.toString()).then((data) => {
      this.dataAfiliadp = JSON.parse(JSON.stringify(data));
        // console.log( "Este es el arreglo  "+this.dataAfiliadp );
        this.monedero = this.dataAfiliadp.monedero;
        this.porcentaje = this.dataAfiliadp.porcentaje;
        // console.log(this.monedero + this.porcentaje);

        if(param == 'newcode'){
          const primerItem = this.dataAfiliadp.lstCodigoas[0]; // El primer ítem (último creado)
          this.seleccionarFila(primerItem);
        }

    }, (error) => {
      console.log(error);
    });
  }

  CargarAfiliadosRelacion(idcod){

    if (sessionStorage.getItem("token")) {
      this.token = sessionStorage.getItem("token");
      this.tokendatos = jwt_decode(this.token);
      this.userGw = this.tokendatos;
      this.idcliente = this.userGw.idUser;
    } else {
        this.mensajeService.subject$.next(new MensajePopup("Su sesión ha expirado", false, false));
        this.router.navigate(['/herramientasDigitales/login']);
        return;
    }

    if (this.idcliente ==-1){
        this.mensajeService.subject$.next(new MensajePopup("Su sesión ha expirado", false, false));
        this.router.navigate(['/herramientasDigitales/login']);
        return;
    }

    this.documentosService.CargarAfiliadosRelacion(this.idcliente.toString(), idcod ).then((data) => {
      this.dataRelacionAfiliado = JSON.parse(JSON.stringify(data));

    }, (error) => {
      console.log(error);
    });

  }

  bajarelacion(idrel){
    Swal.fire({
      title: 'ELIMINAR RELACIÓN A CÓDIGO?',
      text: "¿Seguro que desea eliminar la relación?",
      icon: 'question',
      iconColor: '#8C8C8C',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Eliminar'
     }).then((result) => {
        if (result.isConfirmed) {
            this.bajarelacionproceso(idrel);
        }
     })
  }

  bajarelacionproceso(idrel){

    if (sessionStorage.getItem("token")) {
      this.token = sessionStorage.getItem("token");
      this.tokendatos = jwt_decode(this.token);
      this.userGw = this.tokendatos;
      this.idcliente = this.userGw.idUser;
    } else {
        this.mensajeService.subject$.next(new MensajePopup("Su sesión ha expirado", false, false));
        this.router.navigate(['/herramientasDigitales/login']);
        return;
    }

    if (this.idcliente ==-1){
        this.mensajeService.subject$.next(new MensajePopup("Su sesión ha expirado", false, false));
        this.router.navigate(['/herramientasDigitales/login']);
        return;
    }

    var dtarsp : string;
    // console.log(this.afiliadoidcod)
    this.documentosService.oprAfiliadosRelacion( "BJA" , idrel, this.idcliente.toString(), this.afiliadoidcod, this.relnombre, this.relTelefn , this.relcorreo  ).then((data) => {
       dtarsp = JSON.parse(JSON.stringify(data));
        // console.log(dtarsp );
        this.CargarAfiliadosRelacion(this.afiliadoidcod)

    }, (error) => {
      console.log(error);
    });

  }

  onCodigoSeleccionado(event: any) {
    const idcod = event.target.value; // Obtener el valor seleccionado del select

    // console.log(idcod);

    let tipo: any;

    for (let i = 0; i < this.dataAfiliadp.lstCodigoas.length; i++) {
        if (this.dataAfiliadp.lstCodigoas[i] && this.dataAfiliadp.lstCodigoas[i].idcod == idcod) {
            tipo = this.dataAfiliadp.lstCodigoas[i].tipo;
            break; // Terminar el bucle si se encuentra un elemento válido
        }
    }

    // if (tipo === 'Restringido') {
    //     this.showSectionUseRestringido = true;
    // }else{
    //     this.showSectionUseRestringido = false;
    // }

    // console.log(this.dataAfiliadp.lstCodigoas);
    this.CargarAfiliadosRelacion(idcod); // Llamar a tu función con el valor seleccionado
  }

  cambiarImagen() {
    this.imagenActual = this.imagenNueva;
  }

  restaurarImagen() {
    this.imagenActual = this.imagenOriginal;
  }

  showAfiliados(){
    this.showSectionResumen = false;
    this.showSectionAfiliados = true;
  }

  showResumen(){
    this.showSectionAfiliados = false;
    this.showSectionResumen = true;
  }

  showDataResumen(){

    if (sessionStorage.getItem("token")) {
      this.token = sessionStorage.getItem("token");
      this.tokendatos = jwt_decode(this.token);
      this.userGw = this.tokendatos;
      this.idcliente = this.userGw.idUser;
    } else {
        this.mensajeService.subject$.next(new MensajePopup("Su sesión ha expirado", false, false));
        this.router.navigate(['/herramientasDigitales/login']);
        return;
    }

    if (this.idcliente ==-1){
        this.mensajeService.subject$.next(new MensajePopup("Su sesión ha expirado", false, false));
        this.router.navigate(['/herramientasDigitales/login']);
        return;
    }

    // console.log(this.fechainiciohistorial);
    // console.log(this.fechafinhistorial);

    if(this.fechainiciohistorial == "" || this.fechainiciohistorial == undefined || this.fechainiciohistorial == null){
      Swal.fire({
        icon: 'info',
        title: '¡FECHA VACIA!',
        text: 'Es necesario capturar una fecha de inicio'
      });
    return
    }

    if(this.fechafinhistorial == "" || this.fechafinhistorial == undefined || this.fechafinhistorial == null){
      Swal.fire({
        icon: 'info',
        title: '¡FECHA VACIA!',
        text: 'Es necesario capturar la fecha final'
      });
      return
     }

    let fechainicio = this.fechainiciohistorial.replace(/-/g, "");
    let fechafinal = this.fechafinhistorial.replace(/-/g, "");
    var drspta : any;
    // console.log(this.afiliadoidcod)
    this.documentosService.cargarHistorialAfilado(this.idcliente.toString(), fechainicio, fechafinal).then((data) => {
        drspta = JSON.parse(JSON.stringify(data));

        if(drspta == ""){
          Swal.fire({
            icon: 'info',
            title: 'SIN REGISTROS',
            text: 'No se encontraron registros'
          });
          return
         }

       this.LoaderDataHistory(drspta);

    }, (error) => {
        console.log(error);
    });

  }

  LoaderDataHistory(drspta){
    //  console.log(drspta)
     this.datahistoryresumen = drspta;
     console.log(this.datahistoryresumen);
   }

   seleccionarFila(item: any) {
    this.filaSeleccionada = item;
    this.codeSelected = this.filaSeleccionada.idcod;

    const idcod = this.codeSelected; // Obtener el valor seleccionado de la fila en la tabla

    let tipo: any;

    for (let i = 0; i < this.dataAfiliadp.lstCodigoas.length; i++) {
        if (this.dataAfiliadp.lstCodigoas[i] && this.dataAfiliadp.lstCodigoas[i].idcod == idcod) {
            tipo = this.dataAfiliadp.lstCodigoas[i].tipo;
            break; // Terminar el bucle si se encuentra un elemento válido
        }
    }

    this.CargarAfiliadosRelacion(idcod); // Llamar a tu función con el valor seleccionado

  }

  // showSectionSearch(){
  //   this.showSearchsection = true;
  //   this.showCodeTxt = false;
  // }

  // closeSectionSearch(){
  //   this.showSearchsection = false;
  //   this.showCodeTxt = true;
  // }

  onTipoCodigoSelect() {
    this.tipoCodeSelected = this.afiliadoidtipocod;
  }

  searchCodeMethod(){

    if (sessionStorage.getItem("token")) {
        this.token = sessionStorage.getItem("token");
        this.tokendatos = jwt_decode(this.token);
        this.userGw = this.tokendatos;
        this.idcliente = this.userGw.idUser;
    } else {
        this.mensajeService.subject$.next(new MensajePopup("Su sesión ha expirado", false, false));
        this.router.navigate(['/herramientasDigitales/login']);
        return;
    }

    if (this.idcliente ==-1){
        this.mensajeService.subject$.next(new MensajePopup("Su sesión ha expirado", false, false));
        this.router.navigate(['/herramientasDigitales/login']);
        return;
    }

    var valor = this.searchCode;
    let estatus = this.estatus;
    // let fecha = '';

    if(this.tipoCodeSelected == -1 || this.tipoCodeSelected == undefined || this.tipoCodeSelected == null){
       Swal.fire({
        icon: 'info',
        title: 'TIPO NO SELECCIONADO',
        text: 'Es necesario seleccionar un tipo de código para la búsqueda.'
       });
       return
      }

    // if(valor == "" || this.tipoCodeSelected == undefined || this.tipoCodeSelected == null){
    //    Swal.fire({
    //     icon: 'info',
    //     title: 'CAMPO VACÍO',
    //     text: 'Capture el código que desea buscar.'
    //    });
    //    return
    //   }

    // console.log(this.lsttipocodigo);
    // return

    // alert("entro a buscar codigo!" + valor + )

    // /busquedaAfiliado?idcliweb=1124&idtpo=1&valor=3333
    this.documentosService.buscarCodigoAfiliado(this.idcliente.toString(), this.tipoCodeSelected, valor, estatus).then((data) => {
      var datasearch = JSON.parse(JSON.stringify(data));

      this.estatus = '';

      if(datasearch == ""){
         Swal.fire({
          icon: 'info',
          title: 'CÓDIGO NO ENCONTRADO',
          text: 'Verifique su código'
        });
        return
       }else{
        this.dataAfiliadp.lstCodigoas = datasearch;
        console.log(datasearch);
       }

    //  this.LoaderDataHistory(drspta);

  }, (error) => {
      console.log(error);
  });

   }

   onSearchChange() {
    if (this.searchCode.trim() === '') {
      // El input está vacío, puedes llamar al método que necesitas aquí
      this.handleEmptySearch();
    }
  }

  handleEmptySearch() {
    // Método que se ejecutará cuando el input esté vacío
    this.Cargardataafiliado();
  }

}
