import { Component, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { DocumentosService } from 'src/app/servicios/documentos.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EventEmitter, Output } from '@angular/core';


@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.css']
})
export class SignatureComponent implements AfterViewInit {
  @Output() datobaseChanged = new EventEmitter<string>();
  @Output() closeModalChanged = new EventEmitter<boolean>();
  @ViewChild('signatureCanvas') signatureCanvas: ElementRef;
  private context: CanvasRenderingContext2D;
  private drawing: boolean = false;

  public dtDocumentoVal:any = [] ;
  public idDocumento: string;
  public dtDocumento: any = [];
  public dtDocAdjuntos: any = [];
  public urlorigen:string = "";

  constructor(
    private documentosService: DocumentosService,
    private rutaActiva: ActivatedRoute,
    private router: Router
  ) { }

  ngAfterViewInit(): void {
    this.context = this.signatureCanvas.nativeElement.getContext('2d');
    this.context.strokeStyle = '#000';
    this.context.lineWidth = 1;
    this.context.imageSmoothingEnabled = true;
    this.context.lineCap = 'round';
  }

  startDrawing(event: MouseEvent): void {
    this.drawing = true;
    const canvas = this.signatureCanvas.nativeElement;
    const rect = canvas.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    this.context.beginPath();
    this.context.moveTo(x, y);
  }

  draw(event: MouseEvent): void {
    if (!this.drawing) return;
    const canvas = this.signatureCanvas.nativeElement;
    const rect = canvas.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    this.context.lineTo(x, y);
    this.context.stroke();
  }

  endDrawing(): void {
    this.drawing = false;
  }

  clearSignature(): void {
    const canvas = this.signatureCanvas.nativeElement;
    this.context.clearRect(0, 0, canvas.width, canvas.height);
  }

  ngOnInit(): void {
    const url = window.location.href.toLowerCase();

    if (url.includes('/crear-mis-documentos')) {
      // La URL contiene '/crear-mis-documentos'
      // Coloca aquí el código que deseas ejecutar en este caso
    } else if (url.includes('/documentos')) {
      // La URL contiene '/documentos'
      this.rutaActiva.params.subscribe(params => {
        this.idDocumento = params.code;
        if (this.idDocumento !== undefined) {
            this.obtenerFormatoDigital();
          }
      });
    } else {
      // La URL no contiene ninguna de las subcadenas específicas
      this.router.navigate(['/']);
    }

    // this.rutaActiva.params.subscribe(params => {
    //   this.idDocumento = params.code;
    //   if (this.idDocumento !== undefined) {
    //       this.obtenerFormatoDigital();

    //   } else {
    //     this.router.navigate(['/']);
    //   }
    // });
  }

  datobase:string;
  closedatamodal:boolean = false;

  async saveSignature() {
    const canvas = this.signatureCanvas.nativeElement;
    const dataURL = canvas.toDataURL('image/png'); // Puedes cambiar 'image/png' por 'image/jpeg' si prefieres JPEG

    // alert(dataURL);

    this.datobase = dataURL;
    this.closedatamodal = true;
    this.datobaseChanged.emit(this.datobase);
    this.closeModalChanged.emit(this.closedatamodal);

  }

 async obtenerFormatoDigital() {
   await this.documentosService.cargarDocumentos(this.idDocumento).then((data) => {
      this.dtDocumento = JSON.parse(JSON.stringify(data));
      this.dtDocAdjuntos = JSON.parse(JSON.stringify(data));
      // this.obtenerMaxGrupo();
      console.log(this.dtDocumento);
    }, (error) => {
      console.log(error);
    });
  }


}
