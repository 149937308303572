import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from "src/environments/environment";
import { BehaviorSubject, Observable } from "rxjs";
import { MensajeWeb } from '../entidades/mensaje-web';
import { Mensaje } from '../entidades/mensaje';

@Injectable({
  providedIn: 'root'
})
export class AdministrarCuentaService {

  constructor(private http: HttpClient) { }


  sendPost(url:string ,body:FormData):Observable<any>{
    url = `http://localhost:4200/administrar-cuenta/upload`;
    return this.http.post(url, body);
  }

  cargarDatos(idcliente:number){
    let url = `${environment.URL_APIPAQ}/TufEnvObtenerDatosUsr?idcliente=`+idcliente;
    return this.http.get<any>(url).toPromise();
  }

  async actualizarDatos(body:any){
     let url = `${environment.URL_APIPAQ}/TufEnvConfiguracioUsrv2`;
    return this.http.post<any>(url, body).toPromise();
  }

  subirDocs(body:FormData):Observable<any>{
    let url = `${environment.URL_APIPAQ}/TufEnvConfiguracioUsrvDocumentos`;
   return this.http.post<any>(url, body);
 }

 uploadBase64File(body:any, token:string) {
  const httpOptions = {
    headers: new HttpHeaders({
      'Content-type': 'application/json',
      Authorization: 'Bearer ' + token
    })
  };
  let url = `${environment.URL_APIPAQ}/TufEnvConfiguracioUsrvDocumentos`; // URL del endpoint para subida de archivos
  return this.http.post(url, body, httpOptions); // Envía una solicitud POST con el objeto como cuerpo de la solicitud
}


obtenerPromo(nombre:string, correo:string,telefono:string, via:string,tipoPromo:string){
  let url = `${environment.URL_APIPAQ}/gettupromo?nombre=${nombre}&correo=${correo}&telefono=${telefono}&via=${via}&codpromo=${tipoPromo}`;
  return this.http.get<any>(url).toPromise();
}

 validaDatos(pRFC:string, pNombre:string, pDomfiscal:string, pRegFiscal: string ){
  var url = `${environment.URL_APIPAQ}/ValidarDatosFiscales?pRFC=${pRFC}&pNombre=${pNombre}&pDomfiscal=${pDomfiscal}&pRegFiscal=${pRegFiscal}`;
  return this.http.get<Mensaje>(url).toPromise();
}

}
