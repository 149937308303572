export class detalle {

    cobrar: number = 0;
    acuse: number = 0;
    internacional: number = 0;
    cuota: number = 0;
    recoleccion: number = 0;
    otro: number = 0;
    zona: string = "";

    serv: string = "";
    valordec: string = "";
    ref1: string = "";
    ref2: string = "";
    comentario: string = "";
}


