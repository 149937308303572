import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { Rastreo } from '../entidades/rastreo';
import { ColoniasDspEntDm } from '../entidades/solicitud';
import { Ciudades } from '../entidades/ciudades';
import { Estados } from '../entidades/estados';

@Injectable({
  providedIn: 'root'
})
export class RastreoService {

  constructor(
    private http: HttpClient
  ) { }

  private paquetesSource = new BehaviorSubject(new Rastreo);
  paquetes = this.paquetesSource.asObservable();

  private rastreoSeleccionadoSource = new BehaviorSubject(new Rastreo);
  rastreoSeleccionado = this.rastreoSeleccionadoSource.asObservable();

  private coloniasSource = new BehaviorSubject([]);
  colonias = this.coloniasSource.asObservable();

  private ciudadesSource = new BehaviorSubject([]);
  ciudades = this.ciudadesSource.asObservable();

  private estadosSource = new BehaviorSubject([]);
  estados = this.estadosSource.asObservable();

  track_pack(codigo){
    // let url = `${environment.URL_API_ALFA}/commdatosenvio?codigo=${codigo}&push=-`
    let url = `${environment.URL_API}/commdatosenvio?codigo=${codigo}&push=-`
    return this.http.get(url);
  }

  save_packs(paquetes){
    this.paquetesSource.next(paquetes);
  }

  clean_packs(){
    this.paquetesSource.next(new Rastreo);
  }

  guardarRastreoSelected(rastreo: Rastreo) {
    this.rastreoSeleccionadoSource.next(rastreo);
  }

  obtenerDomicilios(codigo: string) {
    //// // console.log(codigo);
    let uri = `${environment.URL_API}/commColoniaValEntDm?rastreo=${codigo}`;

    if (!environment.production) { uri += '&isTestMode=true'; }

    return this.http.get<ColoniasDspEntDm[]>(
      uri
    );
  }

  guardarColinias(colonias: ColoniasDspEntDm[]) {
    this.coloniasSource.next(colonias);
    let ciudad = '';
    let estado = '';
    const ciudades: Ciudades[] = [];
    const estados: Estados[] = [];

    colonias.forEach(colonia => {

      if (colonia.ciudad !== ciudad) {
        ciudades.push(new Ciudades(colonia.ciudad, colonia.idcd));
        ciudad = colonia.ciudad;
      }

      if (colonia.estado !== estado) {
        estados.push(new Estados(colonia.estado, colonia.idedo));
        estado = colonia.estado;
      }

    });

    this.ciudadesSource.next(ciudades);
    this.estadosSource.next(estados);
  }


}
