<div class="container_herramienta">
    <img src="assets/img/web/envia.webp" class="img_toAnimate">
    <div class="alinearIzq">
        <h1 class="title">Administración de cuenta</h1>
        <div class="container_form">
            <form [formGroup]="formulario">
            <div class="datosContacto">
                <h2>Datos de contacto</h2>
                <div class="frm-element">
                    <label>Nombre</label>
                    <input  formControlName="nombre" maxlength="100" type="text"  [(ngModel)]="objectdata.nombre">
                </div>
                <div class="frm-element">
                    <label>Correo</label>
                    <input  formControlName="correo" maxlength="100" type="text" [(ngModel)]="objectdata.correo">
                </div>
                <div class="frm-element">
                    <label>Teléfono</label>
                    <input formControlName="telefono" maxlength="100" type="text" [(ngModel)]="objectdata.telefono">
                </div>
                <!-- <div class="frm-element">
                    <label>Código Postal</label>
                    <input formControlName="cp" maxlength="100" type="text" value="{{objectdata.cp}}">
                </div> -->
            </div>
            <div class="datosfiscales" >
                <h2>Datos fiscales</h2>
                <!-- <div class="cont_check" (click)="toggleFiscaldata()">
                    <label class="switch" >
                        <input type="checkbox">
                        <span class="slider round"></span>
                      </label>
                    <p>Editar</p>
                </div> -->
                <div class="editabledata" id="readonly">
                <div class="frm-element">
                    <label>Razón Social</label>
                    <input formControlName="razonsocial" maxlength="100" type="text" [(ngModel)]="objectdata.reazon">
                </div>
                <div class="frm-element rfc">
                    <label>RFC</label>
                    <input formControlName="rfc" maxlength="13" type="text" [(ngModel)]="objectdata.rfc">
                </div>
                
                <div class="frm-element domfisc">
                    <label>Domicilio Fiscal</label>
                    <input formControlName="domiciliofiscal" maxlength="5" type="number" [(ngModel)]="objectdata.domfisc">
                </div>

                <div class="frm-element">
                    <label>Régimen fiscal:</label>
                    <select class="slct_regimen" id="regimen" name="regimenes" id="no-select" [(ngModel)]="objectdata.regimenfiscal" formControlName="regimenfiscal">
                        <option value="601">601-General de Ley Personas Morales</option>
                        <option value="603">603-Personas Morales con Fines no Lucrativos</option>
                        <option value="605">605-Sueldos y Salarios e Ingresos Asimilados a Salarios</option>
                        <option value="606">606-Arrendamiento</option>
                        <option value="607">607-Régimen de Enajenación o Adquisición de Bienes</option>
                        <option value="608">608-Demás ingresos</option>
                        <option value="610">610-Residentes en el Extranjero sin Establecimiento Permanente en México</option>
                        <option value="611">611-Ingresos por Dividendos (socios y accionistas)</option>
                        <option value="612">612-Personas Físicas con Actividades Empresariales y Profesionales</option>
                        <option value="614">614-Ingresos por intereses</option>
                        <option value="615">615-Régimen de los ingresos por obtención de premios</option>
                        <option value="616">616-Sin obligaciones fiscales</option>
                        <option value="620">620-Sociedades Cooperativas de Producción que optan por diferir sus ingresos</option>
                        <option value="621">621-Incorporación Fiscal</option>
                        <option value="622">622-Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras</option>
                        <option value="623">623-Opcional para Grupos de Sociedades</option>
                        <option value="624">624-Coordinados</option>
                        <option value="625">625-Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas</option>
                        <option value="626">626-Régimen Simplificado de Confianza</option>
                      </select>
                </div>
            </div>
            </div>
            </form>
        </div>
       
      <div class="container_checks">
        <h2>Configuración de notificaciones</h2>
        <div class="cont_check">
            <div class="switch" id="sliderroundtel" (click)="cambiarvalortel()">
                <div class="slider" id="circle"></div>
            </div>
            <p>Teléfono</p>
        </div>
        <div class="cont_check">
            <div class="switch" id="sliderroundwha" (click)="cambiarvalorwha()">
                <div class="slider" id="circle2"></div>
                <input [(ngModel)]="actualizardatos.ckTelefono" type="text" style="visibility: hidden;">
            </div>
              <p>Whatsapp</p>
        </div>
        <div class="cont_check">
            <div class="switch" id="sliderroundcorreo" (click)="cambiarvalorcorreo()">
                <div class="slider" id="circle3"></div>
            </div>
              <p>Correo</p>
        </div>
        <div class="cont_check">
            <div class="switch" id="sliderroundninguno" (click)="cambiarvalorninguno()">
                <div class="slider" id="circle4"></div>
            </div>
              <p>Ninguno</p>
        </div>
        <button class="btn_guardar" (click)="validaDatosFisc()">Guardar Datos</button>
        <button class="btn_cerrarsesion" (click)="cerrarsesion()">Cerrar Sesión</button>
      </div>
      <div class="line"></div>

      <div class="container_ine">
        <h2>Documentos</h2>
        <h3>Adjunta tu INE</h3>
        <div class="cont_cara">
            <div *ngIf="previsualizacion" class="cont_img_ine">
                <img [src]="previsualizacion">
            </div>
            <span>Frente</span>
            <!-- <img src="assets/iconos/avatar.png"> -->
            
        </div>
        <input type="file" id="ine_frente" (change)="capturarfile($event)">
        <br><br>
        <div class="cont_cara">
            <div *ngIf="previsualizacion" class="cont_img_ine">
                <img [src]="previsualizacion2">
            </div>
            <span>Reverso</span>
            <!-- <img src="assets/iconos/avatar.png"> -->
            
        </div>
        <input type="file" id="ine_reverso" (change)="capturarfile2($event)">
        <br><br>
        <button class="btn_guardar" (click)="subirArchivosBase64()">Actualizar</button>
    </div>
    
     <div class="line"></div>
        <div class="cont_buttons">
            <p class="eliminacuenta" (click)="eliminarCuenta()">Eliminar Cuenta</p>
        </div>
    </div>
    

    <!-- <div class="alinearDer">
        
    </div> -->
    


    <div class="container_popup" id="confirma">
        <div class="confirmacion" >
            <!-- <div class="cont_close">
                <div class="btn_close">x</div>
            </div> -->
            <p  style="margin-top: 25px;">{{msg}}</p>
            <a (click)="closePopup()"><button class="btn_aceptar">Aceptar</button></a>
        </div>
    </div>
    </div>
