import { Injectable } from '@angular/core';

import { HttpRequest } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  _loading: boolean = false;
  private loadingSource = new BehaviorSubject(this._loading);
  loading = this.loadingSource.asObservable();

  _loadingFijo: boolean = false;
  private loadingFijoSource =  new BehaviorSubject(this._loadingFijo);
  loadingFijo = this.loadingFijoSource.asObservable();

  private timer;

  private requests: HttpRequest<any>[] = [];

  constructor() { }

  onStarted(req: HttpRequest<any>): void {
    this.requests.push(req);
    this.notify();
  }

  changeLoadingFijo(value: boolean) {
    this.loadingFijoSource.next(value);
  }

  changeLoading(value: boolean) {
    this.loadingSource.next(value);
  }

  onFinished(req: HttpRequest<any>): void {
    let index: number = -1;

    index = this.requests.indexOf(req);

    if (index === -1) {
      this.requests.forEach((reqx, i) => {
        if (req.url === reqx.url) {
          index = i;
        }
      });
    }

    if (index !== -1) {
      this.requests.splice(index, 1);
    }
    this.notify();
  }

  private notify(): void {
    
    var valor = (this.requests.length !== 0);

    if (valor !== this.loadingSource.getValue()) {
      if (valor) {
        this.loadingSource.next(true);      
      } else {
        this.timer = setTimeout(this.timerCallback.bind(null, [this.loadingSource]), 1000);
      }
    }    
  }

  timerCallback(parms: any[]) {
    parms[0].next(false);
  }


}
