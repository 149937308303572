import { Component, OnInit, TemplateRef, Input } from "@angular/core";
import { Router } from "@angular/router";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Destino } from "../../entidades/destino";
import { DestinoService } from "../../servicios/destino.service";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal";
import { CotizacionService } from "../../servicios/cotizacion.service";
import { recolecciondom } from "../../servicios/recoleccion-dom.service";

@Component({
  selector: "app-cotizacion-form",
  templateUrl: "./cotizacion-form.component.html",
  styleUrls: ["./cotizacion-form.component.css"],
})
export class CotizacionFormComponent implements OnInit {
  btnVenta2?: boolean;

  heroForm: FormGroup;
  cotizaForm: FormGroup;
  recoleccionForm: FormGroup;
  rastreo = false;
  heroPos = "cotizar";
  // variables
  origenSeleccionado: Destino;
  destinoSeleccionado: Destino;
  modalRef: BsModalRef;
  TitleModal: string;
  tipo: string;
  ciudades: any;
  submitted = false;
  submittedRec = false;
  mySlideOptions = {
    items: 1,
    dots: true,
    nav: false,
    lazyLoad: true,
    loop: true,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: false,
  };

  idestado: number;
  idciudad: number;
  idcolonia: number;
  cadestados: any[];
  cadciudades: any[];
  cadcolonias: any[];

  selected: any = "";
  constructor(
    private destinoService: DestinoService,
    private modalService: BsModalService,
    private router: Router,
    private cotizacionService: CotizacionService,
    private recolecciondom: recolecciondom
  ) {}

  ngOnInit() {
    this.destinoService.origenSeleccionado.subscribe(
      (data) => (this.origenSeleccionado = data)
    );
    this.destinoService.destinoSeleccionado.subscribe(
      (data) => (this.destinoSeleccionado = data)
    );
    this.cotizacionService
      .getCiudades()
      .subscribe((datos) => (this.ciudades = datos));
    this.recolecciondom.getestados().subscribe((data) => {
      this.cadestados = data;
    });

    this.createForm();

    if (localStorage.getItem("opMenu") == "viajes") {
      this.btnVenta2 = true;
    }
    if (localStorage.getItem("opMenu") == "compra") {
      this.btnVenta2 = false;
    }
  }

  createForm() {
    this.heroForm = new FormGroup({
      rastreo: new FormControl("", [Validators.required]),
    });

    this.cotizaForm = new FormGroup({
      origen: new FormControl("0", [Validators.required]),
      destino: new FormControl("0", [Validators.required]),
    });

    this.recoleccionForm = new FormGroup({
      estado: new FormControl("", [Validators.required]),
      ciudad: new FormControl("", [Validators.required]),
      colonia: new FormControl("", [Validators.required]),
    });
  }

  get a() {
    return this.recoleccionForm.controls;
  }

  estadoChanged(idedo) {
    this.recoleccionForm.controls.ciudad.setValue("");
    this.recoleccionForm.controls.colonia.setValue("");
    this.idestado = idedo;
    this.get_ciudades();
  }

  get_ciudades() {
    this.recolecciondom.getciudad(this.idestado).subscribe((data) => {
      this.cadciudades = data;
    });
  }

  ciudadChanged(idcd) {
    this.recoleccionForm.controls.colonia.setValue("");
    this.idciudad = idcd;
    this.recolecciondom
      .getcolonia(this.idestado, this.idciudad)
      .subscribe((data) => {
        this.cadcolonias = data;
      });
  }

  coloniaChanged(idcol) {
    this.idcolonia = idcol;
  }

  openModal(template: TemplateRef<any>, tipo) {
    if (tipo === "O") {
      this.TitleModal = "Origen";
      this.tipo = "O";
    } else {
      this.TitleModal = "Destino";
      this.tipo = "D";
    }
    this.modalRef = this.modalService.show(template);
  }

  opMenu(num: number) {
    this.selected = "";
    if (num == 0) {
      this.selected = "viajes";
    } else if (num == 1) {
      this.selected = "agregar";
    }
    localStorage.setItem("opMenu", this.selected);
  }

  itemSelector(value) {
    console.log("Entro al itemSelector");
    let bg = document.getElementById("background");
    let fm = document.getElementById("formularioi");
    let im = document.getElementById("imageni");

    switch (value) {
      case "cotizar":
        fm.style.opacity = "0";
        bg.style.backgroundImage =
          "linear-gradient(rgba(0,0,0,0.5), rgba(0, 0, 0, 0.5)), url('assets/img/hero/promociones/3.jpg')";
        this.heroPos = "cotizar";
        break;

      case "rastreo":
        this.heroPos = "rastreo";
        break;

      case "recoleccion":
        im.style.opacity = "0";
        bg.style.backgroundImage =
          "linear-gradient(rgba(0,0,0,0.5), rgba(0, 0, 0, 0.5)), url('assets/img/hero/promocion3.jpg')";
        this.router.navigate(["/herramientas-menu"]);
        this.recoleccionForm.value.estado = "Sonora";
        break;

      case "promociones":
        fm.style.opacity = "0";
        im.style.opacity = "0";
        bg.style.backgroundImage = "none";
        bg.style.backgroundColor = "#000";
        this.heroPos = "promociones";
        break;
    }
  }

  async sendRastreo() {
    let codes = [];
    if (this.heroForm.value.rastreo) {
      if (sessionStorage.getItem("codes")) {
        await sessionStorage.removeItem("codes");
      }
      codes.push(this.heroForm.value.rastreo);
      await sessionStorage.setItem("codes", JSON.stringify(codes));

      this.router.navigate(["/paqueteria/rastreo/captura"], {
        queryParams: { codigo: this.heroForm.value.rastreo },
      });
    }
  }

  getCotizacion() {
    this.submitted = true;
    if (this.cotizaForm.valid) {
      if (
        this.cotizaForm.value.origen !== "0" &&
        this.cotizaForm.value.destino !== "0"
      ) {
        this.cotizacionService.setOrigen(this.cotizaForm.value.origen);
        this.cotizacionService.setDestino(this.cotizaForm.value.destino);
        this.router.navigate(["/cotizacion"]);
      }
    }
  }

  getRecoleccion() {
    this.submittedRec = true;
    if (this.recoleccionForm.valid) {
      if (
        this.recoleccionForm.value.estado !== "0" &&
        this.recoleccionForm.value.ciudad !== "0" &&
        this.recoleccionForm.value.colonia !== "0"
      ) {
        this.recolecciondom.setEstado(this.recoleccionForm.value.estado);
        this.recolecciondom.setCiudad(this.recoleccionForm.value.ciudad);
        this.recolecciondom.setColonia(this.recoleccionForm.value.colonia);

        this.router.navigate(["/paqueteria/recoleccion"]);
      }
    }
  }

  activarRastreo() {
    let elem = document.getElementById("rastreo");
    elem.classList.add("rastreo-active");
  }
  bindText(value1, value2) {
    return value1 + " " + value2;
  }
}
