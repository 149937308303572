<div class="container_herramienta">
   <div class="alinearIzq">
      <div class="container_full">
         <div class="leftBar_container">
            <div class="title_cont">
               <h1>Sucursales</h1>
               <h3 class="h3_cls">Encuentra tu sucursal más cercana.</h3>
            </div>
            <div class="search-name" id="search_bar">
               <input class="form_control" type="text" name="search" [(ngModel)]="searchText" autocomplete="on"
                  placeholder="Buscar" id="frm_control">
               <img src="assets/iconos/busqueda.png" class="search_icon" id="icon_search">
            </div>
            <div class="container_fichas">
               <div id="ficha+i" class="cont_ficha"
                  *ngFor="let ubicacion of ubicacionesData | filter:searchText; let i = index"
                  [ngClass]="{'contficha': visitedIndex === i+1, 'cont_ficha:': visitedIndex != i+1}">
                  <div class="ficha">
                     <div class="show_hide_info"
                        [ngClass]="{'info_show':activeIndex === i+1, 'info_hide':activeIndex != i+1}">
                        <a class="img-flecha" title="Visualizar info.">
                           <button class="btn_down" (click)="toggleMostrar(i+1, i+1)"
                              [ngClass]="{'btn_down':activeIndex === i+1, 'btn_down_rotate':activeIndex != i+1}">
                              <img src="assets/iconos/flecha-hacia-arriba.png" id="imgchange">
                           </button>
                        </a>
                        <div class="info_ficha" (click)="UpdateZoom(ubicacion.lat, ubicacion.lng, ubicacion.zoom)"
                           (click)="togglePintar(i+1)">
                           <h3>{{ubicacion.name}}</h3>
                           <h4>Dirección</h4>
                           <p>{{ubicacion.address}}</p>
                           <h4>Teléfono</h4>
                           <a href="tel:">
                              <p class="tel_modulo">{{ubicacion.phone}}</p>
                           </a>
                           <h4>Características</h4>
                           <p>{{ubicacion.feature}}</p>
                           <h4>Horario</h4>
                           <p style="margin: 0 0 -15px;">{{ubicacion.schedule}}</p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <!-- Contenedor del mapa -->
         <div class="map-container" id="container_map">
            <button class="reg_map" (click)="refresh()">Ver todas</button>
            <!-- Ubicación predeterminada (lati y long) -->
            <agm-map [latitude]="lati" [longitude]="long" [zoom]="zoomdefault" [mapTypeId]="maptypeId">
               <!-- creacion de cada uno de los marcadores -->
               <agm-marker *ngFor="let ubicacion of ubicacionesData" [latitude]="ubicacion.lat"
                  [longitude]="ubicacion.lng" [iconUrl]="iconMap.iconUrl">
                  <agm-info-window class="info_marker">
                     <p>{{ubicacion.name}}</p>
                     <p>{{ubicacion.address}}</p>
                  </agm-info-window>
               </agm-marker>
            </agm-map>
         </div>
      </div>
   </div>
</div>