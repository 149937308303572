export class MensajePopup {
    mensaje: string;
    exito: boolean;
    pregunta: boolean;

    constructor (mensaje: string, exito: boolean, pregunta: boolean) {
        this.mensaje = mensaje;
        this.exito = exito;
        this.pregunta = pregunta;
    }
}
