import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { GuiaswebServiceService } from '../../servicios/guiasweb-service.service';
import { Router } from '@angular/router';
import { tipo } from '../../entidades/gwtipo';
import { documentacion } from '../../entidades/gwdocumentacion';
import { user} from '../../entidades/gwuser';
import { MensajePopup } from 'src/app/entidades/MensajePopup';
import jwt_decode from 'jwt-decode';
import { HttpErrorResponse } from '@angular/common/http';
import { MensajeService } from 'src/app/servicios/mensaje.service';

@Component({
  selector: 'app-gw-doc-tipo',
  templateUrl: './gw-doc-tipo.component.html',
  styleUrls: ['./gw-doc-tipo.component.css']
})
export class GwDocTipoComponent implements OnInit {

  //variables tipo de paquete
  tipoEnvio: any;
  arrtipos = [];
  arr2 = [];
  docTipoForm: FormGroup;
  submittedTipo: boolean;
  doc: documentacion;
  datagw: any;
  tipopaquete: string;

  valtipo: boolean = false;
  valcantidad: boolean = false;
  valpeso: boolean = false;
  vallargo: boolean = false;
  valancho: boolean = false;
  valalto: boolean = false;

  validaciontipos: boolean = false;
  msgtipos: string;

  token: string;
  tokendatos: any;
  showDimensiones: boolean = true;


  constructor(
    private GuiaswebServiceService: GuiaswebServiceService,
    private router: Router,
    private mensajeService: MensajeService
  ) { }

  ngOnInit(): void {
    this.validarsesion();

      this.token = sessionStorage.getItem('token')
      this.tokendatos = jwt_decode(this.token);
    this.tipopaquete = decodeURIComponent(escape(window.atob( sessionStorage.getItem('gwTipopaq') )));
    var docaux = decodeURIComponent(escape(window.atob( sessionStorage.getItem('gwDocumentacion') )));
    this.doc = JSON.parse(docaux);

    this.DocumentacionTipoForm();
    this.obtenerTipoEnvio();
    this.cargardatosGuardatos();
    document.getElementById("tipo").focus();
    window.scrollTo(0,0);


  }

  //form de tipo
  DocumentacionTipoForm() {
    this.docTipoForm = new FormGroup({
      //tipo
      selectTipo: new FormControl('', [Validators.required]),
      cantidad: new FormControl('', [Validators.required, Validators.pattern("^([0-9])*$")]),
      peso: new FormControl('', [Validators.required, Validators.pattern("^([0-9])*$")]),
      largo: new FormControl('', [Validators.required, Validators.pattern("^([0-9])*$")]),
      ancho: new FormControl('', [Validators.required, Validators.pattern("^([0-9])*$")]),
      alto: new FormControl('', [Validators.required, Validators.pattern("^([0-9])*$")]),

    });
  }

  get DocTipo() { return this.docTipoForm.controls; }


  validarsesion(){
    if(sessionStorage.getItem('token') == undefined || sessionStorage.getItem('token') == ""  || sessionStorage.getItem('token') == null ){
      this.mensajeService.subject$.next(new MensajePopup("Su sesión ha expirado", false, false));
      this.router.navigate(['/herramientasDigitales/login']);
    }

    if(sessionStorage.getItem('gwTipopaq') == undefined || sessionStorage.getItem('gwTipopaq') == ""  || sessionStorage.getItem('gwTipopaq') == null || sessionStorage.getItem('gwDocumentacion') == undefined || sessionStorage.getItem('gwDocumentacion') == ""  || sessionStorage.getItem('gwDocumentacion') == null){
      this.mensajeService.subject$.next(new MensajePopup("Su sesión ha expirado", false, false));
      this.router.navigate(['/herramientasDigitales/documentacion/menu']);
    }
  }

  cargardatosGuardatos() {
    if (this.doc.tipo.length > 0) {
      this.arrtipos = this.doc.tipo;
      this.arr2 = this.doc.tipo;
    }
  }


  //tipo de paquete
  //cargamos ciudades destinatario
  async obtenerTipoEnvio() {
    await this.GuiaswebServiceService.obtenerTipoEnvio(this.doc.idsesion, this.token).then(
      d => {

        this.tipoEnvio = d;

      }
    ).catch((error: HttpErrorResponse) => {
      if (error.error.ExceptionMessage == "invalid_token") {
        this.mensajeService.subject$.next(new MensajePopup("Su sesión ha expirado", false, false));
        this.router.navigate(['/herramientasDigitales/login']);
        // this.router.navigate(['/login']);
      } else {
        this.mensajeService.subject$.next(new MensajePopup("ocurrio un error inesperado al iniciar sesión en tu cuenta", false, false));
      }
    });
  }

  tipoChanged(id) {
    console.log(id);
    if(id == '2'){
      this.showDimensiones = false;
      this.docTipoForm.get('largo').setValue(0);
      this.docTipoForm.get('ancho').setValue(0);
      this.docTipoForm.get('alto').setValue(0);
    }else{
      this.showDimensiones = true;
      this.docTipoForm.get('largo').setValue('');
      this.docTipoForm.get('ancho').setValue('');
      this.docTipoForm.get('alto').setValue('');
    }
  }

  quitarTipo(id) {
    var i2 = this.arrtipos.indexOf(id);

    if (id !== -1) {

      this.arrtipos.splice(id, 1);
      this.arr2 = this.arrtipos.slice();

      this.obtenerTipoEnvio();
      this.docTipoForm.controls.selectTipo.setValue('');
      window.scrollTo(0,0);
    }

  }

 async AgregarTablaTipo() {
    this.submittedTipo = true;
    if (this.docTipoForm.valid) {
      const t = new tipo;
      // si no hay
      if (this.arr2.length == 0) {
        t.idtipo = this.docTipoForm.controls.selectTipo.value
        for (let i = 0; i < this.tipoEnvio.length; i++) {
          if (this.tipoEnvio[i].idTipo == t.idtipo) {
            t.tipo = this.tipoEnvio[i].Tipo;
            break;
          }
        }
      }
      // si ya hay
      else {
        t.idtipo = this.arr2[0].idtipo
        t.tipo = this.arr2[0].tipo
      }


      t.cantidad = this.docTipoForm.controls.cantidad.value
      t.peso = this.docTipoForm.controls.peso.value
      t.largo = this.docTipoForm.controls.largo.value
      t.ancho = this.docTipoForm.controls.ancho.value
      t.alto = this.docTipoForm.controls.alto.value

      this.arr2.push(t);

      //    this.verificarTipo()

     await this.GuiaswebServiceService.verificar_tipo(this.doc.idsesion, this.arr2, this.token).then(
        d => {
          var data = d;
          if (data.id > 0) {

            //agregamos el nuevo
           // this.arrtipos = this.arr2;
            this.arrtipos = this.arr2.slice();
            this.validaciontipos = false;

            if (this.arr2.length > 0) {
              this.tipoEnvio = [{ "idTipo": this.arr2[0].idtipo, "Tipo": this.arr2[0].tipo }]
            }

          } else {
            //quitamos el nuevo porque no cumple
            if (this.arrtipos.length == 0) {
              this.arr2 = []
              this.arrtipos = []

            } else {
              this.arr2 = this.arrtipos.slice();
            }

            this.msgtipos = data.msg;
            this.validaciontipos = true;

          }
          this.limpiarCamposTipo();


        }
      ).catch((error: HttpErrorResponse) => {
        if (error.error.ExceptionMessage == "invalid_token") {
          this.mensajeService.subject$.next(new MensajePopup("Su sesión ha expirado", false, false));
          this.router.navigate(['/herramientasDigitales/login']);
          // this.router.navigate(['/login']);
        } else {
          this.mensajeService.subject$.next(new MensajePopup("ocurrio un error inesperado al iniciar sesión en tu cuenta", false, false));
        }
      });

    }
  }


  limpiartodo() {
    this.limpiarCamposTipo();
    this.arrtipos = [];
    this.arr2 = [];
    window.scrollTo(0,0);

  }


  limpiarCamposTipo() {
    this.docTipoForm.controls.selectTipo.setValue('');
    this.docTipoForm.controls.cantidad.setValue('');
    this.docTipoForm.controls.peso.setValue('');
    this.docTipoForm.controls.largo.setValue('');
    this.docTipoForm.controls.ancho.setValue('');
    this.docTipoForm.controls.alto.setValue('');
    this.submittedTipo = false;
    window.scrollTo(0,0);
  }

  cambiarFocus(e) {
    if (!((e.keyCode  >= 48 && e.keyCode  <= 57) || (e.keyCode ==8) || (e.keyCode ==9) || (e.keyCode ==13) ||  (e.keyCode ==38) || (e.keyCode ==40) ||  (e.keyCode ==37) || (e.keyCode ==39) || e.keyCode >= 96 && e.keyCode <= 105 )) {
      e.preventDefault();
    }
  }

  //creamos la sesion en el paso 4
  async guardarsesion_4() {
    this.validarsesion();
    this.doc.tipo = this.arr2
    this.doc.paso = 4
    if (this.arrtipos != [] && this.arrtipos.length > 0) {
      await this.GuiaswebServiceService.GwoprsesionDetV2(this.doc, this.token).then(
        d => {
          this.datagw = d

          // agregamos los datos para el paso 5
          this.doc.idsedet = this.datagw.idsedet;
          this.doc.idsesion = this.datagw.idsesion;
          this.doc.paso = 5;

          this.GuiaswebServiceService.setplantilla(5);
          sessionStorage.removeItem('gwDocumentacion');
          sessionStorage.setItem('gwDocumentacion',  window.btoa(unescape(encodeURIComponent(JSON.stringify(this.doc) ))));
          //    this.obtenerTipoEnvio();
          //pasamos a la pantalla de detalle

        }
      ).catch((error: HttpErrorResponse) => {
        if (error.error.ExceptionMessage == "invalid_token") {
          this.mensajeService.subject$.next(new MensajePopup("Su sesión ha expirado", false, false));
          this.router.navigate(['/herramientasDigitales/login']);
          // this.router.navigate(['/login']);
        } else {
          this.mensajeService.subject$.next(new MensajePopup("ocurrio un error inesperado al iniciar sesión en tu cuenta", false, false));
        }
      });
    }else{
      this.mensajeService.subject$.next(new MensajePopup("Es necesario agregar las especificaciones del paquete", false, false));
    }
  }
  regresarMenu(){
    this.GuiaswebServiceService.setplantilla(3);
     }
}
