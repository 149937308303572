import { HttpClient } from "@angular/common/http";
import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator, MatPaginatorIntl } from "@angular/material/paginator";
import { GuiaswebServiceService } from "../../servicios/guiasweb-service.service";
import { MatTableDataSource } from "@angular/material/table";
import { recolecciondom } from "../../servicios/recoleccion-dom.service";
//import {MatTableModule} from '@angular/material/table';
import jwt_decode from "jwt-decode";
import { user } from "../../entidades/gwuser";

export interface ListHistorial {
  folio: number;
  nombre: string;
  estatus: string;
  correo: string;
  telefono: number;
  estado: string;
  ciudad: string;
  colonia: string;
  direccion: string;
  referencia: string;
}

@Component({
  selector: "app-listado-recoleccion",
  templateUrl: "./listado-recoleccion.component.html",
  styleUrls: ["./listado-recoleccion.component.css"],
})
export class ListadoRecoleccionComponent implements OnInit {
  imgtochange1: String = "assets/iconos/time_white.png";
  imgtochange2: String = "assets/iconos/package-box.png";
  imgtochange3: String = "assets/iconos/envio.png";
  imgtochange4: String = "assets/iconos/caja-de-entrega.png";
  imgtochange: String;
  ELEMENT_DATA: ListHistorial[];
  isLoguedin: boolean;
  listdetallerecol: any[];
  idfol: number;
  element: any;
  foliomdl: any;
  nombremdl: any;
  estatusrecols: any[];

  userGw: user;
  datagw: any;
  token: string;
  tokendatos: any;
  idcliente: number;

  displayedColumns: string[] = [
    "Folio",
    "Nombre",
    "Estatus",
    "Correo",
    "Telefono",
    "Estado",
    "Ciudad",
    "Colonia",
    "Direccion",
    "Referencia",
  ];
  dataSource = new MatTableDataSource<ListHistorial>();

  constructor(
    private GuiaswebServiceService: GuiaswebServiceService,
    private recolecciondom: recolecciondom,
    private http: HttpClient
  ) {}

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = "Datos por hoja:";
    this.paginator._intl.nextPageLabel = "Pagina siguiente";
    this.paginator._intl.previousPageLabel = "Pagina anterior";
    this.paginator._intl.firstPageLabel = "Pagina inicial";
    this.paginator._intl.lastPageLabel = "Pagina final";
  }

  ngOnInit(): void {
    this.GuiaswebServiceService.setModuloG(5);
    if (localStorage.getItem("login")) {
      if (localStorage.getItem("login") === "true") {
        this.isLoguedin = true;
        // console.log("sesión true");
      }
    }

    this.oprObtenerSolRec();
    this.cambiarestatusimg();
  }

  cambiarColor(color: any) {
    color = document.getElementById("tabletd").textContent;

    if (color == "Entregado") {
      document.getElementById("tabletd").style.color = "orange !important";
    }
  }

  oprObtenerSolRec() {
    this.token = sessionStorage.getItem("token");
    this.tokendatos = jwt_decode(this.token);
    this.userGw = this.tokendatos;
    this.idcliente = this.userGw.idUser;

    let resp = this.recolecciondom.gethistorialsolicitudes(this.idcliente);
    resp.subscribe(
      (report) => (this.dataSource.data = report as ListHistorial[])
    );
  }

  highlight(row) {
    this.foliomdl = row.folio;
    this.nombremdl = row.Nombre;

    this.recolecciondom.getStatusRec(row.folio).then((data) => {
      this.estatusrecols = data;
    });
  }

  cambiarestatusimg() {
    var status = document.getElementById("status");
    // console.log(status);
    if (status.textContent === "Solicitado") {
      this.imgtochange = this.imgtochange1;
    }
    if (status.textContent === "Recolectado") {
      this.imgtochange = this.imgtochange2;
    }

    if (status.textContent === "En recorrido") {
      this.imgtochange = this.imgtochange3;
    } else {
      this.imgtochange = this.imgtochange4;
    }
  }
}
