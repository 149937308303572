<div class="container_herramienta">
    <img src="assets/img/web/envia.webp" class="img_toAnimate">
    <!-- <div class="volver" routerLink="**">Volver al Inicio</div> -->
    <div class="alinearIzq">
        <div class="gw-titulogeneral">
            <h1>Crear cuenta</h1>
            <p>Ingrese los siguentes campos</p>
            <div class="gw-container">
                <form [formGroup]="usrForm">
                    <div class="div-form ">
                        <label class="lbl-text">Nombre completo</label>
                        <input class="doc-lbl" formControlName="nombre" maxlength="100" type="text" inputmode="text"
                            autocomplete="name"
                            [ngClass]="{validacion: (usr.nombre.invalid || usr.nombre.hasError('IsEmpty')) && submitted}">
                        <div class="val-valido" [ngClass]="{invalido: (usr.nombre.invalid)  && submitted }">* Introduce
                            el
                            nombre</div>
                    </div>

                    <div class="div-form divpassword">
                        <label class="lbl-text">Contraseña</label>

                        <div class="">
                            <input class="inputpassword " formControlName="contrasena" type={{tipopass}}
                                name="contrasena" #password maxlength="30" inputmode="text" autocomplete="new-password"
                                [ngClass]="{validacion: (usr.contrasena.invalid || usr.contrasena.hasError('IsEmpty')) && submitted}">

                            <img class="lazyload imgmenu" (click)="mostrarpassword()" data-src={{passimg}}
                                alt="Promociones" />
                        </div>

                        <div class="val-valido"
                            [ngClass]="{invalido: (usr.contrasena.invalid)  && submitted && usr.contrasena.value !== ''}">
                            * Las
                            contraseñas debe contener una mayúsculas, un dígitos y un mínimo de 8 caracteres</div>
                        <div class="val-valido" [ngClass]="{invalido: (usr.contrasena.value == '')  && submitted}">*
                            Introduce
                            la contraseña</div>
                    </div>
                    <div class="div-form">
                        <label class="lbl-text">Confirmar contraseña</label>
                        <input class="doc-lbl" type="text" formControlName="contrasena2" type="password"
                            name="contrasena2" #password maxlength="30"
                            [ngClass]="{validacion: (usr.contrasena2.invalid || usr.contrasena2.hasError('IsEmpty')) && submitted}">
                        <div class="val-valido"
                            [ngClass]="{invalido: usr.contrasena2.invalid  && submitted && usr.contrasena2.value != ''}">
                            * La
                            contraseña no coinciden. Inténtalo de nuevo</div>
                        <div class="val-valido" [ngClass]="{invalido: (usr.contrasena2.value == '')  && submitted}">*
                            Confirma
                            tu contraseña</div>
                    </div>

                    <div class="div-form">
                        <label class="lbl-text">Correo</label>
                        <input formControlName="correo" maxlength="100" type="email" inputmode="email"
                            autocomplete="email"
                            [ngClass]="{validacion: (usr.correo.invalid || usr.correo.hasError('IsEmpty')) && submitted}">
                        <div class="val-valido" [ngClass]="{invalido: (usr.correo.invalid)  && submitted}">* Introduce
                            un correo
                        </div>
                    </div>

                    <div class="div-form">
                        <label class="lbl-text">Teléfono</label>
                        <input formControlName="telefono" maxlength="10" minlength="10" type="text" inputmode="tel"
                            autocomplete="tel" (keydown)="validarnumeros($event)"
                            [ngClass]="{validacion: (usr.telefono.invalid || usr.telefono.hasError('IsEmpty')) && submitted}">
                        <div class="val-valido"
                            [ngClass]="{invalido: (usr.telefono.invalid)  && submitted && usr.telefono.value != ''}">*
                            Intenta
                            con un
                            teléfono valido</div>

                        <div class="val-valido" [ngClass]="{invalido: (usr.telefono.value == '')  && submitted}">*
                            Introduce
                            un teléfono</div>
                    </div>

                    <div class="div-form">
                        <label class="lbl-text">Código postal</label>
                        <input formControlName="cp" maxlength="5" minlength="5" type="text" inputmode="decimal"
                            (keydown)="validarnumeros($event)" autocomplete="postal-code"
                            [ngClass]="{validacion: (usr.cp.value == null || usr.cp.invalid || usr.cp.hasError('IsEmpty')) && submitted}">
                        <div class="val-valido"
                            [ngClass]="{invalido: (usr.cp.invalid)  && submitted && usr.cp.value != ''}">*
                            Intenta con un
                            código postal valido</div>
                        <div class="val-valido" [ngClass]="{invalido: (usr.cp.value == '')  && submitted}">* Introduce
                            el código
                            postal</div>
                    </div>

                    <div class="div-form">
                        <label class="lbl-text">RFC (opcional) </label>
                        <input type="text" maxlength="13" formControlName="rfc">
                    </div>

                    <div class="div-form">
                        <button name="Aceptar" class="gw-btn-continuar" type="submit" (click)="guardarusr()">Crear
                            cuenta</button>
                    </div>

                    <p class="textSecond">Si olvidó su contraseña, le recomendamos mandar un email a:<span
                            class="posttitulo">
                            contacto@tufesapack.com</span></p>
                </form>
            </div>
        </div>
    </div>
</div>