import { Component, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
// import { recolecciondom } from "../../servicios/recoleccion-dom.service";
import { AdministrarCuentaService } from "src/app/servicios/administrar-cuenta.service";
import { DomSanitizer } from "@angular/platform-browser";
import { user } from "../../entidades/gwuser";
import jwt_decode from "jwt-decode";
import { AnimationQueryMetadata } from "@angular/animations";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { GuiaswebServiceService } from '../../servicios/guiasweb-service.service';
import { Router, NavigationStart } from '@angular/router';
import { MensajeService } from 'src/app/servicios/mensaje.service';
import { MensajePopup } from 'src/app/entidades/MensajePopup';

@Component({
  selector: "app-administrar-cuenta",
  templateUrl: "./administrar-cuenta.component.html",
  styleUrls: ["./administrar-cuenta.component.css"],
})
export class AdministrarCuentaComponent implements OnInit {
  isVisited: boolean = false;
  public archivos: any[];
  public previsualizacion: string;
  public previsualizacion2: string;

  datacuenta: any;
  dataAct: any;
  msg: string;

  userGw: user;
  datagw: any;
  token: string;
  tokendatos: any;
  idcliente: number = -1;

  // Definimos el formulario con sus campos y validaciones
  formulario = new FormGroup({
    nombre: new FormControl("", Validators.required),
    correo: new FormControl("", [Validators.required, Validators.email]),
    telefono: new FormControl(""),
    cp: new FormControl(""),
    razonsocial: new FormControl(""),
    rfc: new FormControl(""),
    domiciliofiscal: new FormControl(""),
    regimenfiscal: new FormControl(""),
  });

  constructor(
    private administrarCuentaService: AdministrarCuentaService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private GuiaswebServiceService: GuiaswebServiceService,
    private mensajeService: MensajeService
  ) {}

  ngOnInit(): void {
    this.VerificarToken();
    this.scrollTop();
    // this.pintarChecks();
    this.cargardata();
    // this.cambiarvalortel();

  }

  VerificarToken() {
    if (sessionStorage.getItem('token') == undefined || sessionStorage.getItem('token') == "" || sessionStorage.getItem('token') == null) {
      this.mensajeService.subject$.next(new MensajePopup("Su sesión ha expirado", false, false));
      this.router.navigate(['/herramientasDigitales/login']);
      return;
    }
  }

  objectdata = {
    idcliweb: 1,
    nombre: "Humberto alonso garcia quiroz",
    correo: "humbertogarciaq@gmail.com",
    telefono: "6441675927",
    cp: "85000",
    chktelefono: "",
    chkwhats: "",
    chkcorreo: "",
    chkninguno: "",
    reazon: "asasas",
    rfc: "sasas",
    domfisc: "asasas",
    regimenfiscal: "605",
    imagenes: [{ idimg: 7, ref1: 0, ref2: 0, tipo1: 0, tipo2: 0, img: "" }],
    documentos: [{ iddoc: "", tipo1: "", tipo2: "", img: "" }],
    sesion: [{ nummsg: "1", msg: "Bienvenido" }],
  };

  actualizardatos = {
    idcliweb: 1,
    ckTelefono: "1",
    ckWpp: "1",
    ckCorreo: "1",
    ninguno: "0",
    opr: "MDF",
    razon: "Humberto garcia",
    rfc: "asdfasdf",
    domfisc: "85000",
    regfisc: "616",
  };

  dataDocumentos = [ {
    tipo1 : "",
    tipo2 : "",
    img : "",
    usr : "",
  }
];

  scrollTop() {
    window.scroll(0, 0);
  }

  toggleFiscaldata() {
    this.isVisited = !this.isVisited;
    //     console.log(this.isVisited);
    if ((this.isVisited = true)) {
      document.getElementById("readonly").style.pointerEvents = "auto";
      document.getElementById("readonly").style.opacity = "1";
    }
  }

  capturarfile(event) {
    const archivoCapturado = event.target.files[0];
    this.extraerBase64(archivoCapturado).then((imagen: any) => {
      this.previsualizacion = imagen.base;
      console.log(imagen);
    });
    this.archivos.push(archivoCapturado);
  }

  capturarfile2(event) {
    const archivoCapturado2 = event.target.files[0];
    this.extraerBase64(archivoCapturado2).then((imagen2: any) => {
      this.previsualizacion2 = imagen2.base2;
      console.log(imagen2);
    });
    this.archivos.push(archivoCapturado2);
  }


  extraerBase64 = async ($event: any) =>
    new Promise((resolve, reject) => {
      try {
        const unsafeImg = window.URL.createObjectURL($event);
        const image = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeImg);
        const reader = new FileReader();
        reader.readAsDataURL($event);
        reader.onload = () => {
          resolve({
            base: reader.result,
            base2: reader.result,
          });
        };
        reader.onerror = (error) => {
          resolve({
            base: null,
            base2: null,
          });
        };
      } catch (e) {
        return null;
      }
    });

  subirArchivo(): any {
    try {
      const formularioDeDatos = new FormData();
      this.archivos.forEach((archivo) => {
        formularioDeDatos.append("files", archivo);
      });
      this.administrarCuentaService
        .subirDocs(formularioDeDatos).subscribe((res) => {
        });
    } catch (e) {
      console.log("ERROR", e);
    }
  }




  cambiarvalortel() {
    // this.checktel = !this.checktel;
    if (this.objectdata.chktelefono === "1") {
      this.objectdata.chktelefono = "0";
      document.getElementById("circle").style.left = "1px";
      document.getElementById("sliderroundtel").style.backgroundColor = "#ccc";
    } else {
        this.objectdata.chktelefono = "1";
        document.getElementById("sliderroundtel").style.backgroundColor =
          "#ff5b23";
        document.getElementById("circle").style.left = "26px";
    }
  }

  cambiarvalorwha() {
    if (this.objectdata.chkwhats === "1") {
      this.objectdata.chkwhats = "0";
      document.getElementById("circle2").style.left = "1px";
      document.getElementById("sliderroundwha").style.backgroundColor = "#ccc";
    } else {
        this.objectdata.chkwhats = "1";
        document.getElementById("circle2").style.left = "26px";
        document.getElementById("sliderroundwha").style.backgroundColor =
          "#ff5b23";
    }
  }

  cambiarvalorcorreo() {

    if (this.objectdata.chkcorreo === "1") {
      this.objectdata.chkcorreo = "0";
      document.getElementById("circle3").style.left = "1px";
      document.getElementById("sliderroundcorreo").style.backgroundColor =
        "#ccc";
    } else {

        this.objectdata.chkcorreo = "1";
        document.getElementById("circle3").style.left = "26px";
        document.getElementById("sliderroundcorreo").style.backgroundColor =
          "#ff5b23";
    }
  }

  cambiarvalorninguno(){
    if (this.objectdata.chkninguno === "1") {
      this.objectdata.chkninguno = "0";
      document.getElementById("circle4").style.left = "1px";
      document.getElementById("sliderroundninguno").style.backgroundColor =
        "#ccc";
        this.actualizardata();
        this.cargardata();
        this.pintarChecks();
    } else {
        this.objectdata.chkninguno = "1";
        this.reestablecervalores();
        this.actualizardata();
        this.cargardata();
        this.pintarChecks();
        document.getElementById("circle4").style.left = "26px";
        document.getElementById("sliderroundninguno").style.backgroundColor =
          "#ff5b23";
    }
  }

  cargardata() {
    if (sessionStorage.getItem("token")) {
      this.token = sessionStorage.getItem("token");
      this.tokendatos = jwt_decode(this.token);
      this.userGw = this.tokendatos;
      this.idcliente = this.userGw.idUser;
    } else {
      this.idcliente = -1;
    }
    this.administrarCuentaService.cargarDatos(this.idcliente).then((data) => {
      this.datacuenta = data;
        this.objectdata = this.datacuenta;
        this.pintarChecks();
        if(this.objectdata.documentos != null){
          if(this.objectdata.documentos.length > 0){
            //FORMA MAS SENCILLA DE REALIZAR UN FOR EN ANGULAR
            // for (let i of this.objectdata) {
            //   console.log(i);
            // }
            for(var i =0; i < this.objectdata.documentos.length; i++){
              if(this.objectdata.documentos[i].tipo1 == "1"){
                if(this.objectdata.documentos[i].tipo2 == "1"){
                   this.previsualizacion = this.objectdata.documentos[i].img;
                }
                if(this.objectdata.documentos[i].tipo2 == "2"){
                  this.previsualizacion2 = this.objectdata.documentos[i].img;
                }
              }
            }
          }
        }
    });
  }

  async actualizardata() {
    if (sessionStorage.getItem("token")) {
      this.token = sessionStorage.getItem("token");
      this.tokendatos = jwt_decode(this.token);
      this.userGw = this.tokendatos;
      this.idcliente = this.userGw.idUser;
    } else {
      this.idcliente = -1;
    }

    this.actualizardatos.idcliweb = this.objectdata.idcliweb;
    this.actualizardatos.ckTelefono = this.objectdata.chktelefono;
    this.actualizardatos.ckWpp = this.objectdata.chkwhats;
    this.actualizardatos.ckCorreo = this.objectdata.chkcorreo;
    this.actualizardatos.ninguno = this.objectdata.chkninguno;
    this.actualizardatos.opr = "MDF";
    this.actualizardatos.razon = this.objectdata.reazon;
    this.actualizardatos.rfc = this.objectdata.rfc;
    this.actualizardatos.domfisc = this.objectdata.domfisc;
    this.actualizardatos.regfisc = this.objectdata.regimenfiscal;

    console.log(this.actualizardatos);


  await this.administrarCuentaService
  .actualizarDatos(this.actualizardatos).then((data) => {
    this.dataAct = data;
    console.log(this.dataAct);
    if ((this.dataAct.nummsj) === "1") {
      this.showMensaje(this.dataAct.msg);
      this.actualizardatos = this.dataAct.list;
      document.getElementById("confirma").style.visibility = "visible";
      document.getElementById("confirma").style.height = "200px";
    } else {
      this.showMensaje(this.dataAct.msg);
      document.getElementById("confirma").style.visibility = "visible";
      document.getElementById("confirma").style.height = "200px";
    }

    this.administrarCuentaService
      .cargarDatos(this.idcliente).then((data) => {
        this.datacuenta = data;
        this.objectdata = this.datacuenta;
      });
      this.pintarChecks();
  });

  }

  async eliminarCuenta() {
    if (sessionStorage.getItem("token")) {
      this.token = sessionStorage.getItem("token");
      this.tokendatos = jwt_decode(this.token);
      this.userGw = this.tokendatos;
      this.idcliente = this.userGw.idUser;
    } else {
      this.idcliente = -1;
    }

    this.actualizardatos.idcliweb = this.objectdata.idcliweb;
    this.actualizardatos.ckTelefono += this.objectdata.chktelefono;
    this.actualizardatos.ckWpp += this.objectdata.chkwhats;
    this.actualizardatos.ckCorreo += this.objectdata.chkcorreo;
    this.actualizardatos.ninguno += this.objectdata.chkninguno;
    this.actualizardatos.opr = "DEL";
    this.actualizardatos.razon = this.objectdata.reazon;
    this.actualizardatos.rfc = this.objectdata.rfc;
    this.actualizardatos.domfisc = this.objectdata.domfisc;
    this.actualizardatos.regfisc = this.objectdata.regimenfiscal;

    await this.administrarCuentaService
      .actualizarDatos(this.actualizardatos).then((data) => {
        this.dataAct = data;
        console.log(this.dataAct);
        if ((this.dataAct.nummsj) === "1") {
          this.showMensaje(this.dataAct.msg);
          this.actualizardatos = this.dataAct.list;
          document.getElementById("confirma").style.visibility = "visible";
          document.getElementById("confirma").style.height = "200px";
        } else {
          this.showMensaje(this.dataAct.msg);
          document.getElementById("confirma").style.visibility = "visible";
          document.getElementById("confirma").style.height = "200px";
        }
        this.cerrarsesion();
      });
  }

  subirArchivosBase64(){
    if (sessionStorage.getItem("token")) {
      this.token = sessionStorage.getItem("token");
      this.tokendatos = jwt_decode(this.token);
      this.userGw = this.tokendatos;
      this.idcliente = this.userGw.idUser;
    } else {
      this.idcliente = -1;
    }

   let Doc = [
    {
      tipo1 : "1",
      tipo2 : "1",
      img : this.previsualizacion,
      usr : this.idcliente,
    },
    {
      tipo1 : "1",
      tipo2 : "2",
      img : this.previsualizacion2,
      usr : this.idcliente,
    }
  ];

    this.administrarCuentaService.uploadBase64File(Doc,this.token).subscribe(response => {
      console.log('Archivo subido correctamente');
    }, error => {
      console.log('Error al subir el archivo', error);
    });
    this.actualizardata();
  }




  showMensaje(msg: string) {
    this.msg = msg;
  }

  closePopup() {
    document.getElementById("confirma").style.visibility = "hidden";
    document.getElementById("confirma").style.height = "0px";
  }
  cerrarsesion() {
    sessionStorage.removeItem('token')
    localStorage.clear();
    this.router.navigate(['/herramientasDigitales/login']);
    this.GuiaswebServiceService.setnumlogin(false);
  }

  pintarChecks() {
    if (this.objectdata.chktelefono === "1") {
      document.getElementById("sliderroundtel").style.backgroundColor =
        "#ff5b23";
      document.getElementById("circle").style.left = "26px";
    } else {
      document.getElementById("sliderroundtel").style.backgroundColor = "#ccc";
      document.getElementById("circle").style.left = "1px";
    }

    if (this.objectdata.chkwhats === "1") {
      document.getElementById("sliderroundwha").style.backgroundColor =
        "#ff5b23";
      document.getElementById("circle2").style.left = "26px";
    } else {
      document.getElementById("sliderroundwha").style.backgroundColor = "#ccc";
      document.getElementById("circle2").style.left = "1px";
    }
    if (this.objectdata.chkcorreo === "1") {
      console.log(this.objectdata.chkcorreo);
      document.getElementById("sliderroundcorreo").style.backgroundColor =
        "#ff5b23";
      document.getElementById("circle3").style.left = "26px";
    } else {
      document.getElementById("sliderroundcorreo").style.backgroundColor =
        "#ccc";
      document.getElementById("circle3").style.left = "1px";
    }

    if (this.objectdata.chkninguno === "1") {
      document.getElementById("sliderroundninguno").style.backgroundColor =
        "#ff5b23";
      document.getElementById("circle4").style.left = "26px";
    } else {
      document.getElementById("sliderroundninguno").style.backgroundColor =
        "#ccc";
      document.getElementById("circle4").style.left = "1px";
    }
  }
  reestablecervalores(){
    this.objectdata.chktelefono === "0";
    this.objectdata.chkwhats === "0";
    this.objectdata.chkcorreo === "0";
    this.objectdata.chkninguno === "1";
  }

 async validaDatosFisc(){
    this.actualizardatos.razon = this.objectdata.reazon;
    this.actualizardatos.rfc = this.objectdata.rfc;
    this.actualizardatos.domfisc = this.objectdata.domfisc;
    this.actualizardatos.regfisc = this.objectdata.regimenfiscal;

    if(this.actualizardatos.rfc != ""){
      this.administrarCuentaService.validaDatos(this.actualizardatos.rfc,this.actualizardatos.razon, this.actualizardatos.domfisc, this.actualizardatos.regfisc).then((datav) => {
        if(datav.NumMsj > 0){
         this.actualizardata();
          console.log(datav);
        }
        else{
          this.showMensaje(datav.TxtMsj);
          document.getElementById("confirma").style.visibility = "visible";
          document.getElementById("confirma").style.height = "200px";
          console.log(datav);
          return;
        }
      });
    }
    else{
      this.actualizardata();
    }
    }


}
