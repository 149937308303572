import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { ReloadiframeService } from 'src/app/servicios/reloadiframe.service';

@Component({
  selector: 'app-buzon',
  templateUrl: './buzon.component.html',
  styleUrls: ['./buzon.component.css']
})
export class BuzonComponent implements OnInit {
  url: any; 

  constructor(
    private sanitizer: DomSanitizer,
    private reloadIframeS: ReloadiframeService,
    
  ) { }

  ngOnInit() {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(environment.URLTools + "/buzon?lang=" + environment.language+ '&v=' + environment.version);

    this.reloadIframeS.subject$.subscribe(
      d => {
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(environment.URLTools + "/buzon?lang=" + environment.language + '&v=' + environment.version);
      }
    );
  }
  
 

}
