<div class="gw-container">
    <form [formGroup]="docDetalleForm">
        <!-- Detalle -->
        <div class="doc-div">
            <div class="gw-flex-titulos">
                <div>
                    <h4>Detalle</h4>
                    <p class="gw-flex-titulo2">{{this.tipopaquete}}</p>
                </div>
                <div>
                    <button class="gw-btn-backpantalla" (click)="limpiarDetalle()" type="button">Limpiar</button>
                </div>
            </div>

            <div *ngIf="valcheck" class="guiaDoc-container ">

                <label class="custom-radio-checkbox" [ngClass]="{'checkbox-true': valcheck.cobrar == 1}">
                    <!-- Input oculto -->
                    <input class="custom-radio-checkbox__input" type="checkbox" formControlName="porcobrar"
                        (click)="cambiarcheck('cobrar')">
                    <!-- Imagen en sustitucion -->
                    <span class="custom-radio-checkbox__show custom-radio-checkbox__show--checkbox"></span>
                    <!-- Texto -->
                    <span class="custom-radio-checkbox__text">Por cobrar</span>
                </label>

                <label class="custom-radio-checkbox" [ngClass]="{'checkbox-true': valcheck.recoleccion == 1}">
                    <!-- Input oculto -->
                    <input class="custom-radio-checkbox__input" type="checkbox" formControlName="recoleccion"
                        (click)="cambiarcheck('recoleccion')">
                    <!-- Imagen en sustitucion -->
                    <span class="custom-radio-checkbox__show custom-radio-checkbox__show--checkbox"></span>
                    <!-- Texto -->
                    <span class="custom-radio-checkbox__text">Recolección</span>
                </label>


                <label class="custom-radio-checkbox" [ngClass]="{'checkbox-true': valcheck.acuse == 1}">
                    <!-- Input oculto -->
                    <input class="custom-radio-checkbox__input" type="checkbox" formControlName="acuse"
                        (click)="cambiarcheck('acuse')">
                    <!-- Imagen en sustitucion -->
                    <span class="custom-radio-checkbox__show custom-radio-checkbox__show--checkbox"></span>
                    <!-- Texto -->
                    <span class="custom-radio-checkbox__text">Acuse recibo</span>
                </label>

            </div>

            <div class="div-form">
                <label class="lbl-text">Valor declarado</label>
                <input class="doc-lbl" type="text" formControlName="ValorDeclarado"
                    [ngClass]="{validacion: (DocDetalle.ValorDeclarado.invalid || DocDetalle.ValorDeclarado.hasError('IsEmpty')) && submittedDetalle}">
            </div>

            <div class="div-form">
                <label class="lbl-text">Comentario</label>
                <input class="doc-lbl" type="text" formControlName="comentario"
                    [ngClass]="{validacion: (DocDetalle.comentario.invalid || DocDetalle.comentario.hasError('IsEmpty')) && submittedDetalle}">
            </div>

            <div class="gw-reverse-btn">
                <button name="Aceptar" class="gw-btn-continuar gw-btn-pasos" type="submit"
                    (click)="guardarsesion_5()">Continuar</button>
                <button class="btn-back gw-btn-pasos" type="button" (click)="regresarMenu()">Regresar</button>
            </div>

        </div>
    </form>

</div>
