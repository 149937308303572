import { Component, OnInit } from "@angular/core";
import { FormGroup, Validators, FormBuilder, FormControl } from "@angular/forms";
import { documentacion } from "../../entidades/gwdocumentacion";
import { GuiaswebServiceService } from "../../servicios/guiasweb-service.service";
import { Router } from "@angular/router";
import { tipo } from "../../entidades/gwtipo";
import { MensajeService } from "src/app/servicios/mensaje.service";
import { MensajePopup } from "src/app/entidades/MensajePopup";
import { HttpErrorResponse } from "@angular/common/http";
import jwt_decode from "jwt-decode";
import { user } from "../../entidades/gwuser";
import  Swal  from 'sweetalert2';
import { BolsaTrabajoComponent } from "../bolsa-trabajo/bolsa-trabajo.component";

@Component({
  selector: "app-gw-doc-completa",
  templateUrl: "./gw-doc-completa.component.html",
  styleUrls: ["./gw-doc-completa.component.css"],
})
export class GwDocCompletaComponent implements OnInit {
  documentacionForm: FormGroup;
  arrtipos = [];
  arrSolGuias = [];
  submitted: boolean;
  d: documentacion;
  guia: any;
  dato: any;
  tipopaquete: string;
  imptotal: string="3.00";
  valcheckm: boolean;
  valcheckl: boolean;

  token: string;
  tokendatos: any;
  isLoguedin: boolean;
  userGw: user;
  idcliente: number=-1;

  cuponValidacion: string = "default";
  mostrarLabelDescuento = false;
  mensajecuponvacio: string;
  precioinicialtachado: string;
  txtcuponmayusculas:string;

  guiasDescripcion =
    {
      id: 0,
      numGuias: 0,
      costo: "",
      detalle: "",
      tipo: "",
      beneficios: [""]
    };

  constructor(
    private GuiaswebServiceService: GuiaswebServiceService,
    private router: Router,
    private mensajeService: MensajeService
  ) {}

  ngOnInit(): void {
    this.validarsesion();

    this.tipopaquete = decodeURIComponent(
      escape(window.atob(sessionStorage.getItem("gwTipopaq")))
    );
    var docaux = decodeURIComponent(
      escape(window.atob(sessionStorage.getItem("gwDocumentacion")))
    );

    this.d = JSON.parse(docaux);

    window.scrollTo(0, 0);

    this.DocumentacionForm();
    this.cargarGuiacompleta();
  }

  validarsesion() {
    if (
      sessionStorage.getItem("token") == undefined ||
      sessionStorage.getItem("token") == "" ||
      sessionStorage.getItem("token") == null
    ) {
      this.mensajeService.subject$.next(
        new MensajePopup("Su sesión ha expirado", false, false)
      );
      this.router.navigate(["/herramientasDigitales/login"]);
    }

    if (
      sessionStorage.getItem("gwTipopaq") == undefined ||
      sessionStorage.getItem("gwTipopaq") == "" ||
      sessionStorage.getItem("gwTipopaq") == null ||
      sessionStorage.getItem("gwDocumentacion") == undefined ||
      sessionStorage.getItem("gwDocumentacion") == "" ||
      sessionStorage.getItem("gwDocumentacion") == null
    ) {
      this.mensajeService.subject$.next(
        new MensajePopup("Su sesión ha expirado", false, false)
      );
      this.router.navigate(["/herramientasDigitales/documentacion/menu"]);
    }
  }

  //creamos la sesion en el paso 5
  async cargarGuiacompleta() {
    await this.GuiaswebServiceService.cargarGuiacompleta(
      this.d.idsesion,
      this.d.idcliente,
      this.token
    )
      .then((d) => {
        this.guia = d;
        this.llenarDocumentacion();
      })
      .catch((error: HttpErrorResponse) => {
        if (error.error.ExceptionMessage == "invalid_token") {
          this.mensajeService.subject$.next(
            new MensajePopup("Su sesión ha expirado", false, false)
          );
          this.router.navigate(["/herramientasDigitales/login"]);
          // this.router.navigate(['/login']);
        } else {
          this.mensajeService.subject$.next(
            new MensajePopup(
              "ocurrio un error inesperado al iniciar sesión en tu cuenta",
              false,
              false
            )
          );
        }
      });
  }

  //creamos la sesion en el paso 5
  async finalizardoc() {

    var checkmostrador = document.getElementById("chkmostrador") as HTMLInputElement;
    var checklinea = document.getElementById("chkenlinea") as HTMLInputElement;

    if (checklinea.checked && checkmostrador.checked){
        // alert("chequeados");
        Swal.fire({
          icon: 'info',
          title: 'TIPO DE PAGO',
          text: 'Seleccione solo un tipo de pago.'
        });
        checklinea.checked = false;
        checkmostrador.checked = false;
        return
      }

    this.validarsesion();
    window.scrollTo(0, 0);
    this.GuiaswebServiceService.finalizardoc(
      this.d.idsedet,
      this.d.idsesion,
      this.d.idcliente,
      this.token
    )
      .then(async (d) => {
        window.scrollTo(0, 0);
        this.dato = d;
        if (this.dato.id > 0) {

          var checkl = document.getElementById("chkenlinea") as HTMLInputElement;
          if (checkl.checked)
          {
            this.comprarGuias();
            return;
          }
          else
          {
            this.crearpdf();
          }

          // this.mensajeService.subject$.next(new MensajePopup(this.dato.msg, true, true));
          await this.mensajeService.subject$.next(
            new MensajePopup(this.dato.msg, true, true)
          );
          await this.router.navigate([
            "/herramientasDigitales/documentacion/menu",
          ]);
        } else {
          this.mensajeService.subject$.next(
            new MensajePopup(this.dato.msg, false, false)
          );
        }
      })
      .catch((error: HttpErrorResponse) => {
        if (error.error.ExceptionMessage == "invalid_token") {
          this.mensajeService.subject$.next(
            new MensajePopup("Su sesión ha expirado", false, false)
          );
          this.router.navigate(["/herramientasDigitales/login"]);
          // this.router.navigate(['/login']);
        } else {
          this.mensajeService.subject$.next(
            new MensajePopup(
              "ocurrio un error inesperado al iniciar sesión en tu cuenta",
              false,
              false
            )
          );
        }
      });
  }

  comprarGuias(){

    // localStorage.setItem("totalGuia", this.guiasDescripcion.costo);


    if(sessionStorage.getItem("token")){


      this.guiasDescripcion.id=this.d.idsesion;
      this.guiasDescripcion.numGuias=1;
      this.guiasDescripcion.costo=this.imptotal;
      this.guiasDescripcion.detalle="Guias web mostrador";
      this.guiasDescripcion.tipo="GWC";

      this.token = sessionStorage.getItem("token");
      this.tokendatos = jwt_decode(this.token);
      this.isLoguedin = true;
      this.userGw = this.tokendatos;
      this.idcliente = this.userGw.idUser;
        var cadpago="";
        cadpago =  btoa( JSON.stringify (this.guiasDescripcion));
        this.router.navigate(["/detalle-transaccion"],{queryParams:{rf:cadpago}});
      }
     else {
      this.isLoguedin = false;

      this.router.navigate(["/herramientasDigitales/login"],{queryParams:{to:'gw-doc-completa'}});
    }
  }

  calarchecks()
  {

    var checkm = document.getElementById("chkenlinea") as HTMLInputElement;
    console.log(checkm.checked);
if (checkm.checked)
{
  this.comprarGuias();
}

  }

cambiarcheck(){
  var checkm = document.getElementById("chkmostrador") as HTMLInputElement;
  var checkl = document.getElementById("chkenlinea") as HTMLInputElement;

  if (checkm.checked)
  {
    checkl.checked=false;
  }
  if (checkl.checked)
  {
    checkm.checked=false;
  }
}

  async crearpdf() {
    var aux, aux1;
    aux = this.d.idsedet + "Guia-Tufesa";
    aux1 = this.d.idsedet;
    await this.GuiaswebServiceService.facturapdf("-", aux, aux1, "2").then();
  }

  DocumentacionForm() {
    this.documentacionForm = new FormGroup({
      noEmpleado: new FormControl({ value: null, disabled: true }),
      fecha: new FormControl({ value: null, disabled: true }),
      //remintente
      Rnombre: new FormControl({ value: null, disabled: true }),
      RselectPais: new FormControl({ value: null, disabled: true }),
      RselectEstado: new FormControl({ value: null, disabled: true }),
      RselectCiudad: new FormControl({ value: null, disabled: true }),
      RselectColonia: new FormControl({ value: null, disabled: true }),
      Rdireccion: new FormControl({ value: null, disabled: true }),
      Rtelefono: new FormControl({ value: null, disabled: true }),
      Rcp: new FormControl({ value: null, disabled: true }),
      //Destinatario
      Dnombre: new FormControl({ value: null, disabled: true }),
      DselectPais: new FormControl({ value: null, disabled: true }),
      DselectEstado: new FormControl({ value: null, disabled: true }),
      DselectCiudad: new FormControl({ value: null, disabled: true }),
      DselectColonia: new FormControl({ value: null, disabled: true }),
      Ddireccion: new FormControl({ value: null, disabled: true }),
      Dtelefono: new FormControl({ value: null, disabled: true }),
      Dcp: new FormControl({ value: null, disabled: true }),

      cobrar: new FormControl({ value: null, disabled: true }),
      recoleccion: new FormControl({ value: null, disabled: true }),
      acuse: new FormControl({ value: null, disabled: true }),
      ValorDeclarado: new FormControl({ value: null, disabled: true }),
      comentario: new FormControl({ value: null, disabled: true }),
      valordec: new FormControl({ value: null, disabled: true }),
    });
  }
  get doc() {
    return this.documentacionForm.controls;
  }

  llenarDocumentacion() {
    this.documentacionForm.controls.noEmpleado.setValue(this.guia.idcliente);
    this.documentacionForm.controls.fecha.setValue(this.guia.fecha);
    this.documentacionForm.controls.Rnombre.setValue(
      this.guia.remitente.nombre
    );
    this.documentacionForm.controls.RselectPais.setValue(
      this.guia.remitente.pais
    );
    this.documentacionForm.controls.RselectEstado.setValue(
      this.guia.remitente.estado
    );
    this.documentacionForm.controls.RselectCiudad.setValue(
      this.guia.remitente.ciudad
    );
    this.documentacionForm.controls.RselectColonia.setValue(
      this.guia.remitente.colonia
    );
    this.documentacionForm.controls.Rdireccion.setValue(
      this.guia.remitente.direccion
    );
    this.documentacionForm.controls.Rtelefono.setValue(this.guia.remitente.tel);
    this.documentacionForm.controls.Rcp.setValue(this.guia.remitente.cp);

    this.documentacionForm.controls.Dnombre.setValue(
      this.guia.destinatario.nombreD
    );
    this.documentacionForm.controls.DselectPais.setValue(
      this.guia.destinatario.paisD
    );
    this.documentacionForm.controls.DselectEstado.setValue(
      this.guia.destinatario.estadoD
    );
    this.documentacionForm.controls.DselectCiudad.setValue(
      this.guia.destinatario.ciudadD
    );
    this.documentacionForm.controls.DselectColonia.setValue(
      this.guia.destinatario.coloniaD
    );
    this.documentacionForm.controls.Ddireccion.setValue(
      this.guia.destinatario.direccionD
    );
    this.documentacionForm.controls.Dtelefono.setValue(
      this.guia.destinatario.telD
    );
    this.documentacionForm.controls.Dcp.setValue(this.guia.destinatario.cpD);

    this.imptotal=this.guia.imptotal;

    var tiposaux = [];
    var cantidadaux = [];
    var pesoaux = [];
    var largoaux = [];
    var anchoaux = [];
    var altoaux = [];

    tiposaux = this.guia.doctipo.tipo.split(",");
    cantidadaux = this.guia.doctipo.cantidad.split(",");
    pesoaux = this.guia.doctipo.peso.split(",");
    largoaux = this.guia.doctipo.largo.split(",");
    anchoaux = this.guia.doctipo.ancho.split(",");
    altoaux = this.guia.doctipo.alto.split(",");

    for (let i = 0; i < tiposaux.length; i++) {
      const t = new tipo();
      t.tipo = tiposaux[i];
      t.cantidad = cantidadaux[i];
      t.peso = pesoaux[i] + " kg.";
      t.largo = largoaux[i] + " cm.";
      t.ancho = anchoaux[i] + " cm.";
      t.alto = altoaux[i] + " cm.";

      this.arrtipos.push(t);
    }

    if (this.guia.detalles.cobrar == 1) {
      this.documentacionForm.controls.cobrar.setValue(true);
    } else {
      this.documentacionForm.controls.cobrar.setValue(false);
    }
    if (this.guia.detalles.recoleccion == 1) {
      this.documentacionForm.controls.recoleccion.setValue(true);
    } else {
      this.documentacionForm.controls.recoleccion.setValue(false);
    }
    if (this.guia.detalles.acuse == 1) {
      this.documentacionForm.controls.acuse.setValue(true);
    } else {
      this.documentacionForm.controls.acuse.setValue(false);
    }
    this.documentacionForm.controls.ValorDeclarado.setValue(
      this.guia.detalles.valordec
    );
    this.documentacionForm.controls.comentario.setValue(
      this.guia.detalles.comentario
    );

    if (this.guia.destinatario.coloniaD == "OCURRE") {
      this.documentacionForm.controls.valordec.setValue("Ocurre");
    } else {
      this.documentacionForm.controls.valordec.setValue("Servicio a Domicilio");
    }
    this.arrSolGuias = this.guia.lstsolc;
  }


  validarCupon()
  {
    var txtcupon = document.getElementById("txtcupon") as HTMLInputElement;
    this.txtcuponmayusculas = txtcupon.value.toUpperCase();
    this.validaCupon(this.guia.idsesion,this.txtcuponmayusculas,this.guia.imptotal);
  }

    validaCupon(idsesion: number,cupon: string,importe: string): number {
      var imp: number=0;
      this.GuiaswebServiceService.ValidarCupon(
        idsesion,
        cupon,
        sessionStorage.getItem("token"),
        importe
      )
        .then(async (d) => {
          this.dato = d;
          this.precioinicialtachado = this.dato.numGuias;

          if (this.dato.descripcion2 == "Ok") {
              this.imptotal = this.dato.costo;
              this.cuponValidacion = 'valido';
              this.mostrarLabelDescuento = true;
          } else if(this.dato.descripcion2 == "Error" && this.dato.descripcion1 == 'Debe indicar un código'){
                    this.mensajecuponvacio = this.dato.descripcion1;
                    this.cuponValidacion = 'novalido';
                    this.mostrarLabelDescuento = false;
                    this.imptotal = this.guia.imptotal;

          }
          else
          {
            // this.mensajeService.subject$.next(
            //   new MensajePopup(this.dato.descripcion1, false, false)
            // );
            this.cuponValidacion = 'novalido';
            this.mensajecuponvacio = 'Hubo un error en el cupón';
            this.mostrarLabelDescuento = false;
            this.imptotal = this.guia.imptotal;
          }
        })
        .catch((error: HttpErrorResponse) => {
          if (error.error.ExceptionMessage == "invalid_token") {
            this.mensajeService.subject$.next(
              new MensajePopup("Su sesión ha expirado", false, false)
            );
            this.router.navigate(["/herramientasDigitales/login"]);
            // this.router.navigate(['/login']);
          } else {
            this.mensajeService.subject$.next(
              new MensajePopup(
                "ocurrio un error inesperado al iniciar sesión en tu cuenta",
                false,
                false
              )
            );
          }
        });
    return imp;
    }

obtenerprecio(origen: string, destino: string, peso: number, tipocobro: string,entrega: string,descripcion: string,clasificacion: string): number {
  var imp: number=0;
  this.GuiaswebServiceService.obtenerImporteGuia(
    this.token,
    origen,
    destino,
    peso,
    tipocobro,
    entrega,
    descripcion,
    clasificacion
  )
    .then(async (d) => {
      this.dato = d;
      if (this.dato.descripcion2 = "Ok") {
        imp= this.dato.costo;
      }
    })
    .catch((error: HttpErrorResponse) => {
      if (error.error.ExceptionMessage == "invalid_token") {
        this.mensajeService.subject$.next(
          new MensajePopup("Su sesión ha expirado", false, false)
        );
        this.router.navigate(["/herramientasDigitales/login"]);
        // this.router.navigate(['/login']);
      } else {
        this.mensajeService.subject$.next(
          new MensajePopup(
            "ocurrio un error inesperado al iniciar sesión en tu cuenta",
            false,
            false
          )
        );
      }
    });
return imp;
}

  regresarMenu() {
    this.GuiaswebServiceService.setplantilla(5);
  }
}
