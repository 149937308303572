export class destinatario {
    nombre: string = "";
    compania: string = "";
    direccion: string = "";
    colonia: number = 0;
    ciudad: number = 0;
    estado: number = 0;
    NombreColonia: string = "";
    Nombreciudad: string = "";
    NombreEstado: string = "";
    tel: string = "";
    cp: number = 0;

    // nombre: string = "prueba";
    // compania: string = "ew";
    // direccion: string = "we";
    // colonia: number = 49533;
    // ciudad: number = 941;
    // estado: number = 14;
    // pais: number = 1;
    // NombreColonia: string = "2";
    // Nombreciudad: string = "2";
    // NombreEstado: string = "3";
    // NombrePais: string = "23";
    // tel: string = "6442355323";
    // cp: number = 44890;
}





