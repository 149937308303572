<div class="container_full">
  <div class="container-secciones">
    <div class="container-full-sections">
      <div class="form-info">
        <div>
          <label for="plantillas">Plantillas</label>
          <div class="select-class">
             <select
              name="plantillas"
              id="plantillas"
              [(ngModel)]="selectedPlantilla"
              (change)="seleccionarPlantilla()"
              >
              <option [ngValue]="null" disabled selected>Selecciona una plantilla</option>
              <option *ngFor="let plantilla of plantillas" [ngValue]="plantilla">
              {{ plantilla.formato }}
              </option>
            </select>
          </div>

        </div>

        <!-- <h3>Encabezados:</h3> -->

        <div *ngFor="let encabezado of encabezados">
          <ng-container>
            <br />
            <ul>
              <li>
                <!-- <strong>ID Encabezado:</strong> {{ encabezado.idencabezado }} <br /> -->
                {{ encabezado.tituloencabezado }}
                <br />
                <!-- <strong>Tipo de Input:</strong> {{ encabezado.tipoinput }} <br /> -->
                <!-- Mostrar otros atributos según sea necesario -->
              </li>
            </ul>

            <div *ngIf="encabezado.tipoinput === 'Select'">
              <div class="select-class">
                <select
                  name="select"
                  id="{{ encabezado.idencabezado }}"
                  [(ngModel)]="selectedOption"
                >
                  <option value="" disabled>Seleccione</option>
                  <option *ngFor="let opcionesSelect of encabezado.catalogorsp" [ngValue]="opcionesSelect.respuesta">
                    {{ opcionesSelect.respuesta }}
                  </option>
                </select>
               </div>
             </div>


            <!-- <input
              *ngIf="encabezado.tipoinput === 'firma'"
              name="firma"
              type="text"
              class="cls_input"
              id="{{ encabezado.idencabezado }}"
              [(ngModel)]="encabezado.catalogorsp"
            /> -->

             <div class="container-signature" *ngIf="encabezado.tipoinput === 'Firma'">
              <div class="btnSignature-class" *ngIf="!encabezado.catalogorsp || (encabezado.catalogorsp && encabezado.catalogorsp.length === 0)" (click)="openModalSignature(encabezado.idencabezado)">
                <label style="margin-bottom: 0px;"> Capturar firma </label>
                <!-- <img *ngIf="encabezado.catalogorsp && encabezado.catalogorsp.length > 0" [src]="encabezado.catalogorsp" alt="" style="width: 140px; margin-left: 10px;"> -->
                <img src="assets/iconos/signature.png" alt="" style="width: 24px; margin-left: 10px;">
              </div>
                 <div class="btnSignature-class" style="padding: 8px 0px;" *ngIf="encabezado.catalogorsp && encabezado.catalogorsp.length > 0" (click)="openModalSignature(encabezado.idencabezado)">
                    <img  [src]="encabezado.catalogorsp" alt="" style="width: 170px;">
                 </div>
             </div>

            <textarea
              *ngIf="encabezado.tipoinput === 'Textlong'"
              name="textlong"
              style="height: 80px; resize: none; padding: 5px 10px"
              cols="30"
              rows="10"
              class="cls_input"
              id="{{ encabezado.idencabezado }}"
              [(ngModel)]="encabezado.catalogorsp"
            >
            </textarea>

            <input
              *ngIf="encabezado.tipoinput === 'Text'"
              name="text"
              type="text"
              class="cls_input"
              id="{{ encabezado.idencabezado }}"
              [(ngModel)]="encabezado.catalogorsp"
            />
            <input
              *ngIf="encabezado.tipoinput === 'Phone'"
              name="phone"
              type="text"
              class="cls_input"
              placeholder="ej.(55) 1234-5678"
              maxlength="13"
              (input)="validarTelefono($event)"
              id="{{ encabezado.idencabezado }}"
              [(ngModel)]="encabezado.catalogorsp"
            />
            <input
              *ngIf="encabezado.tipoinput === 'Numeric'"
              name="numeric"
              class="cls_input"
              type="text"
              (input)="soloNumeros($event)"
              id="{{ encabezado.idencabezado }}"
              [(ngModel)]="encabezado.catalogorsp"

            />
            <input
              *ngIf="encabezado.tipoinput === 'Input'"
              name="text"
              type="text"
              class="cls_input"
              id="{{ encabezado.idencabezado }}"
              [(ngModel)]="encabezado.catalogorsp"
            />
            <input
              *ngIf="encabezado.tipoinput === 'Email'"
              name="email"
              class="cls_input"
              type="email"
              id="{{ encabezado.idencabezado }}"
              [(ngModel)]="encabezado.catalogorsp"
            />
            <input
              *ngIf="encabezado.tipoinput === 'Date'"
              name="date"
              type="date"
              class="cls_input"
              id="{{ encabezado.idencabezado }}"
              [(ngModel)]="encabezado.catalogorsp"
            />
            <input
              *ngIf="encabezado.tipoinput === 'Currency'"
              type="text"
              name="currency"
              class="cls_input"
              inputmode="numeric"
              step="0.01"
              placeholder="0.00"
              (input)="soloNumeros($event)"
              id="{{ encabezado.idencabezado }}"
              [(ngModel)]="encabezado.catalogorsp"
            />
          </ng-container>
        </div>
        <!-- <div *ngIf="encabezados.length === 0">
          <p>No hay encabezados disponibles para esta plantilla.</p>
        </div> -->
        <br />
        <div>
          <button *ngIf="encabezados.length > 0" class="btnModalFin-class" type="submit" (click)="crearFormatoDigital()">Crear</button>
          <!-- <button (click)="validarInput(encabezados)">Validar</button> -->
        </div>
      </div>
    </div>
  </div>
</div>

<!-- MODAL FIRMA -->
 <div class="modal-container" [ngClass]="{'show': showModalSignature}" (click)="closeModalSignature()">
  <div class="modal-content" (click)="$event.stopPropagation()">
    <span class="close-button" (click)="closeModalSignature()">&times;</span>
    <p>Capture su firma.</p>
    <app-signature></app-signature>
  </div>
</div>
