import { Component, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-servicios',
  templateUrl: './servicios.component.html',
  styleUrls: ['./servicios.component.css']
})
export class ServiciosComponent implements OnInit {

  animation = false;
  animation1 = false;

  constructor(private renderer: Renderer2) { }

  ngOnInit() {
  }

  public onIntersection({ target, visible}: { target: Element; visible: boolean; id: number}): void {
    if(visible){
    target.classList.add('scale-in-center');
    }
  }

  public onIntersectionText({ target, visible}: { target: Element; visible: boolean; id: number}): void {
    if(visible){
      target.classList.add('fade-in-bottom');
    }
}
}
