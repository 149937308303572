<app-gw-menusuperior></app-gw-menusuperior>

<div class="gw-titulogeneral">
    <h1 class="tituloEstructura">Facturas</h1>
    <P>Historial de facturas</P>
    <div class="gw-container">
        <form>
            <div class="doc-div">
                <div class="gw-flex">
                    <div class="gw-flex-l">
                        <label class="lbl-text">Fecha inicio</label>
                        <input type="doc-lbl" class="inp-text " placeholder="&nbsp;" bsDatepicker
                            [bsConfig]="datePickerConfig" [bsValue]="fch1"
                            (bsValueChange)="onValueChangefchini($event)" />
                    </div>
                    <div class="gw-flex-r">
                        <label class="lbl-text">Fecha Final</label>
                        <input type="doc-lbl" class="inp-text  " placeholder="&nbsp;" bsDatepicker
                            [bsConfig]="datePickerConfig" [bsValue]="fch2" [minDate]="minDate"
                            (bsValueChange)="onValueChangefchfin($event)" />
                    </div>
                </div>
                <div class="form">

                    <button name="Aceptar" class="gw-btn-continuar" type="submit" (click)="obtener()">Obtener</button>
                </div>

            </div>
        </form>
        <!-- menu -->
        <ng-container *ngIf="factutas">
            <ng-container *ngIf="factutas[0].idmsg == -578; else elseHist">
                <p class="p-validacion">No se encontraron facturas</p>


            </ng-container>
            <ng-template #elseHist>
                <div class="factura-div" *ngFor="let valor = index let i of factutas">
                    <div class="hist-flex">
                        <!-- fecha -->
                        <div class="hist-w-fecha div-estado">

                            <span class="hist-fecha-text">{{i.fechafac.slice(3,6)}}</span>
                            <span class="hist-fecha-number">{{i.fechafac.slice(0,2)}}</span>

                        </div>
                        <!-- info -->
                        <div class="hist-w div-datos ">

                            <span class="is-Type1">Folio</span>
                            <span class="is-Type">{{i.folio}}</span>
                            <span class="is-Type1">total</span>
                            <span class="is-Type">{{i.total}}</span>
                        </div>
                        <!-- estado -->
                        <div class="hist-w hist-main div-estado">

                            <span class="is-Type1">Estado</span>
                            <span class="is-Type2">{{i.status}}</span>
                            <button class="gw-btn-descargar"
                                (click)="descargarFactura(i.seriefol, i.idref)">Descargar</button>

                        </div>
                    </div>
                </div>
            </ng-template>
        </ng-container>

    </div>
</div>
