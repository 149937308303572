<div class="container_herramienta">
  <!-- <img src="assets/img/web/cotiza-2.webp" class="img_toAnimate" id="img_toChange"> -->
  <!-- <img src="assets/iconos/servicios/paqueteria.webp" class="img_toAnimate2" id="showCaja"> -->
  <!-- <img src="assets/iconos/servicios/sobre.webp" class="img_toAnimate3" id="showSobre"> -->
  <div class="alinearIzq">
    <div [formGroup]="cotizaForm" class="cotizacionContainer">
      <h1>Cotizar</h1>
      <p>Use esta sencilla herramienta para obtener una tarifa estimada.
      </p>

      <div class="form card-tipos">
        <div class="card-tipos-det">
          <input id="rbOperador" type="radio" name="rbOpcion" value="1" checked (click)="cotizacionPor(1)">
          <label class="ctz-tituloweb" for="rbOperador" id="txt_cotRapida">Cotización rápida</label>
          <label class="ctz-titulomobile" for="rbOperador">Rápida</label>
        </div>
        <div class="card-tipos-det">
          <input id="rbFecha" type="radio" name="rbOpcion" value="2" (click)="cotizacionPor(2)">
          <label class="ctz-tituloweb" for="rbFecha" id="txt_cotAvanzada">Cotización avanzada</label>
          <label class="ctz-titulomobile" for="rbOperador">Avanzada</label>
        </div>
      </div>

      <div *ngIf="bancotizacion; else templateAvanzado">
        <div class="inputContainer">
          <div>
            <label>Origen</label>
            <div class="logo-gps">
              <ng-select
                [ngClass]="{'error' : (cotizaForm.get('origen').invalid || cotizaForm.get('origen').value === '0') && submitted }"
                [items]="ciudades" bindLabel="sucursal" bindValue="cp" appendTo="body" [multiple]="false"
                [clearable]="false" [editableSearchTerm]="false" [virtualScroll]="true" notFoundText="No se encontro"
                formControlName="origen" (change)="ChangedOrigen($event)">
              </ng-select>

              <button class='buttone' (click)="abrirDetalleGps('O')">
                Ubicar
              </button>

            </div>
          </div>
          <div>
            <label>Destino</label>
            <div class="logo-gps">
              <ng-select
                [ngClass]="{'error' : (cotizaForm.get('origen').invalid || cotizaForm.get('origen').value === '0') && submitted }"
                [items]="ciudades" bindLabel="sucursal" bindValue="cp" appendTo="body" [multiple]="false"
                [clearable]="false" [editableSearchTerm]="false" [virtualScroll]="true" notFoundText="No se encontro"
                formControlName="destino" (change)="ChangedDestino($event)">
              </ng-select>
              <button class='buttone' (click)="abrirDetalleGps('D')">
                Ubicar
              </button>
            </div>
          </div>
          <div *ngIf="mostrarInputCorreo">
            <label>Correo Electronico</label>
            <div class="">
              <input formControlName="correoFormCotizar" type="text" placeholder="Email"
              [ngClass]="{'error': cotizaForm.get('correoFormCotizar').invalid && (cotizaForm.get('correoFormCotizar').touched || submitted)}">
            </div>
          </div>

          <div>
            <label>Peso(kg)</label>
            <div class="logo-gps">
              <input type="text" placeholder="Peso(Kg)" formControlName="peso" numeric numericType="decimal">

              <button class='buttonCotizar' (click)="getCotizacion('bsc')">
                Cotizar
              </button>

            </div>
          </div>

        </div>
        <div *ngIf="cotizacion">

          <ng-container *ngFor="let valor = index let i of cotizacion.costos">

            <div class="card-general" *ngIf="i.Tipo == 'PQ'">
              <div class="card-tipos">
                <div class="card-div1">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                    id="Capa_1" x="0px" y="0px" viewBox="0 0 473.8 473.8" style="enable-background:new 0 0 473.8 473.8;"
                    xml:space="preserve" width="30px" height="30px">
                    <g>
                      <path
                        d="M454.8,111.7c0-1.8-0.4-3.6-1.2-5.3c-1.6-3.4-4.7-5.7-8.1-6.4L241.8,1.2c-3.3-1.6-7.2-1.6-10.5,0L25.6,100.9   c-4,1.9-6.6,5.9-6.8,10.4v0.1c0,0.1,0,0.2,0,0.4V362c0,4.6,2.6,8.8,6.8,10.8l205.7,99.7c0.1,0,0.1,0,0.2,0.1   c0.3,0.1,0.6,0.2,0.9,0.4c0.1,0,0.2,0.1,0.4,0.1c0.3,0.1,0.6,0.2,0.9,0.3c0.1,0,0.2,0.1,0.3,0.1c0.3,0.1,0.7,0.1,1,0.2   c0.1,0,0.2,0,0.3,0c0.4,0,0.9,0.1,1.3,0.1c0.4,0,0.9,0,1.3-0.1c0.1,0,0.2,0,0.3,0c0.3,0,0.7-0.1,1-0.2c0.1,0,0.2-0.1,0.3-0.1   c0.3-0.1,0.6-0.2,0.9-0.3c0.1,0,0.2-0.1,0.4-0.1c0.3-0.1,0.6-0.2,0.9-0.4c0.1,0,0.1,0,0.2-0.1l206.3-100c4.1-2,6.8-6.2,6.8-10.8   V112C454.8,111.9,454.8,111.8,454.8,111.7z M236.5,25.3l178.4,86.5l-65.7,31.9L170.8,57.2L236.5,25.3z M236.5,198.3L58.1,111.8   l85.2-41.3L321.7,157L236.5,198.3z M42.8,131.1l181.7,88.1v223.3L42.8,354.4V131.1z M248.5,442.5V219.2l85.3-41.4v58.4   c0,6.6,5.4,12,12,12s12-5.4,12-12v-70.1l73-35.4V354L248.5,442.5z"
                        data-original="#000000" class="active-path" data-old_color="#000000" fill="#F05A28" />
                    </g>
                  </svg>
                  <h5>Paquete</h5>
                  <p class="card-detalle" (click)="abrirDetalle(valor, i)">Detalles</p>
                </div>
                <div class="card-div2">
                  <h3><b> $ {{i.CostoFinal}}</b></h3>

                  <p *ngIf="i.CostoFinal < i.CostoBase " class="card-precioant">$ {{i.CostoBase}}</p>
                  <p class="card-leyenda">Precio sujeto a cambios según peso y medidas.</p>
                </div>

              </div>
              <div class="card-detalleDiv" style="display: none;" id={{valor}}>
                <hr>
                <p class="titulodetalle">Detalle de servicio</p>
                <ul>
                  <ng-container *ngFor="let valor1 = index let j of i.Caracteristicas">
                    <li type="disc"> <span>{{j.descripcion}}</span></li>
                  </ng-container>
                </ul>
              </div>
            </div>
            <div class="card-general" *ngIf="i.Tipo == 'SB'">
              <div class="card-tipos">
                <div class="card-div1">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                    id="Capa_1" x="0px" y="0px" viewBox="0 0 483.3 483.3" style="enable-background:new 0 0 483.3 483.3;"
                    xml:space="preserve" width="30px" height="30px">
                    <g>
                      <path
                        d="M424.3,57.75H59.1c-32.6,0-59.1,26.5-59.1,59.1v249.6c0,32.6,26.5,59.1,59.1,59.1h365.1c32.6,0,59.1-26.5,59.1-59.1    v-249.5C483.4,84.35,456.9,57.75,424.3,57.75z M456.4,366.45c0,17.7-14.4,32.1-32.1,32.1H59.1c-17.7,0-32.1-14.4-32.1-32.1v-249.5    c0-17.7,14.4-32.1,32.1-32.1h365.1c17.7,0,32.1,14.4,32.1,32.1v249.5H456.4z"
                        data-original="#000000" class="active-path" data-old_color="#000000" fill="#F05A28" />
                      <path
                        d="M304.8,238.55l118.2-106c5.5-5,6-13.5,1-19.1c-5-5.5-13.5-6-19.1-1l-163,146.3l-31.8-28.4c-0.1-0.1-0.2-0.2-0.2-0.3    c-0.7-0.7-1.4-1.3-2.2-1.9L78.3,112.35c-5.6-5-14.1-4.5-19.1,1.1c-5,5.6-4.5,14.1,1.1,19.1l119.6,106.9L60.8,350.95    c-5.4,5.1-5.7,13.6-0.6,19.1c2.7,2.8,6.3,4.3,9.9,4.3c3.3,0,6.6-1.2,9.2-3.6l120.9-113.1l32.8,29.3c2.6,2.3,5.8,3.4,9,3.4    c3.2,0,6.5-1.2,9-3.5l33.7-30.2l120.2,114.2c2.6,2.5,6,3.7,9.3,3.7c3.6,0,7.1-1.4,9.8-4.2c5.1-5.4,4.9-14-0.5-19.1L304.8,238.55z"
                        data-original="#000000" class="active-path" data-old_color="#000000" fill="#F05A28" />
                    </g>
                  </svg>
                  <h5>Sobre</h5>
                  <p class="card-detalle" (click)="abrirDetalle(valor, i)">Detalles</p>
                </div>
                <div class="card-div2">
                  <h3><b> $ {{i.CostoFinal}}</b></h3>
                  <p *ngIf="i.CostoFinal < i.CostoBase" class="card-precioant">$ {{i.CostoBase}}</p>
                  <p class="card-leyenda">Precio sujeto a cambios según peso y medidas.</p>
                </div>

              </div>
              <div class="card-detalleDiv" style="display: none;" id={{valor}}>
                <hr>
                <p class="titulodetalle">Detalle de servicio</p>
                <ul>
                  <ng-container *ngFor="let valor1 = index let j of i.Caracteristicas">

                    <li type="disc"> <span>{{j.descripcion}}</span> </li>
                  </ng-container>
                </ul>
              </div>
            </div>


          </ng-container>

          <div class="cotiza-container" (click)="mandarRecolecion()">
            <a target=_self><button class="btnContinuar btnrecoleccion">Solicitar
                recolección a domicilio</button></a>
          </div>

          <!-- link más información -->
          <div class="solicita_info" (click)="openCloseInfo()">
            <a target=_self>
              <button class="solinfo">Solicitar más información</button>
            </a>
          </div>



        </div>
      </div>
      <ng-template #templateAvanzado>
        <div class="inputContainer">
          <div>
            <label>Origen</label>
            <div class="logo-gps">
              <ng-select class="selt1"
                [ngClass]="{'error' : (cotizaForm.get('origen').invalid || cotizaForm.get('origen').value === '0') && submitted }"
                [items]="ciudades" bindLabel="sucursal" bindValue="cp" appendTo="body" [multiple]="false"
                [clearable]="false" [editableSearchTerm]="false" [virtualScroll]="true" notFoundText="No se encontro"
                formControlName="origen" (change)="ChangedOrigen($event)">
              </ng-select>
              <button class='buttone' (click)="abrirDetalleGps('O')">
                Ubicar
              </button>

            </div>
          </div>
          <div>
            <label>Destino</label>
            <div class="logo-gps">
              <ng-select
                [ngClass]="{'error' : (cotizaForm.get('origen').invalid || cotizaForm.get('origen').value === '0') && submitted }"
                [items]="ciudades" bindLabel="sucursal" bindValue="cp" appendTo="body" [multiple]="false"
                [clearable]="false" [editableSearchTerm]="false" [virtualScroll]="true" notFoundText="No se encontro"
                formControlName="destino" (change)="ChangedDestino($event)">
              </ng-select>
              <button class='buttone' (click)="abrirDetalleGps('D')">
                Ubicar
              </button>
            </div>
          </div>

          <div *ngIf="mostrarInputCorreo">
            <label>Correo Electronico</label>
            <div class="">
              <input formControlName="correoFormCotizar" type="text" placeholder="Email"
              [ngClass]="{'error': cotizaForm.get('correoFormCotizar').invalid && (cotizaForm.get('correoFormCotizar').touched || submitted)}">
            </div>
          </div>

          <div>
            <label>Tipo</label>
            <div class="">
              <ng-select class="selt1"
                [ngClass]="{'error' : (cotizaForm.get('tipo').invalid || cotizaForm.get('tipo').value === '0') && submitted }"
                [items]="listatipo" bindLabel="sucursal" bindValue="cp" appendTo="body" [multiple]="false"
                [clearable]="false" [editableSearchTerm]="false" [virtualScroll]="true" notFoundText="No se encontro"
                formControlName="tipo" (change)="onChange($event)">
              </ng-select>
            </div>
          </div>

          <!-- Agregar el detalle de los envios-->
          <div *ngIf="banTipoPaq">
            <hr>
            <h3>Paquete</h3>

            <div [formGroup]="cotizaAvanzadoForm" class="inputContainer">
              <div>
                <label>Cantidad</label>
                <input id="formCantidad" type="text" placeholder="" formControlName="cantidad" numeric
                  numericType="number"
                  [ngClass]="{validacion: (Avanzado.cantidad.invalid || Avanzado.cantidad.hasError('IsEmpty')) && submittedAvz}">
              </div>
              <div *ngIf="tipoPaq === 'Caja'">
                <label>Largo (Cm)</label>
                <input type="text" placeholder="" formControlName="largo" numeric numericType="decimal"
                  [ngClass]="{validacion: (Avanzado.largo.invalid || Avanzado.largo.hasError('IsEmpty')) && submittedAvz}">
              </div>
              <div *ngIf="tipoPaq === 'Caja'">
                <label>Alto (Cm)</label>
                <input type="text" placeholder="" formControlName="alto" numeric numericType="decimal"
                  [ngClass]="{validacion: (Avanzado.alto.invalid || Avanzado.alto.hasError('IsEmpty')) && submittedAvz}">
              </div>
              <div *ngIf="tipoPaq === 'Caja'">
                <label>Ancho (Cm)</label>
                <input type="text" placeholder="" formControlName="ancho" numeric numericType="decimal"
                  [ngClass]="{validacion: (Avanzado.ancho.invalid || Avanzado.ancho.hasError('IsEmpty')) && submittedAvz}">
              </div>
              <div>
                <label>Peso unitario (Kg)</label>
                <input type="text" placeholder="" formControlName="peso" numeric numericType="decimal"
                  [ngClass]="{validacion: (Avanzado.peso.invalid || Avanzado.peso.hasError('IsEmpty')) && submittedAvz}">
              </div>
            </div>
            <button class="btnBack" (click)="addPackages()" id="cotizacion-atras">
              Agregar
            </button>
            <h3 *ngIf="arrPack?.length > 0">Paquete(s) info</h3>

            <div class="wrapper" *ngIf="arrPack?.length >0 ">

              <div class="table">

                <div class="row header">
                  <div class="cell titilo ">
                    Paquete(s)
                  </div>
                  <div class="cell titilo">
                    Dimensiones
                  </div>
                  <div class="cell titilo">

                  </div>
                </div>

                <div class="row" *ngFor="let paq of arrPack">
                  <div class="cell" data-title="Paquete(s)">
                    {{paq.cantidad}} {{tipoPaq}}(s)
                  </div>
                  <div class="cell" data-title="Dimensiones" *ngIf="tipoPaq==='Caja'">
                    {{paq.largo}} x {{paq.alto}} x
                    {{paq.ancho}} {{paq.peso}}Kg.
                  </div>
                  <div class="cell" data-title="Dimensiones" *ngIf="tipoPaq==='Sobre'">
                    {{paq.peso}}Kg.
                  </div>
                  <div class="cell" data-title="Quitar">
                    <button class="btn-x" (click)="quitarTipo(paq)">x</button>
                  </div>


                </div>

              </div>
            </div>



            <!-- servicios -->
            <h3>Servicios</h3>

            <div [formGroup]="serviciosForm" class="form">
              <div>
                <input type="checkbox" id="porcob" formControlName="porcob">
                <label for="porcob"
                  tooltip=" Al momento en que su destinatario reciba su paquetería efectuará el pago del mismo, En este servicio el cobro se transfiere a su consignatario."
                  placement="right">Serv. Por cobrar </label>
              </div>
              <div>
                <input type="checkbox" id="seguro" formControlName="seguro">
                <label for="seguro"
                  tooltip="Para brindar una protección adicional a su mercancía solo debe cubrir el 3% del valor declarado. Este servicio cubre por montos de hasta $10,000."
                  placement="right" (click)="showInput('monto')">Seguro
                  especial</label>
              </div>
              <div class="inputContainer" id="monto">
                <label>Monto</label>
                <input type="text" placeholder="" formControlName="monto" numeric numericType="number">
              </div>
              <div>
                <input type="checkbox" id="domic" formControlName="domic">
                <label for="domic"
                  tooltip="En las manos de su destinatario entregamos su envío en la dirección que el cliente indique (dentro de la cobertura de entrega Tufesa)."
                  placement="right" (click)="showInput('domic')">Entrega a domicilio</label>
              </div>
              <div class="inputContainer" id="domicilio">
                <label>Dirección</label>
                <input type="text" placeholder="" formControlName="dirDomic">
              </div>
              <div>
                <input type="checkbox" id="recol" formControlName="recol">
                <label for="recol"
                  tooltip="Pasamos por su paquetería en la dirreción que usted inidque si no puede venir a nuestras oficinas. (dentro de la cobertura de recoleeción Tufesa)."
                  placement="right" (click)="showInput('recoleccion')">Recolección</label>
              </div>
              <div class="inputContainer" id="recoleccion">
                <label>Dirección</label>
                <input type="text" placeholder="" formControlName="dirReco">
              </div>
              <div>
                <input type="checkbox" id="emb" formControlName="embalaje">
                <label for="emb"
                  tooltip="Contamos con cajas y sobres especiales para su mercancía en caso de no tener a la mano."
                  placement="right" (click)="showInput('embalaje')">Embalaje</label>
              </div>
              <div class="inputContainer" id="embalaje">
                <label>Tipo embalaje</label>
                <div class="select">
                  <select id="select" formControlName="SelEmbalaje">
                    <option value="" id="opt" disabled selected>Seleccione</option>
                    <option value="sobre">Sobre</option>
                    <option value="caja">Caja</option>
                    <option value="emplaye">Emplaye</option>
                  </select>
                </div>
              </div>
              <div>
                <input type="checkbox" id="recib" formControlName="recibido">
                <label for="recib" tooltip="El remitente puede tener la información de quien recibe su paquete."
                  placement="right">Acuse de recibido</label>
              </div>
            </div>
            <button class="btnContinuar" (click)="getCotizacion('avz')">
              Continuar
              <svg _ngcontent-vuf-c10="" class="material-icons" height="24" viewBox="0 0 24 24" width="24"
                xmlns="http://www.w3.org/2000/svg">
                <path _ngcontent-vuf-c10="" d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" fill="#FFFF">
                </path>
                <path _ngcontent-vuf-c10="" d="M0 0h24v24H0z" fill="none"></path>
              </svg>
            </button>
            <i *ngIf="submitted2 && !arrPack?.length">No hay ningun paquete agregado</i>
          </div>

        </div>
      </ng-template>

    </div>


    <ng-template #modal>
      <div class="modal-header">
        <h1 class="modal-title pull-left">Cotizar</h1>


        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="modal-div">
          <div class="modal-div2">
            <p class="modal-origen">Origen</p>
            <p class="modal-p">{{detorigen}} </p>
          </div>
          <div class="modal-div2">

            <p *ngIf="ban" class="modal-origen">Destino</p>
            <p *ngIf="ban" class="modal-p">{{detdestino}} </p>
          </div>
        </div>
        <div *ngIf="directorio">
          <agm-map [latitude]="directorio[0].lat" [longitude]="directorio[0].lng">
            <div *ngFor="let i of directorio">
              <agm-marker [latitude]="i.lat" [longitude]="i.lng"></agm-marker>
            </div>
          </agm-map>
          <div *ngFor="let i of directorio">
            <label class="modal-titulo">{{i.name}}</label>
            <div class="modal-det">
              <p class="modal-subtitulo">Dirección</p>
              <p class="modal-p">{{i.address}}</p>

              <p class="modal-subtitulo">Características</p>
              <p class="modal-p">{{i.feature}}
              </p>

              <div class="modal-div">
                <div class="modal-div2">
                  <p class="modal-subtitulo">Horario de atención</p>
                  <p class="modal-p">{{i.schedule}}</p>
                </div>
                <div class="modal-div2">
                  <p class="modal-subtitulo">Teléfono</p>
                  <p class="modal-p">{{i.phone}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </ng-template>
  </div>
  <div class="container-right">
    <div class="container-items-registro animate-container">
      <div class="animate-text" style="padding-right: 20px;">
        <h3 class="slide-left">¿Quieres conocer nuestras promociones?</h3>
        <h1 class="slide-left">¿REALIZAS ENVÍOS CONSTANTES?</h1>
        <h5 class="slide-left">¡REGÍSTRATE Y OBTÉN TARIFAS ESPECIALES!</h5>
      </div>
      <img class="img-registro-form animate-image" src="assets/img/web/cotiza.webp" alt="">
      <div class="container-inputs-registro">
         <input [(ngModel)]="nombreSeccionRegistro" type="text" placeholder="Nombre" id="nombreinputsecregistro">
         <input [(ngModel)]="apellidoSeccionRegistro" type="text" placeholder="Apellido" id="apellidoinputsecregistro">
         <input [(ngModel)]="correoSeccionRegistro" type="text" placeholder="Correo" maxlength="150">
         <button class="btn-form-registro" (click)="registrarSectionForm(nombreSeccionRegistro,apellidoSeccionRegistro,correoSeccionRegistro)">Registrar ></button>
      </div>
    </div>
   </div>

</div>

<button id="btn-modal" (click)="openModal(modal)" hidden></button>

<!-- formulario más información modal -->
<div id="modal_cotiza" [ngClass]="{'isInactive': isClassActive}">
<div class="formContainer">
  <span (click)="openCloseInfo()" class="closeCotiza">x</span>
  <p>Recibe mas información de tu cotización, solo proporciona los siguientes datos</p>
  <div>
    <input type="text" [(ngModel)]="inputnombre" placeholder="Nombre" maxlength="100">
    <input type="text" [(ngModel)]="inputapellido" placeholder="Apellido" maxlength="100">
    <input type="email" [(ngModel)]="inputcorreo" placeholder="Correo electrónico" maxlength="350">
    <input type="tel" [(ngModel)]="inputtelefono" placeholder="Teléfono" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" maxlength="10">

    <button class="btnsolinfo" (click)="solicitarMasInfo()">Solicitar</button>
  </div>
</div>
</div>
<!-- termina formulario mas info -->
