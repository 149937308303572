<div class="container_herramienta">
    <img src="assets/img/web/mensajeriaPaq.webp" class="img_toAnimate">
    <div class="alinearIzq">
        <h1 class="txt-section">Mensajería y Paquetería</h1>
        <p>Diseñamos soluciones de envíos, resguardos y entregas 
            asegurando el valor que significa para ti en un tiempo de traslado 
            muy corto. Damos certidumbre y confianza al cuidar de tus 
            pertenencias.
            <br>
            <br>
            Queremos hacer de la movilidad de paquetes y mensajería una 
            experiencia segura, puntual y exitosa.
            <br>
            <br>
            Cubriendo desde 0 a 10 kg para mensajería y de 10 a 80 kg en 
            paquetería, ofreciendo entrega y recolección a la hora que 
            necesitas.</p>
    </div>
</div>