
<div class="container_herramienta">
    <img src="assets/iconos/info-util/icon_envEx.webp" class="img_toAnimate">
    <div class="alinearIzq">
        <h1 class="txt-section">Envíos al extranjero</h1>
        <p>Artículos prohibidos Exportaciones:</p>
        <ol>
            <li>Munición</li>
            <li>A un código postal militar</li>
            <li>Artículos de valor extraordinario</li>
            <li>Fluidos y tejidos corporales</li>
            <li>Giros postales/dinero en metálico</li>
            <li>Cadáveres</li>
            <li>Diamantes industriales</li>
            <li>Marfil</li>
            <li>Envíos de cartas de crédito</li>
            <li>Animales vivos</li>
            <li>Material pornográfico</li>
            <li>Piedras preciosas</li>
            <li>Sellos</li>
            <li>Restos humanos</li>
            <li>cristales, raíces, líquidos, polvos, productos que tengan contacto con el ser humano, pieles exóticas.</li>
            <li>DIMENSIONES Y PESOS: Paquetes con un peso real de más de 70 kg , o paquetes que, al ser medidos para
                determinar el peso a ser facturado exceden 274 cm de largo, no son aceptados para transporte.</li>
        </ol>
    </div>
  </div>