export class ResumenSolicitudCS {
    idsol: number
    msgnum: number
    msgtxt: string
    nombre: string
    correo: string
    telefono: string
    direccion: string
    colonia: string
    ciudad: string
    estado: string
    referencia: string

    /*constructor() {
        this.msgnum = 1;
        this.msgtxt = "";
    }*/
}