<div [formGroup]="recoleccionForm" novalidate="" class="main fade-in">
  <h1>Recolección a domicilio</h1>
  <h6>Solicita una recolección en donde estes.</h6>
  <div class="inputs">
    <div class="form">
      <label class="lbl-text">Estado</label>
      <div class="select">
        <select id="estado" name="estado" formControlName="estado" (change)="estadoChanged($event.target.value)"
          [ngClass]="{validacion: (a.estado.invalid || a.estado.hasError('isEmpty')) && submittedRec}" required>
          <option value="" disabled selected class="option s">Seleccione</option>
          <option *ngFor="let i of cadestados" [value]="i.idedo">
            {{i.estado}}
          </option>
        </select>
      </div>
    </div>

    <div class="form">
      <label class="lbl-text">Ciudad</label>
      <div class="select">
        <select id="ciudad" name="ciudad" formControlName="ciudad" (change)="ciudadChanged($event.target.value)"
          [ngClass]="{validacion: (a.ciudad.invalid || a.ciudad.hasError('isEmpty')) && submittedRec}" required>
          <option value="" disabled selected class="option s">Seleccione</option>
          <option *ngFor="let i of cadciudades" [value]="i.idcd">
            {{i.ciudad}}
          </option>
        </select>
      </div>
    </div>

    <div class="form">
      <label class="lbl-text">Colonia</label>
      <div class="select">
        <select id="colonia" name="colonia" formControlName="colonia" (change)="coloniaChanged($event.target.value)"
          [ngClass]="{validacion: (a.colonia.invalid || a.colonia.hasError('isEmpty')) && submittedRec}" required>
          <option value="" disabled selected class="option s">Seleccione</option>
          <option *ngFor="let i of cadcolonias" [value]="i.idcol">
            {{i.colonia}}
          </option>
        </select>
      </div>
    </div>

    <div class="formbtn">
      <button class="btnCotizar" id="cotizar" (click)="getRecoleccion()">
        Solicitar
        <svg _ngcontent-vuf-c10="" id="cotizar1" class="material-icons" height="24" viewBox="0 0 24 24" width="24"
          xmlns="http://www.w3.org/2000/svg">
          <path _ngcontent-vuf-c10="" d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" fill="#FFFF" id="cotizar2">
          </path>
          <path _ngcontent-vuf-c10="" d="M0 0h24v24H0z" fill="none" id="cotizar3"></path>
        </svg>
      </button>
    </div>
  </div>
</div>
