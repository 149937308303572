import { AbstractControl } from '@angular/forms';

export class MyValidators {

  static isEmpty(control: AbstractControl) {
    const value = control.value;
    if (value === null || value === ' ' || value.length < 1) {
      return { IsEmpty: true };
    }
    return null;
  }

}
