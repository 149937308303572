<app-gw-menusuperior></app-gw-menusuperior>

<div class="gw-titulogeneral">
    <h1 class="tituloEstructura">Historial</h1>
    <P>Historial de guías</P>
    <ng-container *ngIf="numpantalla == 1; else elseTemplate">
        <div class="gw-container">
            <form>
                <div class="doc-div">
                    <div class="gw-flex">
                        <div class="gw-flex-l">
                            <label class="lbl-text">Fecha inicio</label>
                            <input type="doc-lbl" class="inp-text " placeholder="&nbsp;" bsDatepicker
                                [bsConfig]="datePickerConfig" [bsValue]="fch1"
                                (bsValueChange)="onValueChangefchini($event)" />
                        </div>
                        <div class="gw-flex-r">
                            <label class="lbl-text">Fecha Final</label>
                            <input type="doc-lbl" class="inp-text  " placeholder="&nbsp;" bsDatepicker
                                [bsConfig]="datePickerConfig" [bsValue]="fch2" [minDate]="minDate"
                                (bsValueChange)="onValueChangefchfin($event)" />
                        </div>
                    </div>
                    <div class="form">

                        <button name="Aceptar" class="gw-btn-continuar" type="submit"
                            (click)="obtener()">Obtener</button>
                    </div>

                </div>
            </form>
            <!-- menu -->
            <ng-container *ngIf="historial">
                <ng-container *ngIf="historial[0].nummsj == -568; else elseHist">
                    <p class="p-validacion" >{{historial[0].msj}}</p>


                </ng-container>
                <ng-template #elseHist>
                    <div class="historial-div" *ngFor="let valor = index let i of historial">
                        <div class="hist-header">
                            <span class="span-titulo">No. guía: {{i.idguia}}</span>
                            <button class="button-titulo" (click)="verdetalle(i)">VER DETALLES DE ENVIO >></button>
                        </div>
                        <div class="hist-flex">
                            <!-- fecha -->
                            <div class="hist-w-fecha div-estado">

                                <span class="hist-fecha-text">{{i.fecha.slice(3,6)}}</span>
                                <span class="hist-fecha-number">{{i.fecha.slice(0,2)}}</span>

                            </div>
                            <!-- info -->
                            <div class="hist-w div-datos ">

                                <span class="is-Type1">Origen</span>
                                <span class="is-Type">{{i.remitente.ciudad}}</span>
                                <span class="is-Type1">Destino</span>
                                <span class="is-Type">{{i.destinatario.ciudadD}}</span>
                                <span class="is-Type1">Destinatario</span>
                                <span class="is-Type">{{i.destinatario.nombreD}}</span>

                            </div>
                            <!-- estado -->
                            <div class="hist-w hist-main div-estado">

                                <span class="is-Type1">Estado</span>
                                <span class="is-Type2">{{i.estado}}</span>

                                <!-- <span class="is-Type1">Ver historial</span>
                                <span class="is-Type2">{{i.estado}}</span> -->


                                <button  *ngIf="i.rastreo > 0"  name="Aceptar" class="btn-verhistorial" type="submit"
                                (click)="historialRastreo(i.rastreo)">Ver historial</button>

                            </div>
                        </div>
                    </div>
                </ng-template>

            </ng-container>
        </div>
    </ng-container>
    <ng-template #elseTemplate>
        <app-gw-historial-det></app-gw-historial-det>
    </ng-template>


</div>


<ng-template #modal >
  <div *ngIf="movements" >
    <div class="modal-header">
        <h1 class="modal-title pull-left">Historial de rastreo </h1>


        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
      <h5>Movimientos</h5>
      <ul *ngIf="movements[0].historial" class="timeline">
          <li *ngFor="let mov of movements[0].historial; let lastItem = last">
              <span class="timeline-text">{{mov.MensageCliente}} - {{mov.UbicacionLegible}}</span>
              <br>
              <span class="date">{{mov.fchlegible}}</span>
          </li>
          <ng-container *ngFor="let mov of movements[0].historial; let lastItem = last">
              <div class="paquete-entregado" *ngIf="movements[0].entrega.msgnum > -1 && lastItem">
                  <span class="timeline-text"> Recibió - {{movements[0].entrega.recibe}}</span>
              </div>
          </ng-container>

      </ul>
    </div>
  </div>
</ng-template>



<button id="btn-modal" (click)="openModal(modal)" hidden></button>
