<section>
  <div class="resumenTitle">
    <h1>{{cotizacion.origen}}</h1>
    <svg xmlns="http://www.w3.org/2000/svg" id="Layer" enable-background="new 0 0 64 64" viewBox="0 0 64 64" width="30"
      class="">
      <g>
        <path
          d="m37.379 12.552c-.799-.761-2.066-.731-2.827.069-.762.8-.73 2.066.069 2.828l15.342 14.551h-39.963c-1.104 0-2 .896-2 2s.896 2 2 2h39.899l-15.278 14.552c-.8.762-.831 2.028-.069 2.828.393.412.92.62 1.448.62.496 0 .992-.183 1.379-.552l17.449-16.62c.756-.755 1.172-1.759 1.172-2.828s-.416-2.073-1.207-2.862z"
          data-original="#000000" class="active-path" data-old_color="#000000" fill="#f05a28" />
      </g>
    </svg>
    <h1>{{cotizacion.destino}}</h1>
  </div>

  <div>
    <h3>Paquete</h3>

    <div class="grid" *ngIf="cotizacion.dirDomicilio">
      <p>Dirección de Entrega</p>
      <i><small> {{cotizacion.dirDomicilio}}</small></i>
    </div>

    <div class="grid" *ngIf="cotizacion.dirRecoleccion">
      <p>Dirección de Recolección</p>
      <i><small> {{cotizacion.dirRecoleccion}}</small></i>
    </div>
    <hr>
    
    <div class="desk">
      <div class="grid">
        <p>Tipo</p>
        <p>Cantidad</p>
        <p>Dimensiones</p>
      </div>
      <ng-container *ngFor="let valor = index let i of cotizacion.dsg">

        <div class="grid">
          <p>{{i.tipo}}</p>
          <p>{{i.cant}}</p>
          <p>{{i.largo}} Cm. x {{i.ancho}} Cm. x {{i.alto}} Cm. {{i.peso}} Kg.</p>
        </div>

      </ng-container>
    </div>
    <div class="mobile">
      <ng-container *ngFor="let valor = index let i of cotizacion.dsg">

        <div class="grid">
          <p>Tipo: {{i.tipo}}</p>
          <p>Cantidad: {{i.cant}}</p>
          <p>dimensiones: {{i.largo}} Cm. x {{i.ancho}} Cm. x {{i.alto}} Cm. {{i.peso}} Kg.</p>
        </div>

      </ng-container>

    </div>

    <div class="grid">
      <strong>Costo base <mark> {{cotizacion.costoBase | currency }}</mark></strong>
    </div>
    <hr>
    <div class="grid">
      <p>Servicio por cobrar</p>
      <strong> {{cotizacion.cobrar | currency}}</strong>
      <i><small> Al momento en que su destinatario reciba su paquetería efectuará el pago del mismo. En este servicio el
          cobro se transfiere a su consignatario.</small></i>
    </div>
    <div class="grid">
      <p>Seguro especial</p>
      <strong> {{cotizacion.seguro | currency}}</strong>
      <i><small>Para brindar una protección adicional a su mercancía solo debe cubrir el 3% del valor declarado. Este
          servicio cubre por montos de hasta $10,000.</small></i>
    </div>
    <div class="grid">
      <p>Entrega a domicilio</p>
      <strong> {{cotizacion.domicilio | currency}}</strong>
      <i><small>En las manos de su destinatario entregamos su envío en la dirección que el cliente indique (dentro de la
          cobertura de entrega Tufesa).</small></i>
    </div>
    <div class="grid">
      <p>Recolección</p>
      <strong> {{cotizacion.recoleccion | currency}}</strong>
      <i><small>Pasamos por su paquetería en la dirección que usted indique si no puede venir a nuestras oficinas.
          (dentro de la cobertura de recolección Tufesa).</small></i>
    </div>
    <div class="grid">
      <p>Embalaje</p>
      <strong> {{cotizacion.embalaje | currency}}</strong>
      <i><small>Contamos con cajas y sobres especiales para su mercancía en caso de no tener a la mano.</small></i>
    </div>
    <div class="grid">
      <p>Acuse de recibido</p>
      <strong>{{cotizacion.acuse | currency}}</strong>
      <i><small>El remitente puede tener la información de quien recibe su paquete.</small></i>
    </div>
    <hr>
    <div class="grid">
      <strong> Total <mark>{{total | currency }}</mark></strong>
    </div>
  </div>

  <button class="btnBack" (click)="backCotizacion()">Regresar</button>

  <!-- formulario más información -->
  <div class="solicita_info" (click)="openCloseInfo()">
    <a target=_self>
      <button class="solinfo">Solicitar más información</button>
    </a>
  </div>


</section>

<!-- formulario más información modal -->
<div id="modal_cotiza2" [ngClass]="{'isInactive': isClassActive}"> 
  <div class="formContainer">
    <span (click)="openCloseInfo()" class="closeCotiza">x</span>
    <p>Recibe mas información de tu cotización, solo proporciona los siguientes datos</p>
    <div>
      <input type="text" [(ngModel)]="inputnombre" placeholder="Nombre" maxlength="100">
      <input type="text" [(ngModel)]="inputapellido" placeholder="Apellido" maxlength="100">
      <input type="email" [(ngModel)]="inputcorreo" placeholder="Correo electrónico" maxlength="350">
      <input type="tel" [(ngModel)]="inputtelefono" placeholder="Teléfono" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" maxlength="10">
     
      <button class="btnsolinfo" (click)="solicitarMasInfo()">Solicitar</button>
    </div> 
  </div>
  </div>
  <!-- termina formulario mas info -->
