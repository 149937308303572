<div class="container_herramienta">
  <img src="assets/iconos/info-util/icon_politicasRec.webp" class="img_toAnimate">
  <div class="alinearIzq">
    <h1 class="txt-section">Políticas de recepción y/o envío</h1>
    <p>Envíos Restringidos</p>
    <ol>
      <li>Perecederos.</li>
      <li>Medicamento controlado.</li>
      <li>Líquidos.</li>
      <li>Armas de fuego o deporte.</li>
      <li>Drogas.</li>
      <li>Bebidas etílicas.</li>
      <li>Animales.</li>
      <li>Dinero.</li>
      <li>Documentación fácilmente negociable.</li>
      <li>Joyería.</li>
      <li>Órganos.</li>
      <li>Hieleras.</li>
      <li>Contenedores no sellados de fábrica.</li>
      <li>Solventes.</li>
      <li>Material biológico.</li>
      <li>Productos inflamables.</li>
      <li>Pesticidas.</li>
      <li>Explosivos.</li>
      <li>Envases o tanques comprimidos.</li>
      <li>Embalajes en caja de huevo.</li>
      <li>Baterías de carro.</li>
      <li>Refacciones que contengan combustible o lubricante.</li>
      <li>Atados.</li>
    </ol>
  </div>
</div>
