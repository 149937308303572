import { Injectable } from '@angular/core';
import {
    HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';
import { LoadingService } from 'src/app/servicios/loading.service';
import { finalize } from 'rxjs/operators';

@Injectable()
export class httpInterceptor implements HttpInterceptor {
    constructor(private loadingService: LoadingService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        this.loadingService.onStarted(req);

        const token = localStorage.getItem('token') || sessionStorage.getItem('token');
        if (token) {
          req = req.clone({
            setHeaders: {
              'Authorization': 'Bearer ' + token
            }
          });
       }

        return next
        .handle(req)
        .pipe(
            finalize(
                () => this.loadingService.onFinished(req)
            )
        );

  }


}
